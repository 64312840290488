import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { REGEXS } from 'src/app/components/shared/constant/regex.constant';
import { TOOLTIPS } from 'src/app/components/shared/constant/tooltips-enum.constant';
import { FORM_TEMPLATE_FIELD_INPUT_TYPE } from 'src/app/model/form-template-field.model';
import { InstitutionModel } from 'src/app/model/institution.model';
import { GeneralService } from 'src/app/services/general.service';
import { InstitutionService } from 'src/app/services/institution.service';
import { InstitutionDialogDetailComponent } from '../institution-dialog-detail/institution-dialog-detail.component';
import { ProjectModel } from 'src/app/model/project.model';
import { ProjectService } from 'src/app/services/project.service';
import { FormTypeConfigurationModel } from 'src/app/model/form-type-configuration.model';
import { FormTypeConfigurationService } from 'src/app/services/form-type-configuration.service';
import {HttpResponse} from "@angular/common/http";
import {ReportDetailService} from "../../../../services/report-detail.service";
import { SystemConfigurationService } from 'src/app/services/system-configuration.service';
import { SystemConfigModel } from 'src/app/model/system-config.model';
import { RioTemplateService } from 'src/app/services/rio-template.service';
import { RioTemplate } from 'src/app/model/rio-template.model';
import {RioReportDetailService} from "../../../../services/rio-report-detail.service";
@Component({
    selector: 'app-institution-dialog-new-or-edit',
    templateUrl: './institution-dialog-new-or-edit.component.html',
})
export class InstitutionDialogNewOrEditComponent implements OnInit {
    dialogType: string = '';
    isSaving: boolean = false;
    institutionFormGroup: FormGroup;
    parentInstitutions: InstitutionModel[] = [];
    tiers: { id: string; name: string; value: number }[] = [
        {
            id: '1',
            name: 'Function',
            value: 1,
        },
        {
            id: '2',
            name: 'Unit',
            value: 2,
        },
        {
            id: '3',
            name: 'Team',
            value: 3,
        },
    ];
    emailContent: string = TOOLTIPS.emailContent;
    phoneContent: string = TOOLTIPS.phoneContent;
    loading: boolean = false;

    text = FORM_TEMPLATE_FIELD_INPUT_TYPE.TEXT;
    checkbox = FORM_TEMPLATE_FIELD_INPUT_TYPE.CHECKBOX;
    select = FORM_TEMPLATE_FIELD_INPUT_TYPE.SELECT;
    isLoading = FORM_TEMPLATE_FIELD_INPUT_TYPE.LOADING;
    multiSelect = FORM_TEMPLATE_FIELD_INPUT_TYPE.MULTISELECT;
    projectList: ProjectModel[];
    formTypeConfigurationList: FormTypeConfigurationModel[];
    warningMsgInactiveShown: boolean = false;
    isRenderedSubmissionGroup: boolean = false;
    submissionGroupList: SystemConfigModel[] = []
    rioTemplatesList: RioTemplate[];

    groupInstitution: InstitutionModel;

    constructor(
        @Inject(MAT_DIALOG_DATA) public institutionModel: InstitutionModel,
        public dialogRef: MatDialogRef<InstitutionDialogNewOrEditComponent>,
        private institutionService: InstitutionService,
        private reportDetailService:ReportDetailService,
        private rioReportDetailService:RioReportDetailService,
        private formBuilder: FormBuilder,
        private generalService: GeneralService,
        private matDialog: MatDialog,
        private projectService: ProjectService,
        private formTypeConfigurationService: FormTypeConfigurationService,
        private systemConfigurationService: SystemConfigurationService,
        private rioTemplateService: RioTemplateService
    ) {}

    ngOnInit(): void {
        if (this.institutionModel) {
            this.dialogType = 'update';
            this.institutionFormGroup = this.formBuilder.group({
                id: this.formBuilder.control(this.institutionModel.id),
                name: this.formBuilder.control(this.institutionModel.name, Validators.required),
                shortName: this.formBuilder.control(this.institutionModel.shortName),
                tier: this.formBuilder.control(this.tiers.find((t) => t.value === this.institutionModel.tier), Validators.required),
                parentInstitution: this.formBuilder.control(this.institutionModel.parentInstitution),
                businessRegNo: this.formBuilder.control(this.institutionModel.businessRegNo),
                industry: this.formBuilder.control(this.institutionModel.industry),
                address: this.formBuilder.control(this.institutionModel.address),
                phone: this.formBuilder.control(this.institutionModel.phone, Validators.pattern(REGEXS.PHONE_FILTER)),
                fax: this.formBuilder.control(this.institutionModel.fax, Validators.pattern(REGEXS.PHONE_FILTER)),
                email: this.formBuilder.control(this.institutionModel.email, Validators.email),
                website: this.formBuilder.control(this.institutionModel.website),
                createdBy: this.formBuilder.control(this.institutionModel.createdBy),
                createdDate: this.formBuilder.control(this.institutionModel.createdDate),
                lastModifiedBy: this.formBuilder.control(this.institutionModel.lastModifiedBy),
                lastModifiedDate: this.formBuilder.control(this.institutionModel.lastModifiedDate),
                active: this.formBuilder.control(this.institutionModel.active),
                reportRequired: this.formBuilder.control(this.institutionModel.reportRequired),
                projects: this.formBuilder.control(this.institutionModel.projects),
                formTypeConfigurations: this.formBuilder.control(this.institutionModel.formTypeConfigurations.slice().sort((a, b) => a.code.localeCompare(b.code))),
                isCBS: this.formBuilder.control(this.institutionModel.isCBS),
                isRio: this.formBuilder.control(this.institutionModel.isRio),
                systemConfigurationId: this.formBuilder.control(this.institutionModel.systemConfigurationId),
                rioTemplates: this.formBuilder.control(this.institutionModel.rioTemplates),
            });
            this.institutionService.getParentEntityByTier(this.institutionModel.tier).subscribe({
                next: (value) => {
                    this.parentInstitutions = value.body;
                    this.parentInstitutions = this.parentInstitutions.sort((a, b) => a.name.localeCompare(b.name));
                    this.deactiveLoading();
                },
            });
            if (this.institutionModel.isRio) {
                this.getAllSubmissionGroup(this.institutionModel.systemConfigurationId)
                this.getAllRioTemplate()
            }
        } else {
            this.dialogType = 'create';
            this.institutionFormGroup = this.formBuilder.group({
                name: this.formBuilder.control(null, Validators.required),
                active: this.formBuilder.control(false),
                shortName: this.formBuilder.control(null),
                tier: this.formBuilder.control(null, Validators.required),
                parentInstitution: this.formBuilder.control(null),
                businessRegNo: this.formBuilder.control(null),
                industry: this.formBuilder.control(null),
                address: this.formBuilder.control(null),
                phone: this.formBuilder.control(null, Validators.pattern(REGEXS.PHONE_FILTER)),
                fax: this.formBuilder.control(null, Validators.pattern(REGEXS.PHONE_FILTER)),
                email: this.formBuilder.control(null, Validators.email),
                website: this.formBuilder.control(null),
                createdBy: this.formBuilder.control(null),
                createdDate: this.formBuilder.control(null),
                reportRequired: this.formBuilder.control(false),
                projects: this.formBuilder.control(null),
                formTypeConfigurations: this.formBuilder.control(null),
                isCBS: this.formBuilder.control(false),
                isRio: this.formBuilder.control(false),
                systemConfigurationId: this.formBuilder.control(''),
                rioTemplates: this.formBuilder.control(null)
            });
        }

        // this.institutionService.getAll().subscribe({
        //     next: (value) => {
        //         this.parentInstitutions = value.body;
        //         this.deactiveLoading();
        //     },
        // });

        this.projectService.getAll().subscribe((res) => {
            this.projectList = res.body;
        });

        this.formTypeConfigurationService.getAll().subscribe((res) => {
            this.formTypeConfigurationList = res.body;
            this.formTypeConfigurationList = this.formTypeConfigurationList.sort((a, b) => a.code.localeCompare(b.code));
        });

        this.setGroupInstitution();
    }

    get isRio() {
        return this.institutionFormGroup.get('isRio')
    }

    get reportRequired() {
        return this.institutionFormGroup.get('reportRequired')
    }

    get systemConfigurationId() {
        return this.institutionFormGroup.get('systemConfigurationId')
    }

    get isCBS() {
        return this.institutionFormGroup.get('isCBS')
    }

    save(): void {
        this.isSaving = true;
        this.activeLoading();
        const entityFormValue = this.institutionFormGroup.getRawValue();
        entityFormValue.tier = entityFormValue.tier.value; // Modify tier to get value only

        if(entityFormValue.tier ===1){
            entityFormValue.parentInstitution = this.groupInstitution;
        }

        if(entityFormValue.systemConfigurationId){
            entityFormValue.systemConfigurationId = entityFormValue.systemConfigurationId.id
        }

        if (this.dialogType === 'create') {
            this.institutionService.save(entityFormValue).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: (error) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.generalService.snackbarMessage(error.message, 'Close');
                },
            });
        } else {
            this.institutionService.update(entityFormValue).subscribe({
                next: (value) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                    this.openInstitutionDialogDetail(value.body);
                },
                error: (error) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.generalService.snackbarMessage(error.message, 'Close');
                },
            });
        }
    }

    activeLoading() {
        this.loading = true;
    }

    deactiveLoading() {
        this.loading = false;
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

    tierSelectionChange() {
        this.institutionFormGroup.get('parentInstitution').setValue(null);
        this.institutionService.getParentEntityByTier(this.institutionFormGroup.get('tier').value.value).subscribe({
            next: (value) => {
                this.parentInstitutions = value.body;
                this.parentInstitutions = this.parentInstitutions.sort((a, b) => a.name.localeCompare(b.name));
                this.parentInstitutions = this.parentInstitutions.filter(institution => institution.name !== this.institutionModel.name);
                this.deactiveLoading();
            },
        });
    }

    openInstitutionDialogCreateOrEdit(institutionModel?: InstitutionModel) {
        const modalRef = this.matDialog.open(InstitutionDialogNewOrEditComponent, {
            data: institutionModel,
            panelClass: 'mat-dialog-lg',
        });

        modalRef.afterClosed().subscribe();
    }

    openInstitutionDialogDetail(institutionModel?: InstitutionModel) {
        this.institutionService.getInstitutionById(institutionModel.id).subscribe({
            next: (value) => {
                const modalRef = this.matDialog.open(InstitutionDialogDetailComponent, {
                    data: value.body,
                    panelClass: 'mat-dialog-md',
                });

                modalRef.afterClosed().subscribe({
                    next: (edit) => {
                        if (edit) this.openInstitutionDialogCreateOrEdit(institutionModel);
                    },
                });
            },
        });
    }

    onToppingRemovedFormTypeConfigurations(topping: string, formControlName: string) {
        const toppings = this.institutionFormGroup.get(formControlName).value as string[];
        this.removeFirst(toppings, topping);
        this.institutionFormGroup.get(formControlName).setValue(toppings); // To trigger change detection
    }

    private removeFirst<T>(array: T[], toRemove: T): void {
        const index = array.indexOf(toRemove);
        if (index !== -1) {
            array.splice(index, 1);
        }
    }

    changeActive(): void {
      if (this.institutionFormGroup?.value?.active && !this.warningMsgInactiveShown) {
        this.warningMsgInactiveShown = true;
        this.generalService.snackbarMessage('Inactive will cause users linked to this entity, to be unable to login', 'Close');
      }
    }

    setGroupInstitution(): void {
        this.institutionService.getGroupInstitution().subscribe(
            (response: HttpResponse<InstitutionModel>) => {
                this.groupInstitution = response.body;
            },
            (error) => {
                console.error('Error fetching group institution:', error);
            }
        );
    }

    changeIsRIo(): void {
        if (!this.isRio.value) {
            if (!this.isRenderedSubmissionGroup) {
                this.getAllSubmissionGroup()
                this.getAllRioTemplate()
                this.isRenderedSubmissionGroup = true
            }
        }
    }

    changeReportRequired(): void {
        if (this.reportRequired.value) {
            this.institutionFormGroup.patchValue({
                isCBS: false,
                isRio: false,
                formTypeConfigurations: null,
                systemConfigurationId: '',
                rioTemplates: null
            })
        }
    }

    getAllSubmissionGroup(setValueId: string = ''): void {
        this.systemConfigurationService.getAllSystemConfigRioSubmission().subscribe((sysConfig: HttpResponse<SystemConfigModel[]>) => {
            this.submissionGroupList = sysConfig.body

            if (setValueId) {
                const submissionGroup = this.submissionGroupList.find((sub: SystemConfigModel) => sub.id === setValueId)
                this.systemConfigurationId.setValue(submissionGroup)
            }
        })
    }

    getAllRioTemplate(): void {
        this.rioTemplateService.getAllRioTemplate().subscribe({
            next: (response: HttpResponse<RioTemplate[]>) => {
                this.rioTemplatesList = response.body
            }
        })
    }
}
