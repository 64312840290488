import { Injectable } from '@angular/core';
import { FileHandle } from './directive/dragDrop.directive';
import { GeneralService } from 'src/app/services/general.service';

@Injectable({
    providedIn: 'root',
})
export class SharedMethod {

	constructor(
        public generalService: GeneralService,
    ) {}
	
    truncateString(str: string): string {
        if (str !== null || undefined) {
            if (str.length <= 15) {
                return str;
            }
            const truncated = str.slice(0, 15);
            return `${truncated}...`;
        } else {
            return '-';
        }
    }

    formatBytes(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes'
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB',];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    compareFile(files: FileHandle[]){
        let fileType = files[0].file.type;
        switch(fileType) {
            case "image/png" :
                return files;
            
            case "image/jpg" :
                return files;

            case "image/jpeg" :
                return files;

            default: 
            this.generalService.snackbarMessage(`File only accept JPG, JPEG, PNG format.`, 'Close');
        }
    }

    passImageUrlForDownload(url:any){
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = 'image';
        a.click();
        this.generalService.snackbarMessage(`File Downloaded: image`, 'Close');
    }
}