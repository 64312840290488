import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FORM_TEMPLATE_FIELD_INPUT_TYPE} from 'src/app/model/form-template-field.model';
import {GeneralService} from 'src/app/services/general.service';
import {ReportUploadModel} from 'src/app/model/report-upload.model';
import {ReportDetailUploadModel} from 'src/app/model/report-detail-upload.model';
import {AuthenticateService} from '../../../../auth/authenticate.service';
import {convertToBase64} from '../../../shared/util';
import {RioReportDetailService} from "../../../../services/rio-report-detail.service";
import {DialogSimpleComponent} from "../../../shared/ui/dialog-simple/dialog-simple.component";

@Component({
    selector: 'app-report-upload-rio-dialog-upload',
    templateUrl: './report-upload-rio-dialog-upload.component.html',
})
export class ReportUploadRioDialogUploadComponent implements OnInit {

    isSaving: boolean = false;
    loading: boolean = false;
    reportUploadFormGroup: FormGroup;
    attachment: any;
    attachmentBase64: string;
    attachmentGroupId: string;
    reportStatus: string;

    text = FORM_TEMPLATE_FIELD_INPUT_TYPE.TEXT;
    uploadFile = FORM_TEMPLATE_FIELD_INPUT_TYPE.UPLOAD_FILE;

    constructor(
        @Inject(MAT_DIALOG_DATA) public reportUploadModel: ReportUploadModel,
        public dialogRef: MatDialogRef<ReportUploadRioDialogUploadComponent>,
        private formBuilder: FormBuilder,
        private generalService: GeneralService,
        private rioReportDetailService: RioReportDetailService,
        private authService: AuthenticateService,
        private matDialog: MatDialog,
    ) {
    }

    ngOnInit(): void {
        this.reportUploadActiveLoading();
        this.getReportDetails(this.reportUploadModel.reportDetailId);
        if (this.reportUploadModel) {
            this.reportUploadFormGroup = this.formBuilder.group({
                reportingYear: this.formBuilder.control({
                    value: this.reportUploadModel.reportingYear,
                    disabled: true
                }, Validators.required),
                reportingMonth: this.formBuilder.control({
                    value: this.reportUploadModel.reportingMonth,
                    disabled: true
                }, Validators.required),
                projectName: this.formBuilder.control({value: this.reportUploadModel.projectName || "-", disabled: true}),
                companyName: this.formBuilder.control({value: this.reportUploadModel.companyName, disabled: true}),
                function: this.formBuilder.control({value: this.reportUploadModel.function, disabled: true}),
                unit: this.formBuilder.control({
                    value: this.reportUploadModel.unit || "-",
                    disabled: true
                }, Validators.required),
                team: this.formBuilder.control({
                    value: this.reportUploadModel.team || "-",
                    disabled: true
                }, Validators.required),
                attachment: this.formBuilder.control(this.attachment, Validators.required),

                institutionId: this.formBuilder.control(this.authService.getCurInsId()),
                id: this.formBuilder.control(this.reportUploadModel.reportDetailId),
                projectId: this.formBuilder.control(this.reportUploadModel.projectId),
                submittedBy: this.formBuilder.control({value: this.reportUploadModel.submittedBy || "-", disabled: true}),
                submittedFilename: this.formBuilder.control({
                    value: this.reportUploadModel.submittedFilename || "-",
                    disabled: true
                })
            });
            this.reportStatus = this.reportUploadModel.status;
        }
        this.reportUploadDeactiveLoading();
        console.log(this.reportUploadFormGroup); // Log the FormGroup
    }

    getReportDetails(reportId: string) {
        this.rioReportDetailService.getReportDetails(reportId).subscribe({
            next: (reportDetails) => {
                console.log(reportDetails.body.attachmentGroupId);
                this.attachmentGroupId = reportDetails.body.attachmentGroupId;
            }
        })
    }

    reportUploadActiveLoading() {
        this.loading = true;
    }

    reportUploadDeactiveLoading() {
        this.loading = false;
    }

    save(): void {
        this.isSaving = true;
        this.reportUploadActiveLoading();

        const bodyRequest: ReportDetailUploadModel = {
            id: this.reportUploadFormGroup.controls['id'].value,
            filename: this.extractFileName(this.reportUploadFormGroup.controls['attachment'].value),
            blobFile: this.attachmentBase64
        };

        const saveCallback = () => {
            this.rioReportDetailService.save(this.reportUploadFormGroup.controls['id'].value, bodyRequest).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.reportUploadDeactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: error => {
                    this.isSaving = false;
                    this.reportUploadDeactiveLoading();
                    this.generalService.snackbarMessage(error.message, 'Close');
                }
            });
        };

        if (this.reportStatus === 'EXTRACTED') {
            const modalRef = this.matDialog.open(DialogSimpleComponent, {
                data: {
                    title: 'Confirm Overwrite ',
                    message: `Are you sure want to overwrite this report?`,
                    buttonPositive: 'Yes',
                    buttonPositiveColor: 'warn',
                    buttonNegative: 'No',
                },
                panelClass: 'mat-dialog-sm',
                id: 'overwrite-report',
            });

            modalRef.afterClosed().subscribe({
                next: (yes) => {
                    if (yes) {
                        saveCallback();
                    } else {
                        this.isSaving = false;
                        this.reportUploadDeactiveLoading();
                    }
                },
            });
        } else {
            saveCallback();
        }
    }

    extractFileName(filePath: string): string {
        const parts = filePath.split("\\");
        return parts[parts.length - 1];
    }

    async onFileSelected(event: any) {
        const fileInput = event.target;

        if (fileInput.files && fileInput.files.length > 0) {
            if (fileInput.files[0]) {
                try {
                    this.attachmentBase64 = await convertToBase64(fileInput.files[0]);
                } catch (error) {
                    console.error('Error converting to Base64:', error);
                }
            }
        }
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

}
