import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InstitutionModel, InstitutionUploadListingModel } from '../model/institution.model';
import { Observable } from 'rxjs';
import { AuthenticateService } from '../auth/authenticate.service';
import { ACCOUNT_COMPONENT } from '../app.constant';

@Injectable({
    providedIn: 'root',
})
export class InstitutionService {
    private institutionApi: string = ACCOUNT_COMPONENT + '/admin/institution';
    private institutionApiNonConfiguration: string = ACCOUNT_COMPONENT + '/institution';

    constructor(private httpClient: HttpClient, private authService: AuthenticateService) {}

    save(model: InstitutionModel): Observable<HttpResponse<InstitutionModel>> {
        return this.httpClient.post<InstitutionModel>(this.institutionApi, model, {
            observe: 'response',
        });
    }

    update(model: InstitutionModel): Observable<HttpResponse<InstitutionModel>> {
        return this.httpClient.put<InstitutionModel>(this.institutionApi, model, {
            observe: 'response',
        });
    }

    getAllInstitutions(
        pageNumber: number,
        pageSize: number,
        sortOrder: string,
        criteria
    ): Observable<any> {
        let httpParams = new HttpParams();
        if (pageNumber) {
            httpParams = httpParams.set('page', `${pageNumber}`);
        }
        if (pageSize) {
            httpParams = httpParams.set('size', `${pageSize}`);
        }
        if (sortOrder) {
            httpParams = httpParams.set('sort', sortOrder);
        }

        if (criteria) {
            if (criteria.name) {
                httpParams = httpParams.set('name', criteria.name);
            }
            if (criteria.id) {
                httpParams = httpParams.set('id', criteria.id);
            }
            if (criteria.tier) {
                httpParams = httpParams.set('tier', criteria.tier);
            }
        }
        return this.httpClient.get(`${this.institutionApi}`, {
            observe: 'response',
            params: httpParams,
        });
    }

    getAll(): Observable<HttpResponse<InstitutionModel[]>> {
        const params = new HttpParams().set('id', this.authService.getCurInsId());
        return this.httpClient.get<InstitutionModel[]>(this.institutionApi + '/no-pagination', {
            observe: 'response',
            params,
        });
    }


    getInstitutionById(id: string): Observable<HttpResponse<InstitutionModel>> {
        return this.httpClient.get<InstitutionModel>(this.institutionApi + "/" + id, {
            observe: 'response'
        });
    }

    getInsByParentId(id: number): Observable<HttpResponse<InstitutionModel[]>> {
        const params = new HttpParams().set('id', id);
        return this.httpClient.get<InstitutionModel[]>(this.institutionApi + `/parent`, {
            observe: 'response',
            params,
        });
    }

    getInstitutionUploadListByParentId(): Observable<HttpResponse<InstitutionUploadListingModel[]>> {
        return this.httpClient.get<InstitutionUploadListingModel[]>(this.institutionApiNonConfiguration + '/upload-list/' + this.authService.getCurInsId(), {
            observe: 'response'
        });
    }

    getByActiveTrueAndTierMoreThanEqual(tier: number): Observable<HttpResponse<InstitutionModel[]>> {
        return this.httpClient.get<InstitutionModel[]>(this.institutionApi + `/tier/more-than-equal/` + tier, {
            observe: 'response'
        });
    }

    getParentEntityByTier(tier: number) {
        return this.httpClient.get<InstitutionModel[]>(this.institutionApi + `/parent-by-tier/` + tier, {
            observe: 'response'
        });
    }

    getGroupInstitution() {
        return this.httpClient.get<InstitutionModel>(this.institutionApi + `/tier/0`, {
            observe: 'response'
        });
    }
}
