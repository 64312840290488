import { MxMultiSelectComponent } from './ui/mx-input-field/mx-multi-select/mx-multi-select.component';
import { MxPhoneFieldComponent } from './ui/mx-input-field/mx-phone-field/mx-phone-field.component';
import { MxConfirmPasswordComponent } from './ui/mx-input-field/mx-confirm-password/mx-confirm-password.component';
import { MxPasswordComponent } from './ui/mx-input-field/mx-password/mx-password.component';
import { MxSelectComponent } from './ui/mx-input-field/mx-select/mx-select.component';
import { MxTextFieldComponent } from './ui/mx-input-field/mx-text-field/mx-text-field.component';
import { MxInputFieldComponent } from './ui/mx-input-field/mx-input-field.component';
import { MxSearchBarComponent } from './ui/mx-search-bar/mx-search-bar.component';
import { MxAttachmentComponent } from './ui/mx-input-field/mx-attachment/mx-attachment.component';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DialogSimpleComponent } from './ui/dialog-simple/dialog-simple.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MaterialModule } from './material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutofocusDirective } from './directive/autofocus.directive';
import { ScreenPermissionDirective } from './directive/screen-permission.directive';
import { MxTitleBarComponent } from './ui/mx-title-bar/mx-title-bar.component';
import { MxTitleWithoutButtonBarComponent } from './ui/mx-title-without-button-bar/mx-title-without-button-bar.component';
import { MxCheckboxComponent } from './ui/mx-input-field/mx-checkbox/mx-checkbox.component';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input-angular-13';
import { MxDataLoadingComponent } from './ui/mx-data-loading/mx-data-loading.component';
import { MxPasswordStrengthComponent } from './ui/mx-password-strength/mx-password-strength.component';
import { DragDirective } from './directive/dragDrop.directive';
import { NgxColorsModule } from 'ngx-colors';
import { YearlyDatePickerComponent } from './multiDatePicker/yearly-date-picker/yearly-date-picker.component';
import { BasicDatePickerComponent } from './multiDatePicker/basic-date-picker/basic-date-picker.component';
import { WeeklyDatePickerComponent } from './multiDatePicker/weekly-date-picker/weekly-date-picker.component';
import { MultiDatePickerComponent } from './multiDatePicker/multi-date-picker.component';
import { MultiDatePickerModule } from './multiDatePicker/multi-date-picker.module';
@NgModule({
    declarations: [
        AutofocusDirective,
        ScreenPermissionDirective,
        DialogSimpleComponent,
        MxTitleBarComponent,
        MxTitleWithoutButtonBarComponent,
        MxSearchBarComponent,
        MxInputFieldComponent,
        MxTextFieldComponent,
        MxCheckboxComponent,
        MxSelectComponent,
        MxPasswordComponent,
        MxConfirmPasswordComponent,
        MxPhoneFieldComponent,
        MxMultiSelectComponent,
        MxDataLoadingComponent,
        MxDataLoadingComponent,
        MxPasswordStrengthComponent,
        DragDirective,
        MxAttachmentComponent,
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatIconModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMatIntlTelInputModule,
        NgxColorsModule,
        MultiDatePickerModule
    ],
    exports: [
        AutofocusDirective,
        ScreenPermissionDirective,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        DatePipe,
        MxTitleBarComponent,
        MxTitleWithoutButtonBarComponent,
        MxSearchBarComponent,
        MxInputFieldComponent,
        MxTextFieldComponent,
        MxCheckboxComponent,
        MxSelectComponent,
        MxPasswordComponent,
        MxConfirmPasswordComponent,
        MxPhoneFieldComponent,
        MxMultiSelectComponent,
        MxDataLoadingComponent,
        MxPasswordStrengthComponent,
        DragDirective,
        MxAttachmentComponent,
    ],
    providers: [DatePipe],
})
export class SharedModule {}
