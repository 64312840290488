import { Component, Input} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'mx-checkbox',
    templateUrl: './mx-checkbox.component.html',
})
export class MxCheckboxComponent {
    @Input() public formGroupChild: FormGroup;
    @Input() public formControlNameChild: string;
    @Input() public label: String;

}
