import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RioTemplate } from 'src/app/model/rio-template.model';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';

@Component({
    selector: 'app-rio-template-types-dialog-detail',
    templateUrl: './rio-template-types-dialog-detail.component.html',
})
export class RioTemplateTypesDialogDetailComponent {
    readonly RIO_TEMPLATE_UPDATE = ApplicationPermissionService.RIO_TEMPLATE_UPDATE;

    constructor(
        @Inject(MAT_DIALOG_DATA) public rioTemplate: RioTemplate,
        public dialogRef: MatDialogRef<RioTemplateTypesDialogDetailComponent>
    ) { }
}
