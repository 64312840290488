import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';
import { BehaviorSubject } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { FORM_TEMPLATE_FIELD_INPUT_TYPE } from 'src/app/model/form-template-field.model';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { GeneralService } from 'src/app/services/general.service';
import { DialogSimpleComponent } from 'src/app/components/shared/ui/dialog-simple/dialog-simple.component';
import { EmploymentTypeService } from 'src/app/services/employment-type.service';
import { EmploymentDialogNewOrEditComponent } from '../employment-dialog-new-or-edit/employment-dialog-new-or-edit.component';
import { EmploymentDialogDetailComponent } from '../employment-dialog-detail/employment-dialog-detail.component';
import { EmploymentTypeModel } from 'src/app/model/employment.model';

@Component({
  selector: 'app-employment-type-listing',
  templateUrl: './employment-type-listing.component.html',
})
export class EmploymentTypeListingComponent implements OnInit {

    readonly EMPLOYMENT_TYPE_CREATE = ApplicationPermissionService.EMPLOYMENT_TYPE_CREATE;
    readonly EMPLOYMENT_TYPE_DELETE = ApplicationPermissionService.EMPLOYMENT_TYPE_DELETE;
    readonly EMPLOYMENT_TYPE_UPDATE = ApplicationPermissionService.EMPLOYMENT_TYPE_UPDATE;
    readonly EMPLOYMENT_TYPE_READ = ApplicationPermissionService.EMPLOYMENT_TYPE_READ;

    employmentTypeDataSource = new MatTableDataSource<any>();
    obs: BehaviorSubject<any>;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayColumns = [
        'name',
        'actions'
    ];

    employmentTypeSearchValue: String = null;
    employmentTypePageNumbers: number = 0;
    employmentTypeNumberOfRecords: number = 0;
    employmentTypeCurrentPage:number = 0;
    employmentTypePageSize:number = 50;
    employmentTypeSortOrder = 'name,asc';
    loading : boolean = false;
    isLoading = FORM_TEMPLATE_FIELD_INPUT_TYPE.LOADING;

    constructor(
        public employmentTypeActivatedRoute: ActivatedRoute,
        private matDialog: MatDialog,
        private employmentTypeService: EmploymentTypeService,
        private generalService : GeneralService,
    ) { }

    ngOnInit(): void {
        this.employmentTypeActiveLoading();
        if (this.employmentTypeActivatedRoute.snapshot.queryParams['page']) {
            this.employmentTypeSortOrder = this.employmentTypeActivatedRoute.snapshot.queryParams['sort'] ? this.employmentTypeActivatedRoute.snapshot.queryParams['sort']: undefined;
            this.employmentTypeCurrentPage = this.employmentTypeActivatedRoute.snapshot.queryParams['page'] ? this.employmentTypeActivatedRoute.snapshot.queryParams['page']: undefined;
            this.employmentTypePageSize = this.employmentTypeActivatedRoute.snapshot.queryParams['pageSize'] ? this.employmentTypeActivatedRoute.snapshot.queryParams['pageSize']: undefined;
        }
        this.refreshForm();
    }

    openEmploymentTypeDialogCreateOrEdit(employmentTypeModel? : EmploymentTypeModel){
        if(employmentTypeModel === undefined){
            this.passDataToDialogCreateOrEdit(employmentTypeModel);
        }else{
            this.employmentTypeService.getEmploymentById(employmentTypeModel.id).subscribe({
                next: (value) =>{
                    this.passDataToDialogCreateOrEdit(value.body);
                }
            });
        }
    }

    passDataToDialogCreateOrEdit(event: any){
        const modalRef = this.matDialog.open(EmploymentDialogNewOrEditComponent, {
            data: event,
            panelClass: 'mat-dialog-lg'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
            if (yes) this.search(this.employmentTypeCurrentPage, this.employmentTypeSearchValue);
        }})
    }

    openEmploymentTypeDialogDetail(employmentTypeModel? : EmploymentTypeModel){
        this.employmentTypeService.getEmploymentById(employmentTypeModel.id).subscribe({
            next: (value) =>{
                const modalRef = this.matDialog.open(EmploymentDialogDetailComponent, {
                    data: value.body,
                    panelClass: 'mat-dialog-md'
                })

                modalRef.afterClosed().subscribe({
                    next: (edit) => {
                        if (edit) this.openEmploymentTypeDialogCreateOrEdit(employmentTypeModel);
                    }
                })
            }
        });
    }

    search(currentPage: number, employmentTypeSearchValue: String){
        this.employmentTypeSearchValue = employmentTypeSearchValue;
        this.employmentTypeService
                .getAllEmploymentTypes(this.employmentTypeCurrentPage, this.employmentTypePageSize, this.employmentTypeSortOrder, {
                name: employmentTypeSearchValue,
            })
            .subscribe({
                next: ({ body }) => {
                    this.employmentTypeDataSource.data = body.content;
                    this.employmentTypeNumberOfRecords = body.totalElements;
                    this.employmentTypeDeactiveLoading();
                },
                error: (error) => {
                    this.generalService.snackbarMessage(error.message, 'Close');
                    this.employmentTypeDeactiveLoading();
                },
        });
    }

    refreshForm(){
        this.search(0, null);
    }

    sortPage(event): void {
        this.employmentTypeSortOrder = `${event.active},${event.direction}`;
        this.search(this.employmentTypeCurrentPage, this.employmentTypeSearchValue);
    }

    navigateToPage(event): void {
        this.employmentTypePageSize = event.employmentTypePageSize;
        this.employmentTypeCurrentPage = event.pageIndex;
        this.search(this.employmentTypeCurrentPage, this.employmentTypeSearchValue);
    }

    openEmploymentTypeDialogDelete(employmentTypeModel? : EmploymentTypeModel): void {
        const modalRef = this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: 'Confirm Delete Employment Type ?',
                message: `Are you sure want to delete ${employmentTypeModel.name}?`,
                buttonPositive: 'Yes',
                buttonNegative: 'No'
            },
            panelClass: 'mat-dialog-sm'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) {
                    this.employmentTypeService.delete(employmentTypeModel.id.toString()).subscribe({
                        next: () => {
                            this.search(this.employmentTypeCurrentPage, this.employmentTypeSearchValue);
                        },
                        error: (error) => {
                            this.generalService.snackbarMessage(error.message,'Close');
                        },
                    });
                }
            }
        })
    }

    employmentTypeActiveLoading(){
        this.loading = true;
    }

    employmentTypeDeactiveLoading(){
        this.loading = false;
    }

}
