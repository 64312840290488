import { Component, EventEmitter, Input,Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FORM_TEMPLATE_FIELD_INPUT_TYPE } from 'src/app/model/form-template-field.model';

@Component({
    selector: 'mx-input-field',
    templateUrl: './mx-input-field.component.html',
})
export class MxInputFieldComponent{
    @Input() public inputType: FORM_TEMPLATE_FIELD_INPUT_TYPE;
    @Input() public formGroupChild: FormGroup;
    @Input() public formControlNameChild: string;
    @Input() public label: String;
    @Input() public placeholder: string = '';
    @Input() public minLength: number;
    @Input() public maxLength: number;
    @Input() public showMeter: boolean;

    @Input() public errorMessage: String;
    @Input() public autoFocusChild: boolean = false;
    @Input() public required: boolean = false;
    @Input() public suffixIcon: String = null;
    @Input() public suffixIconTooltip: string = '';
    @Input() public loading: boolean = false;

    @Input() public compareWithChild: (o1: any, o2: any) => boolean;
    @Input() public disabledChild: boolean;
    @Input() public list: any[];
    @Output() public onSelectionChange = new EventEmitter<any>();

    @Input() public passwordFormControlName: string;

    text = FORM_TEMPLATE_FIELD_INPUT_TYPE.TEXT;
    checkbox = FORM_TEMPLATE_FIELD_INPUT_TYPE.CHECKBOX;
    select = FORM_TEMPLATE_FIELD_INPUT_TYPE.SELECT;
    password = FORM_TEMPLATE_FIELD_INPUT_TYPE.PASSWORD;
    confirmPassword = FORM_TEMPLATE_FIELD_INPUT_TYPE.CONFIRMPASSWORD;
    phone = FORM_TEMPLATE_FIELD_INPUT_TYPE.PHONE;
    multiSelect = FORM_TEMPLATE_FIELD_INPUT_TYPE.MULTISELECT;
    isLoading = FORM_TEMPLATE_FIELD_INPUT_TYPE.LOADING;
    uploadFile = FORM_TEMPLATE_FIELD_INPUT_TYPE.UPLOAD_FILE;

    selectionChange($event) {
        this.onSelectionChange.emit($event);
    }
}
