import { Component, Input,  } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'mx-password',
    templateUrl: './mx-password.component.html',
})
export class MxPasswordComponent {
    @Input() public formGroupChild: FormGroup;
    @Input() public formControlNameChild: string;
    @Input() public label: String;
    @Input() public placeholder: string = '';
    @Input() public maxLength: number;
    @Input() public minLength: number;
    @Input() public autoFocusChild: boolean;
    @Input() public errorMessage: String;
    @Input() public required: boolean = false;
    @Input() public showMeter: boolean = false;

    isShowPassword: boolean = false;

    submitted = false;
    working = false;
    complete = false;
    strongPassword = false;

    showPassword() {
        this.isShowPassword = !this.isShowPassword;
    }

    get formGroupValue() {
        return this.formGroupChild.controls;
    }

    onPasswordStrengthChanged(event: boolean) {
        this.strongPassword = event;
    }

    onSubmit() {
        this.submitted = true;

        if (this.formGroupChild.invalid) {
            return;
        }

        this.working = true;
        setTimeout(() => {
            this.formGroupChild.reset();
            this.working = false;
            this.complete = true;
        }, 1000);
    }
}
