<div class="flex justify-between items-center px-6 py-4 border-b border-b-gray-light">
    <div class="text-lg font-bold">View User '{{ userModel.fullName }}'</div>
    <button type="button" data-dismiss="modal" class="text-2xl focus-visible:outline-none" (click)="dialogRef.close()">
        &times;
    </button>
</div>
<div class="max-h-[70vh] overflow-auto p-6 relative whitespace-pre-line">
    <div class="grid grid-cols-2 gap-4">
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Email</label>
            <div class="mb-6 break-all">
                {{ userModel.email || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Status</label>
            <div
                *ngIf="userModel.active"
                class="w-fit border-0 text-center rounded-xl px-2 py-0.5 mb-6 break-all bg-green-light text-green"
            >
                Active
            </div>
            <div
                *ngIf="!userModel.active"
                class="w-fit border-0 text-center rounded-xl px-2 py-0.5 mb-6 break-all bg-gray text-white"
            >
                Inactive
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Full Name</label>
            <div class="mb-6 break-all">
                {{ userModel.fullName || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Phone Number</label>
            <div class="mb-6 break-all">
                {{ userModel.phoneCountry + userModel.phoneNumber || '-' }}
            </div>
        </div>
        <div class="col-span-2">
            <label class="text-sm text-gray font-bold">Authority</label>
            <div class="mb-6 break-all">
                <mat-chip-list>
                    <mat-chip *ngFor="let item of userModel.authorities" [removable]="false">
                        {{ item.name }}
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Country</label>
            <div class="mb-6 break-all">
                {{ userModel.country?.name || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Employment Type</label>
            <div class="mb-6 break-all">{{ userModel.employmentType?.name || '-' }}</div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Reference Number</label>
            <div class="mb-6 break-all">
                {{ userModel.refNo || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Failed Login Attempt</label>
            <div class="mb-6 break-all">
                {{ userModel.loginFailAttempt }}
            </div>
        </div>
    </div>
</div>
<div class="flex justify-end p-6 border-t border-gray-light gap-2">
    <button type="button" mat-raised-button class="m-1" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">cancel</mat-icon>
        <span class="text-sm font-bold">Close</span>
    </button>
    <button type="button" mat-raised-button class="m-1" (click)="dialogRef.close('edit')" color="accent" *appScreenPermission="[UPDATE_APPLICATION_USER]">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">edit</mat-icon>
        <span class="text-sm font-bold">Edit</span>
    </button>
</div>
