import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRolesNewOrEditComponent } from './user-roles-new-or-edit/user-roles-new-or-edit.component';
import { UserRolesDetailComponent } from './user-roles-detail/user-roles-detail.component';
import { UserRolesListingComponent } from './user-roles-listing/user-roles-listing.component';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from 'src/app/directives/directives.module';

const routes: Routes = [
    {
        path: '',
        component: UserRolesListingComponent,
    },
];

@NgModule({
    declarations: [UserRolesNewOrEditComponent, UserRolesDetailComponent, UserRolesListingComponent],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule.forChild(routes),
        DirectivesModule,
        FormsModule,
        ReactiveFormsModule,
    ],
})
export class UserRolesModule {}
