import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlCostCodeModel } from 'src/app/model/gl-cost-code.model';

@Component({
  selector: 'app-gl-cost-code-dialog-detail',
  templateUrl: './gl-cost-code-dialog-detail.component.html',
})
export class GlCostCodeDialogDetailComponent{

    constructor(
        @Inject(MAT_DIALOG_DATA) public glCostCodeModel: GlCostCodeModel,
        public dialogRef: MatDialogRef<GlCostCodeDialogDetailComponent>,
    ) { }
}
