import { Component,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CountryModel } from 'src/app/model/country.model';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';

@Component({
  selector: 'app-country-dialog-detail',
  templateUrl: './country-dialog-detail.component.html',
})
export class CountryDialogDetailComponent{

    readonly COUNTRY_UPDATE = ApplicationPermissionService.COUNTRY_UPDATE;

    constructor(
        @Inject(MAT_DIALOG_DATA) public countryModel: CountryModel,
        public dialogRef: MatDialogRef<CountryDialogDetailComponent>,
    ) { }
}
