<mat-form-field appearance="outline"  class="mr-2">
	<mat-label>Choose a week</mat-label>
	<mat-date-range-input [rangePicker]="picker"[min]="min" 
	[max]="max" >
	  <input matStartDate placeholder="Start date" [formControl]="dateFromFormControl" (click)="_openDatepickerOnClick(picker)" readonly>
	  <input matEndDate placeholder="End date" [formControl]="dateToFormControl" (click)="_openDatepickerOnClick(picker)"   readonly>
	</mat-date-range-input>
	<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
	<mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
