import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'mx-title-bar',
    templateUrl: './mx-title-bar.component.html',
})
export class MxTitleBarComponent {
    @Input() title: string;
    @Input() buttonIcon: string;
    @Input() buttonLabel: string;
    @Input() isShowing: boolean = true;
    @Input() buttonPermission: string[];
    @Output() onButtonClick = new EventEmitter<void>();

    onTitleBarButtonClick() {
        this.onButtonClick.emit();
    }
}
