import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeneralService } from 'src/app/services/general.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FORM_TEMPLATE_FIELD_INPUT_TYPE } from 'src/app/model/form-template-field.model';
import { CountryService } from 'src/app/services/country.service';
import { CountryModel } from 'src/app/model/country.model';
import { CountryDialogDetailComponent } from '../country-dialog-detail/country-dialog-detail.component';

@Component({
  selector: 'app-country-dialog-new-or-edit',
  templateUrl: './country-dialog-new-or-edit.component.html',
})
export class CountryDialogNewOrEditComponent implements OnInit {

    dialogType: string = '';
    isSaving: boolean = false;
    countryFormGroup: FormGroup;
    loading: boolean = false;

    text = FORM_TEMPLATE_FIELD_INPUT_TYPE.TEXT;
    checkbox = FORM_TEMPLATE_FIELD_INPUT_TYPE.CHECKBOX;

    constructor(
        @Inject(MAT_DIALOG_DATA) public countryModel: CountryModel,
        public dialogRef: MatDialogRef<CountryDialogNewOrEditComponent>,
        private countryService: CountryService,
        private formBuilder: FormBuilder,
        private generalService: GeneralService,
        private matDialog: MatDialog
    ) { }

    ngOnInit(): void {
        if (this.countryModel) {
            this.dialogType = 'update';
            this.countryFormGroup = this.formBuilder.group({
                id: this.formBuilder.control(this.countryModel.id),
                name: this.formBuilder.control(this.countryModel.name,Validators.required),
                code: this.formBuilder.control(this.countryModel.code, Validators.required),
                description: this.formBuilder.control(this.countryModel.description),
                active: this.formBuilder.control(this.countryModel.active),
            });
        } else {
            this.dialogType = 'create';
            this.countryFormGroup = this.formBuilder.group({
                name: this.formBuilder.control(null, Validators.required),
                code: this.formBuilder.control(null, Validators.required),
                description: this.formBuilder.control(null),
                active: this.formBuilder.control(false),
            });
        }
    }

    
    activeLoading() {
        this.loading = true;
    }

    deactiveLoading() {
        this.loading = false;
    }

    save() {
        this.isSaving = true;
        this.activeLoading();
        if (this.dialogType === 'create') {
            this.countryService.save(this.countryFormGroup.getRawValue()).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: (error) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.generalService.snackbarMessage(error.message, 'Close');
                },
            });
        } else {
            this.countryService.update(this.countryFormGroup.getRawValue()).subscribe({
                next: (value) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                    this.openCountryDialogDetail(value.body);
                },
                error: (error) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.generalService.snackbarMessage(error.message, 'Close');
                },
            });
        }
    }

    openCountryDialogCreateOrEdit(countryModel?: CountryModel) {
        
        const modalRef = this.matDialog.open(CountryDialogNewOrEditComponent, {
            data: countryModel,
            panelClass: 'mat-dialog-lg',
        });

        modalRef.afterClosed().subscribe();
    }

    openCountryDialogDetail(countryModel?: CountryModel) {
        this.countryService.getCountryById(countryModel.id).subscribe({
            next:(value) => {
                const modalRef = this.matDialog.open(CountryDialogDetailComponent, {
                    data: value.body,
                    panelClass: 'mat-dialog-md',
                });
        
                modalRef.afterClosed().subscribe({
                    next: (edit) => {
                        if (edit) this.openCountryDialogCreateOrEdit(countryModel);
                    },
                });
            }
        })
        
    }

}
