<div class="flex justify-between items-center px-6 py-4 border-b border-b-gray-light">
    <div class="text-lg font-bold">View System Configuration '{{ systemConfigModel.code }}'</div>
    <button type="button" data-dismiss="modal" class="text-2xl" (click)="dialogRef.close()">&times;</button>
</div>
<div class="max-h-[70vh] overflow-auto p-6 relative whitespace-pre-line">
    <div class="grid grid-cols-2 gap-4">
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Code</label>
            <div class="mb-6 break-all">
                {{ systemConfigModel.code || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Status</label>
            <div
                *ngIf="systemConfigModel.active"
                class="w-fit border-0 text-center rounded-xl px-2 py-0.5 mb-6 break-all bg-green-light text-green"
            >
                Active
            </div>
            <div
                *ngIf="!systemConfigModel.active"
                class="w-fit border-0 text-center rounded-xl px-2 py-0.5 mb-6 break-all bg-gray text-white"
            >
                Inactive
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Description</label>
            <div class="mb-6 break-all">
                {{ systemConfigModel.description || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Value</label>
            <div class="mb-6 break-all" maxlength="100">
                {{ systemConfigModel.value || '-' }}
            </div>
        </div>
        
    </div>
</div>
<div class="flex justify-end p-6 border-t border-gray-light gap-2">
    <button type="button" mat-raised-button class="m-1" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">clear</mat-icon>
        <span class="text-sm font-bold">Close</span>
    </button>
    <button type="button" mat-raised-button class="m-1" (click)="dialogRef.close('edit')" color="accent" *appScreenPermission="[SYSTEM_CONFIGURATION_UPDATE]">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">edit</mat-icon>
        <span class="text-sm font-bold">Edit</span>
    </button>
</div>
