<div class="flex justify-between items-center px-6 py-4 border-b border-b-gray-light">
    <div class="text-lg font-bold">
        <ng-container *ngIf="dialogType === 'create'; else elseTemplate"> Create New Authority </ng-container>
        <ng-template #elseTemplate> Edit Authority '{{ applicationRoleModel.name }}' </ng-template>
    </div>
    <button type="button" data-dismiss="modal" class="text-2xl focus:outline-none" (click)="dialogRef.close()">
        &times;
    </button>
</div>
<div *ngIf="loading">
    <mx-data-loading></mx-data-loading>
</div>
<div class="max-h-[70vh] overflow-auto p-6 relative whitespace-pre-line">
    <form [formGroup]="applicationRoleFormGroup">
        <div class="grid grid-cols-2 gap-x-4">
            <mx-input-field 
                class="col-span-2 md:col-span-1" 
                [inputType]="text"
                [formGroupChild]="applicationRoleFormGroup" 
                formControlNameChild="name" 
                label="Name"
                placeholder="Enter name" 
                [maxLength]="50" 
                errorMessage="Name is required" 
                [autoFocusChild]="true"
                [required]="true">
            </mx-input-field>
            <mx-input-field 
                class="col-span-2 md:col-span-1 flex items-center"
                [inputType]="checkbox"
                [formGroupChild]="applicationRoleFormGroup" 
                formControlNameChild="active"
                label="Active">
            </mx-input-field>
            <mx-input-field
                class="col-span-2 md:col-span-1"
                [inputType]="select"
                [formGroupChild]="applicationRoleFormGroup"
                formControlNameChild="institution"
                label="Entity"
                placeholder="Select entity"
                [compareWithChild]="compareObjects"
                [disabledChild]="checkInstitution()"
                [list]="institutionSelectionList"
                [required]="true">
            </mx-input-field>
            <!-- TODO: To refactor as shared component -->
            <div class="col-span-2 md:col-span-2 pt-4">
                <label>Permissions</label>
                <div class="pt-4 flex items-center gap-3">
                    <button mat-raised-button color="primary" class="px-8 py-3 font-semibold rounded hover:z-10 hover:shadow-md"
                        (click)="setRIOPermissions()">
                        <div class="flex flex-row gap-2">
                            <mat-icon class="mt-[6px]">checklist</mat-icon> Set RIO Permissions
                        </div>
                    </button>
                    <button mat-raised-button color="primary" class="px-8 py-3 font-semibold rounded hover:z-10 hover:shadow-md"
                        (click)="setCBSPermissions()">
                        <div class="flex flex-row gap-2">
                            <mat-icon class="mt-[6px]">checklist</mat-icon> Set CBS Permissions
                        </div>
                    </button>
                </div>
                <mat-tree class="bg-transparent" [dataSource]="dataSource" [treeControl]="treeControl">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding="1">
                        <mat-checkbox appApplicationPermissionSelected
                            [applicationRoleFormGroupInput]="applicationRoleFormGroup"
                            [applicationPermissionModelNameInput]="node.code"
                            (click)="updateApplicationRoleApplicationPermissions(node.code)" color="primary">
                            {{ node.code }}
                        </mat-checkbox>
                    </mat-tree-node>

                    <mat-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                            </mat-icon>
                        </button>
                        {{ node.name }}
                    </mat-tree-node>
                </mat-tree>
            </div>
        </div>
    </form>
    <span class="text-primary text-sm">* Required Field For Submission</span>
</div>
<div class="flex justify-end p-6 border-t border-gray-light gap-2" *ngIf="!loading">
    <button type="button" mat-raised-button class="m-1" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">clear</mat-icon>
        <span class="text-sm font-bold">Cancel</span>
    </button>
    <button type="button" mat-raised-button class="m-1" [disabled]="isSaving || applicationRoleFormGroup.invalid"
        (click)="save()" color="accent">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">save</mat-icon>
        <span class="text-sm font-bold">Save</span>
    </button>
</div>