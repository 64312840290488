<div class="mx-10 my-6" *appScreenPermission="[COMPANY_READ]">
    <mx-title-bar
        title="Company"
        buttonIcon="add"
        buttonLabel="Add Company"
        (onButtonClick)="openCompanyDialogCreateOrEdit()"
        [buttonPermission]="[COMPANY_CREATE]"
    ></mx-title-bar>
    <mx-search-bar searchFieldName="Name" (onSubmit)="search(0, $event)"></mx-search-bar>
    <div class="my-2">
        <strong>{{ companyNumberOfRecords }}</strong> result<span *ngIf="companyNumberOfRecords > 1">s</span>
    </div>

    <mat-card-content class="mx-content-table">
        <div *ngIf="loading">
            <mx-data-loading></mx-data-loading>
        </div>
        <table
            mat-table
            matSort
            (matSortChange)="sortPage($event)"
            [dataSource]="companyDataSource"
            *ngIf="companyDataSource.data"
            class="w-full"
            aria-label="table-company"
        >
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let element" [matTooltip]="element.name">
                    {{ element.name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="text-white">Actions</th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon
                        (click)="openCompanyDialogCreateOrEdit(element)"
                        matTooltip="Edit"
                        class="mr-4 cursor-pointer"
                        *appScreenPermission="[COMPANY_UPDATE]"
                        >edit</mat-icon
                    >
                    <mat-icon class="mr-4 cursor-pointer" (click)="openCompanyDialogDelete(element)" matTooltip="Delete"
                        *appScreenPermission="[COMPANY_DELETE]"
                        >delete</mat-icon
                    >
                    <mat-icon
                        class="mr-4 cursor-pointer"
                        (click)="openCompanyDialogDetail(element)"
                        matTooltip="View"
                        >remove_red_eye</mat-icon
                    >
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell text-center" colspan="6">No data match your search "{{ companySearchValue }}"</td>
            </tr>
        </table>
    </mat-card-content>
    <mat-paginator
        (page)="navigateToPage($event)"
        [length]="companyNumberOfRecords"
        [pageSize]="companyPageSize"
        [pageSizeOptions]="[5, 10, 20, 50]"
        [showFirstLastButtons]="true"
        class="mx-paginator"
    >
    </mat-paginator>
</div>
