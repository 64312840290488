<div class="mx-10 my-6" *appScreenPermission="[RIO_TEMPLATE_READ]">
    <mx-title-bar title="RIO Template Types" buttonIcon="add" buttonLabel="Add RIO Template Type"
        (onButtonClick)="openRIOTemplateDialogCreateOrEdit()" [buttonPermission]="[RIO_TEMPLATE_CREATE]"></mx-title-bar>
     <mx-search-bar searchFieldName="Name" (onSubmit)="search(0, $event)"></mx-search-bar>
    <div class="my-2">
        <strong>{{ rioTemplateNumberOfRecords }}</strong> result<span *ngIf="rioTemplateNumberOfRecords > 1">s</span>
    </div>
    <mat-card-content class="mx-content-table">
        <div *ngIf="loading">
            <mx-data-loading></mx-data-loading>
        </div>
        <table mat-table matSort (matSortChange)="sortPage($event)" [dataSource]="rioTemplateDataSource" *ngIf="rioTemplateDataSource.data"
            class="w-full" aria-label="table-expense-type">

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.name || "-" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.description || "-" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="text-white">Actions</th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon class="mr-4 cursor-pointer" (click)="openRIOTemplateDialogCreateOrEdit(element)"
                        matTooltip="Edit" *appScreenPermission="[RIO_TEMPLATE_UPDATE]">edit</mat-icon>
                    <mat-icon class="mr-4 cursor-pointer" (click)="openProjectDialogDelete(element)"
                        matTooltip="Delete" *appScreenPermission="[RIO_TEMPLATE_DELETE]">delete</mat-icon>
                    <mat-icon (click)="openRioTemplateDialogDetail(element)" matTooltip="View" class="mr-4 cursor-pointer">remove_red_eye</mat-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell text-center" colspan="6">No data match your search "{{ rioTemplateSearchValue }}"</td>
            </tr>

        </table>
    </mat-card-content>
    <mat-paginator (page)="navigateToPage($event)" [length]="rioTemplateNumberOfRecords" [pageSize]="rioTemplatePageSize"
        [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" class="mx-paginator">
    </mat-paginator>
</div>
