import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstitutionListingComponent } from './institution-listing/institution-listing.component';
import { InstitutionDialogNewOrEditComponent } from './institution-dialog-new-or-edit/institution-dialog-new-or-edit.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InstitutionDialogDetailComponent } from './institution-dialog-detail/institution-dialog-detail.component';

const routes: Routes = [
    {
        path: '',
        component: InstitutionListingComponent,
        // canActivate: [
        //     AuthenticatedGuard
        // ]
    },
];

@NgModule({
    declarations: [InstitutionListingComponent, InstitutionDialogNewOrEditComponent, InstitutionDialogDetailComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(routes), FormsModule, ReactiveFormsModule],
})
export class InstitutionsModule {}
