<div class="flex justify-between items-center px-6 py-4 border-b border-b-gray-light">
    <div class="text-lg font-bold">
        <ng-container *ngIf="dialogType === 'create'; else elseTemplate">
            Create New Gl Cost Code
        </ng-container>
        <ng-template #elseTemplate>
            Edit Gl Cost Code '{{ glCostCodeModel.code }}'
        </ng-template>
    </div>
    <button type="button" data-dismiss="modal" class="text-2xl focus:outline-none" (click)="dialogRef.close()">
        &times;
    </button>
</div>
<div *ngIf="loading" class="mx-empty-result">
    <div class="mx-data-loading"></div>
</div>
<div class="max-h-[70vh] overflow-auto p-6 relative whitespace-pre-line">
    <form [formGroup]="glCodeFormGroup">
        <div class="grid grid-cols-2 gap-x-4">
            <mx-input-field class="col-span-2 md:col-span-1" [inputType]="text" [formGroupChild]="glCodeFormGroup"
                formControlNameChild="code" label="Code" placeholder="Enter code" [maxLength]="255"
                [required]="true"></mx-input-field>
            <div class="my-3 relative">
                <label>Parent Gl Cost Code</label>
                <mat-select
                class="col-span-2 md:col-span-1 mt-1 block w-full pl-3 py-2 border rounded-md text-sm shadow-sm focus:outline-none focus:ring-1 bg-white"
                [formControlName]="'parentGlCostCode'"
                [compareWith]="compareObjects"
                placeholder="Select parent GL cost code"
            >
            <mat-option *ngFor="let item of parentGlCostCodes" [value]="item">
                {{ item.code? item.code : item }}
            </mat-option>
            </mat-select>
            </div>
            
            <mx-input-field class="col-span-2" [inputType]="text" [formGroupChild]="glCodeFormGroup"
                formControlNameChild="description" label="Description" placeholder="Enter description" [maxLength]="255"
                [required]="false"></mx-input-field>
        </div>
    </form>
    <span class="text-primary text-sm">* Required Field For Submission</span>

</div>
<div class="flex justify-end p-6 border-t border-gray-light gap-2" *ngIf="!loading">
    <button type="button" mat-raised-button class="m-1" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">clear</mat-icon>
        <span class="text-sm font-bold">Cancel</span>
    </button>
    <button type="button" mat-raised-button class="m-1" [disabled]="isSaving || glCodeFormGroup.invalid"
        (click)="save()" color="accent">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">save</mat-icon>
        <span class="text-sm font-bold">Save</span>
    </button>
</div>