<div class="flex justify-between items-center px-6 py-4 border-b border-b-gray-light">
    <div class="text-lg font-bold">View GL Code '{{ glCostCodeModel.code }}'</div>
    <button type="button" data-dismiss="modal" class="text-2xl" (click)="dialogRef.close()">&times;</button>
</div>
<div class="max-h-[70vh] overflow-auto p-6 relative whitespace-pre-line">
    <div class="grid grid-cols-2 gap-4">
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Gl Cost Code</label>
            <div class="mb-6 break-all" maxlength="100">
                {{ glCostCodeModel.code || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Parent Gl Cost Code</label>
            <div class="mb-6 break-all" maxlength="100">
                {{ glCostCodeModel.parentGlCostCode?.code || '-' }}
            </div>
        </div>
        <!-- <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Code</label>
            <div class="mb-6 break-all" maxlength="100">
                {{ glCodeModel.code || '-' }}
            </div>
        </div> -->
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Description</label>
            <div class="mb-6 break-all">
                {{ glCostCodeModel.description || '-' }}
            </div>
        </div>
    </div>
</div>
<div class="flex justify-end p-6 border-t border-gray-light gap-2">
    <button type="button" mat-raised-button class="m-1" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">clear</mat-icon>
        <span class="text-sm font-bold">Close</span>
    </button>

    <button type="button" mat-raised-button class="m-1" (click)="dialogRef.close('edit')" color="accent">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">edit</mat-icon>
        <span class="text-sm font-bold">Edit</span>
    </button>
</div>