import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { Routes, RouterModule } from '@angular/router';
import { MatBadgeModule } from '@angular/material/badge';
import { SharedModule } from 'src/app/components/shared/shared.module';
import { HomeComponent } from './home.component';
import { DashboardActionComponent } from './dashboard-component/dashboard-action/dashboard-action.component';
import { MxPieChartComponent } from './dashboard-component/mx-pie-chart/mx-pie-chart.component';
import { MultiDatePickerModule } from "../../shared/multiDatePicker/multi-date-picker.module";
import { AppTimeSpentChartComponent } from './dashboard-component/app-time-spent-chart/app-time-spent-chart.component';
import { ModuleTimeSpentChartComponent } from './dashboard-component/module-time-spent-chart/module-time-spent-chart.component';
import { ModuleRecordsChartComponent } from './dashboard-component/module-records-chart/module-records-chart.component';
import { ActiveUserChartComponent } from './dashboard-component/active-user-chart/active-user-chart.component';
import { UserActivityChartComponent } from './dashboard-component/user-activity-chart/user-activity-chart.component';

const routes: Routes = [{
  path: '',
  component: HomeComponent
}];

@NgModule({
    declarations: [
        HomeComponent,
        DashboardActionComponent,
        MxPieChartComponent,
        AppTimeSpentChartComponent,
        ModuleTimeSpentChartComponent,
        ModuleRecordsChartComponent,
        ActiveUserChartComponent,
        UserActivityChartComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        MatButtonModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatSidenavModule,
        MatToolbarModule,
        FormsModule,
        ReactiveFormsModule,
        MatSnackBarModule,
        MatSelectModule,
        MatBadgeModule,
        RouterModule.forChild(routes),
        MultiDatePickerModule
    ]
})
export class HomeModule { }
