import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FORM_TEMPLATE_FIELD_INPUT_TYPE } from 'src/app/model/form-template-field.model';
import { RioTemplate } from 'src/app/model/rio-template.model';
import { GeneralService } from 'src/app/services/general.service';
import { RioTemplateService } from 'src/app/services/rio-template.service';
import { RioTemplateTypesDialogDetailComponent } from '../rio-template-types-dialog-detail/rio-template-types-dialog-detail.component';

@Component({
    selector: 'app-rio-template-types-dialog-new-or-edit',
    templateUrl: './rio-template-types-dialog-new-or-edit.component.html',
})
export class RioTemplateTypesDialogNewOrEditComponent {
    dialogType: string = '';
    rioTemplateFormGroup: FormGroup;
    text = FORM_TEMPLATE_FIELD_INPUT_TYPE.TEXT;
    isSaving: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public rioTemplate: RioTemplate,
        public dialogRef: MatDialogRef<RioTemplateTypesDialogNewOrEditComponent>,
        private formBuilder: FormBuilder,
        private rioTemplateService: RioTemplateService,
        private generalService: GeneralService,
        private matDialog: MatDialog
    ) {
        if (this.rioTemplate) {
            this.dialogType = 'update';
            this.rioTemplateFormGroup = this.formBuilder.group({
                id: this.formBuilder.control(this.rioTemplate.id),
                name: this.formBuilder.control(this.rioTemplate.name),
                description: this.formBuilder.control(this.rioTemplate.description),
            })
        } else {
            this.dialogType = 'create';
            this.rioTemplateFormGroup = this.formBuilder.group({
                name: this.formBuilder.control(null, Validators.required),
                description: this.formBuilder.control(null),
            })
        }
    }

    save(): void {
        this.isSaving = true;
        const getRawValue = this.rioTemplateFormGroup.getRawValue();
        if (this.dialogType === 'create') {
            this.rioTemplateService.save(getRawValue).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.dialogRef.close('ok');
                },
                error: (error) => {
                    this.isSaving = false;
                    this.generalService.snackbarMessage(error.message, 'Close');
                },
            });
        } else {
            this.rioTemplateService.update(getRawValue).subscribe({
                next: (value) => {
                    this.isSaving = false;
                    this.dialogRef.close('ok');
                    this.openRioTemplateDialogDetail(value.body);
                },
                error: (error) => {
                    this.isSaving = false;
                    this.generalService.snackbarMessage(error.message, 'Close');
                },
            });
        }
    }

    openRioTemplateDialogDetail(rioTemplate?: RioTemplate) {
        this.rioTemplateService.getRioTemplateById(rioTemplate.id).subscribe({
            next: (value) => {
                const modalRef = this.matDialog.open(RioTemplateTypesDialogDetailComponent, {
                    data: value.body,
                    panelClass: 'mat-dialog-md',
                });

                modalRef.afterClosed().subscribe({
                    next: (edit) => {
                        if (edit) this.openRioTemplateDialogCreateOrEdit(rioTemplate);
                    },
                });
            },
        });
    }

    openRioTemplateDialogCreateOrEdit(rioTemplate?: RioTemplate) {
        this.rioTemplateService.getRioTemplateById(rioTemplate.id).subscribe({
            next: (value) => {
                const modalRef = this.matDialog.open(RioTemplateTypesDialogNewOrEditComponent, {
                    data: value.body,
                    panelClass: 'mat-dialog-lg',
                });

                modalRef.afterClosed().subscribe();
            },
        });
    }
}
