import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmploymentTypeModel } from 'src/app/model/employment.model';
import { FORM_TEMPLATE_FIELD_INPUT_TYPE } from 'src/app/model/form-template-field.model';
import { EmploymentTypeService } from 'src/app/services/employment-type.service';
import { GeneralService } from 'src/app/services/general.service';
import { EmploymentDialogDetailComponent } from '../employment-dialog-detail/employment-dialog-detail.component';

@Component({
  selector: 'app-employment-dialog-new-or-edit',
  templateUrl: './employment-dialog-new-or-edit.component.html',
})
export class EmploymentDialogNewOrEditComponent implements OnInit {

    dialogType: string = '';
    isSaving: boolean = false;
    loading: boolean = false;
    employmentTypeFormGroup: FormGroup;

    text = FORM_TEMPLATE_FIELD_INPUT_TYPE.TEXT;

    constructor(
        @Inject(MAT_DIALOG_DATA) public employmentTypeModel: EmploymentTypeModel,
        public dialogRef: MatDialogRef<EmploymentDialogNewOrEditComponent>,
        private employmentTypeService: EmploymentTypeService,
        private formBuilder: FormBuilder,
        private generalService: GeneralService,
        private matDialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.employmentaActiveLoading();
        if (this.employmentTypeModel) {
            this.dialogType = 'update';
            this.employmentTypeFormGroup = this.formBuilder.group({
                id: this.formBuilder.control(this.employmentTypeModel.id),
                name: this.formBuilder.control(this.employmentTypeModel.name,Validators.required),
                description: this.formBuilder.control(this.employmentTypeModel.description),
            });
        } else {
            this.dialogType = 'create';
            this.employmentTypeFormGroup = this.formBuilder.group({
                name: this.formBuilder.control(null,Validators.required),
                description: this.formBuilder.control(null),
            });
        }
        this.employmentDeactiveLoading();
    }

    employmentaActiveLoading(){
        this.loading = true;
    }

    employmentDeactiveLoading(){
        this.loading = false;
    }

    save():void{
        this.isSaving = true;
        this.employmentaActiveLoading();
        const getRawValue = this.employmentTypeFormGroup.getRawValue();
        if (this.dialogType === 'create') {
            this.employmentTypeService.save(getRawValue).subscribe({
                next: () => { 
                    this.isSaving = false;
                    this.employmentDeactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: error => {
                    this.isSaving = false;
                    this.employmentDeactiveLoading();
                    this.generalService.snackbarMessage( error.message,'Close');
                }
            });
        } else {
            this.employmentTypeService.update(getRawValue).subscribe({
                next: (value) => {
                    this.isSaving = false;
                    this.employmentDeactiveLoading();
                    this.dialogRef.close('ok');
                    this.openEmploymentTypeDialogDetail(value.body);
                },
                error: error => {
                    this.isSaving = false;
                    this.employmentDeactiveLoading();
                    this.generalService.snackbarMessage( error.message,'Close');
                }
            });
        }
    }

    openEmploymentTypeDialogCreateOrEdit(employmentTypeModel?: EmploymentTypeModel){
        this.employmentTypeService.getEmploymentById(employmentTypeModel.id).subscribe({
            next:(value)=> {
                const modalRef = this.matDialog.open(EmploymentDialogNewOrEditComponent, {
                    data: value.body,
                    panelClass: 'mat-dialog-lg'
                })
        
                modalRef.afterClosed().subscribe();
            }});
    }

    openEmploymentTypeDialogDetail(employmentTypeModel?: EmploymentTypeModel){
        this.employmentTypeService.getEmploymentById(employmentTypeModel.id).subscribe({
            next:(value)=> {
                const modalRef = this.matDialog.open(EmploymentDialogDetailComponent, {
                    data: value.body,
                    panelClass: 'mat-dialog-md'
                })
        
                modalRef.afterClosed().subscribe({
                    next: (edit) => {
                        if (edit) this.openEmploymentTypeDialogCreateOrEdit(employmentTypeModel);
                    }
                })
            }
        })
    }

}
