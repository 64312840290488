import {Injectable,} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {AuthenticateService} from '../auth/authenticate.service';
import {Observable} from 'rxjs';
import {REPORT_COMPONENT, RIO_REPORT_COMPONENT} from '../app.constant';


@Injectable({
    providedIn: 'root'
})
export class ReportHeaderService {

    private apiReportHeader: string = REPORT_COMPONENT + '/report-headers';

    constructor(private httpClient: HttpClient, private authService: AuthenticateService) {
    }

    getReportingYearList(): Observable<HttpResponse<string[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('institutionId', this.authService.getCurInsId());
        return this.httpClient.get<string[]>(this.apiReportHeader + '/reporting-year-list', {
            observe: 'response',
            params: httpParams,
        });
    }

}
