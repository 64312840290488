import { Component, EventEmitter,  OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { DAY_MODE_FORMATS } from '../../constant/datepicker-format.constant';

@Component({
    selector: 'basic-date-picker',
    templateUrl: './basic-date-picker.component.html',
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: DAY_MODE_FORMATS },
    ],
})
export class BasicDatePickerComponent implements OnInit {

    @Output() onDateChanged = new EventEmitter<Date>();
    dateFormControl = new FormControl(new Date(new Date().setDate(new Date().getDate() - 1)));

    max: Date;
    min: Date;

    constructor() {
        const currentYear = new Date().getFullYear();
        this.min = new Date(currentYear - 11, 12, 99);
        this.max = new Date(new Date().setDate(new Date().getDate() - 1));
    }

    ngOnInit(): void {
        this._subscribeDateFormControlValueChanges();
    }

    _openDatepickerOnClick(datepicker: MatDatepicker<Moment>) {
        if (!datepicker.opened) {
            datepicker.open();
        }
    }

    _subscribeDateFormControlValueChanges() {
        this.dateFormControl.valueChanges.subscribe((value) => {
            const dateValue = new Date(value);
            this.onDateChanged.emit(dateValue);
        });
    }
}
