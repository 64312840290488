import { Component, OnInit, AfterViewInit } from '@angular/core';
// import { Subject } from 'rxjs';
// import { AuthenticateService } from 'src/app/auth/authenticate.service';
// import { FilterDates } from 'src/app/model/filter-dates.model';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit, AfterViewInit {
    readonly DASHBOARD_ACTIVITY_READ = ApplicationPermissionService.DASHBOARD_ACTIVITY_READ;

    // moduleRecordsFilterDates: Subject<FilterDates> = new Subject();
    // appDownloadFilterDates: Subject<FilterDates> = new Subject();
    // appTimeSpentFilterDates: Subject<FilterDates> = new Subject();
    // moduleTimeFilterDates: Subject<FilterDates> = new Subject();
    // userActivityFilterDates: Subject<FilterDates> = new Subject();
    // activeUserFilterDates: Subject<FilterDates> = new Subject();

    // constructor(
    //     private authService: AuthenticateService,
    // ) {
    // }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        // let filtetDates : FilterDates = new FilterDates;
        // filtetDates.dateFrom  = new Date(new Date().setDate(new Date().getDate() - 1));
        // filtetDates.dateType = 'DAILY';
        // this.datesChanged(filtetDates);
        // this.appDownloadDatesChanged(filtetDates);
        // this.appTimeSpentDatesChanged(filtetDates);
        // this.moduleTimeDatesChanged(filtetDates);
        // this.userActivityDatesChanged(filtetDates);
    }

    // datesChanged($event){
    //     this.moduleRecordsFilterDates.next($event);
    // }

    // appDownloadDatesChanged($event){
    //     this.appDownloadFilterDates.next($event);
    // }

    // appTimeSpentDatesChanged($event){
    //     this.appTimeSpentFilterDates.next($event);
    // }

    // moduleTimeDatesChanged($event){
    //     this.moduleTimeFilterDates.next($event);
    // }

    // userActivityDatesChanged($event){
    //     this.userActivityFilterDates.next($event);
    //     this.activeUserFilterDates.next($event);
    // }
}
