import { GeneralService } from 'src/app/services/general.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { REGEXS } from 'src/app/components/shared/constant/regex.constant';
import { FORM_TEMPLATE_FIELD_INPUT_TYPE } from 'src/app/model/form-template-field.model';
import { UsersService } from 'src/app/services/users.service';
import parsePhoneNumber from 'libphonenumber-js';
import { UserModel } from 'src/app/model/user.model';
import { passwordValidator } from 'src/app/components/shared/validator/password.validator';
import { ApplicationRoleModel } from 'src/app/model/application-roles.model';
import { ApplicationRolesService } from 'src/app/services/application-roles.service';

@Component({
    selector: 'app-user-dialog-new-or-edit',
    templateUrl: './user-dialog-new-or-edit.component.html',
})
export class UserDialogNewOrEditComponent implements OnInit {
    userFormGroup: FormGroup;
    authoritiesList: ApplicationRoleModel[];
    countries: any[] = [];
    employmentTypes: any[] = [];
    isSaving: boolean = false;
    loading: boolean = false;

    text = FORM_TEMPLATE_FIELD_INPUT_TYPE.TEXT;
    checkbox = FORM_TEMPLATE_FIELD_INPUT_TYPE.CHECKBOX;
    select = FORM_TEMPLATE_FIELD_INPUT_TYPE.SELECT;
    multiSelect = FORM_TEMPLATE_FIELD_INPUT_TYPE.MULTISELECT;
    phone = FORM_TEMPLATE_FIELD_INPUT_TYPE.PHONE;
    password = FORM_TEMPLATE_FIELD_INPUT_TYPE.PASSWORD;
    confirmPassword = FORM_TEMPLATE_FIELD_INPUT_TYPE.CONFIRMPASSWORD;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public userDialogData: {
            dialogType: 'create' | 'edit';
            userModel: UserModel;
        },
        public dialogRef: MatDialogRef<UserDialogNewOrEditComponent>,
        private formBuilder: FormBuilder,
        private authorityService: ApplicationRolesService,
        private generalService: GeneralService,
        private usersService: UsersService
    ) {}

    ngOnInit(): void {
        if (this.userDialogData.dialogType === 'create') {
            this.userFormGroup = this.formBuilder.group(
                {
                    email: this.formBuilder.control(null, [Validators.email, Validators.required]),
                    active: this.formBuilder.control(true),
                    fullName: this.formBuilder.control(null, Validators.required),
                    newPassword: this.formBuilder.control(null, [Validators.required, Validators.minLength(8)]),
                    repeatPassword: this.formBuilder.control(null, Validators.required),
                    phoneCountry: this.formBuilder.control(null),
                    phoneNumber: this.formBuilder.control(null),
                    authorities: this.formBuilder.control(null, Validators.required),
                    country: this.formBuilder.control(null, Validators.required),
                    employmentType: this.formBuilder.control(null, Validators.required),
                },
                {
                    validators: [passwordValidator()],
                }
            );
        } else {
            this.userFormGroup = this.formBuilder.group({
                id: this.formBuilder.control(this.userDialogData.userModel.id),
                email: this.formBuilder.control({ value: this.userDialogData.userModel.email, disabled: true }, [
                    Validators.email,
                    Validators.required,
                ]),
                active: this.formBuilder.control(this.userDialogData.userModel.active),
                fullName: this.formBuilder.control(this.userDialogData.userModel.fullName, Validators.required),
                phoneCountry: this.formBuilder.control(null),
                phoneNumber: this.formBuilder.control(
                    this.userDialogData.userModel.phoneCountry + this.userDialogData.userModel.phoneNumber
                ),
                authorities: this.formBuilder.control(this.userDialogData.userModel.authorities, Validators.required),
                country: this.formBuilder.control(this.userDialogData.userModel.country, Validators.required),
                employmentType: this.formBuilder.control(
                    this.userDialogData.userModel.employmentType,
                    Validators.required
                ),
                referenceNumber: this.formBuilder.control({
                    value: this.userDialogData.userModel.refNo,
                    disabled: true,
                }),
                loginFailAttempt: this.formBuilder.control({
                    value: this.userDialogData.userModel.loginFailAttempt,
                    disabled: true,
                }),
            });
        }

        this.authorityService.getAllAuthorities().subscribe((res) => {
            this.authoritiesList = res.body;
            this.authoritiesList = this.authoritiesList.sort((a, b) => a.name.localeCompare(b.name));
        });

        this.generalService.getAllCountries().subscribe((res) => {
            this.countries = res.body;
        });

        this.generalService.getAllEmploymentTypes().subscribe((res) => {
            this.employmentTypes = res.body;
        });
    }

    save(): void {
        this.isSaving = true;
        this.activeLoading();

        const formRawValue = this.userFormGroup.getRawValue();
        formRawValue.phoneCountry = this.userFormGroup.get('phoneNumber').value
            ? `+${parsePhoneNumber(this.userFormGroup.get('phoneNumber').value.toString()).countryCallingCode}`
            : null;
        formRawValue.phoneNumber = this.userFormGroup.get('phoneNumber').value
            ? this.userFormGroup
                  .get('phoneNumber')
                  .value.toString()
                  .replace(
                      `+${parsePhoneNumber(this.userFormGroup.get('phoneNumber').value.toString()).countryCallingCode}`,
                      ''
                  )
            : null;
        formRawValue.password = formRawValue.newPassword;
        delete formRawValue.newPassword;
        delete formRawValue.repeatPassword;
        const userModel: UserModel = formRawValue;
        if (this.userDialogData.dialogType === 'create') {
            this.usersService.createUser(userModel).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: (error) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.generalService.snackbarMessage(error.message, 'Close');
                },
            });
        } else {
            this.usersService.updateUser(userModel).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: (error) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.generalService.snackbarMessage(error.message, 'Close');
                },
            });
        }
    }

    activeLoading() {
        this.loading = true;
    }

    deactiveLoading() {
        this.loading = false;
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }
}
