<div class="flex justify-between items-center px-6 py-4 border-b border-b-gray-light">
    <div class="text-lg font-bold">
        <ng-container *ngIf="dialogType === 'create'; else elseTemplate"> Create New Entity </ng-container>
        <ng-template #elseTemplate> Edit Entity '{{ institutionModel.name }}' </ng-template>
    </div>
    <button type="button" data-dismiss="modal" class="text-2xl focus:outline-none" (click)="dialogRef.close()">
        &times;
    </button>
</div>

<div *ngIf="loading">
    <mx-data-loading></mx-data-loading>
</div>

<div class="max-h-[70vh] overflow-auto p-6 relative whitespace-pre-line">
    <form [formGroup]="institutionFormGroup">
        <div class="grid grid-cols-2 gap-x-4">
            <mx-input-field
                class="col-span-2 md:col-span-1"
                [inputType]="text"
                [formGroupChild]="institutionFormGroup"
                formControlNameChild="name"
                label="Name"
                placeholder="Enter name"
                [maxLength]="100"
                errorMessage="Name is required"
                [autoFocusChild]="true"
                [required]="true"
            ></mx-input-field>
            <mx-input-field
                class="col-span-2 md:col-span-1 flex items-center"
                [inputType]="checkbox"
                [formGroupChild]="institutionFormGroup"
                formControlNameChild="active"
                label="Active"
                (click)="changeActive()"
            ></mx-input-field>
            <mx-input-field
                class="col-span-2 md:col-span-1"
                [inputType]="text"
                [formGroupChild]="institutionFormGroup"
                formControlNameChild="shortName"
                label="Short Name"
                placeholder="Enter short name"
                [maxLength]="30"
                errorMessage="Short Name is required"
                [required]="true"
            ></mx-input-field>
            <mx-input-field
                class="col-span-2 md:col-span-1"
                [inputType]="select"
                [formGroupChild]="institutionFormGroup"
                formControlNameChild="tier"
                label="Type"
                placeholder="Select type"
                [compareWithChild]="compareObjects"
                [disabledChild]="false"
                (onSelectionChange)="tierSelectionChange()"
                [list]="tiers"
                [required]="true"
            ></mx-input-field>
            <mx-input-field
                    *ngIf="
                    institutionFormGroup.get('tier').value &&
                    (institutionFormGroup.get('tier').value?.value === 1)
                "
                    class="col-span-2 md:col-span-1"
                    [inputType]="select"
                    [formGroupChild]="institutionFormGroup"
                    formControlNameChild="parentInstitution"
                    label="Parent Entity"
                    [placeholder]="groupInstitution.name ? groupInstitution.name : 'Group Entity not found'"
                    [compareWithChild]="compareObjects"
                    [disabledChild]="true"
                    [list]="parentInstitutions"
            ></mx-input-field>
            <mx-input-field
                *ngIf="
                    institutionFormGroup.get('tier').value &&
                    (institutionFormGroup.get('tier').value?.value === 2||
                        institutionFormGroup.get('tier').value?.value === 3)
                "
                class="col-span-2 md:col-span-1"
                [inputType]="select"
                [formGroupChild]="institutionFormGroup"
                formControlNameChild="parentInstitution"
                label="Parent Entity"
                placeholder="Select parent entity"
                [compareWithChild]="compareObjects"
                [disabledChild]="false"
                [list]="parentInstitutions"
            ></mx-input-field>
            <mx-input-field
                class="col-span-2 md:col-span-1"
                [inputType]="text"
                [formGroupChild]="institutionFormGroup"
                formControlNameChild="businessRegNo"
                label="Business Registration Number"
                placeholder="Enter business registration number"
                [maxLength]="50"
            ></mx-input-field>
            <mx-input-field
                class="col-span-2 md:col-span-1"
                [inputType]="text"
                [formGroupChild]="institutionFormGroup"
                formControlNameChild="industry"
                label="Industry"
                placeholder="Enter industry"
                [maxLength]="50"
            ></mx-input-field>
            <mx-input-field
                class="col-span-2 md:col-span-1"
                [inputType]="text"
                [formGroupChild]="institutionFormGroup"
                formControlNameChild="address"
                label="Address"
                placeholder="Enter address"
                [maxLength]="500"
            ></mx-input-field>
            <mx-input-field
                class="col-span-2 md:col-span-1"
                [inputType]="text"
                [formGroupChild]="institutionFormGroup"
                formControlNameChild="phone"
                label="Phone"
                placeholder="Enter phone number"
                [maxLength]="20"
            ></mx-input-field>
            <!-- INFO: To add info icon with tooltip, add suffixIcon="info" and [suffixIconTooltip]="phoneContent" in mx-input-field -->
            <mx-input-field
                class="col-span-2 md:col-span-1"
                [inputType]="text"
                [formGroupChild]="institutionFormGroup"
                formControlNameChild="fax"
                label="Fax"
                placeholder="Enter fax number"
                [maxLength]="50"
                errorMessage="Invalid input"
            ></mx-input-field>
            <mx-input-field
                class="col-span-2 md:col-span-1"
                [inputType]="text"
                [formGroupChild]="institutionFormGroup"
                formControlNameChild="email"
                label="Email"
                placeholder="Enter email"
                [maxLength]="100"
                errorMessage="Invalid input"
            ></mx-input-field>
            <mx-input-field
                class="col-span-2 md:col-span-1"
                [inputType]="text"
                [formGroupChild]="institutionFormGroup"
                formControlNameChild="website"
                label="Website"
                placeholder="Enter website"
                [maxLength]="100"
            ></mx-input-field>
            <mx-input-field
                class="col-span-2 md:col-span-1 flex items-center"
                [inputType]="checkbox"
                [formGroupChild]="institutionFormGroup"
                formControlNameChild="reportRequired"
                label="Report Required"
                (click)="changeReportRequired()"
            ></mx-input-field>
            <!-- <mx-input-field class="col-span-2" [inputType]="multiSelect" [formGroupChild]="institutionFormGroup"
                formControlNameChild="projects" label="Project" placeholder="Select project"
                [compareWithChild]="compareObjects" [disabledChild]="false" [list]="projectList"
                [required]="false"
            ></mx-input-field> -->
            <br
                *ngIf="
                    institutionFormGroup.get('tier').value &&
                    (institutionFormGroup.get('tier').value?.value === 2 ||
                        institutionFormGroup.get('tier').value?.value === 3)
                "
            />
            <ng-container *ngIf="reportRequired.value">
                <mx-input-field
                    class="col-span-2"
                    [inputType]="checkbox"
                    [formGroupChild]="institutionFormGroup"
                    formControlNameChild="isCBS"
                    label="Corporate Budget System"
                ></mx-input-field>
                <div class="col-span-2" *ngIf="isCBS.value">
                    <label>Form Type Configuration</label>
                    <mat-select
                        class="col-span-2 md:col-span-1 mt-1 block w-full pl-3 py-2 border rounded-md text-sm shadow-sm focus:outline-none focus:ring-1 bg-white"
                        [formControlName]="'formTypeConfigurations'"
                        [compareWith]="compareObjects"
                        placeholder="Select form type configuration"
                        multiple
                    >
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip
                                    *ngFor="let item of institutionFormGroup.get('formTypeConfigurations').value"
                                    [removable]="true"
                                    (removed)="onToppingRemovedFormTypeConfigurations(item, 'formTypeConfigurations')"
                                >
                                    {{ item.code }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <mat-option *ngFor="let item of formTypeConfigurationList" [value]="item">
                            {{ item.code ? item.code : item }}
                        </mat-option>
                    </mat-select>
                </div>
                <mx-input-field
                    class="col-span-2"
                    [inputType]="checkbox"
                    [formGroupChild]="institutionFormGroup"
                    formControlNameChild="isRio"
                    label="RIO"
                    (click)="changeIsRIo()"
                ></mx-input-field>
                <mx-input-field
                    *ngIf="isRio.value"
                    class="col-span-2"
                    [inputType]="select"
                    [formGroupChild]="institutionFormGroup"
                    formControlNameChild="systemConfigurationId"
                    label="Submission Group"
                    placeholder="Select submission group"
                    [compareWithChild]="compareObjects"
                    [list]="submissionGroupList"
                    [required]="true"
                ></mx-input-field>
                <div class="col-span-2" *ngIf="isRio.value">
                    <label>RIO Template</label>
                    <mat-select
                        class="col-span-2 md:col-span-1 mt-1 block w-full pl-3 py-2 border rounded-md text-sm shadow-sm focus:outline-none focus:ring-1 bg-white"
                        [formControlName]="'rioTemplates'"
                        [compareWith]="compareObjects"
                        placeholder="Select rio template"
                        multiple
                    >
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip
                                    *ngFor="let item of institutionFormGroup.get('rioTemplates').value"
                                    [removable]="true"
                                    (removed)="onToppingRemovedFormTypeConfigurations(item, 'rioTemplates')"
                                >
                                    {{ item.name }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <mat-option *ngFor="let item of rioTemplatesList" [value]="item">
                            {{ item.name }}
                        </mat-option>
                    </mat-select>
                </div>
            </ng-container>
        </div>
    </form>
    <span class="text-primary text-sm">* Required Field For Submission</span>
</div>
<div class="flex justify-end p-6 border-t border-gray-light gap-2" *ngIf="!loading">
    <button type="button" mat-raised-button class="m-1" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">clear</mat-icon>
        <span class="text-sm font-bold">Cancel</span>
    </button>
    <button
        type="button"
        mat-raised-button
        class="m-1"
        [disabled]="isSaving || institutionFormGroup.invalid"
        (click)="save()"
        color="accent"
    >
        <mat-icon class="text-sm mr-2 mt-1 font-bold">save</mat-icon>
        <span class="text-sm font-bold">Save</span>
    </button>
</div>
