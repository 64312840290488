import {  Component,  Input } from '@angular/core';
import {  FormControl,  } from '@angular/forms';

import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { YEAR_MODE_FORMATS } from '../../constant/datepicker-format.constant';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

const moment = _rollupMoment || _moment;

@Component({
    selector: 'yearly-date-picker',
    templateUrl: './yearly-date-picker.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'pt' },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: YEAR_MODE_FORMATS },
        
    ],
})
export class YearlyDatePickerComponent {

    chartDateFormControl: FormControl = new FormControl();

    _max: Moment;
    _min: Moment;

    constructor() {}

    _yearSelectedHandler(chosenDate: Moment, datepicker: MatDatepicker<Moment>) {
        datepicker.close();

        if (!this._isYearEnabled(chosenDate.year())) {
            return;
        }

        chosenDate.set({ date: 1 });

        this.chartDateFormControl.setValue(chosenDate, { emitEvent: false });
        }

        _openDatepickerOnClick(datepicker: MatDatepicker<Moment>) {
            if (!datepicker.opened) {
                datepicker.open();
            }
        }

    private _isYearEnabled(year: number) {
        // disable if the year is greater than maxDate lower than minDate
        if (
          year === undefined ||
          year === null ||
          (this._max && year > this._max.year()) ||
          (this._min && year < this._min.year())
        ) {
          return false;
        }
    
        return true;
    }
}
