import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { Observable} from 'rxjs';
import { SERVER_API } from '../app.constant';
import { SchedulerModel } from '../model/scheduler.model';
@Injectable({
    providedIn: 'root',
})
export class SchedulerService {
    private schedulerApi: string = SERVER_API + 'api/scheduler-component/schedulers';

    public static readonly SCHEDULER_TYPE_OPTIONS: ISchedulerTypeOptions[] = [
        {
            name: 'CBS Aggregation',
            jobGroup: 'report',
            destination: 'report-aggregation'
        },
        {
            name: 'CBS Email Notification',
            jobGroup: 'report',
            destination: 'report-email'
        },
        {
            name: 'CBS Financial Year Update',
            jobGroup: 'common',
            destination: 'financial-year-update'
        },
        {
            name: 'CBS Freeze Submission',
            jobGroup: 'common',
            destination: 'submission-freeze'
        },
        {
            name: 'CBS Unfreeze Submission',
            jobGroup: 'common',
            destination: 'submission-unfreeze'
        },
        {
            name: 'RIO Initial Consolidation',
            jobGroup: 'rio-report',
            destination: 'rio-initial-consolidation'
        },
        {
            name: 'RIO Final Consolidation',
            jobGroup: 'rio-report',
            destination: 'rio-final-consolidation'
        },
        {
            name: 'RIO Shift Dates',
            jobGroup: 'rio-report',
            destination: 'rio-shift-dates'
        },
        {
            name: 'RIO Freeze Submission - Account',
            jobGroup: 'common',
            destination: 'rio-submission-freeze-acc'
        },
        {
            name: 'RIO Unfreeze Submission - Account',
            jobGroup: 'common',
            destination: 'rio-submission-unfreeze-acc',
        },
        {
            name: 'RIO Freeze Submission - Others',
            jobGroup: 'common',
            destination: 'rio-submission-freeze-others'
        },
        {
            name: 'RIO Unfreeze Submission - Others',
            jobGroup: 'common',
            destination: 'rio-submission-unfreeze-others'
        }
    ]

    constructor(private httpClient: HttpClient) {}

    getAllSchedulers(pageNumber: number, pageSize: number, sortOrder: string, name): Observable<any> {
        let httpParams = new HttpParams();
        if (pageNumber) {
            httpParams = httpParams.set('page', `${pageNumber}`);
        }
        if (pageSize) {
            httpParams = httpParams.set('size', `${pageSize}`);
        }
        if (sortOrder) {
            httpParams = httpParams.set('sort', sortOrder);
        }

        if (name) {
            httpParams = httpParams.set('name', name);

        }
        return this.httpClient.get(this.schedulerApi, {
            observe: 'response',
            params: httpParams,
        });
    }

    save(aggregationScheduleModel : SchedulerModel) {
        return this.httpClient.post<any>(this.schedulerApi, aggregationScheduleModel, {
            observe: 'response',
        });
    }

    update(aggregationScheduleModel : SchedulerModel) {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('name', aggregationScheduleModel.jobName);
        httpParams = httpParams.set('group', aggregationScheduleModel.jobGroup);
        return this.httpClient.put<any>(this.schedulerApi, {
            cronExpression: aggregationScheduleModel.cronExpression
        }, {
            observe: 'response',
            params: httpParams,
        });
    }

    delete(jobName: string, jobGroup: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('name', jobName);
        httpParams = httpParams.set('group', jobGroup);
        return this.httpClient.delete<any>(this.schedulerApi, {
            observe: 'response',
            params: httpParams,
        });
    }

    unfreezeSubmission() {
        return this.httpClient.put<any>(this.schedulerApi + '/unfreeze-submission', {
            observe: 'response',
        });
    }

    runAggregation() {
        return this.httpClient.post<any>(this.schedulerApi + '/run-aggregation', {
            observe: 'response',
        });
    }

    runInitialConsolidation() {
        return this.httpClient.post<any>(this.schedulerApi + '/rio-run-initial-consolidations', {
            observe: 'response',
        });
    }

    runFinalConsolidation() {
        return this.httpClient.post<any>(this.schedulerApi + '/rio-run-final-consolidations', {
            observe: 'response',
        });
    }

    runShiftDates() {
        return this.httpClient.post<any>(this.schedulerApi + '/rio-run-shift-dates', {
            observe: 'response',
        });
    }

    pauseOrResumeScheduler(mode: string, jobName: string, jobGroup: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('group', jobGroup);
        httpParams = httpParams.set('name', jobName);
        return this.httpClient.put<any>(this.schedulerApi + '/' + mode, null, {
            observe: 'response',
            params: httpParams,
        });
    }
}

export interface ISchedulerTypeOptions {
    name: string
    jobGroup: string
    destination: string
}
