<div [formGroup]="formGroupChild" class="relative">
    <label>{{ label }} <span *ngIf="required" class="text-primary">*</span></label>
    <mat-select
        class="mt-1 block w-full pl-3 py-2 border rounded-md text-sm shadow-sm focus:outline-none focus:ring-1 bg-white"
        [ngClass]="{
            'border-primary hover:border-primary focus:border-primary focus:ring-primary':
                formGroupChild.get(formControlNameChild).invalid && formGroupChild.get(formControlNameChild).dirty,
            'border-gray-light hover:border-gray focus:border-gray focus:ring-green-light':
                !formGroupChild.get(formControlNameChild).invalid || !formGroupChild.get(formControlNameChild).dirty
        }"
        [formControlName]="formControlNameChild"
        [compareWith]="compareWithChild"
        [disabled]="disabledChild"
        [placeholder]="placeholder"
        (selectionChange)="selectionChange($event)"
        multiple
    >
        <mat-select-trigger>
            <mat-chip-list>
                <mat-chip
                    *ngFor="let item of formGroupChild.get(formControlNameChild).value"
                    [removable]="true"
                    (removed)="onToppingRemoved(item)"
                >
                    {{ item.name }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </mat-select-trigger>

        <mat-option *ngFor="let item of list" [value]="item">{{ item.name }}</mat-option>
    </mat-select>
    <p
        *ngIf="formGroupChild.get(formControlNameChild).invalid && formGroupChild.get(formControlNameChild).dirty"
        class="text-xs text-primary absolute"
    >
        {{ errorMessage }}
    </p>
</div>
