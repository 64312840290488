import { Component, Input } from '@angular/core';

@Component({
    selector: 'mx-title-without-button-bar',
    templateUrl: './mx-title-without-button-bar.component.html',
})
export class MxTitleWithoutButtonBarComponent{
    @Input() title: string;
    @Input() isShowing: boolean = true;
}
