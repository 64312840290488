import { Location } from '@angular/common';
import { Component } from '@angular/core';

@Component({
    selector: 'app-coming-soon',
    templateUrl: './coming-soon.component.html',
})
export class ComingSoonComponent{

    constructor( private location: Location) {}

    goBack() {
        this.location.back();
    }

}
