import { Property } from "../util";

export const DASHBOARD_DATE_ENUM: Property[] = [
	{
		value: 'Daily',
        name: 'Daily',
	},
	{
		value: 'Weekly',
        name: 'Weekly',
	},
	{
		value: 'Monthly',
        name: 'Monthly',
	}
]

export const DAY_MODE_FORMATS = {
	parse: {
	  dateInput: 'YYYY/MM/DD',
	},
	display: {
	  dateInput: 'YYYY/MM/DD',
	  monthYearLabel: 'YYYY MMM DD',
	  dateA11yLabel: 'LL',
	  monthYearA11yLabel: 'YYYY MMMM DD',
	},
};

export const MONTH_MODE_FORMATS = {
	parse: {
	  dateInput: 'YYYY/MM',
	},
	display: {
	  dateInput: 'YYYY/MM',
	  monthYearLabel: 'MMM YYYY',
	  dateA11yLabel: 'LL',
	  monthYearA11yLabel: 'MMMM YYYY',
	},
};


export const YEAR_MODE_FORMATS = {
	parse: {
	  dateInput: 'YYYY',
	},
	display: {
	  dateInput: 'YYYY',
	  monthYearLabel: 'MMM YYYY',
	  dateA11yLabel: 'LL',
	  monthYearA11yLabel: 'MMMM YYYY',
	},
};

export const MONTH_LIST_SHORT: string[] = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
