<div class="flex flex-row">
    <ul class="flex grow list-none m-0 p-0 align-[2px] mt-2">
        <li
            class="bg-white rounded-sm inline-block h-1 mr-0.5 grow last-of-type:m-0"
            [style.background-color]="bar0"
        ></li>
        <li
            class="bg-white rounded-sm inline-block h-1 mr-0.5 grow last-of-type:m-0"
            [style.background-color]="bar1"
        ></li>
        <li
            class="bg-white rounded-sm inline-block h-1 mr-0.5 grow last-of-type:m-0"
            [style.background-color]="bar2"
        ></li>
        <li
            class="bg-white rounded-sm inline-block h-1 mr-0.5 grow last-of-type:m-0"
            [style.background-color]="bar3"
        ></li>
    </ul>

    <p class="text-center mb-0 font-bold grow-[0.1]" [style.color]="messageColor" *ngIf="passwordToCheck?.length">
        {{ message }}
    </p>
</div>
