import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlCostCodeDialogDetailComponent } from './gl-cost-code-dialog-detail/gl-cost-code-dialog-detail.component';
import { GlCostCodeDialogNewOrEditComponent } from './gl-cost-code-dialog-new-or-edit/gl-cost-code-dialog-new-or-edit.component';
import { GlCostCodeListingComponent } from './gl-code-listing/gl-cost-code-listing.component';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const routes: Routes = [
  {
      path: '',
      component: GlCostCodeListingComponent,
      // canActivate: [
      //     AuthenticatedGuard
      // ]
  },
];

@NgModule({
  declarations: [
    GlCostCodeDialogDetailComponent,
    GlCostCodeDialogNewOrEditComponent,
    GlCostCodeListingComponent
  ],
  imports: [CommonModule, SharedModule, RouterModule.forChild(routes), FormsModule, ReactiveFormsModule],

})
export class GlCostCodeModule { }
