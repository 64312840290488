import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectDialogDetailComponent } from './project-dialog-detail/project-dialog-detail.component';
import { ProjectDialogNewOrEditComponent } from './project-dialog-new-or-edit/project-dialog-new-or-edit.component';
import { ProjectListingComponent } from './project-listing/project-listing.component';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const routes: Routes = [
  {
      path: '',
      component: ProjectListingComponent,
      // canActivate: [
      //     AuthenticatedGuard
      // ]
  },
];

@NgModule({
  declarations: [
    ProjectDialogDetailComponent,
    ProjectDialogNewOrEditComponent,
    ProjectListingComponent
  ],
  imports: [CommonModule, SharedModule, RouterModule.forChild(routes), FormsModule, ReactiveFormsModule],

})
export class ProjectModule { }
