<div [formGroup]="formGroupChild" class="relative">
    <label [for]="label">{{ label }}<span *ngIf="required" class="text-primary">*</span></label>
    <input
        [type]="isShowPassword ? 'text' : 'password'"
        class="mt-1 block w-full pl-3 py-2 border rounded-md text-sm shadow-sm focus:outline-none focus:ring-1 pr-8"
        [id]="label"
        [ngClass]="{
            'border-primary hover:border-primary focus:border-primary focus:ring-primary':
                formGroupChild.get(formControlNameChild).invalid && formGroupChild.get(formControlNameChild).dirty,
            'border-gray-light hover:border-gray focus:border-gray focus:ring-green-light':
                !formGroupChild.get(formControlNameChild).invalid || !formGroupChild.get(formControlNameChild).dirty
        }"
        [formControlName]="formControlNameChild"
        [placeholder]="placeholder"
        [maxlength]="maxLength"
        [appAutofocus]="autoFocusChild"
    />
    <p
        *ngIf="
            (formGroupChild.get(formControlNameChild).invalid && formGroupChild.get(formControlNameChild).dirty) ||
            this.formGroupChild.get(passwordFormControlName).value !=
                this.formGroupChild.get(formControlNameChild).value
        "
        class="text-xs text-primary absolute"
    >
        {{ errorMessage }}
    </p>
    <mat-icon
        *ngIf="!isShowPassword"
        (click)="showPassword()"
        class="absolute right-2 bottom-2 cursor-pointer"
        matTooltip="Show Password"
        matTooltipPosition="above"
        >visibility</mat-icon
    >
    <mat-icon
        *ngIf="isShowPassword"
        (click)="showPassword()"
        class="absolute right-2 bottom-2 cursor-pointer"
        matTooltip="Hide Password"
        matTooltipPosition="above"
        >visibility_off</mat-icon
    >
</div>
