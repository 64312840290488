import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { Subject } from 'rxjs';
import { FilterDates } from 'src/app/model/filter-dates.model';
import { DashboardService } from 'src/app/services/dashboard.service';
import { DatePipe } from '@angular/common';
import { ModuleRecord } from 'src/app/model/moduleRecord.model';
@Component({
    selector: 'user-activity-chart',
    templateUrl: './user-activity-chart.component.html',
})
export class UserActivityChartComponent implements OnInit, AfterViewInit {
    @Input() public userActivityFilterDates: Subject<FilterDates>;

    userActivityData = [];
  
    daily = 'DAILY';
    weekly = 'WEEKLY';
    monthly = 'MONTHLY';
    userActivityRoot: am5.Root;
    userActivityChart: am5xy.XYChart;
    userActivityChart2: am5xy.XYChart;
    userActivityLegend: am5.Legend;
    userActivitySeries: am5xy.ColumnSeries;

    yAxis: am5xy.ValueAxis<am5xy.AxisRendererY>;
    xAxis: am5xy.CategoryAxis<am5xy.AxisRendererX>;

    constructor(private dashboardService: DashboardService, private datePipe: DatePipe) {}

    ngOnInit(): void {
        this.userActivityFilterDates.subscribe((userActivityFilterDates) => {
            this.requestUserActivityData(userActivityFilterDates);
        });
    }

    requestUserActivityData(userActivityFilterDates: FilterDates) {
        this.dashboardService
            .getUserActivity(
                userActivityFilterDates.dateType,
                this.datePipe.transform(userActivityFilterDates.dateFrom, 'yyyy-MM-dd', '+800'),
                this.datePipe.transform(userActivityFilterDates.dateTo, 'yyyy-MM-dd', '+800'),
                this.datePipe.transform(userActivityFilterDates.yearMonth, 'yyyy-MM', '+800')
            )
            .subscribe({
                next: (value) => {
                    this.userActivityData = this.processUserActivityResponse(value.body,userActivityFilterDates.dateType);
                    this.xAxis.data.setAll(this.userActivityData);
                    this.userActivitySeries.data.setAll(this.userActivityData);
                    this.userActivityLegend.data.setAll(this.userActivityChart.series.values);
                },
            });
    }

    ngAfterViewInit(): void {
        this.userActivityRoot = am5.Root.new('chartUserActivity');

        this.userActivityRoot.setThemes([am5themes_Animated.new(this.userActivityRoot)]);

        this.userActivityChart = this.userActivityRoot.container.children.push(
            am5xy.XYChart.new(this.userActivityRoot, {
                panX: true,
                panY: true,
                wheelX: 'panX',
                wheelY: 'zoomX',
                pinchZoomX: true,
                layout: this.userActivityRoot.horizontalLayout,
                paddingBottom: 30,
            })
        );

        this.xAxis = this.userActivityChart.xAxes.push(
            am5xy.CategoryAxis.new(this.userActivityRoot, {
                maxDeviation: 0.3,
                categoryField: 'category',
                renderer: am5xy.AxisRendererX.new(this.userActivityRoot, {
                    minGridDistance: 30,
                }),
            })
        );

        this.xAxis.get('renderer').labels.template.setAll({
            textAlign: 'center',
        });

        this.yAxis = this.userActivityChart.yAxes.push(
            am5xy.ValueAxis.new(this.userActivityRoot, {
                min: 0,
                maxDeviation: 0,
                renderer: am5xy.AxisRendererY.new(this.userActivityRoot, { inside: false }),
            })
        );

        this.userActivitySeries = this.userActivityChart.series.push(
            am5xy.ColumnSeries.new(this.userActivityRoot, {
                name: 'Total open the app',
                xAxis: this.xAxis,
                yAxis: this.yAxis,
                valueYField: 'count',
                categoryXField: 'category',
                tooltip: am5.Tooltip.new(this.userActivityRoot, {
                    pointerOrientation: 'vertical',
                    labelText: 'Total Count: {valueY}',
                }),
                fill: am5.color(0xf09397),
            })
        );

        this.userActivitySeries.columns.template.setAll({
            cornerRadiusTL: 5,
            cornerRadiusTR: 5,
            tooltipText: "Total Count: {valueYWorking.formatNumber('#.')}",
            tooltipY: 0,
        });

        this.userActivitySeries.columns.template.setAll({
            width: 80,
        });

        this.userActivityLegend = this.userActivityChart.children.push(
            am5.Legend.new(this.userActivityRoot, {
                centerY: am5.p50,
                y: am5.p50,
                centerX: am5.p0,
                x: am5.percent(85),
                layout: this.userActivityRoot.verticalLayout,
                verticalScrollbar: am5.Scrollbar.new(this.userActivityRoot, {
                    orientation: 'vertical',
                }),
            })
        );

        this.xAxis.data.setAll(this.userActivityData);
        this.userActivitySeries.data.setAll(this.userActivityData);

        // Make stuff animate on load
        this.userActivitySeries.appear(1000);
        this.userActivityChart.appear(1000, 100);
    }

    private processUserActivityResponse(data: any, dataType: string): ModuleRecord[] {
        let userActiveRecordList: ModuleRecord[] = [];
        if(dataType === this.daily){
            data.forEach((element) => {
                const date = element.dateFrom.day + '\n' + element.dateFrom.formatted;
                let dataRecord: ModuleRecord = {
                    category: date,
                    count: element.totalOpenCount,
                };
                userActiveRecordList.push(dataRecord);
            });
            return userActiveRecordList;
        }else if(dataType === this.weekly){
            data.forEach((element) => {
                const week = element.dateFrom.formatted + '-\n' + element.dateTo.formatted;
                let dataRecord: ModuleRecord = {
                    category: week,
                    count: element.totalOpenCount,
                };
                userActiveRecordList.push(dataRecord);
            });
            return userActiveRecordList;

        }else if(dataType === this.monthly){
            data.forEach((element) => {
                let dataRecord: ModuleRecord = {
                    category: element.yearMonth.formatted,
                    count: element.totalOpenCount,
                };
                userActiveRecordList.push(dataRecord);
            });
            return userActiveRecordList;
        }
    }
}
