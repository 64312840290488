import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';
import { CompanyDialogNewOrEditComponent } from '../company-dialog-new-or-edit/company-dialog-new-or-edit.component';
import { BehaviorSubject } from 'rxjs';
import { GeneralService } from 'src/app/services/general.service';
import { CompanyDialogDetailComponent } from '../company-dialog-detail/company-dialog-detail.component';
import { AuthenticateService } from 'src/app/auth/authenticate.service';
import { FORM_TEMPLATE_FIELD_INPUT_TYPE } from 'src/app/model/form-template-field.model';
import { SharedMethod } from 'src/app/components/shared/shared.component';
import { CompanyService } from 'src/app/services/company.service';
import { CompanyModel } from 'src/app/model/company.model';
import { DialogSimpleComponent } from 'src/app/components/shared/ui/dialog-simple/dialog-simple.component';

@Component({
    selector: 'app-company-listing',
    templateUrl: './company-listing.component.html',
})
export class CompanyListingComponent implements OnInit {
    readonly COMPANY_CREATE = ApplicationPermissionService.COMPANY_CREATE;
    readonly COMPANY_UPDATE = ApplicationPermissionService.COMPANY_UPDATE;
    readonly COMPANY_DELETE = ApplicationPermissionService.COMPANY_DELETE;
    readonly COMPANY_READ = ApplicationPermissionService.COMPANY_READ;

    companyDataSource = new MatTableDataSource<CompanyModel>();
    obs: BehaviorSubject<any>;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayColumns = ['name', 'actions'];

    companySearchValue: String = null;
    companyPageNumbers: number = 0;
    companyNumberOfRecords: number = 0;
    companyCurrentPage: number = 0;
    companyPageSize: number = 50;
    companySortOrder = 'name,asc';
    companyCountLoad = 0;
    CompanyFormGroup: FormGroup;
    loading: boolean = false;

    isLoading = FORM_TEMPLATE_FIELD_INPUT_TYPE.LOADING;

    constructor(
        public activatedRoute: ActivatedRoute,
        private matDialog: MatDialog,
        private companyService: CompanyService,
        private changeDetectorRef: ChangeDetectorRef,
        public generalService: GeneralService,
        private authService: AuthenticateService,
        public sharedMethod: SharedMethod
    ) {
        this.companyDataSource.paginator = this.paginator;
    }

    ngOnInit(): void {
        this.companyActiveLoading();
        if (this.activatedRoute.snapshot.queryParams['page']) {
            this.companySortOrder = this.activatedRoute.snapshot.queryParams['sort']
                ? this.activatedRoute.snapshot.queryParams['sort']
                : undefined;
            this.companyCurrentPage = this.activatedRoute.snapshot.queryParams['page']
                ? this.activatedRoute.snapshot.queryParams['page']
                : undefined;
            this.companyPageSize = this.activatedRoute.snapshot.queryParams['pageSize']
                ? this.activatedRoute.snapshot.queryParams['pageSize']
                : undefined;
        }
        this.refreshForm();
    }

    search(companyCurrentPage: number, companySearchValue: String) {
        this.companySearchValue = companySearchValue;
        this.companyService
            .getAllCompanies(this.companyCurrentPage, this.companyPageSize, this.companySortOrder, {
                name: companySearchValue,
                id: this.authService.getCurInsId(),
            })
            .subscribe({
                next: ({ body }) => {
                    this.companyDataSource.data = body.content;
                    this.companyNumberOfRecords = body.totalElements;
                    this.changeDetectorRef.detectChanges();
                    this.obs = this.companyDataSource.connect();
                    this.companyDeactiveLoading();

                    if (companyCurrentPage === 0) {
                        this.paginator.firstPage();
                    } else {
                        this.paginator.pageIndex = companyCurrentPage;
                    }
                },
                error: (error) => {
                    this.generalService.snackbarMessage(error.debugMessage, 'Close');
                    this.companyDeactiveLoading();
                },
            });
    }

    refreshForm() {
        this.search(0, null);
    }

    getTimeString(dateToChange: Date): string {
        let newDate: Date = new Date(dateToChange.getTime() + 8 * 60 * 60 * 1000);
        return newDate.toISOString();
    }

    ngOnDestroy(): void {
        if (this.companyDataSource) {
            this.companyDataSource.disconnect();
        }
    }

    openCompanyDialogCreateOrEdit(companyModel?: CompanyModel) {
        if (companyModel === undefined) {
            this.passDataToDialogCreateOrEdit(companyModel);
        } else {
            this.companyService.getCompanyById(companyModel.id).subscribe({
                next: (value) => {
                    this.passDataToDialogCreateOrEdit(value.body);
                },
            });
        }
    }

    openCompanyDialogDelete(companyModel?: CompanyModel): void {
        const modalRef = this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: 'Confirm Delete Company?',
                message: `Are you sure want to delete ${companyModel.name}?`,
                buttonPositive: 'Yes',
                buttonNegative: 'No',
            },
            panelClass: 'mat-dialog-sm',
        });

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) {
                    this.companyService.delete(companyModel.id.toString()).subscribe({
                        next: () => {
                            this.search(this.companyCurrentPage, this.companySearchValue);
                        },
                        error: (error) => {
                            this.generalService.snackbarMessage(error.message, 'Close');
                        },
                    });
                }
            },
        });
    }

    passDataToDialogCreateOrEdit(event: any) {
        const modalRef = this.matDialog.open(CompanyDialogNewOrEditComponent, {
            data: event,
            panelClass: 'mat-dialog-lg',
        });

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) this.search(this.companyCurrentPage, this.companySearchValue);
            },
        });
    }

    openCompanyDialogDetail(companyModel?: CompanyModel) {
        this.companyService.getCompanyById(companyModel.id).subscribe({
            next: (value) => {
                const modalRef = this.matDialog.open(CompanyDialogDetailComponent, {
                    data: value.body,
                    panelClass: 'mat-dialog-md',
                });

                modalRef.afterClosed().subscribe({
                    next: (edit) => {
                        if (edit) this.openCompanyDialogCreateOrEdit(companyModel);
                    },
                });
            },
        });
    }

    sortPage(event): void {
        this.companySortOrder = `${event.active},${event.direction}`;
        this.search(this.companyCurrentPage, this.companySearchValue);
    }

    navigateToPage(event): void {
        this.companyPageSize = event.pageSize;
        this.companyCurrentPage = event.pageIndex;
        this.search(this.companyCurrentPage, this.companySearchValue);
    }

    companyActiveLoading() {
        this.loading = true;
    }

    companyDeactiveLoading() {
        this.loading = false;
    }
}
