import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormTypeConfigurationDialogDetailComponent } from './form-type-configuration-dialog-detail/form-type-configuration-dialog-detail.component';
import { FormTypeConfigurationDialogNewOrEditComponent } from './form-type-configuration-dialog-new-or-edit/form-type-configuration-dialog-new-or-edit.component';
import { FormTypeConfigurationListingComponent } from './form-type-configuration-listing/form-type-configuration-listing.component';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const routes: Routes = [
  {
      path: '',
      component: FormTypeConfigurationListingComponent,
      // canActivate: [
      //     AuthenticatedGuard
      // ]
  },
];

@NgModule({
  declarations: [
    FormTypeConfigurationDialogDetailComponent,
    FormTypeConfigurationDialogNewOrEditComponent,
    FormTypeConfigurationListingComponent
  ],
  imports: [CommonModule, SharedModule, RouterModule.forChild(routes), FormsModule, ReactiveFormsModule],

})
export class FormTypeConfigurationModule { }
