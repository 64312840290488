import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';

export function passwordValidator(): ValidatorFn {
    return (control: AbstractControl) => {
        const applicationUserFormGroup = control as FormGroup;
        const passwordFormControl = applicationUserFormGroup.get('newPassword') as FormControl;
        const repeatPasswordFormControl = applicationUserFormGroup.get('repeatPassword') as FormControl;
        if (passwordFormControl.value !== repeatPasswordFormControl.value) {
            applicationUserFormGroup.get('repeatPassword').setErrors({ mismatch: true });
            
            return {};
        } else {
            applicationUserFormGroup.get('repeatPassword').setErrors(null);
            return {};
        }
    };
}
