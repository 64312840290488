import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ACCOUNT_COMPONENT } from '../app.constant';
import { AuthenticateService } from '../auth/authenticate.service';
import { CompanyModel } from '../model/company.model';
import { DropDownListingResponse } from '../model/dropdown-listing-response';

@Injectable({
    providedIn: 'root',
})
export class CompanyService {
    private companyApi: string = ACCOUNT_COMPONENT + '/companies';
    private dropdownListApi: string = ACCOUNT_COMPONENT + '/companies/dropdown-list';

    constructor(private httpClient: HttpClient, private authService: AuthenticateService) {}

    save(model: CompanyModel): Observable<HttpResponse<CompanyModel>> {
        return this.httpClient.post<CompanyModel>(this.companyApi, model, {
            observe: 'response',
        });
    }

    update(model: CompanyModel): Observable<HttpResponse<CompanyModel>> {
        return this.httpClient.put<CompanyModel>(this.companyApi + `/${model.id}`, model, {
            observe: 'response',
        });
    }

    delete(companyId: string): Observable<HttpResponse<any>> {
        let httpParams = new HttpParams();
        return this.httpClient.delete<any>(this.companyApi + '/' + companyId, {
            observe: 'response',
            params: httpParams,
        });
    }

    getAllCompanies(pageNumber: number, pageSize: number, sortOrder: string, criteria): Observable<any> {
        let httpParams = new HttpParams();
        if (pageNumber) {
            httpParams = httpParams.set('page', `${pageNumber}`);
        }
        if (pageSize) {
            httpParams = httpParams.set('size', `${pageSize}`);
        }
        if (sortOrder) {
            httpParams = httpParams.set('sort', sortOrder);
        }

        if (criteria) {
            if (criteria.name) {
                httpParams = httpParams.set('name', criteria.name);
            }
            if (criteria.id) {
                httpParams = httpParams.set('id', criteria.id);
            }
        }
        return this.httpClient.get(`${this.companyApi}`, {
            observe: 'response',
            params: httpParams,
        });
    }

    getCompanyById(id: string): Observable<HttpResponse<CompanyModel>> {
        return this.httpClient.get<CompanyModel>(this.companyApi + '/' + id, {
            observe: 'response',
        });
    }

    getCompaniesNoPagination(): Observable<HttpResponse<CompanyModel[]>> {
        return this.httpClient.get<CompanyModel[]>(this.companyApi + '/all', {
            observe: 'response',
        });
    }

    getAllCompanyDropdownList(): Observable<HttpResponse<DropDownListingResponse[]>> {
        return this.httpClient.get<DropDownListingResponse[]>(this.dropdownListApi, {
            observe: 'response'
        })
    }
}
