import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {REPORT_COMPONENT, RIO_REPORT_COMPONENT} from '../app.constant';
import {ReportDetailUploadModel} from "../model/report-detail-upload.model";

@Injectable({
    providedIn: 'root'
})
export class RioReportDetailService {

    private apiReportDetails: string = RIO_REPORT_COMPONENT + '/report-details';
    private getUploadReportApi: string = RIO_REPORT_COMPONENT + '/report-details/get-upload-report'

    constructor(
        private httpClient: HttpClient
    ) {
    }

    getReportUploadList(
        reportingYear: string,
        reportingMonth: string,
        companyId: string,
        projectId: string,
        pageNumber: number,
        pageSize: number,
        sortOrder: string,
        institutionId: string,
        criteria
    ): Observable<HttpResponse<any>> {
        let httpParams = new HttpParams();
        if (pageNumber !== undefined || pageNumber !== null) {
            httpParams = httpParams.set('page', `${pageNumber}`);
        }
        if (pageSize) {
            httpParams = httpParams.set('size', `${pageSize}`);
        }
        if (sortOrder !== undefined || sortOrder !== null) {
            httpParams = httpParams.set('sort', sortOrder);
        }

        if (criteria.name) {
            if (criteria.formType) {
                httpParams = httpParams.set('formType', criteria.formType);
            }
            if (criteria.status) {
                httpParams = httpParams.set('status', criteria.status);
            }
        }
        httpParams = httpParams.set('institutionId', institutionId);
        httpParams = httpParams.set('year', reportingYear);

        if (reportingMonth) {
            httpParams = httpParams.set('month', reportingMonth);
        }

        if (companyId) {
            httpParams = httpParams.set('companyId', companyId);
        }

        if (projectId) {
            httpParams = httpParams.set('projectId', projectId);
        }

        return this.httpClient.get(this.getUploadReportApi, {
            observe: 'response',
            params: httpParams,
        });
    }

    existByInstitutionIdAndStatusNotPending(institutionId: string,
    ): Observable<HttpResponse<any>> {
        return this.httpClient.get(`${this.apiReportDetails}/reports-submitted/${institutionId}`, {
            observe: 'response',
        });
    }

    downloadReportDetails(
        reportId: string,
    ): Observable<HttpResponse<any>> {
        return this.httpClient.get(`${this.apiReportDetails}/download/${reportId}`, {
            observe: 'response',
            responseType: 'blob',
        });
    }

    getReportDetails(
        reportId: string,
    ): Observable<HttpResponse<any>> {
        return this.httpClient.get(`${this.apiReportDetails}/${reportId}`, {
            observe: 'response',
        });
    }

    save(
        reportId: string,
        reportDetailUploadModel: ReportDetailUploadModel
    ): Observable<HttpResponse<ReportDetailUploadModel>> {
        return this.httpClient.put<ReportDetailUploadModel>(`${this.apiReportDetails}/${reportId}`, reportDetailUploadModel, {
            observe: 'response',
        });
    }

    delete(reportId: string
    ): Observable<HttpResponse<any>> {
        return this.httpClient.delete(
            `${this.apiReportDetails}/extracted/deleteExcelData/${reportId}`,
            { observe: 'response'
            });
    }

}
