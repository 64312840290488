import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { InstitutionModel } from 'src/app/model/institution.model';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';
import { AuthenticateService } from 'src/app/auth/authenticate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { InstitutionService } from 'src/app/services/institution.service';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationRolesService } from 'src/app/services/application-roles.service';
import { UserRolesDetailComponent } from '../user-roles-detail/user-roles-detail.component';
import { UserRolesNewOrEditComponent } from '../user-roles-new-or-edit/user-roles-new-or-edit.component';
import { ApplicationRoleModel } from 'src/app/model/application-roles.model';
import { GeneralService } from 'src/app/services/general.service';
import { DialogSimpleComponent } from 'src/app/components/shared/ui/dialog-simple/dialog-simple.component';
import { FORM_TEMPLATE_FIELD_INPUT_TYPE } from 'src/app/model/form-template-field.model';

@Component({
    selector: 'app-user-roles-listing',
    templateUrl: './user-roles-listing.component.html',
})
export class UserRolesListingComponent implements OnInit {

    dataSource = new MatTableDataSource<any>();
    obs: BehaviorSubject<any>;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayColumns = ['name', 'entity', 'active', 'actions'];

    searchFormGroup: FormGroup;
    institutions: InstitutionModel[];
    RoleFormGroup: FormGroup;

    pageNumbers: number[];
    numberOfRecords: number = 0;
    countLoad = 0;
    currentPage: number = 0;
    pageSize: number = 50;
    sortOrder: string = 'name,asc';
    loading: boolean = false;
    searchValue: String = null;

    isLoading = FORM_TEMPLATE_FIELD_INPUT_TYPE.LOADING;

    readonly ROLE_CREATE = ApplicationPermissionService.ROLE_CREATE;
    readonly ROLE_DELETE = ApplicationPermissionService.ROLE_DELETE;
    readonly ROLE_UPDATE = ApplicationPermissionService.ROLE_UPDATE;
    readonly ROLE_READ = ApplicationPermissionService.ROLE_READ;
    readonly PERMISSION_READ = ApplicationPermissionService.PERMISSION_READ;

    constructor(
        private applicationRolesService: ApplicationRolesService,
        private authService: AuthenticateService,
        private router: Router,
        public activatedRoute: ActivatedRoute,
        private institutionsService: InstitutionService,
        private matDialog: MatDialog,
        private generalService: GeneralService
    ) {
        this.dataSource.paginator = this.paginator;
    }

    ngOnInit(): void {
        this.activeLoading();
        this.institutionsService.getAll().subscribe({
            next: (value) => (this.institutions = value.body),
        });

        if (this.activatedRoute.snapshot.queryParams['page']) {
            this.currentPage = this.activatedRoute.snapshot.queryParams['page']
                ? this.activatedRoute.snapshot.queryParams['page']
                : undefined;
            this.sortOrder = this.activatedRoute.snapshot.queryParams['sort']
                ? this.activatedRoute.snapshot.queryParams['sort']
                : undefined;
            this.pageSize = this.activatedRoute.snapshot.queryParams['pageSize']
                ? this.activatedRoute.snapshot.queryParams['pageSize']
                : undefined;
        }
        this.refreshForm();
    }

    search(currentPage: number, searchValue: String) {
        this.searchValue = searchValue;
        this.applicationRolesService
            .getAllbyInstitution(currentPage, this.pageSize, this.sortOrder, {
                name: searchValue,
                currentInstitution: this.authService.getCurInsId(),
            })
            .subscribe({
                next: ({ body }) => {
                    this.dataSource.data = body.content;
                    this.numberOfRecords = body.totalElements;
                    this.deactiveLoading();
                },
                error: (error) => {
                    this.generalService.snackbarMessage(error.message, 'Close');
                    this.deactiveLoading();
                },
            });
    }

    refreshForm() {
        this.search(0, null);
    }

    activeLoading() {
        this.loading = true;
    }

    deactiveLoading() {
        this.loading = false;
    }

    getTimeString(dateToChange: Date): string {
        let newDate: Date = new Date(dateToChange.getTime() + 8 * 60 * 60 * 1000);
        return newDate.toISOString();
    }

    ngOnDestroy(): void {
        if (this.dataSource) {
            this.dataSource.disconnect();
        }
    }

    createRole(): void {
        this.router
            .navigate(['create'], {
                relativeTo: this.activatedRoute,
            })
            .then();
    }

    editRole(applicationRoleModel: ApplicationRoleModel): void {
        this.router
            .navigate(['edit'], {
                relativeTo: this.activatedRoute,
                state: {
                    applicationRoleModel,
                },
            })
            .then();
    }

    sortPage(event): void {
        if (event.active === 'entity') {
            this.sortOrder = `i.name,${event.direction}`;
        } else {
            this.sortOrder = `${event.active},${event.direction}`;
        }
        this.search(this.currentPage, this.searchValue);
    }

    navigateToPage(event): void {
        this.currentPage = event.pageIndex;
        this.pageSize = event.pageSize;
        this.search(this.currentPage, this.searchValue);
    }

    openUserRolesDialogCreateOrEdit(applicationRoleModel?: ApplicationRoleModel) {
        if(applicationRoleModel === undefined){
            this.passDataToDialogCreateOrEdit(applicationRoleModel);
        }else{
            this.applicationRolesService.getAuthorityById(applicationRoleModel.id).subscribe({
                next: (value) =>{
                    this.passDataToDialogCreateOrEdit(value.body);
                }
            });
        }
    }

    passDataToDialogCreateOrEdit(event: any){
        const modalRef = this.matDialog.open(UserRolesNewOrEditComponent, {
            data: event,
            panelClass: 'mat-dialog-lg'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
            if (yes) this.search(this.currentPage, this.searchValue);
        }})
    }

    openUserRolesDialogDetail(applicationRoleModel?: ApplicationRoleModel) {
        this.applicationRolesService.getAuthorityById(applicationRoleModel.id).subscribe({
            next: (value) =>{
                const modalRef = this.matDialog.open(UserRolesDetailComponent, {
                    data: value.body,
                    panelClass: 'mat-dialog-md',
                });

                modalRef.afterClosed().subscribe({
                    next: (edit) => {
                        if (edit) this.openUserRolesDialogCreateOrEdit(applicationRoleModel);
                    },
                });
            }
        });
    }

    openUserRolesDialogDelete(applicationRoleModel: ApplicationRoleModel): void {
        const modalRef = this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: 'Confirm Delete Authority?',
                message: `Are you sure want to delete ${applicationRoleModel.name}?`,
                buttonPositive: 'Yes',
                buttonNegative: 'No',
            },
            panelClass: 'mat-dialog-sm',
        });

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) {
                    this.applicationRolesService.delete(applicationRoleModel.id.toString()).subscribe({
                        next: () => {
                            this.search(this.currentPage, this.searchValue);
                        },
                        error: (error) => {
                            this.generalService.snackbarMessage(error.message, 'Close');
                        },
                    });
                }
            },
        });
    }
}
