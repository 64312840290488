import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators  } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FORM_TEMPLATE_FIELD_INPUT_TYPE } from 'src/app/model/form-template-field.model';
import { GeneralService } from 'src/app/services/general.service';
import { passwordValidator } from 'src/app/components/shared/validator/password.validator';
import { UsersService } from 'src/app/services/users.service';
import { AuthenticateService } from 'src/app/auth/authenticate.service';


@Component({
  selector: 'app-user-dialog-reset-password',
  templateUrl: './user-dialog-reset-password.component.html',
})
export class UserDialogResetPasswordComponent implements OnInit {

	loading: boolean = false;
	isSaving: boolean = false;
	applicationUserFormGroup : FormGroup;

	password = FORM_TEMPLATE_FIELD_INPUT_TYPE.PASSWORD;
    confirmPassword = FORM_TEMPLATE_FIELD_INPUT_TYPE.CONFIRMPASSWORD;

    constructor(
		public dialogRef: MatDialogRef<UserDialogResetPasswordComponent>,
        private formBuilder: FormBuilder,
		private userService : UsersService,
        private generalService: GeneralService,
		private authService: AuthenticateService,
	) { }

    ngOnInit(): void {
		this.applicationUserFormGroup = this.formBuilder.group({
			currentPassword: this.formBuilder.control(null,Validators.required),
			newPassword: this.formBuilder.control(null,[Validators.required, Validators.minLength(8)]),
			repeatPassword: this.formBuilder.control(null, Validators.required),
		},
		{
			validators: [passwordValidator()],
		});
    }

	save():void{
		this.isSaving = true;
        this.activeLoading();
        const getRawValue = this.applicationUserFormGroup.getRawValue();
		this.userService.resetPassword(getRawValue).subscribe({
			next: () => { 
				this.isSaving = false;
				this.deactiveLoading();
				this.dialogRef.close('ok');
				this.authService.logout();
			},
			error: error => {
				this.isSaving = false;
				this.deactiveLoading();
				this.generalService.snackbarMessage( error.message,'Close');
			}
		});
	}

	activeLoading(){
        this.loading = true;
    }

    deactiveLoading(){
        this.loading = false;
    }

}
