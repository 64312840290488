import { Injectable, } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { AuthenticateService } from '../auth/authenticate.service';
import { Observable } from 'rxjs';
import { CountryModel } from '../model/country.model';
import { SERVER_API } from '../app.constant';


@Injectable({
    providedIn: 'root'
})
export class CountryService {

    private apiCountry: string = SERVER_API + 'api/common-component/admin/countries';

    constructor(private httpClient: HttpClient, private authService: AuthenticateService) { }

    save(
        countryModel : CountryModel
    ):Observable<HttpResponse<CountryModel>>{
        return this.httpClient.post<CountryModel>( this.apiCountry, countryModel, {
            observe: 'response',
        });
    }

    getAllCountryList(
        countryPageNumber: number,
        countryPageSize: number,
        countrySortOrder: string,
        criteria
    ): Observable<any> {
        let countryHttpParams = new HttpParams();
        if (countryPageNumber !== undefined || countryPageNumber !== null) {
            countryHttpParams = countryHttpParams.set('page', `${countryPageNumber}`);
        }
        if (countryPageSize) {
            countryHttpParams = countryHttpParams.set('size', `${countryPageSize}`);
        }
        if (countrySortOrder !== undefined ||   countrySortOrder !== null) {
            countryHttpParams = countryHttpParams.set('sort', countrySortOrder);
        }

        if (criteria) {
            if (criteria.code) {
                countryHttpParams = countryHttpParams.set('code', criteria.code);
            }
            if (criteria.name) {
                countryHttpParams = countryHttpParams.set('name', criteria.name);
            }
        }
        return this.httpClient.get(`${this.apiCountry}`, {
            observe: 'response',
            params: countryHttpParams,
        });
    }
    
    update(
        countryModel: CountryModel,
    ): Observable<HttpResponse<CountryModel>> {
        const countryId = countryModel.id;
        return this.httpClient.put<CountryModel>(this.apiCountry + '/' + countryId,countryModel, {
            observe: 'response',
        });
    }

    delete(countryId: string): Observable<HttpResponse<CountryModel[]>> {
        let countryHttpParams = new HttpParams();
        countryHttpParams = countryHttpParams.set('currentInstitutionId', this.authService.getCurInsId());
        return this.httpClient.delete<CountryModel[]>(this.apiCountry + '/' + countryId, {
            observe: 'response',
            params: countryHttpParams,
        });
    }

    getCountryById(countryId: string): Observable<HttpResponse<CountryModel[]>> {
        return this.httpClient.get<CountryModel[]>(this.apiCountry+ '/' + countryId, {
            observe: 'response',
        });
    }
}
