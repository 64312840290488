<div class="mx-10 my-6" *appScreenPermission="[SYSTEM_CONFIGURATION_READ]">
    <mx-title-bar title="System Configuration" buttonIcon="add" buttonLabel="Add System Configuration"
        (onButtonClick)="openSystemConfigDialogCreateOrEdit()" [buttonPermission]="[SYSTEM_CONFIGURATION_CREATE]"></mx-title-bar>
     <mx-search-bar searchFieldName="Name" (onSubmit)="search(0, $event)"></mx-search-bar>
    <div class="my-2">
        <strong>{{ sysConfigNumberOfRecords }}</strong> result<span *ngIf="sysConfigNumberOfRecords > 1">s</span>
    </div>
    <mat-card-content class="mx-content-table">
        <div *ngIf="loading">
            <mx-data-loading></mx-data-loading>
        </div>
        <table mat-table matSort (matSortChange)="sortPage($event)" [dataSource]="sysConfigDataSource" *ngIf="sysConfigDataSource.data"
            class="w-full" aria-label="table-segment">
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.code || '-' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.description || '-' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef class="text-white">Value</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.value || '-' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="text-white">Actions</th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon class="mr-4" (click)="openSystemConfigDialogCreateOrEdit(element)" matTooltip="Edit"
                        class="cursor-pointer" *appScreenPermission="[SYSTEM_CONFIGURATION_UPDATE]">edit</mat-icon>
                    <mat-icon (click)="openSystemConfigDialogDetail(element)" matTooltip="View"
                        class="cursor-pointer">remove_red_eye</mat-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell text-center" colspan="6">No data match your search "{{ sysConfigSearchValue }}"</td>
            </tr>
        </table>
    </mat-card-content>
    <mat-paginator (page)="navigateToPage($event)" [length]="sysConfigNumberOfRecords" [pageSize]="sysConfigPageSize"
        [pageSizeOptions]="[5, 10, 20, 50]" [showFirstLastButtons]="true" class="mx-paginator">
    </mat-paginator>
</div>
