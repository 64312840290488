//input type
export enum FORM_TEMPLATE_FIELD_INPUT_TYPE {
    TEXT = 'Text',
    TEXTAREA = 'Textarea',
    SELECT = 'Select',
    MULTISELECT = 'MultiSelect',
    NUMBER = 'Number',
    CHECKBOX = 'Checkbox',
    LABEL = 'Label',
    PASSWORD = 'Password',
    CONFIRMPASSWORD = 'ConfirmPassword',
    PHONE = 'Phone',
    LOADING = 'Loading',
    UPLOAD_IMAGE='UploadImage',
    UPLOAD_FILE='UploadFile'
}
