import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { ReportUploadCbsDialogDetailComponent } from './report-upload-cbs-dialog-detail/report-upload-cbs-dialog-detail.component';
import { ReportUploadCbsDialogUploadComponent } from './report-upload-cbs-dialog-upload/report-upload-cbs-dialog-upload.component';
import { ReportUploadCbsListingComponent } from './report-upload-cbs-listing/report-upload-cbs-listing.component';
import { SharedModule } from '../../shared/shared.module';

const routes: Routes = [
  {
      path: '',
      component: ReportUploadCbsListingComponent,
  },
];

@NgModule({
  declarations: [
    ReportUploadCbsListingComponent,
    ReportUploadCbsDialogDetailComponent,
    ReportUploadCbsDialogUploadComponent
  ],
  imports: [
    CommonModule, 
    SharedModule, 
    RouterModule.forChild(routes), 
    FormsModule, 
    ReactiveFormsModule
],

})
export class ReportsUploadCbsModule { }
