<div class="flex justify-between items-center px-6 py-4 border-b border-b-gray-light">
    <div class="text-lg font-bold">View Report Upload</div>
    <button type="button" data-dismiss="modal" class="text-2xl" (click)="dialogRef.close()">&times;</button>
</div>
<div class="max-h-[70vh] overflow-auto p-6 relative whitespace-pre-line">
    <div class="grid grid-cols-2 gap-4">
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Reporting Year</label>
            <div class="mb-6 break-all">
                {{ reportUploadModel?.reportingYear || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Reporting Month</label>
            <div class="mb-6 break-all">
                {{ reportUploadModel?.reportingMonth || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Project</label>
            <div class="mb-6 break-all" maxlength="100">
                {{ reportUploadModel?.projectName || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Status</label>
            <div class="mb-6 break-all" maxlength="100">
                {{ reportUploadModel?.status || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Company</label>
            <div class="mb-6 break-all" maxlength="100">
                {{ reportUploadModel?.companyName || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Function</label>
            <div class="mb-6 break-all" maxlength="100">
                {{ reportUploadModel?.function || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Unit</label>
            <div class="mb-6 break-all" maxlength="100">
                {{ reportUploadModel?.unit || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Team</label>
            <div class="mb-6 break-all" maxlength="100">
                {{ reportUploadModel?.team || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Submitted By</label>
            <div class="mb-6 break-all" maxlength="100">
                {{ reportUploadModel?.submittedBy || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Submitted Date</label>
            <div class="mb-6 break-all" maxlength="100">
                {{ reportUploadModel?.submittedDate || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Submitted File Name</label>
            <div class="mb-6 break-all" maxlength="100">
                {{ reportUploadModel?.submittedFilename || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Remark</label>
            <div class="mb-6 break-all" maxlength="100">
                {{ reportUploadModel?.remark || '-' }}
            </div>
        </div>
    </div>
</div>
<div class="flex justify-end p-6 border-t border-gray-light gap-2">
    <button type="button" mat-raised-button class="m-1" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">clear</mat-icon>
        <span class="text-sm font-bold">Close</span>
    </button>

    <button type="button" mat-raised-button class="m-1" (click)="dialogRef.close('edit')" color="accent">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">edit</mat-icon>
        <span class="text-sm font-bold">Edit</span>
    </button>
</div>
