<div class="flex justify-between items-center px-6 py-4 border-b border-b-gray-light">
    <div class="text-lg font-bold">View Project '{{ projectModel.name }}'</div>
    <button type="button" data-dismiss="modal" class="text-2xl" (click)="dialogRef.close()">&times;</button>
</div>
<div class="max-h-[70vh] overflow-auto p-6 relative whitespace-pre-line">
    <div class="grid grid-cols-2 gap-4">
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Name</label>
            <div class="mb-6 break-all" maxlength="100">
                {{ projectModel.name || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Status</label>
            <div
                *ngIf="projectModel.status === 'ACTIVE'"
                class="w-fit border-0 text-center rounded-xl px-2 py-0.5 mb-6 break-all bg-green-light text-green"
            >
                Active
            </div>
            <div
                *ngIf="projectModel.status === 'INACTIVE'"
                class="w-fit border-0 text-center rounded-xl px-2 py-0.5 mb-6 break-all bg-gray text-white"
            >
                Inactive
            </div>
            <div *ngIf="projectModel.status === 'PROCESSING_INACTIVE'"
                 class="w-fit border-0 text-center rounded-xl px-2 py-0.5 text-black"
                 style="background-color: #FFA500;">
                Processing Inactive
            </div>
            <div *ngIf="projectModel.status === 'PROCESSING_DELETE'"
                 class="w-fit border-0 text-center rounded-xl px-2 py-0.5 text-black"
                 style="background-color: #FFA500;">
                Processing Delete
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Code</label>
            <div class="mb-6 break-all" maxlength="100">
                {{ projectModel.code || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Description</label>
            <div class="mb-6 break-all">
                {{ projectModel.description || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Type</label>
            <div class="mb-6 break-all" maxlength="100">
                {{ projectModel.type || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Company</label>
            <div class="mb-6 break-all">
                {{ projectModel.company?.name || '-' }}
            </div>
        </div>
        <div class="col-span-2">
            <label class="text-sm text-gray font-bold">Entities</label>
            <div class="mb-6 break-all">
                <mat-chip-list>
                    <mat-chip *ngFor="let item of projectModel.institutions" [removable]="false">
                        {{ item.name }}
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
        <div class="col-span-2">
            <label class="text-sm text-gray font-bold">Form Type Configurations</label>
            <div class="mb-6 break-all">
                <mat-chip-list>
                    <mat-chip *ngFor="let item of projectModel.formTypeConfigurations" [removable]="false">
                        {{ item.code }}
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">LinkedProject</label>
            <div *ngIf="projectModel.linkedProject"class="mb-6 break-all">
                {{ projectModel.linkedProject.name || '-' }}
            </div>
            <div *ngIf="!projectModel.linkedProject"class="mb-6 break-all">
                {{ '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Remark</label>
            <div *ngIf="projectModel.remark"class="mb-6 break-all">
                {{ projectModel?.remark || '-' }}
            </div>
        </div>
    </div>
</div>
<div class="flex justify-end p-6 border-t border-gray-light gap-2">
    <button type="button" mat-raised-button class="m-1" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">clear</mat-icon>
        <span class="text-sm font-bold">Close</span>
    </button>

    <button type="button" mat-raised-button class="m-1" (click)="dialogRef.close('edit')" color="accent" *appScreenPermission="[PROJECT_UPDATE]">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">edit</mat-icon>
        <span class="text-sm font-bold">Edit</span>
    </button>
</div>
