<div class="flex items-center justify-center bg-gray-light w-full">
    <div class="w-screen max-w-lg !rounded-xl bg-white p-4 pt-0">
        <button type="button" data-dismiss="modal" class="text-[20px] focus:outline-none float-right"
            (click)="dialogRef.close()">
            &times;
        </button>
        <mat-card-header class="flex justify-center text-center">
            <mat-card-title>
                <img class="m-auto pb-5 w-1/2" src="assets/skyworld-logo-circle.png" alt="SkyWorld Logo" />
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="text-center">
                <h2 class="text-2xl font-bold">Change Password</h2>
            </div>
            <div class="text-center">
                <h4>
                    Enter the current password you're using for your account and the new password.
                </h4>
            </div>
            <div [formGroup]="applicationUserFormGroup">
                <mx-input-field class="col-span-2 md:col-span-1" [inputType]="password"
                    [formGroupChild]="applicationUserFormGroup" formControlNameChild="currentPassword"
                    label="Current Password" placeholder="Enter current password" errorMessage="Password is required"
                    [required]="true"></mx-input-field>
                <mx-input-field class="col-span-2 md:col-span-1" [inputType]="password"
                    [formGroupChild]="applicationUserFormGroup" formControlNameChild="newPassword" label="New Password"
                    placeholder="Enter new password" errorMessage="Password is required" [required]="true"
                    [showMeter]="true" [minLength]="8" [maxLength]="15"></mx-input-field>
                <mx-input-field class="col-span-2 md:col-span-1" [inputType]="confirmPassword"
                    [formGroupChild]="applicationUserFormGroup" formControlNameChild="repeatPassword"
                    label="Confirm New Password" placeholder="Confirm New Password"
                    errorMessage="Password and Re-enter password not same" [required]="true"
                    passwordFormControlName="newPassword"></mx-input-field>
            </div>
        </mat-card-content>
        <br>
        <button class="w-full" type="button" mat-raised-button color="primary" (click)="save()"
            [disabled]="isSaving || applicationUserFormGroup.invalid">
            <span>SAVE CHANGES</span>
        </button>
    </div>
</div>