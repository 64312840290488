<div class="flex justify-between items-center px-6 py-4 border-b border-b-gray-light">
    <div class="text-lg font-bold">
        <ng-container *ngIf="userDialogData.dialogType === 'create'; else elseTemplate"> Create New User </ng-container>
        <ng-template #elseTemplate> Edit User '{{ userDialogData.userModel.fullName }}' </ng-template>
    </div>
    <button type="button" data-dismiss="modal" class="text-2xl focus:outline-none" (click)="dialogRef.close()">
        &times;
    </button>
</div>
<div *ngIf="loading">
    <mx-data-loading></mx-data-loading>
</div>
<div class="max-h-[70vh] overflow-auto p-6 relative whitespace-pre-line">
    <form [formGroup]="userFormGroup">
        <div class="grid grid-cols-2 gap-x-4">
            <mx-input-field class="col-span-2 md:col-span-1" [inputType]="text" [formGroupChild]="userFormGroup"
                formControlNameChild="email" label="Email" placeholder="Enter email" [maxLength]="100"
                [autoFocusChild]="true" errorMessage="Email is invalid" [required]="true"></mx-input-field>
            <mx-input-field class="col-span-2 md:col-span-1 flex items-center" [inputType]="checkbox"
                [formGroupChild]="userFormGroup" formControlNameChild="active" label="Active"></mx-input-field>
            <mx-input-field class="col-span-2" [inputType]="text" [formGroupChild]="userFormGroup"
                formControlNameChild="fullName" label="Full Name" placeholder="Enter full name" [maxLength]="200"
                errorMessage="Full name is required" [required]="true"></mx-input-field>
            <mx-input-field *ngIf="userDialogData.dialogType === 'create'" class="col-span-2 md:col-span-1"
                [inputType]="password" [formGroupChild]="userFormGroup" formControlNameChild="newPassword"
                label="New Password" placeholder="Enter new password" errorMessage="Password is required"
                [required]="true" [showMeter]="true" [maxLength]="15"></mx-input-field>
            <mx-input-field *ngIf="userDialogData.dialogType === 'create'" class="col-span-2 md:col-span-1"
                [inputType]="confirmPassword" [formGroupChild]="userFormGroup" formControlNameChild="repeatPassword"
                label="Re-enter New Password" placeholder="Re-enter New Password"
                errorMessage="Password and Re-enter password not same" [required]="true"
                passwordFormControlName="newPassword"></mx-input-field>
            <mx-input-field class="col-span-2" [inputType]="phone" [formGroupChild]="userFormGroup"
                formControlNameChild="phoneNumber" label="Phone Number" errorMessage="Invalid phone number"></mx-input-field>
            <mx-input-field class="col-span-2" [inputType]="multiSelect" [formGroupChild]="userFormGroup"
                formControlNameChild="authorities" label="Authority" placeholder="Select authority"
                [compareWithChild]="compareObjects" [disabledChild]="false" [list]="authoritiesList"
                errorMessage="Authority is required" [required]="true"></mx-input-field>
            <mx-input-field class="col-span-2 md:col-span-1" [inputType]="select" [formGroupChild]="userFormGroup"
                formControlNameChild="country" label="Country" placeholder="Select country"
                [compareWithChild]="compareObjects" [disabledChild]="false" [list]="countries"
                errorMessage="Country is required" [required]="true"></mx-input-field>
            <mx-input-field class="col-span-2 md:col-span-1" [inputType]="select" [formGroupChild]="userFormGroup"
                formControlNameChild="employmentType" label="Employment Type" placeholder="Select employment type"
                [compareWithChild]="compareObjects" [disabledChild]="false" [list]="employmentTypes"
                errorMessage="Employment type is required" [required]="true"></mx-input-field>
            <mx-input-field *ngIf="userDialogData.dialogType === 'edit'" class="col-span-2 md:col-span-1"
                [inputType]="text" [formGroupChild]="userFormGroup" formControlNameChild="referenceNumber"
                label="Reference Number" placeholder="Enter reference number" [maxLength]="100"></mx-input-field>
            <mx-input-field *ngIf="userDialogData.dialogType === 'edit'" class="col-span-2 md:col-span-1"
                [inputType]="text" [formGroupChild]="userFormGroup" formControlNameChild="loginFailAttempt"
                label="Failed Login Attempt" placeholder="Enter failed login attempt"
                [maxLength]="100"></mx-input-field>
        </div>
    </form>
</div>

<div class="flex justify-end p-6 border-t border-gray-light gap-2" *ngIf="!loading">
    <button type="button" mat-raised-button class="m-1" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">clear</mat-icon>
        <span class="text-sm font-bold">Cancel</span>
    </button>
    <button type="button" mat-raised-button class="m-1" [disabled]="isSaving || userFormGroup.invalid" (click)="save()"
        color="accent">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">save</mat-icon>
        <span class="text-sm font-bold">Save</span>
    </button>
</div>