<div class="flex justify-between items-center px-6 py-4 border-b border-b-gray-light">
    <div class="text-lg font-bold">
        <ng-container>
            Report Upload
        </ng-container>
    </div>
    <button type="button" data-dismiss="modal" class="text-2xl focus:outline-none" (click)="dialogRef.close()">
        &times;
    </button>
</div>
<div *ngIf="loading">
    <mx-data-loading></mx-data-loading>
</div>
<div class="max-h-[70vh] overflow-auto p-6 relative whitespace-pre-line">
    <form [formGroup]="reportUploadFormGroup">
        <div class="grid grid-cols-2 gap-x-4">
            <mx-input-field class="col-span-2 md:col-span-1"
                [inputType]="text"
                [formGroupChild]="reportUploadFormGroup"
                formControlNameChild="reportingYear"
                label="Reporting Year"
                disabled>
            </mx-input-field>
            <mx-input-field class="col-span-2 md:col-span-1"
                            [inputType]="text"
                            [formGroupChild]="reportUploadFormGroup"
                            formControlNameChild="reportingMonth"
                            label="Reporting Month"
                            disabled>
            </mx-input-field>
            <mx-input-field class="col-span-2 md:col-span-1"
                [inputType]="text"
                [formGroupChild]="reportUploadFormGroup"
                formControlNameChild="projectName"
                label="Project"
                disabled>
            </mx-input-field>
            <mx-input-field class="col-span-2 md:col-span-1"
                [inputType]="text"
                [formGroupChild]="reportUploadFormGroup"
                formControlNameChild="companyName"
                label="Company"
                disabled>
            </mx-input-field>
            <mx-input-field class="col-span-2 md:col-span-1"
                [inputType]="text"
                [formGroupChild]="reportUploadFormGroup"
                formControlNameChild="function"
                label="Function"
                disabled>
            </mx-input-field>
            <mx-input-field class="col-span-2 md:col-span-1"
                [inputType]="text"
                [formGroupChild]="reportUploadFormGroup"
                formControlNameChild="unit"
                label="Unit">
            </mx-input-field>
            <mx-input-field class="col-span-2 md:col-span-1"
                [inputType]="text"
                [formGroupChild]="reportUploadFormGroup"
                formControlNameChild="team"
                label="Team">
            </mx-input-field>
            <mx-input-field class="col-span-2 md:col-span-1"
                            [inputType]="text"
                            [formGroupChild]="reportUploadFormGroup"
                            formControlNameChild="submittedBy"
                            label="Submitted By">
            </mx-input-field>
            <mx-input-field class="col-span-2 md:col-span-1"
                [inputType]="text"
                [formGroupChild]="reportUploadFormGroup"
                formControlNameChild="submittedFilename"
                label="Submitted File Name">
            </mx-input-field>
            <mx-attachment class="col-span-2 md:col-span-1"
                [formGroupChild]="reportUploadFormGroup"
                formControlNameChild="attachment"
                label="Attachment"
                (onSelectionChange)="onFileSelected($event)">
            </mx-attachment>
        </div>
    </form>
</div>
<div class="flex justify-end p-6 border-t border-gray-light gap-2" *ngIf="!loading">
    <button type="button" mat-raised-button class="m-1" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">clear</mat-icon>
        <span class="text-sm font-bold">Cancel</span>
    </button>
    <button type="button" mat-raised-button class="m-1" [disabled]="isSaving || reportUploadFormGroup.invalid"
        (click)="save()" color="accent">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">save</mat-icon>
        <span class="text-sm font-bold">Save</span>
    </button>
</div>
