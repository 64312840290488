import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstitutionsModule } from './institutions/institutions.module';
import { SysConfigModule } from './sys-config/sys-config.module';
import { UsersModule } from './users/users.module';
import { UserRolesModule } from './user-roles/user-roles.module';
import { CountryModule } from './country/country.module';
import { EmploymentTypeModule } from './employment-type/employment-type.module';
import { ProjectModule } from './project/project.module';
import { GlCostCodeModule } from './gl-cost-code/gl-cost-code.module';
import { FormTypeConfigurationModule } from './form-type-configuration/form-type-configuration.module';
import { CompanyModule } from './company/company.module';
import { SchedulerModule } from './scheduler/scheduler.module';
import { RioTemplateTypesModule } from './rio-template-types/rio-template-types.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        InstitutionsModule,
        CompanyModule,
        SysConfigModule,
        UsersModule,
        UserRolesModule,
        CountryModule,
        EmploymentTypeModule,
        ProjectModule,
        GlCostCodeModule,
        FormTypeConfigurationModule,
        SchedulerModule,
        RioTemplateTypesModule
    ],
})
export class ConfigurationModule {}
