import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable, } from '@angular/core';
import { Observable } from 'rxjs';
import { RIO_REPORT_COMPONENT } from '../app.constant';
import { AuthenticateService } from '../auth/authenticate.service';


@Injectable({
    providedIn: 'root'
})
export class RioReportHeaderService {

    private apiReportHeader: string = RIO_REPORT_COMPONENT + '/report-headers';

    constructor(private httpClient: HttpClient, private authService: AuthenticateService) { }

    getReportingYearList(): Observable<HttpResponse<string[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('institutionId', this.authService.getCurInsId());
        return this.httpClient.get<string[]>(this.apiReportHeader + '/reporting-year-list', {
            observe: 'response',
            params: httpParams,
        });
    }

}
