import { Injectable, } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { AuthenticateService } from '../auth/authenticate.service';
import { Observable } from 'rxjs';
import { REPORT_COMPONENT } from '../app.constant';
import { ReportUploadModel } from '../model/report-upload.model';
import { ReportDetailUploadModel } from '../model/report-detail-upload.model';


@Injectable({
    providedIn: 'root'
})
export class ReportDetailService {

    private apiReportDetails: string = REPORT_COMPONENT + '/report-details';
    private apiReportUploadList: string = '/get-upload-report';

    constructor(private httpClient: HttpClient, private authService: AuthenticateService) { }

    getReportUploadList(
        reportingYear: string,
        companyId: string,
        projectId: string,
        formType: string,
        pageNumber: number,
        pageSize: number,
        sortOrder: string[],
        institutionId: string,
        criteria
    ): Observable<HttpResponse<any>> {
        let httpParams = new HttpParams();
        if (pageNumber !== undefined || pageNumber !== null) {
            httpParams = httpParams.set('page', `${pageNumber}`);
        }
        if (pageSize) {
            httpParams = httpParams.set('size', `${pageSize}`);
        }
        if (sortOrder !== undefined && sortOrder !== null) {
            // Loop through sortOrder array and append each value to the 'sort' parameter
            sortOrder.forEach(order => {
                httpParams = httpParams.append('sort', order);
            });
        }

        if (formType) {
            httpParams = httpParams.set('formType', formType);
        }

        if (companyId) {
            httpParams = httpParams.set('companyId', companyId);
        }

        if (projectId) {
            httpParams = httpParams.set('projectId', projectId);
        }

        if (criteria.name) {
            if (criteria.formType) {
                httpParams = httpParams.set('formType', criteria.formType);
            }
            // if (criteria.reportName) {
            //     httpParams = httpParams.set('reportName', criteria.reportName);
            // }
            // if (criteria.reportingYear) {
            //     httpParams = httpParams.set('reportingYear', criteria.reportingYear);
            // }
            // if (criteria.name) {
            //     httpParams = httpParams.set('name', criteria.name);
            // }
            if (criteria.status) {
                httpParams = httpParams.set('status', criteria.status);
            }
        }
        httpParams = httpParams.set('institutionId', institutionId);
        httpParams = httpParams.set('year', reportingYear);
        return this.httpClient.get(`${this.apiReportDetails + this.apiReportUploadList}`, {
            observe: 'response',
            params: httpParams,
        });
    }

    save(
        reportId: string,
        reportDetailUploadModel : ReportDetailUploadModel
    ):Observable<HttpResponse<ReportDetailUploadModel>>{
        return this.httpClient.put<ReportDetailUploadModel>(`${this.apiReportDetails}/${reportId}` , reportDetailUploadModel, {
            observe: 'response',
        });
    }

    delete(reportId: string
    ): Observable<HttpResponse<any>> {
        return this.httpClient.delete(
            `${this.apiReportDetails}/extracted/deleteExcelData/${reportId}`,
            { observe: 'response'
            });
    }

    getReportDetails(
        reportId: string,
    ): Observable<HttpResponse<any>> {
        return this.httpClient.get(`${this.apiReportDetails}/${reportId}`, {
            observe: 'response',
        });
    }

    downloadReportDetails(
        reportId: string,
    ): Observable<HttpResponse<any>> {
        return this.httpClient.get(`${this.apiReportDetails}/download/${reportId}`, {
            observe: 'response',
            responseType: 'blob',
        });
    }

    existByInstitutionIdAndStatusNotPending(institutionId: string,
                                            ): Observable<HttpResponse<any>> {
        return this.httpClient.get(`${this.apiReportDetails}/existByInstitutionIdAndStatusNotPending/${institutionId}`, {
            observe: 'response',
        });
    }

}
