import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormTypeConfigurationModel } from 'src/app/model/form-type-configuration.model';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';

@Component({
    selector: 'app-form-type-configuration-dialog-detail',
    templateUrl: './form-type-configuration-dialog-detail.component.html',
})
export class FormTypeConfigurationDialogDetailComponent {
    readonly FORM_TYPE_UPDATE = ApplicationPermissionService.FORM_TYPE_UPDATE;

    constructor(
        @Inject(MAT_DIALOG_DATA) public formTypeConfigurationModel: FormTypeConfigurationModel,
        public dialogRef: MatDialogRef<FormTypeConfigurationDialogDetailComponent>
    ) {}
}
