import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountryListingComponent } from './country-listing/country-listing.component';
import { CountryDialogDetailComponent } from './country-dialog-detail/country-dialog-detail.component';
import { CountryDialogNewOrEditComponent } from './country-dialog-new-or-edit/country-dialog-new-or-edit.component';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const routes: Routes = [
  {
      path: '',
      component: CountryListingComponent,
      // canActivate: [
      //     AuthenticatedGuard
      // ]
  },
];

@NgModule({
  declarations: [
    CountryListingComponent,
    CountryDialogDetailComponent,
    CountryDialogNewOrEditComponent
  ],
  imports: [CommonModule, SharedModule, RouterModule.forChild(routes), FormsModule, ReactiveFormsModule],

})
export class CountryModule { }
