module.exports = {
  content: ["./src/**/*.{html,js}"],
  theme: {
    screens: {
      sm: '480px',
      md: '768px',
      lg: '976px',
      xl: '1440px',
    },
    colors: {
      'primary': '#131B3B',
      'accent': '#ffbf62',
      'green': '#33B469',
      'green-light': '#DBF4E7',
      'green-celadon': '#AFE1AF',
      'green-emerald': '#50C878',
      'gray': '#595959',
      'gray-light': '#f3f3f3',
      'medium-gray':'#ACACAC',
      'white': '#FFFFFF',
      'dark': '#1C1C1C',
      'light-blue': '#E2E6F3'
    },
    fontFamily: {
      sans: ['Roboto', 'sans-serif'],
      serif: ['Merriweather', 'serif'],
    },
    extend: {
      animation: {
        'spin': 'spin 2s linear infinite',
      },
      spacing: {
        '128': '32rem',
        '144': '36rem',
      },
      borderRadius: {
        '4xl': '2rem',
      }
    }
  },
  plugins: [],
};
