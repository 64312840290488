import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Router } from '@angular/router';
import { AuthenticateService } from 'src/app/auth/authenticate.service';
import { ApplicationPermissionModel } from 'src/app/model/application-permission.model';
import { ApplicationRoleModel } from 'src/app/model/application-roles.model';
import { FORM_TEMPLATE_FIELD_INPUT_TYPE } from 'src/app/model/form-template-field.model';
import { InstitutionModel } from 'src/app/model/institution.model';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';
import { ApplicationRolesService } from 'src/app/services/application-roles.service';
import { GeneralService } from 'src/app/services/general.service';
import { InstitutionService } from 'src/app/services/institution.service';

interface ApplicationPermissionNode {
    name: string;
    code: string;
    children: ApplicationPermissionNode[];
}

interface ApplicationPermissionFlatNode {
    name: string;
    code: string;
    level: number;
    expandable: boolean;
}

@Component({
    selector: 'app-user-roles-new-or-edit',
    templateUrl: './user-roles-new-or-edit.component.html',
})
export class UserRolesNewOrEditComponent implements OnInit {
    text = FORM_TEMPLATE_FIELD_INPUT_TYPE.TEXT;
    checkbox = FORM_TEMPLATE_FIELD_INPUT_TYPE.CHECKBOX;
    select = FORM_TEMPLATE_FIELD_INPUT_TYPE.SELECT;

    dialogType: string = '';
    applicationRoleFormGroup: FormGroup;
    isSaving: boolean = false;
    treeControl: FlatTreeControl<ApplicationPermissionFlatNode>;
    treeFlattener: MatTreeFlattener<ApplicationPermissionNode, ApplicationPermissionFlatNode>;
    dataSource: MatTreeFlatDataSource<ApplicationPermissionNode, ApplicationPermissionFlatNode>;
    hasChild = (_: number, node: ApplicationPermissionFlatNode) => node.expandable;
    applicationPermissionModelByName: Map<string, ApplicationPermissionModel>;
    institutions: InstitutionModel[] = [];
    loading: boolean = true;
    institutionSelectionList: InstitutionModel[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public applicationRoleModel: ApplicationRoleModel,
        public dialogRef: MatDialogRef<UserRolesNewOrEditComponent>,
        private formBuilder: FormBuilder,
        private applicationRolesService: ApplicationRolesService,
        private router: Router,
        private snackBar: MatSnackBar,
        private applicationPermissionsService: ApplicationPermissionService,
        private institutionsService: InstitutionService,
        private authService: AuthenticateService,
        private generalService: GeneralService
    ) {}

    ngOnInit(): void {
        if (this.applicationRoleModel) {
            this.dialogType = 'update';
            this.applicationRoleFormGroup = this.formBuilder.group({
                id: this.formBuilder.control(this.applicationRoleModel.id),
                name: this.formBuilder.control({ value: this.applicationRoleModel.name, disabled: true }, Validators.required),
                active: this.formBuilder.control(this.applicationRoleModel.active),
                institution: this.formBuilder.control(this.applicationRoleModel.institution , Validators.required),
                permissions: this.formBuilder.array(
                    this.applicationRoleModel.permissions.map((value) =>
                        this.formBuilder.group({
                            id: value.id,
                            name: value.name,
                            code: value.code,
                        })
                    )
                ),
            });
        } else {
            this.dialogType = 'create';
            this.applicationRoleFormGroup = this.formBuilder.group({
                name: this.formBuilder.control(null, Validators.required),
                active: this.formBuilder.control(false),
                institution: this.formBuilder.control(null, Validators.required),
                permissions: this.formBuilder.array([]),
            });
        }

        this.applicationPermissionsService.getAllNoPagination().subscribe({
            next: (httpResponse) => {
                httpResponse.body.sort((body: any) => body.category === "Menu Access" ? -1 : 1)
                this.dataSource.data = UserRolesNewOrEditComponent.constructApplicationPermissionNode(
                    httpResponse.body
                );
                httpResponse.body.forEach((applicationPermissionModel) => {
                    this.applicationPermissionModelByName.set(
                        applicationPermissionModel.code,
                        applicationPermissionModel
                    );
                });
                this.deactiveLoading();
            },
        });
        this.institutionsService.getAll().subscribe({
            next: (value) => (this.institutions = value.body),
        });
        this.institutionsService.getByActiveTrueAndTierMoreThanEqual(1).subscribe({
            next: (value) => (this.institutionSelectionList = value.body),
        });

        this.treeControl = new FlatTreeControl<ApplicationPermissionFlatNode>(
            (dataNode) => dataNode.level,
            (dataNode) => dataNode.expandable
        );
        this.treeFlattener = new MatTreeFlattener(
            (node, level) => {
                return {
                    expandable: node.children && node.children.length > 0,
                    name: node.name,
                    code: node.code,
                    level: level,
                };
            },
            (node) => node.level,
            (node) => node.expandable,
            (node) => node.children
        );
        this.dataSource = new MatTreeFlatDataSource<ApplicationPermissionNode, ApplicationPermissionFlatNode>(
            this.treeControl,
            this.treeFlattener
        );
        this.applicationPermissionModelByName = new Map<string, ApplicationPermissionModel>();
    }

    activeLoading() {
        this.loading = true;
    }

    deactiveLoading() {
        this.loading = false;
    }

    save(): void {
        this.isSaving = true;
        this.activeLoading();
        if (this.dialogType === 'create') {
            this.applicationRolesService.save(this.applicationRoleFormGroup.getRawValue()).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: (error) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.generalService.snackbarMessage(error.message, 'Close');
                },
            });
        } else {
            this.applicationRolesService.update(this.applicationRoleFormGroup.getRawValue()).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: (error) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.generalService.snackbarMessage(error.message, 'Close');
                },
            });
        }
    }

    private static constructApplicationPermissionNode(
        applicationPermissionModels: ApplicationPermissionModel[]
    ): ApplicationPermissionNode[] {
        const applicationPermissionNodesByType = new Map<string, ApplicationPermissionNode>();
        for (const applicationPermissionModel of applicationPermissionModels) {
            const category = applicationPermissionModel.category;
            if (applicationPermissionNodesByType.has(category)) {
                const applicationPermissionNode = applicationPermissionNodesByType.get(category);
                applicationPermissionNode.children.push({
                    name: applicationPermissionModel.name,
                    code: applicationPermissionModel.code,
                    children: [],
                });
            } else {
                applicationPermissionNodesByType.set(applicationPermissionModel.category, {
                    name: applicationPermissionModel.category,
                    code: applicationPermissionModel.code,
                    children: [
                        {
                            name: applicationPermissionModel.name,
                            code: applicationPermissionModel.code,
                            children: [],
                        },
                    ],
                });
            }
        }

        return Array.from(applicationPermissionNodesByType.values());
    }

    updateApplicationRoleApplicationPermissions(applicationPermissionCode: string): void {
        const applicationPermissionsFormArray = this.applicationRoleFormGroup.get('permissions') as FormArray;
        const indexOfDataToUpdate = applicationPermissionsFormArray.controls.findIndex((value) => {
            const applicationPermissionFormGroup = value as FormGroup;
            return applicationPermissionFormGroup.get('code').value === applicationPermissionCode;
        });

        if (indexOfDataToUpdate === -1) {
            const applicationPermissionModel = this.applicationPermissionModelByName.get(applicationPermissionCode);
            applicationPermissionsFormArray.push(
                this.formBuilder.group({
                    id: this.formBuilder.control(applicationPermissionModel.id),
                    name: this.formBuilder.control(applicationPermissionModel.name),
                    code: this.formBuilder.control(applicationPermissionModel.code),
                    category: this.formBuilder.control(applicationPermissionModel.category),
                })
            );
        } else {
            applicationPermissionsFormArray.removeAt(indexOfDataToUpdate);
        }
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

    checkInstitution() {
        if (this.applicationRoleFormGroup.get('id')) {
            return true;
        } else {
            return false;
        }
    }

    setRIOPermissions(): void {
        const rioPermissions: string[] = [
            "REPORT_DOWNLOAD_MENU",
            "REPORT_UPLOAD_MENU",
            "REPORT_CREATE_RIO",
            "REPORT_READ_RIO",
            "REPORT_DOWNLOAD_RIO",
            "RIO_TEMPLATE_READ",
            "SYSTEM_CONFIGURATION_READ",
            "ENTITY_READ",
            "PROJECT_READ",
            "USER_READ"
        ]

        for (let permissionCode of rioPermissions) {
            const applicationPermissionsFormArray = this.applicationRoleFormGroup.get('permissions') as FormArray;
            const value = applicationPermissionsFormArray.value
            
            if (!value.find(permission => permission.code === permissionCode)) {
                this.updateApplicationRoleApplicationPermissions(permissionCode)
            }
        }
        this.treeControl.collapseAll()
        this.treeControl.expandAll()
        this.generalService.snackbarMessage('RIO Permissions has been set!', 'Close');
    }

    setCBSPermissions(): void {
        const cbsPermissions: string[] = [
            "REPORT_DOWNLOAD_MENU",
            "REPORT_UPLOAD_MENU",
            "REPORT_READ",
            "REPORT_CREATE",
            "REPORT_DOWNLOAD",
            "SYSTEM_CONFIGURATION_READ",
            "ENTITY_READ",
            "PROJECT_READ",
            "USER_READ"
        ]

        for (let permissionCode of cbsPermissions) {
            const applicationPermissionsFormArray = this.applicationRoleFormGroup.get('permissions') as FormArray;
            const value = applicationPermissionsFormArray.value
            
            if (!value.find(permission => permission.code === permissionCode)) {
                this.updateApplicationRoleApplicationPermissions(permissionCode)
            }
        }
        this.treeControl.collapseAll()
        this.treeControl.expandAll()
        this.generalService.snackbarMessage('CBS Permissions has been set!', 'Close');
    }
}
