<div class="h-screen flex items-center justify-center bg-gray-light">
    <div class="w-screen max-w-lg !rounded-xl bg-white p-4">
        <mat-card-header class="flex justify-center text-center">
            <mat-card-title>
                <img class="m-auto pb-5 w-1/2" src="assets/skyworld-logo-circle.png" alt="SkyWorld Logo" />
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="text-center">
                <h2 class="text-2xl font-bold">
                    <span *ngIf="!resetPasswordKey; else resetPasswordTitle">Forget Password</span>
                    <ng-template #resetPasswordTitle> Reset Password </ng-template>
                </h2>
            </div>
            <div class="text-center">
                <h4>
                    <span *ngIf="!resetPasswordKey; else resetPasswordDescription">
                        Enter the email you're using for your account and we will send a link to reset your password.
                    </span>
                    <ng-template #resetPasswordDescription>
                        Enter a new password for {{ resetPasswordEmail }}
                    </ng-template>
                </h4>
            </div>
            <br />
            <div [formGroup]="applicationUserFormGroup">
                <span *ngIf="!resetPasswordKey; else resetPassword">
                    <mx-input-field
                        [inputType]="text"
                        [formGroupChild]="applicationUserFormGroup"
                        formControlNameChild="email"
                        label="Email"
                        placeholder="Enter email"
                        [maxLength]="100"
                        errorMessage="Email is required"
                        [autoFocusChild]="true"
                        [required]="true"
                    ></mx-input-field>
                </span>
                <ng-template #resetPassword>
                    <mx-input-field
                        [inputType]="text"
                        [formGroupChild]="applicationUserFormGroup"
                        formControlNameChild="key"
                        label="Reset Key"
                        placeholder="Enter reset key"
                        errorMessage="Reset Key is required"
                        [required]="true"
                    ></mx-input-field>
                    <mx-input-field
                        [inputType]="password"
                        [formGroupChild]="applicationUserFormGroup"
                        formControlNameChild="newPassword"
                        label="New Password"
                        placeholder="Enter new password"
                        errorMessage="Password is required"
                        [required]="true"
                        [minLength]="8"
                        [maxLength]="15"
                    ></mx-input-field>
                    <mx-input-field
                        [inputType]="confirmPassword"
                        [formGroupChild]="applicationUserFormGroup"
                        formControlNameChild="repeatPassword"
                        label="Re-enter New Password"
                        placeholder="Re-enter New Password"
                        errorMessage="Password and Re-enter password not same"
                        [required]="true"
                        passwordFormControlName="newPassword"
                    ></mx-input-field>
                </ng-template>
            </div>
        </mat-card-content>
        <br />
        <br />
        <button
            class="w-full"
            type="button"
            mat-raised-button
            color="primary"
            [disabled]="isSaving || applicationUserFormGroup.invalid"
            (click)="save()"
        >
            <ng-container *ngIf="!resetPasswordKey; else elseFalseCheckFlagWord"> Submit </ng-container>
            <ng-template #elseFalseCheckFlagWord> Reset Password </ng-template>
        </button>
        <div class="mt-6 font-semibold text-primary text-sm text-center cursor-pointer" (click)="returnLogin()">
            <span>Back to sign in</span>
        </div>
    </div>
</div>
