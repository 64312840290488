<div class="flex justify-between items-center px-6 py-4 border-b border-b-gray-light">
    <div class="text-lg font-bold">
        <ng-container *ngIf="dialogType === 'create'; else elseTemplate"> Create New RIO Template Type </ng-container>
        <ng-template #elseTemplate> Edit RIO Template Type '{{ rioTemplate.name }}' </ng-template>
    </div>
    <button type="button" data-dismiss="modal" class="text-2xl focus:outline-none" (click)="dialogRef.close()">
        &times;
    </button>
</div>
<div class="max-h-[70vh] overflow-auto p-6 relative whitespace-pre-line">
    <form [formGroup]="rioTemplateFormGroup">
        <div class="grid grid-cols-2 gap-x-4">
            <mx-input-field
                class="col-span-2 md:col-span-1"
                [inputType]="text"
                [formGroupChild]="rioTemplateFormGroup"
                formControlNameChild="name"
                label="Name"
                placeholder="Enter name"
                [maxLength]="50"
                errorMessage="Name is required"
                [required]="true"
            ></mx-input-field>
            <mx-input-field
                class="col-span-2 md:col-span-1"
                [inputType]="text"
                [formGroupChild]="rioTemplateFormGroup"
                formControlNameChild="description"
                label="Description"
                placeholder="Enter description"
                [maxLength]="200"
            ></mx-input-field>
        </div>
    </form>
    <div class="text-primary text-sm mt-5">* Required Field For Submission</div>
</div>
<div class="flex justify-end p-6 border-t border-gray-light gap-2">
    <button type="button" mat-raised-button class="m-1" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">clear</mat-icon>
        <span class="text-sm font-bold">Cancel</span>
    </button>
    <button
        type="button"
        mat-raised-button
        class="m-1"
        [disabled]="isSaving || rioTemplateFormGroup.invalid"
        (click)="save()"
        color="accent"
    >
        <mat-icon class="text-sm mr-2 mt-1 font-bold">save</mat-icon>
        <span class="text-sm font-bold">Save</span>
    </button>
</div>
