import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FORM_TEMPLATE_FIELD_INPUT_TYPE } from 'src/app/model/form-template-field.model';
import { GeneralService } from 'src/app/services/general.service';
import { GlCostCodeDialogDetailComponent } from '../gl-cost-code-dialog-detail/gl-cost-code-dialog-detail.component';
import { GlCostCodeModel } from 'src/app/model/gl-cost-code.model';
import { GlCostCodeService } from 'src/app/services/gl-cost-code.service';
import { AuthenticateService } from 'src/app/auth/authenticate.service';

@Component({
  selector: 'app-gl-cost-code-dialog-new-or-edit',
  templateUrl: './gl-cost-code-dialog-new-or-edit.component.html',
})
export class GlCostCodeDialogNewOrEditComponent implements OnInit {

    dialogType: string = '';
    isSaving: boolean = false;
    loading: boolean = false;
    glCodeFormGroup: FormGroup;
    parentGlCostCodes: GlCostCodeModel[];

    text = FORM_TEMPLATE_FIELD_INPUT_TYPE.TEXT;
    select = FORM_TEMPLATE_FIELD_INPUT_TYPE.SELECT;

    constructor(
        @Inject(MAT_DIALOG_DATA) public glCostCodeModel: GlCostCodeModel,
        public dialogRef: MatDialogRef<GlCostCodeDialogNewOrEditComponent>,
        private glCodeService: GlCostCodeService,
        private formBuilder: FormBuilder,
        private generalService: GeneralService,
        private matDialog: MatDialog,
        private authService: AuthenticateService
    ) { }

    ngOnInit(): void {
        this.glCodeActiveLoading();
        if (this.glCostCodeModel) {
            this.dialogType = 'update';
            this.glCodeFormGroup = this.formBuilder.group({
                id: this.formBuilder.control(this.glCostCodeModel.id),
                code: this.formBuilder.control(this.glCostCodeModel.code,Validators.required),
                description: this.formBuilder.control(this.glCostCodeModel.description),
                institution: this.formBuilder.control(this.glCostCodeModel.institution),
                parentGlCostCode: this.formBuilder.control(this.glCostCodeModel.parentGlCostCode)
            });
        } else {
            this.dialogType = 'create';
            this.glCodeFormGroup = this.formBuilder.group({
                code: this.formBuilder.control(null,Validators.required),
                description: this.formBuilder.control(null),
                institution: this.formBuilder.control(this.authService.getCurIns()),
                parentGlCostCode: this.formBuilder.control(null)
            });
        }
        this.glCodeService.getAll().subscribe((res) => {
                this.parentGlCostCodes = res.body;
            });
        this.glCodeDeactiveLoading();
    }

    glCodeActiveLoading(){
        this.loading = true;
    }

    glCodeDeactiveLoading(){
        this.loading = false;
    }

    save():void{
        this.isSaving = true;
        this.glCodeActiveLoading();
        const getRawValue = this.glCodeFormGroup.getRawValue();
        if (this.dialogType === 'create') {
            this.glCodeService.save(getRawValue).subscribe({
                next: () => { 
                    this.isSaving = false;
                    this.glCodeDeactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: error => {
                    this.isSaving = false;
                    this.glCodeDeactiveLoading();
                    this.generalService.snackbarMessage( error.message,'Close');
                }
            });
        } else {
            this.glCodeService.update(getRawValue).subscribe({
                next: (value) => {
                    this.isSaving = false;
                    this.glCodeDeactiveLoading();
                    this.dialogRef.close('ok');
                    this.openGlCodeDialogDetail(value.body);
                },
                error: error => {
                    this.isSaving = false;
                    this.glCodeDeactiveLoading();
                    this.generalService.snackbarMessage( error.message,'Close');
                }
            });
        }
    }

    openGlCodeDialogCreateOrEdit(glCostCodeModel?: GlCostCodeModel){
        this.glCodeService.getGlCodeById(glCostCodeModel.id).subscribe({
            next:(value)=> {
                const modalRef = this.matDialog.open(GlCostCodeDialogNewOrEditComponent, {
                    data: value.body,
                    panelClass: 'mat-dialog-lg'
                })
        
                modalRef.afterClosed().subscribe();
            }});
    }

    openGlCodeDialogDetail(glCostCodeModel?: GlCostCodeModel){
        this.glCodeService.getGlCodeById(glCostCodeModel.id).subscribe({
            next:(value)=> {
                const modalRef = this.matDialog.open(GlCostCodeDialogDetailComponent, {
                    data: value.body,
                    panelClass: 'mat-dialog-md'
                })
        
                modalRef.afterClosed().subscribe({
                    next: (edit) => {
                        if (edit) this.openGlCodeDialogCreateOrEdit(glCostCodeModel);
                    }
                })
            }
        })
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

    checkGlCostCode() {
        if (this.glCodeFormGroup.get('id')) {
            return true;
        } else {
            return false;
        }
    }

}
