import { Component, Input, } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'mx-confirm-password',
    templateUrl: './mx-confirm-password.component.html',
})
export class MxConfirmPasswordComponent{
    @Input() public formGroupChild: FormGroup;
    @Input() public formControlNameChild: string;
    @Input() public label: String;
    @Input() public placeholder: string = '';
    @Input() public maxLength: number;
    @Input() public autoFocusChild: boolean;
    @Input() public errorMessage: String;
    @Input() public required: boolean = false;
    @Input() public passwordFormControlName: string;

    isShowPassword: boolean = false;

    showPassword() {
        this.isShowPassword = !this.isShowPassword;
    }
}
