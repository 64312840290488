<div class="flex justify-between items-center px-6 py-4 border-b border-b-gray-light">
    <div class="text-lg font-bold">
        <ng-container *ngIf="dialogType === 'create'; else elseTemplate">
            Create New Form Type Configuration
        </ng-container>
        <ng-template #elseTemplate>
            Edit Form Type Configuration '{{ formTypeConfigurationModel.code }}'
        </ng-template>
    </div>
    <button type="button" data-dismiss="modal" class="text-2xl focus:outline-none" (click)="dialogRef.close()">
        &times;
    </button>
</div>
<div *ngIf="loading" class="mx-empty-result">
    <div class="mx-data-loading"></div>
</div>
<div class="max-h-[70vh] overflow-auto p-6 relative whitespace-pre-line">
    <form [formGroup]="formTypeConfigurationFormGroup">
        <div class="grid grid-cols-2 gap-x-4">
            <mx-input-field class="col-span-2 md:col-span-1" [inputType]="text" [formGroupChild]="formTypeConfigurationFormGroup"
                formControlNameChild="code" label="Code" placeholder="Enter code" [maxLength]="255"
                [required]="true"></mx-input-field>
            <mx-input-field
                class="col-span-2 md:col-span-1 flex items-center"
                [inputType]="checkbox"
                [formGroupChild]="formTypeConfigurationFormGroup"
                formControlNameChild="requireAggregation"
                label="Required Aggregation"
            ></mx-input-field>
            <mx-input-field class="col-span-2" [inputType]="text" [formGroupChild]="formTypeConfigurationFormGroup"
                formControlNameChild="description" label="Description" placeholder="Enter description" [maxLength]="255"
                [required]="false"></mx-input-field>
        </div>
    </form>
    <span class="text-primary text-sm">* Required Field For Submission</span>

</div>
<div class="flex justify-end p-6 border-t border-gray-light gap-2" *ngIf="!loading">
    <button type="button" mat-raised-button class="m-1" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">clear</mat-icon>
        <span class="text-sm font-bold">Cancel</span>
    </button>
    <button type="button" mat-raised-button class="m-1" [disabled]="isSaving || formTypeConfigurationFormGroup.invalid"
        (click)="save()" color="accent">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">save</mat-icon>
        <span class="text-sm font-bold">Save</span>
    </button>
</div>