import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportUploadModel } from 'src/app/model/report-upload.model';

@Component({
  selector: 'app-report-upload-cbs-dialog-detail',
  templateUrl: './report-upload-cbs-dialog-detail.component.html',
})
export class ReportUploadCbsDialogDetailComponent{

    constructor(
        @Inject(MAT_DIALOG_DATA) public reportUploadModel: ReportUploadModel,
        public dialogRef: MatDialogRef<ReportUploadCbsDialogDetailComponent>,
    ) { }
}
