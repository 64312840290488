import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { AuthenticateService } from '../auth/authenticate.service';
import { Observable } from 'rxjs';
import { ACCOUNT_COMPONENT } from '../app.constant';
import { ProjectModel } from '../model/project.model';
import { DropDownListingResponse } from '../model/dropdown-listing-response';

@Injectable({
    providedIn: 'root',
})
export class ProjectService {
    private apiProject: string = ACCOUNT_COMPONENT + '/projects';
    private dropdownListApi: string = ACCOUNT_COMPONENT + '/projects/dropdown-list';
    private dropdownListRIONonLinkedApi: string = ACCOUNT_COMPONENT + '/projects/rio-non-linked/dropdown-list';
    private dropdownListCBSNonLinkedApi: string = ACCOUNT_COMPONENT + '/projects/cbs-non-linked/dropdown-list';

    constructor(private httpClient: HttpClient, private authService: AuthenticateService) {}

    save(projectModel: ProjectModel): Observable<HttpResponse<ProjectModel>> {
        return this.httpClient.post<ProjectModel>(this.apiProject, projectModel, {
            observe: 'response',
        });
    }

    getAllProjects(
        projectPageNumber: number,
        projectPageSize: number,
        projectSortOrder: string,
        criteria
    ): Observable<any> {
        let httpParams = new HttpParams();
        if (projectPageNumber !== undefined || projectPageNumber !== null) {
            httpParams = httpParams.set('page', `${projectPageNumber}`);
        }
        if (projectPageSize) {
            httpParams = httpParams.set('size', `${projectPageSize}`);
        }
        if (projectSortOrder !== undefined || projectSortOrder !== null) {
            httpParams = httpParams.set('sort', projectSortOrder);
        }

        if (criteria) {
            if (criteria.name) {
                httpParams = httpParams.set('name', criteria.name);
            }
            if(criteria.type){
                httpParams = httpParams.set('type', criteria.type);
            }
        }
        return this.httpClient.get(`${this.apiProject}`, {
            observe: 'response',
            params: httpParams,
        });
    }

    update(projectModel: ProjectModel): Observable<HttpResponse<ProjectModel>> {
        const projectId = projectModel.id;
        return this.httpClient.put<ProjectModel>(this.apiProject + '/' + projectId, projectModel, {
            observe: 'response',
        });
    }

    delete(projectId: string): Observable<HttpResponse<ProjectModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('currentInstitutionId', this.authService.getCurInsId());
        return this.httpClient.delete<ProjectModel[]>(this.apiProject + '/' + projectId, {
            observe: 'response',
            params: httpParams,
        });
    }

    getProjectById(projectId: string): Observable<HttpResponse<ProjectModel>> {
        return this.httpClient.get<ProjectModel>(this.apiProject + '/' + projectId, {
            observe: 'response',
        });
    }

    getAll(): Observable<HttpResponse<ProjectModel[]>> {
        return this.httpClient.get<ProjectModel[]>(this.apiProject + '/all', {
            observe: 'response',
        });
    }

    getAllProjectDropdownList(type: string): Observable<HttpResponse<DropDownListingResponse[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('type', type);

        return this.httpClient.get<DropDownListingResponse[]>(this.dropdownListApi, {
            observe: 'response',
            params: httpParams,
        })
    }

    getAllProjectRioNonLinkedDropdownList(): Observable<HttpResponse<DropDownListingResponse[]>> {
        let httpParams = new HttpParams();

        return this.httpClient.get<DropDownListingResponse[]>(this.dropdownListRIONonLinkedApi, {
            observe: 'response'
        })
    }

    getAllProjectCbsMonLinkedDropdownList(): Observable<HttpResponse<DropDownListingResponse[]>> {
        return this.httpClient.get<DropDownListingResponse[]>(this.dropdownListCBSNonLinkedApi, {
            observe: 'response'
        })
    }

    createRioReportSummary(projectId: string): Observable<HttpResponse<ProjectModel>> {
        let httpParams = new HttpParams();

        if (projectId) {
            httpParams = httpParams.set('projectId', projectId);
        }

        return this.httpClient.post<ProjectModel>(
            this.apiProject + '/rio/create-report-summary',
            null,  // No request body required for this endpoint
            {
                params: httpParams,  // Include the HTTP parameters
                observe: 'response', // Request full HTTP response
            }
        );
    }
}
