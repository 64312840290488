import { Component, OnInit, ViewChild,  } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { GeneralService } from 'src/app/services/general.service';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';
import { DialogSimpleComponent } from 'src/app/components/shared/ui/dialog-simple/dialog-simple.component';
import { FORM_TEMPLATE_FIELD_INPUT_TYPE } from 'src/app/model/form-template-field.model';
import { SystemConfigurationService } from 'src/app/services/system-configuration.service';
import { SystemConfigModel } from 'src/app/model/system-config.model';
import { SysConfigDialogNewOrEditComponent } from '../sys-config-dialog-new-or-edit/sys-config-dialog-new-or-edit.component';
import { SysConfigDetailComponent } from '../sys-config-detail/sys-config-detail.component';
import { AuthenticateService } from 'src/app/auth/authenticate.service';
@Component({
  selector: 'app-sys-config-listing',
  templateUrl: './sys-config-listing.component.html',
})

export class SysConfigListingComponent implements OnInit {

    readonly SYSTEM_CONFIGURATION_UPDATE = ApplicationPermissionService.SYSTEM_CONFIGURATION_UPDATE;
    readonly SYSTEM_CONFIGURATION_CREATE = ApplicationPermissionService.SYSTEM_CONFIGURATION_CREATE;
    readonly SYSTEM_CONFIGURATION_READ = ApplicationPermissionService.SYSTEM_CONFIGURATION_READ;

    sysConfigDataSource = new MatTableDataSource<any>();
    obs: BehaviorSubject<any>;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayColumns = ['code', 'description', 'value', 'actions'];

    sysConfigSearchValue: String = null;
    sysConfigPageNumbers: number = 0;
    sysConfigNumberOfRecords: number = 0;
    sysConfigCurrentPage: number = 0;
    sysConfigPageSize: number = 50;
    sysConfigSortOrder = 'code,asc';
    loading: boolean = false;
    isLoading = FORM_TEMPLATE_FIELD_INPUT_TYPE.LOADING;

    constructor(
        public sysConfigActivatedRoute: ActivatedRoute,
        private matDialog: MatDialog,
        private sysConfigService: SystemConfigurationService,
        private generalService: GeneralService,
        private authService: AuthenticateService,
    ) { }

    ngOnInit(): void {
        this.sysConfigActiveLoading();
        if (this.sysConfigActivatedRoute.snapshot.queryParams['page']) {
            this.sysConfigSortOrder = this.sysConfigActivatedRoute.snapshot.queryParams['sort']
                ? this.sysConfigActivatedRoute.snapshot.queryParams['sort']
                : undefined;
            this.sysConfigCurrentPage = this.sysConfigActivatedRoute.snapshot.queryParams['page']
                ? this.sysConfigActivatedRoute.snapshot.queryParams['page']
                : undefined;
            this.sysConfigPageSize = this.sysConfigActivatedRoute.snapshot.queryParams['pageSize']
                ? this.sysConfigActivatedRoute.snapshot.queryParams['pageSize']
                : undefined;
        }
        this.refreshForm();
    }

    openSystemConfigDialogCreateOrEdit(sysConfigModel?: SystemConfigModel) {
        if(sysConfigModel === undefined){
            this.passDataToDialogCreateOrEdit(sysConfigModel);
        }else{
            this.sysConfigService.getSystemConfigById(sysConfigModel.id).subscribe({
                next: (value) =>{
                    this.passDataToDialogCreateOrEdit(value.body);
                }
            });
        }
    }

    passDataToDialogCreateOrEdit(event: any){
        const modalRef = this.matDialog.open(SysConfigDialogNewOrEditComponent, {
            data: event,
            panelClass: 'mat-dialog-lg'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
            if (yes) this.search(this.sysConfigCurrentPage, this.sysConfigSearchValue);
        }})
    }


    openSystemConfigDialogDetail(sysConfigModel?: SystemConfigModel) {
        this.sysConfigService.getSystemConfigById(sysConfigModel.id).subscribe({
            next: (value) =>{
                const modalRef = this.matDialog.open(SysConfigDetailComponent, {
                    data: value.body,
                    panelClass: 'mat-dialog-md',
                });

                modalRef.afterClosed().subscribe({
                    next: (edit) => {
                        if (edit) this.openSystemConfigDialogCreateOrEdit(sysConfigModel);
                    },
                });
            }
        });
    }

    search(currentPage: number, sysConfigSearchValue: String) {
        this.sysConfigSearchValue = sysConfigSearchValue;
        this.sysConfigService
            .getAllSystemConfig(this.sysConfigCurrentPage, this.sysConfigPageSize, this.sysConfigSortOrder, {
                code: sysConfigSearchValue,
                institutionId : this.authService.getCurInsId(),
            })
            .subscribe({
                next: ({ body })=> {
                    this.sysConfigDataSource.data = body.content;
                    this.sysConfigNumberOfRecords = body.totalElements;
                    this.sysConfigDeactiveLoading();
                },
                error: (error) => {
                    this.generalService.snackbarMessage(error.debugMessage, 'Close');
                    this.sysConfigDeactiveLoading();
                },
            });
    }

    refreshForm() {
        this.search(0, null);
    }

    sortPage(event): void {
        this.sysConfigSortOrder = `${event.active},${event.direction}`;
        this.search(this.sysConfigCurrentPage, this.sysConfigSearchValue);
    }

    navigateToPage(event): void {
        this.sysConfigPageSize = event.pageSize;
        this.sysConfigCurrentPage = event.pageIndex;
        this.search(this.sysConfigCurrentPage, this.sysConfigSearchValue);
    }

    openSystemConfigDialogDelete(sysConfigModel: SystemConfigModel): void {
        const modalRef = this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: 'Confirm Delete System Config?',
                message: `Are you sure want to delete ${sysConfigModel.code}?`,
                buttonPositive: 'Yes',
                buttonNegative: 'No',
            },
            panelClass: 'mat-dialog-sm',
        });

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) {
                    this.sysConfigService.delete(sysConfigModel.id.toString()).subscribe({
                        next: () => {
                            this.search(this.sysConfigCurrentPage, this.sysConfigSearchValue);
                        },
                        error: (error) => {
                            this.generalService.snackbarMessage(error.message, 'Close');
                        },
                    });
                }
            },
        });
    }

    sysConfigActiveLoading() {
        this.loading = true;
    }

    sysConfigDeactiveLoading() {
        this.loading = false;
    }

}
