import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticateService } from 'src/app/auth/authenticate.service';
import { InstitutionModel } from 'src/app/model/institution.model';
import { SystemConfigModel } from 'src/app/model/system-config.model';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';
import { SystemConfigurationService } from 'src/app/services/system-configuration.service';
import {HttpResponse} from "@angular/common/http";

@Component({
    selector: 'app-institution-dialog-detail',
    templateUrl: './institution-dialog-detail.component.html',
})
export class InstitutionDialogDetailComponent {
    readonly ENTITY_UPDATE = ApplicationPermissionService.ENTITY_UPDATE;

    tiers: { id: string; name: string; value: number }[] = [
        {
            id: '1',
            name: 'Function',
            value: 1,
        },
        {
            id: '2',
            name: 'Unit',
            value: 2,
        },
        {
            id: '3',
            name: 'Team',
            value: 3,
        },
    ];

    constructor(
        @Inject(MAT_DIALOG_DATA) public institutionModel: InstitutionModel,
        public dialogRef: MatDialogRef<InstitutionDialogDetailComponent>,
        public authService: AuthenticateService,
        private systemConfigurationService: SystemConfigurationService
    ) {
        if(this.institutionModel){
            if (this.institutionModel.projects) {
                this.institutionModel.projects.sort((a, b) => a.name.localeCompare(b.name));
            }
            if (this.institutionModel.companies) {
                this.institutionModel.companies.sort((a, b) => a.name.localeCompare(b.name));
            }
            if (this.institutionModel.formTypeConfigurations) {
                this.institutionModel.formTypeConfigurations.sort((a, b) => a.code.localeCompare(b.code));
            }
            if (this.institutionModel.reportRequired && this.institutionModel.isRio && this.institutionModel.systemConfigurationId) {
                this.systemConfigurationService.getAllSystemConfigRioSubmission().subscribe((sysConfig: HttpResponse<SystemConfigModel[]>) => {
                    this.institutionModel.systemConfigurationId = sysConfig.body.find((sub: SystemConfigModel) => sub.id === this.institutionModel.systemConfigurationId).name
                })
            }
        }
    }

    getEntityType() {
        return this.tiers.find((t) => t.value === this.institutionModel.tier).name;
    }
}
