import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FORM_TEMPLATE_FIELD_INPUT_TYPE } from 'src/app/model/form-template-field.model';
import { GeneralService } from 'src/app/services/general.service';
import { FormTypeConfigurationDialogDetailComponent } from '../form-type-configuration-dialog-detail/form-type-configuration-dialog-detail.component';
import { FormTypeConfigurationModel } from 'src/app/model/form-type-configuration.model';
import { FormTypeConfigurationService } from 'src/app/services/form-type-configuration.service';

@Component({
  selector: 'app-form-type-configuration-dialog-new-or-edit',
  templateUrl: './form-type-configuration-dialog-new-or-edit.component.html',
})
export class FormTypeConfigurationDialogNewOrEditComponent implements OnInit {

    dialogType: string = '';
    isSaving: boolean = false;
    loading: boolean = false;
    formTypeConfigurationFormGroup: FormGroup;

    text = FORM_TEMPLATE_FIELD_INPUT_TYPE.TEXT;
    multiSelect = FORM_TEMPLATE_FIELD_INPUT_TYPE.MULTISELECT;
    checkbox = FORM_TEMPLATE_FIELD_INPUT_TYPE.CHECKBOX;

    constructor(
        @Inject(MAT_DIALOG_DATA) public formTypeConfigurationModel: FormTypeConfigurationModel,
        public dialogRef: MatDialogRef<FormTypeConfigurationDialogNewOrEditComponent>,
        private formTypeConfigurationService: FormTypeConfigurationService,
        private formBuilder: FormBuilder,
        private generalService: GeneralService,
        private matDialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.formTypeConfigurationActiveLoading();
        if (this.formTypeConfigurationModel) {
            this.dialogType = 'update';
            this.formTypeConfigurationFormGroup = this.formBuilder.group({
                id: this.formBuilder.control(this.formTypeConfigurationModel.id),
                code: this.formBuilder.control(this.formTypeConfigurationModel.code, Validators.required),
                description: this.formBuilder.control(this.formTypeConfigurationModel.description),
                institutions: this.formBuilder.control(this.formTypeConfigurationModel.institutions),
                requireAggregation: this.formBuilder.control(this.formTypeConfigurationModel.requireAggregation)
            });
        } else {
            this.dialogType = 'create';
            this.formTypeConfigurationFormGroup = this.formBuilder.group({
                code: this.formBuilder.control(null,Validators.required),
                description: this.formBuilder.control(null),
                institutions: this.formBuilder.control([]),
                requireAggregation: this.formBuilder.control(true)
            });
        }
        this.formTypeConfigurationDeactiveLoading();
    }

    formTypeConfigurationActiveLoading(){
        this.loading = true;
    }

    formTypeConfigurationDeactiveLoading(){
        this.loading = false;
    }

    save():void{
        this.isSaving = true;
        this.formTypeConfigurationActiveLoading();
        const getRawValue = this.formTypeConfigurationFormGroup.getRawValue();
        if (this.dialogType === 'create') {
            this.formTypeConfigurationService.save(getRawValue).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.formTypeConfigurationDeactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: error => {
                    this.isSaving = false;
                    this.formTypeConfigurationDeactiveLoading();
                    this.generalService.snackbarMessage( error.message,'Close');
                }
            });
        } else {
            this.formTypeConfigurationService.update(getRawValue).subscribe({
                next: (value) => {
                    this.isSaving = false;
                    this.formTypeConfigurationDeactiveLoading();
                    this.dialogRef.close('ok');
                    this.openFormTypeConfigurationDialogDetail(value.body);
                },
                error: error => {
                    this.isSaving = false;
                    this.formTypeConfigurationDeactiveLoading();
                    this.generalService.snackbarMessage( error.message,'Close');
                }
            });
        }
    }

    openFormTypeConfigurationDialogCreateOrEdit(formTypeConfigurationModel?: FormTypeConfigurationModel){
        this.formTypeConfigurationService.getFormTypeConfigurationById(formTypeConfigurationModel.id).subscribe({
            next:(value)=> {
                const modalRef = this.matDialog.open(FormTypeConfigurationDialogNewOrEditComponent, {
                    data: value.body,
                    panelClass: 'mat-dialog-lg'
                })

                modalRef.afterClosed().subscribe();
            }});
    }

    openFormTypeConfigurationDialogDetail(formTypeConfigurationModel?: FormTypeConfigurationModel){
        this.formTypeConfigurationService.getFormTypeConfigurationById(formTypeConfigurationModel.id).subscribe({
            next:(value)=> {
                const modalRef = this.matDialog.open(FormTypeConfigurationDialogDetailComponent, {
                    data: value.body,
                    panelClass: 'mat-dialog-md'
                })

                modalRef.afterClosed().subscribe({
                    next: (edit) => {
                        if (edit) this.openFormTypeConfigurationDialogCreateOrEdit(formTypeConfigurationModel);
                    }
                })
            }
        })
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

}
