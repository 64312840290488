<div class="mx-10 my-6" *appScreenPermission="[ENTITY_READ]">
    <mx-title-bar title="Entity" buttonIcon="add" buttonLabel="Add Entity"
        (onButtonClick)="openInstitutionDialogCreateOrEdit()" [buttonPermission]="[ENTITY_CREATE]"></mx-title-bar>

    <div class="flex-container">
        <form [formGroup]="institutionFormGroup">
        <div class="px-3 py-2 border rounded-md bg-white grid grid-cols-2 gap-2">
            <mx-input-field
                    class="col-span-2 lg:col-span-1"
                    [inputType]="text"
                    [formGroupChild]="institutionFormGroup"
                    formControlNameChild="name"
                    label="Name"
                    placeholder="Enter name"
            ></mx-input-field>

            <mx-input-field
                    class="col-span-2 lg:col-span-1"
                    [inputType]="select"
                    [formGroupChild]="institutionFormGroup"
                    formControlNameChild="tier"
                    label="Type"
                    placeholder="Select type"
                    [compareWithChild]="compareObjects"
                    [list]="tiers"
            ></mx-input-field>

            <div class="col-span-2 flex justify-end">
                <button
                        mat-raised-button
                        color="primary"
                        class="px-8 py-3 font-semibold rounded"
                        (click)="onSubmitSearch()"
                >
                    Search
                </button>
            </div>
        </div>
        </form>
    </div>

    <div class="my-2">
        <strong>{{ institutionNumberOfRecords }}</strong> <span *ngIf="!currentSearchName"> Function</span> result<span *ngIf="institutionNumberOfRecords > 1">s</span>
    </div>

    <mat-card-content class="mx-content-table">
        <div *ngIf="loading">
            <mx-data-loading></mx-data-loading>
        </div>
        <table mat-table matSort (matSortChange)="sortPage($event)" [dataSource]="institutionDataSource" *ngIf="institutionDataSource.data"
            class="w-full" aria-label="table-institution">
            <ng-container matColumnDef="name">
                <ng-container *ngIf="currentSearchName; else elseName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                </ng-container>
                <ng-template #elseName>
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                </ng-template>
                <td mat-cell *matCellDef="let element">
                    <div class="h-10 flex items-center" [matTooltip]="element.name">
                        <div [ngClass]="currentSearchName ? 'w-0' : 'w-6'">
                            <span *ngIf="element.children?.length > 0" class="material-icons">arrow_drop_down</span>
                        </div>
                        <div>{{ element.name}}</div>
                    </div>
                    <div *ngFor="let child of element.children">
                        <div class="ml-4 h-10 flex items-center" [matTooltip]="child.name">
                            <div class="w-6">
                                <span *ngIf="child.children?.length > 0" class="material-icons">arrow_drop_down</span>
                            </div>
                            <div>{{ child.name}}</div>
                        </div>
                        <div *ngFor="let grandChild of child.children">
                            <div class="ml-8 h-10 flex items-center" [matTooltip]="grandChild.name">
                                <div class="w-6">
                                    <span *ngIf="grandChild.children?.length > 0" class="material-icons">arrow_drop_down</span>
                                </div>
                                <div>{{ grandChild.name}}</div>    
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="shortName">
                <ng-container *ngIf="currentSearchName; else elseShortName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Short Name</th>
                </ng-container>
                <ng-template #elseShortName>
                    <th mat-header-cell *matHeaderCellDef>Short Name</th>
                </ng-template>
                <td mat-cell *matCellDef="let element">
                    <div class="h-10 flex items-center">{{ element.shortName || '-'}}</div>
                    <div *ngFor="let child of element.children">
                        <div class="h-10 flex items-center">{{ child.shortName || '-'}}</div>
                        <div *ngFor="let grandChild of child.children">
                            <div class="h-10 flex items-center">{{ grandChild.shortName || '-'}}</div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="tier">
                <ng-container *ngIf="currentSearchName; else elseTier">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                </ng-container>
                <ng-template #elseTier>
                    <th mat-header-cell *matHeaderCellDef>Type</th>
                </ng-template>
                <td mat-cell *matCellDef="let element">
                    <div class="h-10 flex items-center">{{ tierName(element.tier) || '-'}}</div>
                    <div *ngFor="let child of element.children">
                        <div class="h-10 flex items-center">{{ tierName(child.tier) || '-'}}</div>
                        <div *ngFor="let grandChild of child.children">
                            <div class="h-10 flex items-center">{{ tierName(grandChild.tier) || '-'}}</div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="parentInstitution">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Parent Entity</th>
                <td mat-cell *matCellDef="let element" [matTooltip]="element.parentInstitution?.name">
                    {{ element.parentInstitution?.name ?? '-' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="active">
                <ng-container *ngIf="currentSearchName; else elseStatus">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                </ng-container>
                <ng-template #elseStatus>
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                </ng-template>
                <td mat-cell *matCellDef="let element">
                    <div class="h-10 flex items-center">
                        <ng-container [ngTemplateOutlet]="statusTemplates" [ngTemplateOutletContext]="{ $implicit: element.active }"></ng-container>    
                    </div>
                    <div *ngFor="let child of element.children">
                        <div class="h-10 flex items-center">
                            <ng-container [ngTemplateOutlet]="statusTemplates" [ngTemplateOutletContext]="{ $implicit: child.active }"></ng-container>    
                        </div>
                        <div *ngFor="let grandChild of child.children">
                            <div class="h-10 flex items-center">
                                <ng-container [ngTemplateOutlet]="statusTemplates" [ngTemplateOutletContext]="{ $implicit: grandChild.active }"></ng-container>    
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="text-white">Actions</th>
                <td mat-cell *matCellDef="let element">
                    <div class="h-10 flex items-center">
                        <ng-container [ngTemplateOutlet]="actionTemplates" [ngTemplateOutletContext]="{ $implicit: element }"></ng-container>    
                    </div>
                    <div *ngFor="let child of element.children">
                        <div class="h-10 flex items-center">
                            <ng-container [ngTemplateOutlet]="actionTemplates" [ngTemplateOutletContext]="{ $implicit: child }"></ng-container>    
                        </div>
                        <div *ngFor="let grandChild of child.children">
                            <div class="h-10 flex items-center">
                                <ng-container [ngTemplateOutlet]="actionTemplates" [ngTemplateOutletContext]="{ $implicit: grandChild }"></ng-container>    
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="currentSearchName ? displayColumnsWithParentInstitution :  displayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: (currentSearchName ? displayColumnsWithParentInstitution : displayColumns)"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell text-center" colspan="6">No data match your search "{{ institutionSearchValue }}"</td>
            </tr>
        </table>
    </mat-card-content>
    <mat-paginator (page)="navigateToPage($event)" [length]="institutionNumberOfRecords" [pageSize]="institutionPageSize"
        [pageSizeOptions]="[5, 10]" [showFirstLastButtons]="true" class="mx-paginator">
    </mat-paginator>
</div>

<ng-template #statusTemplates let-active>
    <div class="w-fit border-0 text-center rounded-xl px-2 py-0.5"
        [ngClass]="active ? 'bg-green-light text-green' : 'bg-gray text-white'">
        {{active ? 'Active' : 'Inactive'}}
    </div>
</ng-template>

<ng-template #actionTemplates let-action>
    <mat-icon class="mr-4" (click)="openInstitutionDialogCreateOrEdit(action)" matTooltip="Edit"
        class="cursor-pointer" *appScreenPermission="[ENTITY_UPDATE]">edit</mat-icon>
    <mat-icon (click)="openInstitutionDialogDetail(action)" matTooltip="View"
        class="cursor-pointer">remove_red_eye</mat-icon>
</ng-template>