<div [formGroup]="formGroupChild" class="relative">
    <label [for]="label">{{ label }}<span *ngIf="required" class="text-primary"> *</span></label>
    <input
        type="file"
        class="mt-1 block w-full pl-3 py-2 border rounded-md text-sm shadow-sm focus:outline-none focus:ring-1"
        [id]="label"
        [ngClass]="{
            'border-primary hover:border-primary focus:border-primary focus:ring-primary':
                formGroupChild.get(formControlNameChild).invalid && formGroupChild.get(formControlNameChild).dirty,
            'border-gray-light hover:border-gray focus:border-gray focus:ring-green-light':
                !formGroupChild.get(formControlNameChild).invalid || !formGroupChild.get(formControlNameChild).dirty,
            'pr-8': suffixIcon,
            'pr-3': !suffixIcon
        }"
        [formControlName]="formControlNameChild"
        [placeholder]="placeholder"
        [maxlength]="maxLength"
        [appAutofocus]="autoFocusChild"
        (change)="onFileSelected($event)"
    />
    <p
        *ngIf="formGroupChild.get(formControlNameChild).invalid && formGroupChild.get(formControlNameChild).dirty"
        class="text-xs text-primary absolute"
    >
        {{ errorMessage }}
    </p>
    <mat-icon
        *ngIf="suffixIcon"
        class="absolute right-2 bottom-2"
        [matTooltip]="suffixIconTooltip"
        matTooltipPosition="above"
        >file_upload</mat-icon
    >
</div>
