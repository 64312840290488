import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { GeneralService } from 'src/app/services/general.service';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';
import { DialogSimpleComponent } from 'src/app/components/shared/ui/dialog-simple/dialog-simple.component';
import { CountryService } from 'src/app/services/country.service';
import { CountryModel } from 'src/app/model/country.model';
import { CountryDialogNewOrEditComponent } from '../country-dialog-new-or-edit/country-dialog-new-or-edit.component';
import { CountryDialogDetailComponent } from '../country-dialog-detail/country-dialog-detail.component';

@Component({
    selector: 'app-country-listing',
    templateUrl: './country-listing.component.html',
})
export class CountryListingComponent implements OnInit {
    readonly COUNTRY_CREATE = ApplicationPermissionService.COUNTRY_CREATE;
    readonly COUNTRY_DELETE = ApplicationPermissionService.COUNTRY_DELETE;
    readonly COUNTRY_UPDATE = ApplicationPermissionService.COUNTRY_UPDATE;
    readonly COUNTRY_READ = ApplicationPermissionService.COUNTRY_READ;

    countryDataSource = new MatTableDataSource<any>();
    obs: BehaviorSubject<any>;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayColumns = ['name', 'code', 'active', 'actions'];

    countrySearchValue: String = null;
    countryPageNumbers: number = 0;
    countryNumberOfRecords: number = 0;
    countryCurrentPage: number = 0;
    countryPageSize: number = 50;
    countrySortOrder = 'name,asc';
    countryCountLoad = 0;
    loading: boolean = false;

    constructor(
        public activatedRoute: ActivatedRoute,
        private matDialog: MatDialog,
        private countryService: CountryService,
        public generalService: GeneralService
    ) {}

    ngOnInit(): void {
        this.countryActiveLoading();
        if (this.activatedRoute.snapshot.queryParams['page']) {
            this.countrySortOrder = this.activatedRoute.snapshot.queryParams['sort']
                ? this.activatedRoute.snapshot.queryParams['sort']
                : undefined;
            this.countryCurrentPage = this.activatedRoute.snapshot.queryParams['page']
                ? this.activatedRoute.snapshot.queryParams['page']
                : undefined;
            this.countryPageSize = this.activatedRoute.snapshot.queryParams['pageSize']
                ? this.activatedRoute.snapshot.queryParams['pageSize']
                : undefined;
        }
        this.refreshForm();
    }

    openCountryDialogCreateOrEdit(countryModel?: CountryModel) {
        if (countryModel === undefined) {
            this.passDataToDialogCreateOrEdit(countryModel);
        } else {
            this.countryService.getCountryById(countryModel.id).subscribe({
                next: (value) => {
                    this.passDataToDialogCreateOrEdit(value.body);
                },
            });
        }
    }

    passDataToDialogCreateOrEdit(event: any) {
        const modalRef = this.matDialog.open(CountryDialogNewOrEditComponent, {
            data: event,
            panelClass: 'mat-dialog-lg',
        });

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) this.search(this.countryCurrentPage, this.countrySearchValue);
            },
        });
    }

    openCountryDialogDetail(countryModel?: CountryModel) {
        this.countryService.getCountryById(countryModel.id).subscribe({
            next: (value) => {
                const modalRef = this.matDialog.open(CountryDialogDetailComponent, {
                    data: value.body,
                    panelClass: 'mat-dialog-md',
                });

                modalRef.afterClosed().subscribe({
                    next: (edit) => {
                        if (edit) this.openCountryDialogCreateOrEdit(countryModel);
                    },
                });
            },
        });
    }

    search(currentPage: number, countrySearchValue: String) {
        this.countrySearchValue = countrySearchValue;
        this.countryService
            .getAllCountryList(this.countryCurrentPage, this.countryPageSize, this.countrySortOrder, {
                name: countrySearchValue,
            })
            .subscribe({
                next: ({ body }) => {
                    this.countryDataSource.data = body.content;
                    this.countryNumberOfRecords = body.totalElements;
                    this.countryDeactiveLoading();
                },
                error: (error) => {
                    this.generalService.snackbarMessage(error.message, 'Close');
                    this.countryDeactiveLoading();
                },
            });
    }

    refreshForm() {
        this.search(0, null);
    }

    sortPage(event): void {
        this.countrySortOrder = `${event.active},${event.direction}`;
        this.search(this.countryCurrentPage, this.countrySearchValue);
    }

    navigateToPage(event): void {
        this.countryPageSize = event.pageSize;
        this.countryCurrentPage = event.pageIndex;
        this.search(this.countryCurrentPage, this.countrySearchValue);
    }

    openCountryDialogDelete(countryModel?: CountryModel): void {
        const modalRef = this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: 'Confirm Delete Country ?',
                message: `Are you sure want to delete ${countryModel.name}?`,
                buttonPositive: 'Yes',
                buttonNegative: 'No',
            },
            panelClass: 'mat-dialog-sm',
        });

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) {
                    this.countryService.delete(countryModel.id.toString()).subscribe({
                        next: () => {
                            this.search(this.countryCurrentPage, this.countrySearchValue);
                        },
                        error: (error) => {
                            this.generalService.snackbarMessage(error.message, 'Close');
                        },
                    });
                }
            },
        });
    }

    countryActiveLoading() {
        this.loading = true;
    }

    countryDeactiveLoading() {
        this.loading = false;
    }
}
