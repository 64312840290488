import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RioTemplate } from 'src/app/model/rio-template.model';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';
import { RioTemplateService } from 'src/app/services/rio-template.service';
import { RioTemplateTypesDialogNewOrEditComponent } from '../rio-template-types-dialog-new-or-edit/rio-template-types-dialog-new-or-edit.component';
import { MatTableDataSource } from '@angular/material/table';
import { GeneralService } from 'src/app/services/general.service';
import { DialogSimpleComponent } from 'src/app/components/shared/ui/dialog-simple/dialog-simple.component';
import { RioTemplateTypesDialogDetailComponent } from '../rio-template-types-dialog-detail/rio-template-types-dialog-detail.component';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-rio-template-types-listing',
    templateUrl: './rio-template-types-listing.component.html',
})
export class RioTemplateTypesListingComponent implements OnInit {
    readonly RIO_TEMPLATE_CREATE = ApplicationPermissionService.RIO_TEMPLATE_CREATE;
    readonly RIO_TEMPLATE_READ = ApplicationPermissionService.RIO_TEMPLATE_READ;
    readonly RIO_TEMPLATE_UPDATE = ApplicationPermissionService.RIO_TEMPLATE_UPDATE;
    readonly RIO_TEMPLATE_DELETE = ApplicationPermissionService.RIO_TEMPLATE_DELETE;

    rioTemplateNumberOfRecords: number = 0;
    rioTemplateCurrentPage: number = 0;
    rioTemplateSearchValue: String = null;
    rioTemplatePageSize: number = 20;
    rioTemplateSortOrder = 'name,asc';
    rioTemplateDataSource = new MatTableDataSource<RioTemplate[]>();
    loading: boolean = false;

    displayColumns = [
        'name',
        'description',
        'actions'
    ];

    constructor(
        private rioTemplateService: RioTemplateService,
        private matDialog: MatDialog,
        private generalService: GeneralService,
        private rioTemplateActivatedRoute: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.loading = true;
        if (this.rioTemplateActivatedRoute.snapshot.queryParams['page']) {
            this.rioTemplateSortOrder = this.rioTemplateActivatedRoute.snapshot.queryParams['sort'] ? this.rioTemplateActivatedRoute.snapshot.queryParams['sort']: undefined;
            this.rioTemplateCurrentPage = this.rioTemplateActivatedRoute.snapshot.queryParams['page'] ? this.rioTemplateActivatedRoute.snapshot.queryParams['page']: undefined;
            this.rioTemplatePageSize = this.rioTemplateActivatedRoute.snapshot.queryParams['pageSize'] ? this.rioTemplateActivatedRoute.snapshot.queryParams['pageSize']: undefined;
        }
        this.search(0, null);
    }

    openRIOTemplateDialogCreateOrEdit(rioTemplate?: RioTemplate) {
        if (rioTemplate) {
            this.rioTemplateService.getRioTemplateById(rioTemplate.id).subscribe({
                next: (value) => {
                    this.passDataToDialogCreateOrEdit(value.body);
                }
            });
        } else {
            this.passDataToDialogCreateOrEdit(rioTemplate);
        }
    }

    passDataToDialogCreateOrEdit(event: any) {
        const modalRef = this.matDialog.open(RioTemplateTypesDialogNewOrEditComponent, {
            data: event,
            panelClass: 'mat-dialog-lg'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) this.search(this.rioTemplateCurrentPage, this.rioTemplateSearchValue);
            }
        })
    }

    search(currentPage: number, rioTemplateSearchValue: String) {
        this.rioTemplateSearchValue = rioTemplateSearchValue;
        this.rioTemplateService.getAllRioTemplatePagination(
            currentPage,
            this.rioTemplatePageSize,
            this.rioTemplateSortOrder, {
            name: rioTemplateSearchValue,
        }).subscribe({
            next: ({ body }) => {
                this.rioTemplateDataSource.data = body.content;
                this.rioTemplateNumberOfRecords = body.totalElements;
                this.loading = false;
            },
            error: (error) => {
                this.generalService.snackbarMessage(error.message, 'Close');
                this.loading = false;
            },
        });
    }

    sortPage(event): void {
        this.rioTemplateSortOrder = `${event.active},${event.direction}`;
        this.search(this.rioTemplateCurrentPage, this.rioTemplateSearchValue);
    }

    openProjectDialogDelete(rioTemplate?: RioTemplate): void {
        const modalRef = this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: 'Confirm Delete Record ?',
                message: `Are you sure want to delete ${rioTemplate.name}?`,
                buttonPositive: 'Yes',
                buttonNegative: 'No'
            },
            panelClass: 'mat-dialog-sm'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) {
                    this.rioTemplateService.delete(rioTemplate.id).subscribe({
                        next: () => {
                            this.search(this.rioTemplateCurrentPage, this.rioTemplateSearchValue);
                        },
                        error: (error) => {
                            this.generalService.snackbarMessage(error.message, 'Close');
                        },
                    });
                }
            }
        })
    }

    openRioTemplateDialogDetail(rioTemplate? : RioTemplate){
        this.rioTemplateService.getRioTemplateById(rioTemplate.id).subscribe({
            next: (value) =>{
                const modalRef = this.matDialog.open(RioTemplateTypesDialogDetailComponent, {
                    data: value.body,
                    panelClass: 'mat-dialog-md'
                })

                modalRef.afterClosed().subscribe({
                    next: (edit) => {
                        if (edit) this.openRIOTemplateDialogCreateOrEdit(rioTemplate);
                    }
                })
            }
        });
    }

    navigateToPage(event): void {
        this.rioTemplatePageSize = event.projectPageSize;
        this.rioTemplateCurrentPage = event.pageIndex;
        this.search(this.rioTemplateCurrentPage, this.rioTemplateSearchValue);
    }
}
