<div class="mx-10 my-6">
    <mx-title-bar title="Gl Cost Code" buttonIcon="add" buttonLabel="Gl Cost Code"
        (onButtonClick)="openGlCodeDialogCreateOrEdit()"></mx-title-bar>
     <mx-search-bar searchFieldName="Code" (onSubmit)="search(0, $event)"></mx-search-bar>
    <div class="my-2">
        <strong>{{ glCodeNumberOfRecords }}</strong> result<span *ngIf="glCodeNumberOfRecords > 1">s</span>
    </div>
    <mat-card-content class="mx-content-table">
        <div *ngIf="loading">
            <mx-data-loading></mx-data-loading>
        </div>
        <table mat-table matSort (matSortChange)="sortPage($event)" [dataSource]="glCodeDataSource" *ngIf="glCodeDataSource.data"
            class="w-full" aria-label="table-expense-type">
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.code || "-" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.type || "-" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="parent">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Parent</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.parentGlCostCode?.code || "-" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.description || "-" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="text-white">Actions</th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon class="mr-4" (click)="openGlCodeDialogCreateOrEdit(element)"
                        matTooltip="Edit">edit</mat-icon>
                    <mat-icon class="mr-4" (click)="openGlCodeDialogDelete(element)"
                        matTooltip="Delete">delete</mat-icon>
                    <mat-icon (click)="openGlCodeDialogDetail(element)" matTooltip="View">remove_red_eye</mat-icon>
                </td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell text-center" colspan="6">No data match your search "{{ glCodeSearchValue }}"</td>
            </tr>
        </table>
    </mat-card-content>
    <mat-paginator (page)="navigateToPage($event)" [length]="glCodeNumberOfRecords" [pageSize]="glCodePageSize"
        [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" class="mx-paginator">
    </mat-paginator>
</div>