<div class="my-3 flex flex-wrap justify-between items-center">
    <div>
        <span class="font-bold text-2xl m-0">
            {{ title }}
        </span>
    </div>
    <div class="flex flex-wrap" *ngIf="isShowing">
        <button
            mat-raised-button
            color="accent"
            class="m-1"
            (click)="onTitleBarButtonClick()"
            *appScreenPermission="buttonPermission"
        >
            <mat-icon class="text-sm mr-2 mt-1 font-bold">{{ buttonIcon }}</mat-icon>
            <span class="text-sm font-bold">{{ buttonLabel }}</span>
        </button>
    </div>
</div>
