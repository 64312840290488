import { UserDialogDetailComponent } from './../user-dialog-detail/user-dialog-detail.component';
import { UserModel } from './../../../../model/user.model';
import { UserDialogNewOrEditComponent } from './../user-dialog-new-or-edit/user-dialog-new-or-edit.component';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { GeneralService } from 'src/app/services/general.service';
import { BehaviorSubject } from 'rxjs';
import { FORM_TEMPLATE_FIELD_INPUT_TYPE } from 'src/app/model/form-template-field.model';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';
import { SharedMethod } from 'src/app/components/shared/shared.component';

@Component({
    selector: 'app-user-listing',
    templateUrl: './user-listing.component.html',
})
export class UserListingComponent implements OnInit {
    readonly CREATE_APPLICATION_USER = ApplicationPermissionService.USER_CREATE;
    readonly UPDATE_APPLICATION_USER = ApplicationPermissionService.USER_UPDATE;
    readonly USER_READ = ApplicationPermissionService.USER_READ;

    userDataSource = new MatTableDataSource<any>();
    obs: BehaviorSubject<any>;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayColumns = ['fullName', 'email', 'phoneNumber', 'role', 'active', 'actions'];

    userSearchValue: String = null;
    userPageNumbers = [];
    userNumberOfRecords = 0;
    userCurrentPage = 0;
    userPageSize = 50;
    userSortOrder = 'fullName,asc';
    userCountLoad = 0;
    loading: boolean = false;
    searchFormGroup: FormGroup;

    isLoading = FORM_TEMPLATE_FIELD_INPUT_TYPE.LOADING;

    constructor(
        private formBuilder: FormBuilder,
        public activatedRoute: ActivatedRoute,
        private matDialog: MatDialog,
        private usersService: UsersService,
        private changeDetectorRef: ChangeDetectorRef,
        public generalService: GeneralService,
        public sharedMethod: SharedMethod,
    ) {}

    ngOnInit(): void {
        this.userActiveLoading();
        this.searchFormGroup = this.formBuilder.group({
            searchName: this.formBuilder.control(null),
        });

        if (this.activatedRoute.snapshot.queryParams['page']) {
            this.userSortOrder = this.activatedRoute.snapshot.queryParams['sort']
                ? this.activatedRoute.snapshot.queryParams['sort']
                : undefined;
            this.userCurrentPage = this.activatedRoute.snapshot.queryParams['page']
                ? this.activatedRoute.snapshot.queryParams['page']
                : undefined;
            this.userPageSize = this.activatedRoute.snapshot.queryParams['pageSize']
                ? this.activatedRoute.snapshot.queryParams['pageSize']
                : undefined;
        }
        this.refreshForm();
    }

    search(currentPage: number, userSearchValue: String) {
        this.userSearchValue = userSearchValue;
        this.usersService
            .getAllUsers(this.userCurrentPage, this.userPageSize, this.userSortOrder, {
                fullName: userSearchValue,
            })
            .subscribe({
                next: ({ body }) => {
                    this.userDataSource.data = body.content;
                    this.userNumberOfRecords = body.totalElements;
                    this.changeDetectorRef.detectChanges();
                    this.obs = this.userDataSource.connect();
                    this.userDeactiveLoading();

                    if (currentPage === 0) {
                        this.paginator.firstPage();
                    } else {
                        this.paginator.pageIndex = currentPage;
                    }
                },
                error: (error) => {
                    this.generalService.snackbarMessage(error.debugMessage, 'Close');
                    this.userDeactiveLoading();
                },
            });
    }

    userActiveLoading() {
        this.loading = true;
    }

    userDeactiveLoading() {
        this.loading = false;
    }

    refreshForm() {
        this.search(0, this.userSearchValue);
    }

    openUserDialogCreateOrEdit(dialogType: 'create' | 'edit',userModel? : any){
        if(dialogType === 'create'){
            this.passDataToDialogCreateOrEdit(dialogType,userModel);
        }else{
            this.usersService.getUserById(userModel.id).subscribe({
                next: (value) =>{
                    this.passDataToDialogCreateOrEdit(dialogType,value.body);
                }
            });
        }
    }

    passDataToDialogCreateOrEdit(dialogType: 'create' | 'edit', userModel?: any) {
        const modalRef = this.matDialog.open(UserDialogNewOrEditComponent, {
            data: {
                dialogType,
                userModel,
            },
            panelClass: 'mat-dialog-lg',
        });

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) this.search(this.userCurrentPage, this.userSearchValue);
            },
        });
    }

    openUserDialogDetail(userModel?: UserModel) {
        this.usersService.getUserById(userModel.id).subscribe({
            next: (value) =>{
                const modalRef = this.matDialog.open(UserDialogDetailComponent, {
                    data: value.body,
                    panelClass: 'mat-dialog-md',
                });

                modalRef.afterClosed().subscribe({
                    next: (edit) => {
                        if (edit) this.openUserDialogCreateOrEdit('edit', userModel);
                    },
                });
            }
        });
    }

    sortPage(event): void {
        this.userSortOrder = `${event.active},${event.direction}`;
        this.search(this.userCurrentPage, this.userSearchValue);
    }

    navigateToPage(event): void {
        this.userCurrentPage = event.pageIndex;
        this.userPageSize = event.pageSize;
        this.search(this.userCurrentPage, this.userSearchValue);
    }
}
