import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ACCOUNT_COMPONENT } from '../app.constant';
import { Observable } from 'rxjs';
import { RioTemplate } from '../model/rio-template.model';
import { AuthenticateService } from '../auth/authenticate.service';

@Injectable({
    providedIn: 'root'
})
export class RioTemplateService {

    private api: string = ACCOUNT_COMPONENT + '/rio-templates'
    private allApi: string = ACCOUNT_COMPONENT + '/rio-templates/all'

    constructor(
        private httpClient: HttpClient,
        private authService: AuthenticateService
    ) { }

    getAllRioTemplate(): Observable<HttpResponse<RioTemplate[]>> {
        return this.httpClient.get<RioTemplate[]>(this.allApi, {
            observe: 'response'
        })
    }

    getRioTemplateById(rioTemplateId: string): Observable<HttpResponse<RioTemplate>> {
        return this.httpClient.get<RioTemplate>(`${this.api}/${rioTemplateId}`, {
            observe: 'response',
        });
    }

    getAllRioTemplatePagination(
        rioTemplatePageNumber: number,
        rioTemplatePageSize: number,
        rioTemplateSortOrder: string,
        criteria
    ): Observable<any> {
        let httpParams = new HttpParams();
        if (rioTemplatePageNumber !== undefined || rioTemplatePageNumber !== null) {
            httpParams = httpParams.set('page', `${rioTemplatePageNumber}`);
        }
        if (rioTemplatePageSize) {
            httpParams = httpParams.set('size', `${rioTemplatePageSize}`);
        }
        if (rioTemplateSortOrder !== undefined || rioTemplateSortOrder !== null) {
            httpParams = httpParams.set('sort', rioTemplateSortOrder);
        }

        if (criteria) {
            if (criteria.name) {
                httpParams = httpParams.set('name', criteria.name);
            }
        }
        return this.httpClient.get(this.api, {
            observe: 'response',
            params: httpParams,
        });
    }

    delete(rioTemplateId: string): Observable<HttpResponse<RioTemplate[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('currentInstitutionId', this.authService.getCurInsId());
        return this.httpClient.delete<RioTemplate[]>(`${this.api}/${rioTemplateId}`, {
            observe: 'response',
            params: httpParams,
        });
    }

    save(rioTemplate: RioTemplate): Observable<HttpResponse<RioTemplate>> {
        return this.httpClient.post<RioTemplate>(this.api, rioTemplate, {
            observe: 'response',
        });
    }

    update(rioTemplate: RioTemplate): Observable<HttpResponse<RioTemplate>> {
        return this.httpClient.put<RioTemplate>(`${this.api}/${rioTemplate.id}`, rioTemplate, {
            observe: 'response',
        });
    }
}
