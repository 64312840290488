import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';
import { BehaviorSubject } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { FORM_TEMPLATE_FIELD_INPUT_TYPE } from 'src/app/model/form-template-field.model';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { GeneralService } from 'src/app/services/general.service';
import { DialogSimpleComponent } from 'src/app/components/shared/ui/dialog-simple/dialog-simple.component';
import { FormTypeConfigurationDialogNewOrEditComponent } from '../form-type-configuration-dialog-new-or-edit/form-type-configuration-dialog-new-or-edit.component';
import { FormTypeConfigurationDialogDetailComponent } from '../form-type-configuration-dialog-detail/form-type-configuration-dialog-detail.component';
import { ProjectService } from 'src/app/services/project.service';
import { ProjectModel } from 'src/app/model/project.model';
import { FormTypeConfigurationService } from 'src/app/services/form-type-configuration.service';
import { FormTypeConfigurationModel } from 'src/app/model/form-type-configuration.model';

@Component({
  selector: 'app-form-type-configuration-listing',
  templateUrl: './form-type-configuration-listing.component.html',
})
export class FormTypeConfigurationListingComponent implements OnInit {

    readonly FORM_TYPE_CREATE = ApplicationPermissionService.FORM_TYPE_CREATE;
    readonly FORM_TYPE_DELETE = ApplicationPermissionService.FORM_TYPE_DELETE;
    readonly FORM_TYPE_UPDATE = ApplicationPermissionService.FORM_TYPE_UPDATE;
    readonly FORM_TYPE_READ = ApplicationPermissionService.FORM_TYPE_READ;

    formTypeConfigurationDataSource = new MatTableDataSource<any>();
    obs: BehaviorSubject<any>;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayColumns = [
        'code',
        'description',
        'actions'
    ];

    formTypeConfigurationSearchValue: String = null;
    formTypeConfigurationPageNumbers: number = 0;
    formTypeConfigurationNumberOfRecords: number = 0;
    formTypeConfigurationCurrentPage:number = 0;
    formTypeConfigurationPageSize:number = 50;
    formTypeConfigurationSortOrder = 'code,asc';
    loading : boolean = false;
    isLoading = FORM_TEMPLATE_FIELD_INPUT_TYPE.LOADING;

    constructor(
        public formTypeConfigurationActivatedRoute: ActivatedRoute,
        private matDialog: MatDialog,
        private formTypeConfigurationService: FormTypeConfigurationService,
        private generalService : GeneralService,
    ) { }

    ngOnInit(): void {
        this.formTypeConfigurationActiveLoading();
        if (this.formTypeConfigurationActivatedRoute.snapshot.queryParams['page']) {
            this.formTypeConfigurationSortOrder = this.formTypeConfigurationActivatedRoute.snapshot.queryParams['sort'] ? this.formTypeConfigurationActivatedRoute.snapshot.queryParams['sort']: undefined;
            this.formTypeConfigurationCurrentPage = this.formTypeConfigurationActivatedRoute.snapshot.queryParams['page'] ? this.formTypeConfigurationActivatedRoute.snapshot.queryParams['page']: undefined;
            this.formTypeConfigurationPageSize = this.formTypeConfigurationActivatedRoute.snapshot.queryParams['pageSize'] ? this.formTypeConfigurationActivatedRoute.snapshot.queryParams['pageSize']: undefined;
        }
        this.refreshForm();
    }

    openFormTypeConfigurationDialogCreateOrEdit(formTypeConfigurationModel? : FormTypeConfigurationModel){
        if(formTypeConfigurationModel === undefined){
            this.passDataToDialogCreateOrEdit(formTypeConfigurationModel);
        }else{
            this.formTypeConfigurationService.getFormTypeConfigurationById(formTypeConfigurationModel.id).subscribe({
                next: (value) =>{
                    this.passDataToDialogCreateOrEdit(value.body);
                }
            });
        }
    }

    passDataToDialogCreateOrEdit(event: any){
        const modalRef = this.matDialog.open(FormTypeConfigurationDialogNewOrEditComponent, {
            data: event,
            panelClass: 'mat-dialog-lg'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
            if (yes) this.search(this.formTypeConfigurationCurrentPage, this.formTypeConfigurationSearchValue);
        }})
    }

    openFormTypeConfigurationDialogDetail(formTypeConfigurationModel? : FormTypeConfigurationModel){
        this.formTypeConfigurationService.getFormTypeConfigurationById(formTypeConfigurationModel.id).subscribe({
            next: (value) =>{
                const modalRef = this.matDialog.open(FormTypeConfigurationDialogDetailComponent, {
                    data: value.body,
                    panelClass: 'mat-dialog-md'
                })

                modalRef.afterClosed().subscribe({
                    next: (edit) => {
                        if (edit) this.openFormTypeConfigurationDialogCreateOrEdit(formTypeConfigurationModel);
                    }
                })
            }
        });
    }

    search(currentPage: number, formTypeConfigurationSearchValue: String){
        this.formTypeConfigurationSearchValue = formTypeConfigurationSearchValue;
        this.formTypeConfigurationService
                .getAllFormTypeConfigurations(this.formTypeConfigurationCurrentPage, this.formTypeConfigurationPageSize, this.formTypeConfigurationSortOrder, {
                code: formTypeConfigurationSearchValue,
            })
            .subscribe({
                next: ({ body }) => {
                    this.formTypeConfigurationDataSource.data = body.content;
                    this.formTypeConfigurationNumberOfRecords = body.totalElements;
                    this.formTypeConfigurationDeactiveLoading();
                },
                error: (error) => {
                    this.generalService.snackbarMessage(error.message, 'Close');
                    this.formTypeConfigurationDeactiveLoading();
                },
        });
    }

    refreshForm(){
        this.search(0, null);
    }

    sortPage(event): void {
        this.formTypeConfigurationSortOrder = `${event.active},${event.direction}`;
        this.search(this.formTypeConfigurationCurrentPage, this.formTypeConfigurationSearchValue);
    }

    navigateToPage(event): void {
        this.formTypeConfigurationPageSize = event.formTypeConfigurationPageSize;
        this.formTypeConfigurationCurrentPage = event.pageIndex;
        this.search(this.formTypeConfigurationCurrentPage, this.formTypeConfigurationSearchValue);
    }

    openFormTypeConfigurationDialogDelete(formTypeConfigurationModel? : FormTypeConfigurationModel): void {
        const modalRef = this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: 'Confirm Delete Form Type Configuration ?',
                message: `Are you sure want to delete ${formTypeConfigurationModel.code}?`,
                buttonPositive: 'Yes',
                buttonNegative: 'No'
            },
            panelClass: 'mat-dialog-sm'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) {
                    this.formTypeConfigurationService.delete(formTypeConfigurationModel.id.toString()).subscribe({
                        next: () => {
                            this.search(this.formTypeConfigurationCurrentPage, this.formTypeConfigurationSearchValue);
                        },
                        error: (error) => {
                            this.generalService.snackbarMessage(error.message,'Close');
                        },
                    });
                }
            }
        })
    }

    formTypeConfigurationActiveLoading(){
        this.loading = true;
    }

    formTypeConfigurationDeactiveLoading(){
        this.loading = false;
    }

}
