import { Component, Input,} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'mx-phone-field',
    templateUrl: './mx-phone-field.component.html',
})
export class MxPhoneFieldComponent {
    @Input() public formGroupChild: FormGroup;
    @Input() public formControlNameChild: string;
    @Input() public label: String;
    @Input() public required: boolean = false;
    @Input() public errorMessage: String;
}
