<div [formGroup]="formGroupChild" [ngSwitch]="inputType" class="my-3">
    <mx-text-field *ngSwitchCase="text" [formGroupChild]="formGroupChild" [formControlNameChild]="formControlNameChild"
        [label]="label" [placeholder]="placeholder" [maxLength]="maxLength" [autoFocusChild]="autoFocusChild"
        [errorMessage]="errorMessage" [required]="required" [suffixIcon]="suffixIcon"
        [suffixIconTooltip]="suffixIconTooltip"></mx-text-field>
    <mx-checkbox *ngSwitchCase="checkbox" [formGroupChild]="formGroupChild"
        [formControlNameChild]="formControlNameChild" [label]="label"></mx-checkbox>
    <mx-select *ngSwitchCase="select" [formGroupChild]="formGroupChild" [formControlNameChild]="formControlNameChild"
        [label]="label" [placeholder]="placeholder" [errorMessage]="errorMessage" [required]="required"
        [compareWithChild]="compareWithChild" [disabledChild]="disabledChild" [list]="list"
        (onSelectionChange)="selectionChange($event)"></mx-select>
    <mx-multi-select *ngSwitchCase="multiSelect" [formGroupChild]="formGroupChild"
        [formControlNameChild]="formControlNameChild" [label]="label" [placeholder]="placeholder"
        [errorMessage]="errorMessage" [required]="required" [compareWithChild]="compareWithChild"
        [disabledChild]="disabledChild" [list]="list" (onSelectionChange)="selectionChange($event)"></mx-multi-select>
    <mx-password *ngSwitchCase="password" [formGroupChild]="formGroupChild"
        [formControlNameChild]="formControlNameChild" [label]="label" [placeholder]="placeholder"
        [maxLength]="maxLength" [autoFocusChild]="autoFocusChild" [errorMessage]="errorMessage" [required]="required"
        [minLength]="minLength" [showMeter]="showMeter"></mx-password>
    <mx-confirm-password *ngSwitchCase="confirmPassword" [formGroupChild]="formGroupChild"
        [formControlNameChild]="formControlNameChild" [label]="label" [placeholder]="placeholder"
        [maxLength]="maxLength" [autoFocusChild]="autoFocusChild" [errorMessage]="errorMessage" [required]="required"
        [passwordFormControlName]="passwordFormControlName"></mx-confirm-password>
    <mx-phone-field *ngSwitchCase="phone" [formGroupChild]="formGroupChild"
        [formControlNameChild]="formControlNameChild" [label]="label" [errorMessage]="errorMessage" [required]="required"></mx-phone-field>
    <mx-attachment *ngSwitchCase="uploadFile" [formGroupChild]="formGroupChild" [formControlNameChild]="formControlNameChild"
        [label]="label" [placeholder]="placeholder" [errorMessage]="errorMessage" [required]="required" [suffixIcon]="suffixIcon"
        [suffixIconTooltip]="suffixIconTooltip" (onSelectionChange)="selectionChange($event)"></mx-attachment>
</div>