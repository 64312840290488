export const REGEXS = {
    PHONE_FILTER: (/'^[+0-9]*$'/),
    NUMBER_FILTER: (/'^[+0-9]*$'/),
    IC: (/'^[A-Z0-9-]*$'/),
    DATE: 'dd/MM/yy, h:mm a',
    VEHICLE:(/'^([a-zA-Z0-9]*)$'/),
};

export const SnackBarStatus = {
    CLOSE: 'Close',
    OPEN: 'Open',
};
