import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CategoryEnum, DateTypeEnumModel, TypeEnumModel } from '../model/date-type-enum.model';
import { SERVER_API } from '../app.constant';

@Injectable({
    providedIn: 'root',
})
export class DashboardService {

    private apiDashboard: string = SERVER_API + 'api/account-component/admin/dashboard';

    ASC = 'ASC';

    constructor(private httpClient: HttpClient) {}

    getModuleRecord( dateType: string, dateFrom, dateTo, yearMonth): Observable<any> {
        let httpParams = new HttpParams();
        if (dateType) {
            httpParams = httpParams.set('dateType', `${dateType}`);
        }
        if (dateFrom) {
            httpParams = httpParams.set('dateFrom', `${dateFrom}`);
        }
        if (dateTo) {
            httpParams = httpParams.set('dateTo', `${dateTo}`);
        }
        if (yearMonth) {
            httpParams = httpParams.set('yearMonth', `${yearMonth}`);
        }
        return this.httpClient.get(this.apiDashboard + '/module/records', {
            observe: 'response',
            params: httpParams,
        });
    }

    getAppDownload( appDownloadDateType: string, appDownloadDateFrom, appDownloadDateTo, appDownloadYearMonth): Observable<any> {
        let httpParams = new HttpParams();
        if (appDownloadDateType) {
            httpParams = httpParams.set('dateType', `${appDownloadDateType}`);
        }
        if (appDownloadDateFrom) {
            httpParams = httpParams.set('dateFrom', `${appDownloadDateFrom}`);
        }
        if (appDownloadDateTo) {
            httpParams = httpParams.set('dateTo', `${appDownloadDateTo}`);
        }
        if (appDownloadYearMonth) {
            httpParams = httpParams.set('yearMonth', `${appDownloadYearMonth}`);
        }
        return this.httpClient.get(this.apiDashboard + '/app/download', {
            observe: 'response',
            params: httpParams,
        });
    }

    getAppTimeSpent( appTimeSpentDateType: string, appTimeSpentDateFrom, appTimeSpentDateTo, appTimeSpentYearMonth): Observable<any> {
        let httpParams = new HttpParams();
        if (appTimeSpentDateType) {
            httpParams = httpParams.set('dateType', `${appTimeSpentDateType}`);
        }
        if (appTimeSpentDateFrom) {
            httpParams = httpParams.set('dateFrom', `${appTimeSpentDateFrom}`);
        }
        if (appTimeSpentDateTo) {
            httpParams = httpParams.set('dateTo', `${appTimeSpentDateTo}`);
        }
        if (appTimeSpentYearMonth) {
            httpParams = httpParams.set('yearMonth', `${appTimeSpentYearMonth}`);
        }
        httpParams = httpParams.set('direction', this.ASC);
        return this.httpClient.get(this.apiDashboard + '/app/time-spent', {
            observe: 'response',
            params: httpParams,
        });
    }

    getModuleTimeSpent( moduleTimeSpentDateType: string, moduleTimeSpentDateFrom, moduleTimeSpentDateTo, moduleTimeSpentYearMonth): Observable<any> {
        let httpParams = new HttpParams();
        if (moduleTimeSpentDateType) {
            httpParams = httpParams.set('dateType', `${moduleTimeSpentDateType}`);
        }
        if (moduleTimeSpentDateFrom) {
            httpParams = httpParams.set('dateFrom', `${moduleTimeSpentDateFrom}`);
        }
        if (moduleTimeSpentDateTo) {
            httpParams = httpParams.set('dateTo', `${moduleTimeSpentDateTo}`);
        }
        if (moduleTimeSpentYearMonth) {
            httpParams = httpParams.set('yearMonth', `${moduleTimeSpentYearMonth}`);
        }
        httpParams = httpParams.set('direction', this.ASC);
        return this.httpClient.get(this.apiDashboard + '/module/time-spent', {
            observe: 'response',
            params: httpParams,
        });
    }

    getUserActivity( userActivityDateType: string, userActivityDateFrom, userActivityDateTo, userActivityYearMonth): Observable<any> {
        let httpParams = new HttpParams();
        if (userActivityDateType) {
            httpParams = httpParams.set('dateType', `${userActivityDateType}`);
        }
        if (userActivityDateFrom) {
            httpParams = httpParams.set('dateFrom', `${userActivityDateFrom}`);
        }
        if (userActivityDateTo) {
            httpParams = httpParams.set('dateTo', `${userActivityDateTo}`);
        }
        if (userActivityYearMonth) {
            httpParams = httpParams.set('yearMonth', `${userActivityYearMonth}`);
        }
        httpParams = httpParams.set('direction', this.ASC);
        return this.httpClient.get(this.apiDashboard + '/app/user-activity', {
            observe: 'response',
            params: httpParams,
        });
    }

    getActiveUser( activeUserDateType: string, activeUserDateFrom, activeUserDateTo, activeUserYearMonth): Observable<any> {
        let httpParams = new HttpParams();
        if (activeUserDateType) {
            httpParams = httpParams.set('dateType', `${activeUserDateType}`);
        }
        if (activeUserDateFrom) {
            httpParams = httpParams.set('dateFrom', `${activeUserDateFrom}`);
        }
        if (activeUserDateTo) {
            httpParams = httpParams.set('dateTo', `${activeUserDateTo}`);
        }
        if (activeUserYearMonth) {
            httpParams = httpParams.set('yearMonth', `${activeUserYearMonth}`);
        }
        httpParams = httpParams.set('direction', this.ASC);
        return this.httpClient.get(this.apiDashboard + '/app/active-user', {
            observe: 'response',
            params: httpParams,
        });
    }

    getTypeEnum(): Observable<HttpResponse<TypeEnumModel[]>> {
        return this.httpClient.get<TypeEnumModel[]>(this.apiDashboard + '/type-enum', {
            observe: 'response',
        });
    }

    getDateTypeEnum(): Observable<HttpResponse<DateTypeEnumModel[]>> {
        return this.httpClient.get<DateTypeEnumModel[]>(this.apiDashboard + '/date-type-enum', {
            observe: 'response',
        });
    }

    getCategoryEnum(): Observable<HttpResponse<CategoryEnum[]>> {
        return this.httpClient.get<CategoryEnum[]>(this.apiDashboard + '/category-enum', {
            observe: 'response',
        });
    }
}
