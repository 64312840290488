<div class="mx-10 my-6" *appScreenPermission="[REPORT_READ_RIO]">
    <mx-title-without-button-bar title="Rolling Inflow Outflow (RIO)"></mx-title-without-button-bar>
    <div class="grid grid-cols-2 gap-3 bg-[#E2E6F3] rounded-md p-3 my-5">
        <div class="col-span-2 lg:col-span-1">
            <label>Reporting Year</label>
            <mat-select
                (selectionChange)="toggleYearSelection($event.value)"
                class="mt-1 block w-full pl-3 py-2 border rounded-md text-sm shadow-sm focus:outline-none focus:ring-1 bg-white"
                placeholder="Select reporting year"
                [value]="selectedYear">
                <mat-option *ngFor="let year of reportingYearList" [value]="year">
                    {{ year }}
                </mat-option>
            </mat-select>
        </div>
        <div class="col-span-2 lg:col-span-1">
            <label>Reporting Month</label>
            <mat-select
                (selectionChange)="toggleMonthSelection($event.value)"
                class="mt-1 block w-full pl-3 py-2 border rounded-md text-sm shadow-sm focus:outline-none focus:ring-1 bg-white"
                placeholder="Select reporting month"
                [value]="selectedMonth">
                <mat-option *ngFor="let month of reportingMonthList" [value]="month">
                    {{ month }}
                </mat-option>
            </mat-select>
        </div>
        <div class="col-span-2 lg:col-span-1">
            <label>Company</label>
            <mat-select
                (selectionChange)="toggleCompanySelection($event.value)"
                class="mt-1 block w-full pl-3 py-2 border rounded-md text-sm shadow-sm focus:outline-none focus:ring-1 bg-white"
                placeholder="Select reporting company"
                [value]="selectedCompany"
                [compareWith]="compareObjects">
                <mat-option *ngFor="let company of reportingCompanyList" [value]="company">
                    {{ company.name }}
                </mat-option>
            </mat-select>
        </div>
        <div class="col-span-2 lg:col-span-1">
            <label>Project</label>
            <mat-select
                (selectionChange)="toggleProjectSelection($event.value)"
                class="mt-1 block w-full pl-3 py-2 border rounded-md text-sm shadow-sm focus:outline-none focus:ring-1 bg-white"
                placeholder="Select reporting project"
                [value]="selectedProject"
                [compareWith]="compareObjects">
                <mat-option *ngFor="let project of reportingProjectList" [value]="project">
                    {{ project.name }}
                </mat-option>
            </mat-select>
        </div>
        <div class="col-span-2 flex justify-end">
            <button
                    mat-raised-button
                    class="px-8 py-3 font-semibold rounded"
                    (click)="resetFilter()"
            >
                Reset
            </button>
            <div class="px-2"></div>
            <button
                mat-raised-button
                color="primary"
                class="px-8 py-3 font-semibold rounded"
                (click)="refreshForm()"
            >
                Search
            </button>
        </div>
    </div>
    <div class="flex justify-end gap-5 items-center mb-3">
        <div class="flex items-center gap-2">
            <div class="w-4 h-4 rounded-full bg-[#111C44]"></div>
            <div class="font-medium">Group</div>
        </div>
        <div class="flex items-center gap-2">
            <div class="w-4 h-4 rounded-full bg-[#424154]"></div>
            <div class="font-medium">Function</div>
        </div>
        <div class="flex items-center gap-2">
            <div class="w-4 h-4 rounded-full bg-[#726663]"></div>
            <div class="font-medium">Unit</div>
        </div>
        <div class="flex items-center gap-2">
            <div class="w-4 h-4 rounded-full bg-[#D2AF82]"></div>
            <div class="font-medium">Team</div>
        </div>
    </div>
    <nav *ngIf="!loading" mat-tab-nav-bar [tabPanel]="tabPanel">
        <a *ngFor="let tab of institutionTabs"
           mat-tab-link
           [active]="selectedInstitutionTab.institutionId == tab.institutionId"
           (click)="onSelectedInstitutionTab(tab)"
           [ngStyle]="{
                    'background-color':  selectedInstitutionTab.institutionId == tab.institutionId ? 'white' : bgBasedOnTier(tab.tier),
                    'color': selectedInstitutionTab.institutionId == tab.institutionId ? bgBasedOnTier(tab.tier) : 'white',
                    'border-bottom': selectedInstitutionTab.institutionId == tab.institutionId ? '4px solid' : '',
                    'border-bottom-color': selectedInstitutionTab.institutionId == tab.institutionId ? bgBasedOnTier(tab.tier) : ''
                }"
           class="mat-tab-label"
           style="border-radius: 10px 10px 2px 2px; color: white !important;">
            {{tab.institutionName}}
        </a>
    </nav>
    <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
    <mat-card-content class="mx-content-table">
        <div *ngIf="loading">
            <mx-data-loading></mx-data-loading>
        </div>
        <table mat-table matSort (matSortChange)="sortPage($event)" [dataSource]="dataSource" *ngIf="dataSource.data"
               class="w-full" aria-label="table-expense-type">
            <ng-container matColumnDef="reportingYear">
                <th mat-header-cell *matHeaderCellDef>Reporting Year</th>
                <td mat-cell *matCellDef="let element">
                    {{ element?.reportingYear || "-" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="reportingMonth">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Reporting Month</th>
                <td mat-cell *matCellDef="let element">
                    {{ element?.reportingMonth || "-" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="projectName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white">Project</th>
                <td mat-cell *matCellDef="let element">
                    {{ element?.projectName || "-" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="companyName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white">Company</th>
                <td mat-cell *matCellDef="let element">
                    {{ element?.companyName || "-" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-white">Status</th>
                <td mat-cell *matCellDef="let element">
                    {{ element?.status || "-" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="text-white">Actions</th>
                <td mat-cell *matCellDef="let element">
                    <button *ngIf="element.isUploadEnabled" mat-icon-button matTooltip="Upload" (click)="openReportUploadDialogUpload(element)">
                        <mat-icon>file_upload</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="View" (click)="openReportUploadDialogDetail(element)">
                        <mat-icon>remove_red_eye</mat-icon>
                    </button>
                    <button *ngIf="element.status === 'EXTRACTED'" mat-icon-button matTooltip="Download" (click)="downloadReportDetail(element)">
                        <mat-icon>file_download</mat-icon>
                    </button>
                    <button *ngIf="element.isDeleteEnabled && element.status === 'EXTRACTED'" mat-icon-button
                            matTooltip="Delete" (click)="openReportDeleteConfirmationDialog(element)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>

            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns" [ngStyle]="{'background-color': row.status === 'FAILED' ? failColorCode : 'white'}"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell text-center" colspan="6">No data match your search "{{ searchValue }}"</td>
            </tr>
        </table>
    </mat-card-content>
    <mat-paginator (page)="navigateToPage($event)" [length]="numberOfRecords" [pageSize]="pageSize"
                   [pageSizeOptions]="[5, 10, 20, 50]" [showFirstLastButtons]="true" class="mx-paginator">
    </mat-paginator>
</div>
