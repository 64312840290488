import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticateService } from 'src/app/auth/authenticate.service';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';

@Component({
    selector: 'app-user-roles-detail',
    templateUrl: './user-roles-detail.component.html',
})
export class UserRolesDetailComponent implements OnInit {
    categoryPermissionList: any[] = [];
    readonly ROLE_UPDATE = ApplicationPermissionService.ROLE_UPDATE;
    readonly PERMISSION_READ = ApplicationPermissionService.PERMISSION_READ;

    constructor(
        @Inject(MAT_DIALOG_DATA) public applicationUserRoleModel: any,
        public dialogRef: MatDialogRef<UserRolesDetailComponent>,
        public authService: AuthenticateService
    ) {}

    ngOnInit(): void {
        if (this.applicationUserRoleModel.permissions.length > 0) {
            const permissions = this.applicationUserRoleModel.permissions;
            permissions.sort((a, b) => {
                if (a.code > b.code) {
                    return 1;
                }

                if (a.code < b.code) {
                    return -1;
                }

                return 0;
            });
        }
    }
}
