import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthenticateService } from 'src/app/auth/authenticate.service';
import { CompanyModel } from 'src/app/model/company.model';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';

@Component({
    selector: 'app-company-dialog-detail',
    templateUrl: './company-dialog-detail.component.html',
})
export class CompanyDialogDetailComponent {
    readonly COMPANY_UPDATE = ApplicationPermissionService.COMPANY_UPDATE;

    constructor(
        @Inject(MAT_DIALOG_DATA) public companyModel: CompanyModel,
        public dialogRef: MatDialogRef<CompanyDialogDetailComponent>,
        public authService: AuthenticateService
    ) {
        // Sort the institutions array by name
        if (this.companyModel && this.companyModel.institutions) {
            this.companyModel.institutions.sort((a, b) => a.name.localeCompare(b.name));
        }

        this.companyModel.projects = this.addTypeOnProjectName(this.companyModel.projects)
    }

    addTypeOnProjectName(projectList: any[]) {
        return projectList.map((pro: any) => {
            return {
                ...pro,
                name: `${pro.name} (${pro.type})`
            }
        })
    }
}
