<div class="mx-10 my-6" *appScreenPermission="[DASHBOARD_ACTIVITY_READ]">
	<mx-title-bar title="Dashboard" [isShowing]="false"></mx-title-bar>

	<!-- APP DOWNLOAD -->
	<!-- <div class="bg-white p-3 rounded-lg border border-medium-gray">
		<div class="flex justify-between flex-wrap">
			<div class="text-2xl p-[10px] font-bold flex-shrink-0 max-w-full">App Download</div>
			<div class="flex flex-wrap ml-auto">
				<app-dashboard-action (onDatesChanged)="appDownloadDatesChanged($event)"></app-dashboard-action>
			</div>
		</div>
		<hr class="text-medium-gray">
		<div class="p-[10px]">
			<mx-pie-chart [appDownloadFilterDates]="appDownloadFilterDates"></mx-pie-chart>
		</div>
	</div>
	<br> -->

	<!-- App Time Spent -->
	<!-- <div class="bg-white p-3 rounded-lg border border-medium-gray">
		<div class="flex justify-between flex-wrap">
			<div class="text-2xl p-[10px] font-bold flex-shrink-0 max-w-full">App Time Spent</div>
			<div class="flex flex-wrap ml-auto">
				<app-dashboard-action (onDatesChanged)="appTimeSpentDatesChanged($event)"></app-dashboard-action>
			</div>
		</div>
		<hr class="text-medium-gray" >
		<div class="p-[10px] flex justify-between max-w-full">
			<div class="text-base p-[10px] font-bold ">Spent Time</div>
		</div>
		<app-time-spent-chart [appTimeSpentFilterDates]="appTimeSpentFilterDates"></app-time-spent-chart>
	</div>
	<br> -->

	<!-- Module Time Spent -->
	<!-- <div class="bg-white p-3 rounded-lg border border-medium-gray">
		<div class="flex justify-between flex-wrap">
			<div class="text-2xl p-[10px] font-bold flex-shrink-0 max-w-full">Module Time Spent</div>
			<div class="flex flex-wrap ml-auto">
				<app-dashboard-action (onDatesChanged)="moduleTimeDatesChanged($event)"></app-dashboard-action>
			</div>
		</div>
		<hr class="text-medium-gray">
		<div class="p-[10px] grid grid-cols-6 gap-4 max-w-full">
			<div class="text-base pt-[10px] font-bold col-start-1 col-end-3">Spent Time</div>
		</div>
		<module-time-spent-chart [moduleTimeFilterDates]="moduleTimeFilterDates"></module-time-spent-chart>
	</div>
	<br> -->

	<!-- Module Records -->
	<!-- <div class="bg-white p-3 rounded-lg border border-medium-gray">
		<div class="flex justify-between flex-wrap">
			<div class="text-2xl p-[10px] font-bold flex-shrink-0 max-w-full">Module Records</div>
			<div class="flex flex-wrap ml-auto">
				<app-dashboard-action (onDatesChanged)="datesChanged($event)"></app-dashboard-action>
			</div>
		</div>
		<hr class="text-medium-gray">
		<div class="p-[10px]">
			<div class="text-base p-[10px] font-bold flex-shrink-0 max-w-full">Records Created</div>
			<module-records-chart  [moduleRecordsFilterDates]="moduleRecordsFilterDates"></module-records-chart>
		</div>
	</div>
	<br> -->

	<!-- User Activity -->
	<!-- <div class="bg-white p-3 rounded-lg border border-medium-gray">
		<div>
			<div class="flex justify-between flex-wrap">
				<div class="text-2xl p-[10px] font-bold flex-shrink-0 max-w-full">User Activity</div>
				<div class="flex flex-wrap ml-auto">
					<app-dashboard-action (onDatesChanged)="userActivityDatesChanged($event)"></app-dashboard-action>
				</div>
			</div>
			<hr class="text-medium-gray">
			<div class="p-[10px]">
				<div class="text-base p-[10px] font-bold flex-shrink-0 max-w-full">User Activity</div>
				<user-activity-chart   [userActivityFilterDates]="userActivityFilterDates"></user-activity-chart>
			</div>
		</div>
		<br> -->

		<!-- App Active User -->

		<!-- <div>
			<div class="flex justify-between flex-wrap">
				<div class="text-2xl p-[10px] font-bold flex-shrink-0 max-w-full">App Active User</div>
			</div>
			<hr class="text-medium-gray">
			<div class="p-[10px]">
				<div class="text-base p-[10px] font-bold flex-shrink-0 max-w-full">Active User</div>
				<active-user-chart  [activeUserFilterDates]="activeUserFilterDates"></active-user-chart>
			</div>
		</div>
	</div>
	<br> -->
</div>