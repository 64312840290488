import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TOOLTIPS } from 'src/app/components/shared/constant/tooltips-enum.constant';
import { FORM_TEMPLATE_FIELD_INPUT_TYPE } from 'src/app/model/form-template-field.model';
import { InstitutionModel } from 'src/app/model/institution.model';
import { ProjectModel } from 'src/app/model/project.model';
import { GeneralService } from 'src/app/services/general.service';
import { InstitutionService } from 'src/app/services/institution.service';
import { ProjectService } from 'src/app/services/project.service';
import { CompanyDialogDetailComponent } from '../company-dialog-detail/company-dialog-detail.component';
import { CompanyModel } from 'src/app/model/company.model';
import { CompanyService } from 'src/app/services/company.service';
import { HttpResponse } from '@angular/common/http';

@Component({
    selector: 'app-company-dialog-new-or-edit',
    templateUrl: './company-dialog-new-or-edit.component.html',
})
export class CompanyDialogNewOrEditComponent implements OnInit {
    dialogType: string = '';
    isSaving: boolean = false;
    companyFormGroup: FormGroup;
    institutions: InstitutionModel[] = [];
    emailContent: string = TOOLTIPS.emailContent;
    phoneContent: string = TOOLTIPS.phoneContent;
    loading: boolean = false;

    text = FORM_TEMPLATE_FIELD_INPUT_TYPE.TEXT;
    checkbox = FORM_TEMPLATE_FIELD_INPUT_TYPE.CHECKBOX;
    select = FORM_TEMPLATE_FIELD_INPUT_TYPE.SELECT;
    isLoading = FORM_TEMPLATE_FIELD_INPUT_TYPE.LOADING;
    multiSelect = FORM_TEMPLATE_FIELD_INPUT_TYPE.MULTISELECT;
    projectList: ProjectModel[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public companyModel: CompanyModel,
        public dialogRef: MatDialogRef<CompanyDialogNewOrEditComponent>,
        private institutionService: InstitutionService,
        private companyService: CompanyService,
        private formBuilder: FormBuilder,
        private generalService: GeneralService,
        private matDialog: MatDialog,
        private projectService: ProjectService
    ) {}

    ngOnInit(): void {
        this.activeLoading();
        if (this.companyModel) {
            this.dialogType = 'update';
            this.companyFormGroup = this.formBuilder.group({
                id: this.formBuilder.control(this.companyModel.id),
                name: this.formBuilder.control(this.companyModel.name, Validators.required),
                code: this.formBuilder.control(this.companyModel.code, Validators.required),
                description: this.formBuilder.control(this.companyModel.description),
                institutions: this.formBuilder.control(this.companyModel.institutions.slice().sort((a, b) => a.name.localeCompare(b.name))),
                projects: this.formBuilder.control(this.addTypeOnProjectName(this.companyModel.projects).slice().sort((a, b) => a.name.localeCompare(b.name))),
            });
        } else {
            this.dialogType = 'create';
            this.companyFormGroup = this.formBuilder.group({
                name: this.formBuilder.control(null, Validators.required),
                code: this.formBuilder.control(null, Validators.required),
                description: this.formBuilder.control(null),
                institutions: this.formBuilder.control([]),
                projects: this.formBuilder.control([]),
            });
        }

        // Get institutions and projects dropdown

        this.projectService.getAll().subscribe({
            next: (response: HttpResponse<ProjectModel[]>) => {
                let projects: ProjectModel[] = response.body.filter((project: ProjectModel) => !project.company || this.companyModel?.projects?.find(p => p.id === project.id))
                projects.sort((a, b) => a.name.localeCompare(b.name))
                this.projectList = this.addTypeOnProjectName(projects)
                this.deactiveLoading();
            },
            error: (err) => {
                console.log(err);
                this.deactiveLoading();
            },
        })

        this.institutionService.getByActiveTrueAndTierMoreThanEqual(1).subscribe({
            next: (institutionRes) => {
                this.institutions = institutionRes.body;
                this.institutions = this.institutions.sort((a, b) => a.name.localeCompare(b.name));
                this.deactiveLoading();
            },
            error: (err) => {
                console.log(err);
                this.deactiveLoading();
            },
        })
    }

    save(): void {
        this.isSaving = true;
        this.activeLoading();
        if (this.dialogType === 'create') {
            this.companyService.save(this.companyFormGroup.getRawValue()).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: (error) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.generalService.snackbarMessage(error.message, 'Close');
                },
            });
        } else {
            this.companyService.update(this.companyFormGroup.getRawValue()).subscribe({
                next: (value) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                    this.openCompanyDialogDetail(value.body);
                },
                error: (error) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.generalService.snackbarMessage(error.message, 'Close');
                },
            });
        }
    }

    activeLoading() {
        this.loading = true;
    }

    deactiveLoading() {
        this.loading = false;
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

    openCompanyDialogCreateOrEdit(companyModel?: CompanyModel) {
        const modalRef = this.matDialog.open(CompanyDialogNewOrEditComponent, {
            data: companyModel,
            panelClass: 'mat-dialog-lg',
        });

        modalRef.afterClosed().subscribe();
    }

    openCompanyDialogDetail(companyModel?: CompanyModel) {
        this.companyService.getCompanyById(companyModel.id).subscribe({
            next: (value) => {
                const modalRef = this.matDialog.open(CompanyDialogDetailComponent, {
                    data: value.body,
                    panelClass: 'mat-dialog-md',
                });

                modalRef.afterClosed().subscribe({
                    next: (edit) => {
                        if (edit) this.openCompanyDialogCreateOrEdit(companyModel);
                    },
                });
            },
        });
    }

    onToppingRemovedFormTypeConfigurations(topping: string) {
        const toppings = this.companyFormGroup.get('formTypeConfigurations').value as string[];
        this.removeFirst(toppings, topping);
        this.companyFormGroup.get('formTypeConfigurations').setValue(toppings); // To trigger change detection
    }

    private removeFirst<T>(array: T[], toRemove: T): void {
        const index = array.indexOf(toRemove);
        if (index !== -1) {
            array.splice(index, 1);
        }
    }

    addTypeOnProjectName(projectList: any[]) {
        return projectList.map((pro: any) => {
            return {
                ...pro,
                name: `${pro.name} (${pro.type})`
            }
        })
    }
}
