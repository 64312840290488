import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
    selector: 'mx-search-bar',
    templateUrl: './mx-search-bar.component.html',
})
export class MxSearchBarComponent implements OnInit {
    searchFormGroup: FormGroup;
    @Input() public searchFieldName: string;
    @Output() onSubmit = new EventEmitter<String>();

    constructor(private formBuilder: FormBuilder) {}

    ngOnInit() {
        this.searchFormGroup = this.formBuilder.group({
            searchName: this.formBuilder.control(null),
        });
    }

    onSearchSubmit() {
        let name: String;

        name = this.searchFormGroup.controls.searchName.value ? this.searchFormGroup.controls.searchName.value : null;

        this.onSubmit.emit(name);
    }

    refreshForm() {
        this.searchFormGroup = this.formBuilder.group({
            searchName: this.formBuilder.control(null),
        });
        this.onSubmit.emit(null);
    }
}
