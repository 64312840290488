import { Injectable, } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { AuthenticateService } from '../auth/authenticate.service';
import { Observable } from 'rxjs';
import { ACCOUNT_COMPONENT } from '../app.constant';
import { ProjectModel } from '../model/project.model';
import { FormTypeConfigurationModel } from '../model/form-type-configuration.model';


@Injectable({
    providedIn: 'root'
})
export class FormTypeConfigurationService {

    private apiFormTypeConfiguration: string = ACCOUNT_COMPONENT + '/form-type-configurations';


    constructor(private httpClient: HttpClient, private authService: AuthenticateService) { }

    save(
        formTypeConfigurationModel : FormTypeConfigurationModel
    ):Observable<HttpResponse<FormTypeConfigurationModel>>{
        return this.httpClient.post<FormTypeConfigurationModel>( this.apiFormTypeConfiguration, formTypeConfigurationModel, {
            observe: 'response',
        });
    }

    getAllFormTypeConfigurations(
        formTypeConfigurationPageNumber: number,
        formTypeConfigurationPageSize: number,
        formTypeConfigurationSortOrder: string,
        criteria
    ): Observable<any> {
        let httpParams = new HttpParams();
        if (formTypeConfigurationPageNumber !== undefined || formTypeConfigurationPageNumber !== null) {
            httpParams = httpParams.set('page', `${formTypeConfigurationPageNumber}`);
        }
        if (formTypeConfigurationPageSize) {
            httpParams = httpParams.set('size', `${formTypeConfigurationPageSize}`);
        }
        if (formTypeConfigurationSortOrder !== undefined || formTypeConfigurationSortOrder !== null) {
            httpParams = httpParams.set('sort', formTypeConfigurationSortOrder);
        }

        if (criteria) {
            if (criteria.code) {
                httpParams = httpParams.set('code', criteria.code);
            }
        }
        return this.httpClient.get(`${this.apiFormTypeConfiguration}`, {
            observe: 'response',
            params: httpParams,
        });
    }

    update(
        formTypeConfigurationModel: FormTypeConfigurationModel,
    ): Observable<HttpResponse<FormTypeConfigurationModel>> {
        const formTypeConfigurationId = formTypeConfigurationModel.id;
        return this.httpClient.put<FormTypeConfigurationModel>(this.apiFormTypeConfiguration + '/' + formTypeConfigurationId, formTypeConfigurationModel, {
            observe: 'response',
        });
    }

    delete(formTypeConfigurationId: string): Observable<HttpResponse<FormTypeConfigurationModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('currentInstitutionId', this.authService.getCurInsId());
        return this.httpClient.delete<FormTypeConfigurationModel[]>(this.apiFormTypeConfiguration + '/' + formTypeConfigurationId, {
            observe: 'response',
            params: httpParams,
        });
    }


    getFormTypeConfigurationById(formTypeConfigurationId: string): Observable<HttpResponse<FormTypeConfigurationModel[]>> {
        return this.httpClient.get<FormTypeConfigurationModel[]>(this.apiFormTypeConfiguration+ '/' + formTypeConfigurationId, {
            observe: 'response',
        });
    }

    getAll(): Observable<HttpResponse<FormTypeConfigurationModel[]>> {
        return this.httpClient.get<FormTypeConfigurationModel[]>(this.apiFormTypeConfiguration + '/all', {
            observe: 'response',
        });
    }





}
