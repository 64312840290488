import { Component, Injectable, NgModule } from '@angular/core';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable()
export class MyCustomPaginatorIntl extends MatPaginatorIntl {
    changes = new Subject<void>();

    constructor(private translateService: TranslateService) {
        super();
    }

    getRangeLabel = function (page: number, pageSize: number, length: number): string {
        if (length === 0) {
            return `${this.translateService.instant('PAGE')} 1 ${this.translateService.instant('OF')} 1`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize > length ? (Math.ceil(length / pageSize) - 1) * pageSize : page * pageSize;

        const endIndex = Math.min(startIndex + pageSize, length);
        return startIndex + 1 + ' - ' + endIndex + ' ' + 'of' + ' ' + length;
    };
}
