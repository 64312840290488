import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyListingComponent } from './company-listing/company-listing.component';
import { CompanyDialogNewOrEditComponent } from './company-dialog-new-or-edit/company-dialog-new-or-edit.component';
import { CompanyDialogDetailComponent } from './company-dialog-detail/company-dialog-detail.component';

const routes: Routes = [
    {
        path: '',
        component: CompanyListingComponent,
        // canActivate: [
        //     AuthenticatedGuard
        // ]
    },
];

@NgModule({
    declarations: [CompanyListingComponent, CompanyDialogNewOrEditComponent, CompanyDialogDetailComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(routes), FormsModule, ReactiveFormsModule],
})
export class CompanyModule {}
