import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectModel } from 'src/app/model/project.model';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';

@Component({
    selector: 'app-project-dialog-detail',
    templateUrl: './project-dialog-detail.component.html',
})
export class ProjectDialogDetailComponent {
    readonly PROJECT_UPDATE = ApplicationPermissionService.PROJECT_UPDATE;

    constructor(
        @Inject(MAT_DIALOG_DATA) public projectModel: ProjectModel,
        public dialogRef: MatDialogRef<ProjectDialogDetailComponent>
    ) {

        if(this.projectModel){
            if (this.projectModel.institutions) {
                this.projectModel.institutions.sort((a, b) => a.name.localeCompare(b.name));
            }
            if (this.projectModel.formTypeConfigurations) {
                this.projectModel.formTypeConfigurations.sort((a, b) => a.code.localeCompare(b.code));
            }
        }
    }
}
