import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SysConfigDetailComponent } from './sys-config-detail/sys-config-detail.component';
import { SysConfigDialogNewOrEditComponent } from './sys-config-dialog-new-or-edit/sys-config-dialog-new-or-edit.component';
import { SysConfigListingComponent } from './sys-config-listing/sys-config-listing.component';


const routes: Routes = [
    {
        path: '',
        component: SysConfigListingComponent,
    },
];
@NgModule({
    declarations: [SysConfigDetailComponent,SysConfigDialogNewOrEditComponent,SysConfigListingComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(routes), FormsModule, ReactiveFormsModule],
})
export class SysConfigModule {}
