import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FORM_TEMPLATE_FIELD_INPUT_TYPE } from 'src/app/model/form-template-field.model';
import { GeneralService } from 'src/app/services/general.service';
import { ProjectDialogDetailComponent } from '../project-dialog-detail/project-dialog-detail.component';
import { ProjectModel } from 'src/app/model/project.model';
import { ProjectService } from 'src/app/services/project.service';
import { InstitutionService } from 'src/app/services/institution.service';
import { InstitutionModel } from 'src/app/model/institution.model';
import { FormTypeConfigurationModel } from 'src/app/model/form-type-configuration.model';
import { FormTypeConfigurationService } from 'src/app/services/form-type-configuration.service';
import { CompanyModel } from 'src/app/model/company.model';
import { CompanyService } from 'src/app/services/company.service';
import {DropDownListingResponse} from "../../../../model/dropdown-listing-response";
import {ReportUploadModel} from "../../../../model/report-upload.model";
import {DialogSimpleComponent} from "../../../shared/ui/dialog-simple/dialog-simple.component";

@Component({
    selector: 'app-project-dialog-new-or-edit',
    templateUrl: './project-dialog-new-or-edit.component.html',
})
export class ProjectDialogNewOrEditComponent implements OnInit {
    dialogType: string = '';
    isSaving: boolean = false;
    loading: boolean = false;
    isActive: boolean = false;
    projectFormGroup: FormGroup;
    institutionList: InstitutionModel[];
    formTypeConfigurationList: FormTypeConfigurationModel[];
    companies: CompanyModel[] = [];
    projectTypes: string[] = ['CBS','RIO']

    currentProjectType: string = 'CBS'

    rioNonLinked: DropDownListingResponse[]
    cbsNonLinked: DropDownListingResponse[]

    noneSelectedLinkedProject: DropDownListingResponse = {
        id: '1',
        name: 'None',
    };

    text = FORM_TEMPLATE_FIELD_INPUT_TYPE.TEXT;
    multiSelect = FORM_TEMPLATE_FIELD_INPUT_TYPE.MULTISELECT;
    select = FORM_TEMPLATE_FIELD_INPUT_TYPE.SELECT;
    checkbox = FORM_TEMPLATE_FIELD_INPUT_TYPE.CHECKBOX;

    constructor(
        @Inject(MAT_DIALOG_DATA) public projectModel: ProjectModel,
        public dialogRef: MatDialogRef<ProjectDialogNewOrEditComponent>,
        private projectService: ProjectService,
        private formBuilder: FormBuilder,
        private generalService: GeneralService,
        private matDialog: MatDialog,
        private institutionService: InstitutionService,
        private formTypeConfigurationService: FormTypeConfigurationService,
        private companyService: CompanyService
    ) {}

    ngOnInit(): void {
        this.projectActiveLoading();
        if (this.projectModel) {
            this.dialogType = 'update';
            this.currentProjectType = this.projectModel.type;
            this.isActive = this.projectModel.active;

            this.projectFormGroup = this.formBuilder.group({
                id: this.formBuilder.control(this.projectModel.id),
                name: this.formBuilder.control(this.projectModel.name, Validators.required),
                code: this.formBuilder.control(this.projectModel.code, Validators.required),
                description: this.formBuilder.control(this.projectModel.description),
                institutions: this.formBuilder.control(this.projectModel.institutions),
                company: this.formBuilder.control(this.projectModel.company),
                formTypeConfigurations: this.formBuilder.control(this.projectModel.formTypeConfigurations),
                active: this.formBuilder.control(this.projectModel.active),
                type: this.formBuilder.control(this.projectModel.type),
                linkedProject: this.formBuilder.control(this.projectModel.linkedProject),
            });
        } else {
            this.dialogType = 'create';
            this.projectFormGroup = this.formBuilder.group({
                name: this.formBuilder.control(null, Validators.required),
                code: this.formBuilder.control(null, Validators.required),
                description: this.formBuilder.control(null),
                institutions: this.formBuilder.control([]),
                company: this.formBuilder.control(null),
                formTypeConfigurations: this.formBuilder.control(null),
                active: this.formBuilder.control(true),
                type: this.formBuilder.control(this.projectTypes[0]),
                linkedProject: this.formBuilder.control(null),
            });
        }
        this.institutionService.getByActiveTrueAndTierMoreThanEqual(1).subscribe((res) => {
            this.institutionList = res.body;
            this.institutionList = this.institutionList.sort((a, b) => a.name.localeCompare(b.name));
        });

        this.formTypeConfigurationService.getAll().subscribe((res) => {
            this.formTypeConfigurationList = res.body;
            this.formTypeConfigurationList = this.formTypeConfigurationList.sort((a, b) => a.code.localeCompare(b.code));
        });

        this.companyService.getCompaniesNoPagination().subscribe({
            next: (res) => {
                this.companies = res.body;
                this.companies = this.companies.sort((a, b) => a.name.localeCompare(b.name));
            },
        });

        this.projectService.getAllProjectCbsMonLinkedDropdownList().subscribe({
            next: (res) => {
                this.cbsNonLinked = res.body;
                this.cbsNonLinked = this.cbsNonLinked.sort((a, b) => a.name.localeCompare(b.name));
                if (this.projectModel.linkedProject && this.projectModel.type === 'RIO') {
                    this.cbsNonLinked.unshift(this.projectModel.linkedProject);
                    this.cbsNonLinked.unshift(this.noneSelectedLinkedProject);
                }
            },
        });

        this.projectService.getAllProjectRioNonLinkedDropdownList().subscribe({
            next: (res) => {
                this.rioNonLinked = res.body;
                this.rioNonLinked = this.rioNonLinked.sort((a, b) => a.name.localeCompare(b.name));
                if(this.projectModel.linkedProject && this.projectModel.type === 'CBS' ){
                    this.rioNonLinked.unshift(this.projectModel.linkedProject);
                    this.rioNonLinked.unshift(this.noneSelectedLinkedProject);
                }
            },
        });

        this.projectDeactiveLoading();
    }

    projectActiveLoading() {
        this.loading = true;
    }

    projectDeactiveLoading() {
        this.loading = false;
    }

    save(): void {
        this.isSaving = true;
        this.projectActiveLoading();

        if(this.projectFormGroup.get('linkedProject').value === this.noneSelectedLinkedProject){
            this.projectFormGroup.get('linkedProject').setValue(null);
        }

        const getRawValue = this.projectFormGroup.getRawValue();

        if (this.dialogType === 'create') {
            this.projectService.save(getRawValue).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.projectDeactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: (error) => {
                    this.isSaving = false;
                    this.projectDeactiveLoading();
                    this.generalService.snackbarMessage(error.message, 'Close');
                },
            });
        } else {

            if(this.projectFormGroup.get('active').value === false &&
                this.isActive === true &&
                this.currentProjectType === 'RIO' &&
                this.projectFormGroup.get('type').value === 'RIO'){

                this.openReportDeleteConfirmationDialog(getRawValue);

            }else {
                this.projectService.update(getRawValue).subscribe({
                    next: (value) => {
                        this.isSaving = false;
                        this.projectDeactiveLoading();
                        this.dialogRef.close('ok');
                        this.openProjectDialogDetail(value.body);
                    },
                    error: (error) => {
                        this.isSaving = false;
                        this.projectDeactiveLoading();
                        this.generalService.snackbarMessage(error.message, 'Close');
                    },
                });
            }
        }
    }

    openProjectDialogCreateOrEdit(projectModel?: ProjectModel) {
        this.projectService.getProjectById(projectModel.id).subscribe({
            next: (value) => {
                const modalRef = this.matDialog.open(ProjectDialogNewOrEditComponent, {
                    data: value.body,
                    panelClass: 'mat-dialog-lg',
                });

                modalRef.afterClosed().subscribe();
            },
        });
    }

    openProjectDialogDetail(projectModel?: ProjectModel) {
        this.projectService.getProjectById(projectModel.id).subscribe({
            next: (value) => {
                const modalRef = this.matDialog.open(ProjectDialogDetailComponent, {
                    data: value.body,
                    panelClass: 'mat-dialog-md',
                });

                modalRef.afterClosed().subscribe({
                    next: (edit) => {
                        if (edit) this.openProjectDialogCreateOrEdit(projectModel);
                    },
                });
            },
        });
    }

    openReportDeleteConfirmationDialog(projectModel?: ProjectModel) {
        const modalRef = this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: 'Confirm Delete ',
                message: `Changing RIO project from active to inactive will delete existing submission and report summary (if exist) for current year month. Do you want to proceed ?`,
                buttonPositive: 'Yes',
                buttonPositiveColor: 'warn',
                buttonNegative: 'No',
            },
            panelClass: 'mat-dialog-sm',
            id: 'delete-report',
        });

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) {
                    this.projectService.update(projectModel).subscribe({
                        next: (value) => {
                            this.isSaving = false;
                            this.projectDeactiveLoading();
                            this.dialogRef.close('ok');
                            this.openProjectDialogDetail(value.body);
                        },
                        error: (error) => {
                            this.isSaving = false;
                            this.projectDeactiveLoading();
                            this.generalService.snackbarMessage(error.message, 'Close');
                        },
                    });
                }
            },
        });
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

    compareString(object1: string, object2: string) {
        return object1 === object2;
    }

    onChangeProjectType(){
        if(this.projectModel.type !== this.projectFormGroup.get('type').value){
            this.projectFormGroup.get('linkedProject').setValue(null);
        }else {
            this.projectFormGroup.get('linkedProject').setValue(this.projectModel.linkedProject);
        }

        this.currentProjectType = this.projectFormGroup.get('type').value;
    }

    onToppingRemovedFormTypeConfigurations(topping: string) {
        const toppings = this.projectFormGroup.get('formTypeConfigurations').value as string[];
        this.removeFirst(toppings, topping);
        this.projectFormGroup.get('formTypeConfigurations').setValue(toppings); // To trigger change detection
    }

    private removeFirst<T>(array: T[], toRemove: T): void {
        const index = array.indexOf(toRemove);
        if (index !== -1) {
            array.splice(index, 1);
        }
    }
}
