import { AuthenticateService } from './../auth/authenticate.service';
import { UserModel } from './../model/user.model';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ACCOUNT_COMPONENT } from '../app.constant';
import { ApplicationUserModel } from '../model/application-user.model';
import { AuthenticateRequestModel } from '../model/authenticate-request.model';

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    private apiUser: string = ACCOUNT_COMPONENT + '/admin/user';

    constructor(private httpClient: HttpClient, private authService: AuthenticateService) {}

    requestEmail(applicationUserModel: ApplicationUserModel): Observable<HttpResponse<ApplicationUserModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('email', applicationUserModel.email);
        return this.httpClient.get<ApplicationUserModel>(this.apiUser + '/reset-password', {
            observe: 'response',
            params: httpParams,
        });
    }

    updateResetPasswordEmail(params: any): Observable<HttpResponse<ApplicationUserModel>> {
        return this.httpClient.put<ApplicationUserModel>(this.apiUser + '/reset-password', params, {
            observe: 'response',
        });
    }

    getAllUsers(pageNumber: number, pageSize: number, sortOrder: string, criteria): Observable<any> {
        let httpParams = new HttpParams();
        if (pageNumber) {
            httpParams = httpParams.set('page', `${pageNumber}`);
        }
        if (pageSize) {
            httpParams = httpParams.set('size', `${pageSize}`);
        }
        if (sortOrder) {
            httpParams = httpParams.set('sort', sortOrder);
        }

        if (criteria) {
            if (criteria.fullName) {
                httpParams = httpParams.set('fullName', criteria.fullName);
            }
        }
        httpParams = httpParams.set('currentInstitutionId', this.authService.getCurInsId());
        return this.httpClient.get(`${this.apiUser}/pagination/all`, {
            observe: 'response',
            params: httpParams,
        });
    }

    createUser(model: UserModel): Observable<HttpResponse<UserModel>> {
        let httpParams = new HttpParams();

        httpParams = httpParams.set('currentInstitutionId', this.authService.getCurInsId());
        return this.httpClient.post<UserModel>(this.apiUser, model, {
            observe: 'response',
            params: httpParams,
        });
    }

    updateUser(model: UserModel): Observable<HttpResponse<UserModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('currentInstitutionId', this.authService.getCurInsId());
        return this.httpClient.put<UserModel>(this.apiUser, model, {
            observe: 'response',
            params: httpParams,
        });
    }

    getCurrentUserInfo(): Observable<HttpResponse<AuthenticateRequestModel>> {
        return this.httpClient.get<AuthenticateRequestModel>(this.apiUser, {
            observe: 'response',
        });
    }

    getUserById(id: string): Observable<HttpResponse<UserModel>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('currentInstitutionId', this.authService.getCurInsId());
        return this.httpClient.get<UserModel>(this.apiUser+ '/' + id, {
            observe: 'response',
            params: httpParams,
        });
    }
    
    resetPassword(
        applicationUserModel: ApplicationUserModel
    ): Observable<HttpResponse<ApplicationUserModel>>{
        return this.httpClient.put<ApplicationUserModel>(
            this.apiUser + '/change-password',
            applicationUserModel,
            {
                observe: 'response',
            }
        );
    }
}
