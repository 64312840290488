export const TOOLTIPS = {
    phoneContent:
        'Phone numbers must include correct country code.This field only accepts numerical input; “0-9”.This field accepts up to 20 characters input. E.g: +6737654321',

    emailContent: 'This field accepts up to 100 charactes input. E.g: nbt123@inchcape.com.bn',

    icContent:
        "This field only accepts alphanumerical with special character input; 'A-Z', '0-9', '-' .This field accepts up to 20 characters input",

    attContent:
        "The total attachment size is 30 MB per submission and the acceptable file formats are '.jpeg', '.png', '.pdf', '.mp3' and '.mp4",

    customerAttContent:
        "Max total attachment is 4MB per submission and only acceptable file format is '.jpeg', '.png' and '.jpg'",
};
