import { Component, AfterViewInit, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { Subject } from 'rxjs';
import { FilterDates } from 'src/app/model/filter-dates.model';
import { ModuleRecord } from 'src/app/model/moduleRecord.model';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
    selector: 'module-time-spent-chart',
    templateUrl: './module-time-spent-chart.component.html',
})
export class ModuleTimeSpentChartComponent implements OnInit, AfterViewInit {
    @Input() public moduleTimeFilterDates: Subject<FilterDates>;

    moduleTimeSpentData = [];

    DAILY = 'DAILY';
    WEEKLY = 'WEEKLY';
    MONTHLY = 'MONTHLY';
    moduleTimeSpentRoot: am5.Root;
    moduleTimeSpentSeries: am5xy.LineSeries;
    moduleTimeSpentCursor: am5xy.XYCursor;
    moduleTimeSpentChart: am5xy.XYChart;
    moduleTimeSpentLegend: am5.Legend;

    yAxis: am5xy.ValueAxis<am5xy.AxisRendererY>;
    xAxis: am5xy.CategoryAxis<am5xy.AxisRendererX>;

    constructor(private dashboardService: DashboardService, private datePipe: DatePipe) {}

    ngOnInit(): void {
        this.moduleTimeFilterDates.subscribe((moduleTimeFilterDates) => {
            this.requestModuleTimeSpentData(moduleTimeFilterDates);
        });
    }

    requestModuleTimeSpentData(moduleTimeFilterDates: FilterDates) {
        this.dashboardService
            .getModuleTimeSpent(
                moduleTimeFilterDates.dateType,
                this.datePipe.transform(moduleTimeFilterDates.dateFrom, 'yyyy-MM-dd', '+800'),
                this.datePipe.transform(moduleTimeFilterDates.dateTo, 'yyyy-MM-dd', '+800'),
                this.datePipe.transform(moduleTimeFilterDates.yearMonth, 'yyyy-MM', '+800')
            )
            .subscribe({
                next: (value) => {
                    this.moduleTimeSpentData = this.processModuleTimeSpentResponse(
                        value.body,
                        moduleTimeFilterDates.dateType
                    );
                    this.moduleTimeSpentChart.dispose();
                    this.initChart();
                    this.xAxis.data.setAll(this.moduleTimeSpentData);
                    this.createSeries('Banks', 'banksCount');
                    this.createSeries('Loans', 'loansCount');
                    this.createSeries('Properties', 'propertiesCount');
                    this.createSeries('Vehicles', 'vehiclesCount');
                    this.createSeries('Utilities', 'utilitiesCount');
                    this.createSeries('Insurances', 'insuranceCount');
                    this.createSeries('Credit Cards', 'creditCardCount');
                    this.moduleTimeSpentLegend.data.setAll(this.moduleTimeSpentChart.series.values);
                },
            });
    }

    ngAfterViewInit() {
        this.moduleTimeSpentRoot = am5.Root.new('chartModuleTimeSpent');
        this.initChart();
    }

    initChart() {
      this.moduleTimeSpentRoot.setThemes([am5themes_Animated.new(this.moduleTimeSpentRoot)]);

      this.moduleTimeSpentChart = this.moduleTimeSpentRoot.container.children.push(
          am5xy.XYChart.new(this.moduleTimeSpentRoot, {
              panX: false,
              panY: false,
              wheelX: 'panX',
              wheelY: 'zoomX',
              pinchZoomX: true,
              layout: this.moduleTimeSpentRoot.horizontalLayout,
          })
      );

      // Create Y-axis
      this.xAxis = this.moduleTimeSpentChart.xAxes.push(
          am5xy.CategoryAxis.new(this.moduleTimeSpentRoot, {
              maxDeviation: 0.3,
              categoryField: 'category',
              renderer: am5xy.AxisRendererX.new(this.moduleTimeSpentRoot, {
                  minGridDistance: 50,
                  strokeOpacity: 0.2,
              }),
          })
      );

      this.xAxis.get('renderer').labels.template.setAll({
          textAlign: 'center',
      });

      this.yAxis = this.moduleTimeSpentChart.yAxes.push(
          am5xy.ValueAxis.new(this.moduleTimeSpentRoot, {
              min: 0,
              maxDeviation: 0,
              renderer: am5xy.AxisRendererY.new(this.moduleTimeSpentRoot, { inside: false }),
              numberFormat: '#.#  hour',
          })
      );

      // Add cursor
      this.moduleTimeSpentChart.set(
          'cursor',
          am5xy.XYCursor.new(this.moduleTimeSpentRoot, {
              behavior: 'zoomXY',
              xAxis: this.xAxis,
          })
      );

      this.xAxis.set(
          'tooltip',
          am5.Tooltip.new(this.moduleTimeSpentRoot, {
              themeTags: ['axis'],
          })
      );

      this.yAxis.set(
          'tooltip',
          am5.Tooltip.new(this.moduleTimeSpentRoot, {
              themeTags: ['axis'],
          })
      );

      this.moduleTimeSpentLegend = this.moduleTimeSpentChart.children.push(
          am5.Legend.new(this.moduleTimeSpentRoot, {
              centerY: am5.p50,
              y: am5.p50,
              centerX: am5.p0,
              x: am5.percent(90),
              layout: this.moduleTimeSpentRoot.verticalLayout,
              verticalScrollbar: am5.Scrollbar.new(this.moduleTimeSpentRoot, {
                  orientation: 'vertical',
              }),
          })
      );
      this.xAxis.data.setAll(this.moduleTimeSpentData);
      this.moduleTimeSpentLegend.data.setAll(this.moduleTimeSpentChart.series.values);
    }

    createSeries(name, field) {
        this.moduleTimeSpentSeries = this.moduleTimeSpentChart.series.push(
            am5xy.LineSeries.new(this.moduleTimeSpentRoot, {
                name: name,
                minBulletDistance: 10,
                xAxis: this.xAxis,
                yAxis: this.yAxis,
                valueYField: field,
                categoryXField: 'category',
                tooltip: am5.Tooltip.new(this.moduleTimeSpentRoot, {
                    pointerOrientation: 'horizontal',
                    labelText: '{name} Total Hour: {valueY}',
                }),
            })
        );
        this.moduleTimeSpentSeries
            .get('tooltip')
            .label.set('text', '[bold]{name}[/]\n{valueX.formatDate()}: {valueY}(s)');
        this.moduleTimeSpentSeries.data.setAll(this.moduleTimeSpentData);
    }

    private processModuleTimeSpentResponse(data: any, dataType: string): ModuleRecord[] {
        let moduleTimeSpentRecordList: any[] = [];
        if (dataType === this.DAILY) {
            data.forEach((element) => {
                const date = element.dateFrom.day + '\n' + element.dateFrom.formatted;
                let dataRecord: any = {
                    category: date,
                    banksCount: Math.round((element.banksCount/ 3600) * 100) / 100 ,
                    loansCount: Math.round((element.loansCount/ 3600) * 100) / 100 ,
                    propertiesCount: Math.round((element.propertiesCount/ 3600) * 100) / 100 ,
                    vehiclesCount: Math.round((element.vehiclesCount/ 3600) * 100) / 100 ,
                    utilitiesCount: Math.round((element.utilitiesCount/ 3600) * 100) / 100 ,
                    insuranceCount: Math.round((element.insuranceCount/ 3600) * 100) / 100 ,
                    creditCardCount: Math.round((element.creditCardCount/ 3600) * 100) / 100 ,
                };
                moduleTimeSpentRecordList.push(dataRecord);
            });
            return moduleTimeSpentRecordList;
        } else if (dataType === this.WEEKLY) {
            data.forEach((element) => {
                const week = element.dateFrom.formatted + '-\n' + element.dateTo.formatted;
                let dataRecord: any = {
                    category: week,
                    banksCount: Math.round((element.banksCount/ 3600) * 100) / 100 ,
                    loansCount: Math.round((element.loansCount/ 3600) * 100) / 100 ,
                    propertiesCount: Math.round((element.propertiesCount/ 3600) * 100) / 100 ,
                    vehiclesCount: Math.round((element.vehiclesCount/ 3600) * 100) / 100 ,
                    utilitiesCount: Math.round((element.utilitiesCount/ 3600) * 100) / 100 ,
                    insuranceCount: Math.round((element.insuranceCount/ 3600) * 100) / 100 ,
                    creditCardCount: Math.round((element.creditCardCount/ 3600) * 100) / 100 ,
                };
                moduleTimeSpentRecordList.push(dataRecord);
            });
            return moduleTimeSpentRecordList;
        } else if (dataType === this.MONTHLY) {
            data.forEach((element) => {
                let dataRecord: any = {
                    banksCount: Math.round((element.banksCount/ 3600) * 100) / 100 ,
                    loansCount: Math.round((element.loansCount/ 3600) * 100) / 100 ,
                    propertiesCount: Math.round((element.propertiesCount/ 3600) * 100) / 100 ,
                    vehiclesCount: Math.round((element.vehiclesCount/ 3600) * 100) / 100 ,
                    utilitiesCount: Math.round((element.utilitiesCount/ 3600) * 100) / 100 ,
                    insuranceCount: Math.round((element.insuranceCount/ 3600) * 100) / 100 ,
                    creditCardCount: Math.round((element.creditCardCount/ 3600) * 100) / 100 ,
                };
                moduleTimeSpentRecordList.push(dataRecord);
            });
            return moduleTimeSpentRecordList;
        }
    }
}
