import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'mx-select',
    templateUrl: './mx-select.component.html',
})
export class MxSelectComponent {
    @Input() public formGroupChild: FormGroup;
    @Input() public formControlNameChild: string;
    @Input() public label: String;
    @Input() public placeholder: string = '';

    @Input() public errorMessage: String;
    @Input() public required: boolean = false;

    @Input() public compareWithChild: (o1: any, o2: any) => boolean;
    @Input() public disabledChild: boolean;
    @Input() public list: any[];
    @Output() public onSelectionChange = new EventEmitter<any>();

    selectionChange($event) {
        this.onSelectionChange.emit($event);
    }

    selectionChangeReturnCode($event) {
        this.onSelectionChange.emit($event.value.code);
    }
}
