import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmploymentTypeModel } from 'src/app/model/employment.model';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';

@Component({
    selector: 'app-employment-dialog-detail',
    templateUrl: './employment-dialog-detail.component.html',
})
export class EmploymentDialogDetailComponent {
    readonly EMPLOYMENT_TYPE_UPDATE = ApplicationPermissionService.EMPLOYMENT_TYPE_UPDATE;
    
    constructor(
        @Inject(MAT_DIALOG_DATA) public employmentTypeModel: EmploymentTypeModel,
        public dialogRef: MatDialogRef<EmploymentDialogDetailComponent>
    ) {}
}
