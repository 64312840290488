<div class="mx-10 my-6" *appScreenPermission="[PROJECT_READ]">
    <mx-title-bar title="Project" buttonIcon="add" buttonLabel="Add Project"
        (onButtonClick)="openProjectDialogCreateOrEdit()" [buttonPermission]="[PROJECT_CREATE]"></mx-title-bar>

    <div class="flex-container">
        <form [formGroup]="projectFormGroup">
            <div class="px-3 py-2 border rounded-md bg-white grid grid-cols-2 gap-2">
                <mx-input-field
                        class="col-span-2 lg:col-span-1"
                        [inputType]="text"
                        [formGroupChild]="projectFormGroup"
                        formControlNameChild="name"
                        label="Name"
                        placeholder="Enter name"
                ></mx-input-field>

                <mx-input-field
                        class="col-span-2 lg:col-span-1"
                        [inputType]="select"
                        [formGroupChild]="projectFormGroup"
                        formControlNameChild="type"
                        label="Type"
                        placeholder="Select type"
                        [compareWithChild]="compareString"
                        [list]="projectTypes"
                ></mx-input-field>

                <div class="col-span-2 flex justify-end">
                    <button
                            mat-raised-button
                            color="primary"
                            class="px-8 py-3 font-semibold rounded"
                            (click)="onSubmitSearch()"
                    >
                        Search
                    </button>
                </div>
            </div>
        </form>
    </div>

    <div class="my-2">
        <strong>{{ projectNumberOfRecords }}</strong> result<span *ngIf="projectNumberOfRecords > 1">s</span>
    </div>
    <mat-card-content class="mx-content-table">
        <div *ngIf="loading">
            <mx-data-loading></mx-data-loading>
        </div>
        <table mat-table matSort (matSortChange)="sortPage($event)" [dataSource]="projectDataSource" *ngIf="projectDataSource.data"
            class="w-full" aria-label="table-expense-type">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.name || "-" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.type || "-" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.status === 'ACTIVE'"
                        class="w-fit border-0 text-center rounded-xl px-2 py-0.5 bg-green-light text-green">
                        Active
                    </div>
                    <div *ngIf="element.status === 'INACTIVE'"
                        class="w-fit border-0 text-center rounded-xl px-2 py-0.5 bg-gray text-white">
                        Inactive
                    </div>
                    <div *ngIf="element.status === 'PROCESSING_INACTIVE'"
                         class="w-fit border-0 text-center rounded-xl px-2 py-0.5 text-black"
                         style="background-color: #FFA500;">
                        Processing Inactive
                    </div>
                    <div *ngIf="element.status === 'PROCESSING_DELETE'"
                         class="w-fit border-0 text-center rounded-xl px-2 py-0.5 text-black"
                         style="background-color: #FFA500;">
                        Processing Delete
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="text-white">Actions</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.status !== 'PROCESSING_INACTIVE' && element.status !== 'PROCESSING_DELETE'">
                        <mat-icon class="mr-4 cursor-pointer" (click)="openProjectDialogCreateOrEdit(element)"
                                  matTooltip="Edit" *appScreenPermission="[PROJECT_UPDATE]">edit</mat-icon>
                        <mat-icon class="mr-4 cursor-pointer" (click)="openProjectDialogDelete(element)"
                                  matTooltip="Delete" *appScreenPermission="[PROJECT_DELETE]">delete</mat-icon>
                    </ng-container>
                    <mat-icon class="mr-4 cursor-pointer" (click)="openProjectDialogDetail(element)" matTooltip="View">remove_red_eye</mat-icon>
                    <ng-container *ngIf="element.status !== 'PROCESSING_INACTIVE' && element.status !== 'PROCESSING_DELETE' && element.type === 'RIO' && element.active">
                        <mat-icon class="mr-4 cursor-pointer" (click)="openCreateRioReportSummaryDialog(element)" matTooltip="Generate initial report summary">summarize</mat-icon>
                    </ng-container>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell text-center" colspan="6">No data match your search "{{ projectSearchValue }}"</td>
            </tr>
        </table>
    </mat-card-content>
    <mat-paginator (page)="navigateToPage($event)" [length]="projectNumberOfRecords" [pageSize]="projectPageSize"
        [pageSizeOptions]="[5, 10, 20, 50]" [showFirstLastButtons]="true" class="mx-paginator">
    </mat-paginator>
</div>
