import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute} from '@angular/router';
import {InstitutionModel} from 'src/app/model/institution.model';
import {ApplicationPermissionService} from 'src/app/services/application-permission.service';
import {
    InstitutionDialogNewOrEditComponent
} from '../institution-dialog-new-or-edit/institution-dialog-new-or-edit.component';
import {BehaviorSubject} from 'rxjs';
import {InstitutionService} from 'src/app/services/institution.service';
import {GeneralService} from 'src/app/services/general.service';
import {InstitutionDialogDetailComponent} from '../institution-dialog-detail/institution-dialog-detail.component';
import {AuthenticateService} from 'src/app/auth/authenticate.service';
import {FORM_TEMPLATE_FIELD_INPUT_TYPE} from 'src/app/model/form-template-field.model';
import {SharedMethod} from 'src/app/components/shared/shared.component';

@Component({
    selector: 'app-institution-listing',
    templateUrl: './institution-listing.component.html',
})
export class InstitutionListingComponent implements OnInit {
    readonly ENTITY_CREATE = ApplicationPermissionService.ENTITY_CREATE;
    readonly ENTITY_UPDATE = ApplicationPermissionService.ENTITY_UPDATE;
    readonly ENTITY_READ = ApplicationPermissionService.ENTITY_READ;

    institutionFormGroup: FormGroup;
    institutionDataSource = new MatTableDataSource<any>();
    obs: BehaviorSubject<any>;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayColumns = ['name', 'shortName', 'tier', 'active', 'actions'];
    displayColumnsWithParentInstitution = ['name', 'shortName', 'tier', 'parentInstitution', 'active', 'actions'];
    currentSearchName: string = ''

    tiers: { id: string; name: string; value: number | null }[] = [
        {
            id: '0',
            name: 'All',
            value: null,
        },
        {
            id: '1',
            name: 'Function',
            value: 1,
        },
        {
            id: '2',
            name: 'Unit',
            value: 2,
        },
        {
            id: '3',
            name: 'Team',
            value: 3,
        },
    ];

    institutionSearchValue: String = null;
    institutionPageNumbers: number = 0;
    institutionNumberOfRecords: number = 0;
    institutionCurrentPage: number = 0;
    institutionPageSize: number = 10;
    institutionSortOrder = 'name,asc';
    institutionCountLoad = 0;
    institutions: InstitutionModel[];
    InstitutionFormGroup: FormGroup;
    loading: boolean = false;
    selectedTier: number = null;

    select = FORM_TEMPLATE_FIELD_INPUT_TYPE.SELECT;
    text = FORM_TEMPLATE_FIELD_INPUT_TYPE.TEXT;
    isLoading = FORM_TEMPLATE_FIELD_INPUT_TYPE.LOADING;

    constructor(
        public activatedRoute: ActivatedRoute,
        private matDialog: MatDialog,
        private institutionsService: InstitutionService,
        private changeDetectorRef: ChangeDetectorRef,
        public generalService: GeneralService,
        private authService: AuthenticateService,
        public sharedMethod: SharedMethod,
        private formBuilder: FormBuilder,
    ) {
        this.institutionDataSource.paginator = this.paginator;
        this.institutionFormGroup = this.formBuilder.group({
            name: this.formBuilder.control(null),
            tier: this.formBuilder.control({value: null}),
        });
    }

    get searchName() {
        return this.institutionFormGroup.get('name')
    }

    get searchTier() {
        return this.institutionFormGroup.get('tier')
    }

    ngOnInit(): void {
        this.institutionActiveLoading();
        if (this.activatedRoute.snapshot.queryParams['page']) {
            this.institutionSortOrder = this.activatedRoute.snapshot.queryParams['sort']
                ? this.activatedRoute.snapshot.queryParams['sort']
                : undefined;
            this.institutionCurrentPage = this.activatedRoute.snapshot.queryParams['page']
                ? this.activatedRoute.snapshot.queryParams['page']
                : undefined;
            this.institutionPageSize = this.activatedRoute.snapshot.queryParams['pageSize']
                ? this.activatedRoute.snapshot.queryParams['pageSize']
                : undefined;
        }
        this.refreshForm();
    }

    search(institutionCurrentPage: number, institutionSearchValue: String, tier: number | null) {
        this.institutionSearchValue = institutionSearchValue;
        this.selectedTier = tier;
        this.institutionsService
            .getAllInstitutions(this.institutionCurrentPage, this.institutionPageSize, this.institutionSortOrder, {
                name: institutionSearchValue,
                id: this.authService.getCurInsId(),
                tier: tier,
            })
            .subscribe({
                next: ({body}) => {
                    this.institutionDataSource.data = body.content;
                    this.institutionNumberOfRecords = body.totalElements;
                    this.changeDetectorRef.detectChanges();
                    this.obs = this.institutionDataSource.connect();
                    this.currentSearchName = (this.searchName.value ?? '') + (this.searchTier.value?.value ?? '')
                    this.institutionDeactiveLoading();

                    if (institutionCurrentPage === 0) {
                        this.paginator.firstPage();
                    } else {
                        this.paginator.pageIndex = institutionCurrentPage;
                    }
                },
                error: (error) => {
                    this.generalService.snackbarMessage(error.debugMessage, 'Close');
                    this.currentSearchName = this.searchName.value
                    this.institutionDeactiveLoading();
                },
            });
    }

    refreshForm() {
        this.search(0, null, null);
    }

    getTimeString(dateToChange: Date): string {
        let newDate: Date = new Date(dateToChange.getTime() + 8 * 60 * 60 * 1000);
        return newDate.toISOString();
    }

    ngOnDestroy(): void {
        if (this.institutionDataSource) {
            this.institutionDataSource.disconnect();
        }
    }

    openInstitutionDialogCreateOrEdit(institutionModel?: InstitutionModel) {
        if (institutionModel === undefined) {
            this.passDataToDialogCreateOrEdit(institutionModel);
        } else {
            this.institutionsService.getInstitutionById(institutionModel.id).subscribe({
                next: (value) => {
                    this.passDataToDialogCreateOrEdit(value.body);
                }
            });
        }
    }

    passDataToDialogCreateOrEdit(event: any) {
        const modalRef = this.matDialog.open(InstitutionDialogNewOrEditComponent, {
            data: event,
            panelClass: 'mat-dialog-lg'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) this.search(this.institutionCurrentPage, this.institutionSearchValue, null);
            }
        })
    }

    openInstitutionDialogDetail(institutionModel?: InstitutionModel) {
        this.institutionsService.getInstitutionById(institutionModel.id).subscribe({
            next: (value) => {
                const modalRef = this.matDialog.open(InstitutionDialogDetailComponent, {
                    data: value.body,
                    panelClass: 'mat-dialog-md',
                });

                modalRef.afterClosed().subscribe({
                    next: (edit) => {
                        if (edit) this.openInstitutionDialogCreateOrEdit(institutionModel);
                    },
                });
            }
        });
    }

    sortPage(event): void {
        this.institutionSortOrder = `${event.active},${event.direction}`;
        this.search(this.institutionCurrentPage, this.institutionSearchValue, this.selectedTier);
    }

    navigateToPage(event): void {
        this.institutionPageSize = event.pageSize;
        this.institutionCurrentPage = event.pageIndex;
        this.search(this.institutionCurrentPage, this.institutionSearchValue, this.selectedTier);
    }

    institutionActiveLoading() {
        this.loading = true;
    }

    institutionDeactiveLoading() {
        this.loading = false;
    }

    tierName(tier: number): string {
        if (tier === 1) return 'Function'
        else if (tier === 2) return 'Unit'
        else if (tier === 3) return 'Team'
        else return 'Group'
    }

    onSubmitSearch() {
        const {value} = this.institutionFormGroup;
        this.search(0, value.name, value.tier.value);
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.value === object2.value;
    }
}
