import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { ResetPasswordApplicationUserComponent } from './components/login/reset-password-email/reset-password-email.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./components/main/main.module').then((m) => m.MainModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'login',
        loadChildren: () => import('./components/login/login.module').then((m) => m.LoginModule),
    },
    {
        path: 'reset-password',
        component: ResetPasswordApplicationUserComponent,
    },
    {
        path: 'error',
        loadChildren: () => import('./components/error/error.module').then((m) => m.ErrorModule),
    },
    { path: '**', redirectTo: '/error/404', pathMatch: 'full' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
        }),
    ],

    exports: [RouterModule],
})
export class AppRoutingModule {}
