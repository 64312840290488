<section class="flex items-center h-screen p-16">
    <div class="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div class="max-w-xl text-center">
            <img src="../../../../assets/programming_illustration.svg" class="h-2/3 w-2/3 inline" alt="comming soon"/>
            <div class="mt-4 mb-4 md:mb-8 font-extrabold text-3xl md:text-7xl">
                <span class="sr-only">In Progress</span>Coming Soon
            </div>
            <div class="text-lg md:text-3xl font-semibold">We are coding.</div>
            <div class="mt-4 mb-8">We will notify you once this feature is ready.</div>
            <button class="px-8 py-3 font-semibold rounded bg-accent hover:z-10 hover:shadow-md" (click)="goBack()">
                Back to Previous Page
            </button>
        </div>
    </div>
</section>
