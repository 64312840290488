<div class="flex justify-between items-center px-6 py-4 border-b border-b-gray-light">
    <div class="text-lg font-bold">
        {{ data.title }}
    </div>
    <button type="button" data-dismiss="modal" class="text-2xl" (click)="dialogRef.close()">&times;</button>
</div>
<div class="max-h-[70vh] overflow-auto p-6 relative whitespace-pre-line" [innerHtml]="data.message"></div>
<div class="flex justify-end p-6 border-t border-gray-light gap-2">
    <button type="button" mat-raised-button data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">clear</mat-icon>
        <span class="text-sm font-bold">{{ data.buttonNegative }}</span>
    </button>
    <button type="button" mat-raised-button [color]="data.buttonPositiveColor ?? 'primary'" (click)="closeButtonPositive()">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">check</mat-icon>
        <span class="text-sm font-bold">{{ data.buttonPositive }}</span>
    </button>
</div>
