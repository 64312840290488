import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticateService } from '../auth/authenticate.service';
import { SystemConfigModel } from '../model/system-config.model';
import { SERVER_API } from '../app.constant';

@Injectable({
    providedIn: 'root',
})
export class SystemConfigurationService {

    private apiSystemConfig: string = SERVER_API + 'api/common-component/admin/system-configurations';
    private apiSystemConfigRioSubmission: string = SERVER_API + 'api/common-component/system-config/rio-submission-group';
    private apiRioYearMonth: string = SERVER_API + 'api/common-component/system-config/rio-reporting-year-month';


    constructor(private httpClient: HttpClient, private authService: AuthenticateService) {}

    save(systemConfigModel: SystemConfigModel): Observable<HttpResponse<SystemConfigModel>> {
        return this.httpClient.post<SystemConfigModel>(this.apiSystemConfig, systemConfigModel, {
            observe: 'response',
        });
    }

    getAllSystemConfig(
        sysConfigpageNumber: number,
        sysConfigpageSize: number,
        sysConfigsortOrder: string,
        criteria
    ): Observable<any> {
        let sysConfigHttpParams = new HttpParams();
        if (sysConfigpageNumber !== undefined || sysConfigpageNumber !== null) {
            sysConfigHttpParams = sysConfigHttpParams.set('page', `${sysConfigpageNumber}`);
        }
        if (sysConfigpageSize) {
            sysConfigHttpParams = sysConfigHttpParams.set('size', `${sysConfigpageSize}`);
        }
        if (sysConfigsortOrder !== undefined || sysConfigsortOrder !== null) {
            sysConfigHttpParams = sysConfigHttpParams.set('sort', sysConfigsortOrder);
        }

        if (criteria) {
            if (criteria.code) {
                sysConfigHttpParams = sysConfigHttpParams.set('code', criteria.code);
            }
            if (criteria.institutionId ) {
                sysConfigHttpParams = sysConfigHttpParams.set('institutionId', criteria.institutionId );
            }
        }
        return this.httpClient.get(`${this.apiSystemConfig}`, {
            observe: 'response',
            params: sysConfigHttpParams,
        });
    }

    update(systemConfigModel: SystemConfigModel): Observable<HttpResponse<SystemConfigModel>> {
        const sysConfigId = systemConfigModel.id;
        return this.httpClient.put<SystemConfigModel>(this.apiSystemConfig + '/' + sysConfigId, systemConfigModel, {
            observe: 'response',
        });
    }

    delete(sysConfigId: string): Observable<HttpResponse<SystemConfigModel>> {
        let sysConfigHttpParams = new HttpParams();
        sysConfigHttpParams = sysConfigHttpParams.set('currentInstitutionId', this.authService.getCurInsId());
        return this.httpClient.delete<SystemConfigModel>(this.apiSystemConfig + '/' + sysConfigId, {
            observe: 'response',
            params: sysConfigHttpParams,
        });
    }

    getSystemConfigById(sysConfigId: string): Observable<HttpResponse<SystemConfigModel[]>> {
        return this.httpClient.get<SystemConfigModel[]>(this.apiSystemConfig + '/' + sysConfigId, {
            observe: 'response',
        });
    }

    getAllSystemConfigRioSubmission(): Observable<HttpResponse<SystemConfigModel[]>> {
        return this.httpClient.get<SystemConfigModel[]>(this.apiSystemConfigRioSubmission, {
            observe: 'response'
        })
    }

    getCurrentRioFinancialYearMonth(): Observable<HttpResponse<string>> {
        return this.httpClient.get(this.apiRioYearMonth, {
            observe: 'response',
            responseType: 'text' // Ensure response type is set to 'text' to receive string data
        });
    }
}
