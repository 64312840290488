import { Component, OnDestroy } from '@angular/core';
import { ApplicationUserModel } from 'src/app/model/application-user.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { passwordValidator } from '../../shared/validator/password.validator';
import { GeneralService } from 'src/app/services/general.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FORM_TEMPLATE_FIELD_INPUT_TYPE } from 'src/app/model/form-template-field.model';

@Component({
    selector: 'app-reset-password-email',
    templateUrl: './reset-password-email.component.html',
})
export class ResetPasswordApplicationUserComponent implements OnDestroy {
    applicationUserFormGroup: FormGroup;
    applicationUserModel: ApplicationUserModel;
    isSaving: boolean = false;
    resetPasswordKey: string = '';
    resetPasswordEmail: string = '';
    updateSubscription: Subscription;
    userMessage = 'Email with ';
    userMessage2 = ' is not found. Please try a valid message.';

    text = FORM_TEMPLATE_FIELD_INPUT_TYPE.TEXT;
    password = FORM_TEMPLATE_FIELD_INPUT_TYPE.PASSWORD;
    confirmPassword = FORM_TEMPLATE_FIELD_INPUT_TYPE.CONFIRMPASSWORD;

    username: string;

    constructor(
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private usersService: UsersService,
        private router: Router,
        private generalService: GeneralService,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe({
            next: (queryParam) => {
                if (queryParam.key) {
                    this.username = queryParam.username;
                    this.resetPasswordKey = queryParam.key;
                    this.resetPasswordEmail = queryParam.username;
                    this.applicationUserFormGroup = this.formBuilder.group(
                        {
                            oldPassword: this.formBuilder.control(null),
                            password: this.formBuilder.control(null),
                            newPassword: this.formBuilder.control(null, [Validators.required, Validators.minLength(8)]),
                            repeatPassword: this.formBuilder.control(null, Validators.required),
                            email: this.formBuilder.control(null),
                            key: this.formBuilder.control({
                                value: this.resetPasswordKey,
                                disabled: true,
                            }),
                        },
                        {
                            validators: [passwordValidator()],
                        }
                    );
                } else {
                    this.username = queryParam.username;
                    this.applicationUserFormGroup = this.formBuilder.group(
                        {
                            oldPassword: this.formBuilder.control(null),
                            password: this.formBuilder.control(null),
                            newPassword: this.formBuilder.control(null),
                            repeatPassword: this.formBuilder.control(null),
                            email: this.formBuilder.control(null, [Validators.required, Validators.email]),
                            key: this.formBuilder.control({
                                value: null,
                                disabled: true,
                            }),
                        },
                        {
                            validators: [passwordValidator()],
                        }
                    );
                }
            },
        });
    }

    ngOnDestroy(): void {
        if (this.updateSubscription) {
            this.updateSubscription.unsubscribe();
        }
    }

    save() {
        let userInfo = this.applicationUserFormGroup.getRawValue();
        const params = {
            email: this.username,
            key: userInfo.key,
            password: userInfo.newPassword,
        };
        this.isSaving = true;
        if (!this.resetPasswordKey) {
            this.updateSubscription = this.usersService
                .requestEmail(this.applicationUserFormGroup.getRawValue())
                .subscribe({
                    next: () => {
                        this.generalService.snackbarMessage(
                            'Email Sent',
                            'Close'
                        );
                        this.isSaving = false;
                        this.router.navigate(['/reset-password']);
                    },
                    error: () => {
                        this.isSaving = false;
                        this.generalService.snackbarMessage(
                            this.userMessage + userInfo.email + this.userMessage2,
                            'Close'
                        );
                    },
                });
        } else {
            this.updateSubscription = this.usersService.updateResetPasswordEmail(params).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.router.navigate(['/login']);
                },
                error: (value) => {
                    this.isSaving = false;
                    this.generalService.snackbarMessage(value.message, 'Close');
                },
            });
        }
    }

    checkFlag() {
        return !this.resetPasswordKey;
    }

    returnLogin(): void {
        this.router.navigate(['/login']);
    }
}
