import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';
import { BehaviorSubject } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { FORM_TEMPLATE_FIELD_INPUT_TYPE } from 'src/app/model/form-template-field.model';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { GeneralService } from 'src/app/services/general.service';
import { DialogSimpleComponent } from 'src/app/components/shared/ui/dialog-simple/dialog-simple.component';
import { GlCostCodeDialogNewOrEditComponent } from '../gl-cost-code-dialog-new-or-edit/gl-cost-code-dialog-new-or-edit.component';
import { GlCostCodeDialogDetailComponent } from '../gl-cost-code-dialog-detail/gl-cost-code-dialog-detail.component';
import { GlCostCodeModel } from 'src/app/model/gl-cost-code.model';
import { GlCostCodeService } from 'src/app/services/gl-cost-code.service';

@Component({
  selector: 'app-gl-cost-code-listing',
  templateUrl: './gl-cost-code-listing.component.html',
})
export class GlCostCodeListingComponent implements OnInit {

    readonly GL_COST_CODE_CREATE = ApplicationPermissionService.GL_COST_CODE_CREATE;
    readonly GL_COST_CODE_DELETE = ApplicationPermissionService.GL_COST_CODE_DELETE;
    readonly GL_COST_CODE_UPDATE = ApplicationPermissionService.GL_COST_CODE_UPDATE;

    glCodeDataSource = new MatTableDataSource<any>();
    obs: BehaviorSubject<any>;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayColumns = [
        'code',
        'parent',
        'description',
        'actions'
    ];

    glCodeSearchValue: String = null;
    glCodePageNumbers: number = 0;
    glCodeNumberOfRecords: number = 0;
    glCodeCurrentPage:number = 0;
    glCodePageSize:number = 20;
    glCodeSortOrder = 'code,asc';
    loading : boolean = false;
    isLoading = FORM_TEMPLATE_FIELD_INPUT_TYPE.LOADING;

    constructor(
        public glCodeActivatedRoute: ActivatedRoute,
        private matDialog: MatDialog,
        private glCodeService: GlCostCodeService,
        private generalService : GeneralService,
    ) { }

    ngOnInit(): void {
        this.glCodeActiveLoading();
        if (this.glCodeActivatedRoute.snapshot.queryParams['page']) {
            this.glCodeSortOrder = this.glCodeActivatedRoute.snapshot.queryParams['sort'] ? this.glCodeActivatedRoute.snapshot.queryParams['sort']: undefined;
            this.glCodeCurrentPage = this.glCodeActivatedRoute.snapshot.queryParams['page'] ? this.glCodeActivatedRoute.snapshot.queryParams['page']: undefined;
            this.glCodePageSize = this.glCodeActivatedRoute.snapshot.queryParams['pageSize'] ? this.glCodeActivatedRoute.snapshot.queryParams['pageSize']: undefined;
        }
        this.refreshForm();
    }

    openGlCodeDialogCreateOrEdit(glCostCodeModel? : GlCostCodeModel){
        if(glCostCodeModel === undefined){
            this.passDataToDialogCreateOrEdit(glCostCodeModel);
        }else{
            this.glCodeService.getGlCodeById(glCostCodeModel.id).subscribe({
                next: (value) =>{
                    this.passDataToDialogCreateOrEdit(value.body);
                }
            });
        }
    }

    passDataToDialogCreateOrEdit(event: any){
        const modalRef = this.matDialog.open(GlCostCodeDialogNewOrEditComponent, {
            data: event,
            panelClass: 'mat-dialog-lg'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
            if (yes) this.search(this.glCodeCurrentPage, this.glCodeSearchValue);
        }})
    }
  
    openGlCodeDialogDetail(glCostCodeModel? : GlCostCodeModel){
        this.glCodeService.getGlCodeById(glCostCodeModel.id).subscribe({
            next: (value) =>{
                const modalRef = this.matDialog.open(GlCostCodeDialogDetailComponent, {
                    data: value.body,
                    panelClass: 'mat-dialog-md'
                })
          
                modalRef.afterClosed().subscribe({
                    next: (edit) => {
                        if (edit) this.openGlCodeDialogCreateOrEdit(glCostCodeModel);
                    }
                })
            }
        });
    }
  
    search(currentPage: number, glCodeSearchValue: String){
        this.glCodeSearchValue = glCodeSearchValue;
        this.glCodeService
                .getAllGlCodes(this.glCodeCurrentPage, this.glCodePageSize, this.glCodeSortOrder, {
                name: glCodeSearchValue,
            })
            .subscribe({
                next: ({ body }) => {
                    this.glCodeDataSource.data = body.content;
                    this.glCodeNumberOfRecords = body.totalElements;
                    this.glCodeDeactiveLoading();
                },
                error: (error) => {
                    this.generalService.snackbarMessage(error.message, 'Close');
                    this.glCodeDeactiveLoading();
                },
        });
    }
  
    refreshForm(){
        this.search(0, null);
    }
  
    sortPage(event): void {
        this.glCodeSortOrder = `${event.active},${event.direction}`;
        this.search(this.glCodeCurrentPage, this.glCodeSearchValue);
    }
  
    navigateToPage(event): void {
        this.glCodePageSize = event.glCodePageSize;
        this.glCodeCurrentPage = event.pageIndex;
        this.search(this.glCodeCurrentPage, this.glCodeSearchValue);
    }
  
    openGlCodeDialogDelete(glCostCodeModel? : GlCostCodeModel): void {
        const modalRef = this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: 'Confirm Delete Gl Cost Code ?',
                message: `Are you sure want to delete ${glCostCodeModel.code}?`,
                buttonPositive: 'Yes',
                buttonNegative: 'No'
            },
            panelClass: 'mat-dialog-sm'
        })
  
        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) {
                    this.glCodeService.delete(glCostCodeModel.id.toString()).subscribe({
                        next: () => {
                            this.search(this.glCodeCurrentPage, this.glCodeSearchValue);
                        },
                        error: (error) => {
                            this.generalService.snackbarMessage(error.message,'Close');
                        },
                    });
                }
            }
        })
    }
  
    glCodeActiveLoading(){
        this.loading = true;
    }
  
    glCodeDeactiveLoading(){
        this.loading = false;
    }

}
