import { environment } from "src/environments/environment";

export const SERVER_API = environment.API_URL;
export const ACCOUNT_COMPONENT = SERVER_API + 'api/account-component';
export const CATALOGUE_COMPONENT = SERVER_API + 'api/catalog-component';
export const PAYMENT_COMPONENT = SERVER_API + 'api/payment-component';
export const DOCUMENT_COMPONENT = SERVER_API + 'api/document-component';
export const COMMON_COMPONENT = SERVER_API + 'api/common-component';
export const REPORT_COMPONENT = SERVER_API + 'api/report-component';
export const RIO_REPORT_COMPONENT = SERVER_API + 'api/rio-report-component';
