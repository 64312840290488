import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'mx-multi-select',
    templateUrl: './mx-multi-select.component.html',
})
export class MxMultiSelectComponent {
    @Input() public formGroupChild: FormGroup;
    @Input() public formControlNameChild: string;
    @Input() public label: String;
    @Input() public placeholder: string = '';

    @Input() public errorMessage: String;
    @Input() public required: boolean = false;

    @Input() public compareWithChild: (o1: any, o2: any) => boolean;
    @Input() public disabledChild: boolean;
    @Input() public list: any[];
    @Output() public onSelectionChange = new EventEmitter<any>();

    onToppingRemoved(topping: string) {
        const toppings = this.formGroupChild.get(this.formControlNameChild).value as string[];
        this.removeFirst(toppings, topping);
        this.formGroupChild.get(this.formControlNameChild).setValue(toppings); // To trigger change detection
    }

    private removeFirst<T>(array: T[], toRemove: T): void {
        const index = array.indexOf(toRemove);
        if (index !== -1) {
            array.splice(index, 1);
        }
    }

    selectionChange($event) {
        this.onSelectionChange.emit($event);
    }
}
