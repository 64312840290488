import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticateService } from 'src/app/auth/authenticate.service';
import { InstitutionService } from 'src/app/services/institution.service';
import { SystemConfigModel } from 'src/app/model/system-config.model';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';
@Component({
    selector: 'app-sys-config-detail',
    templateUrl: './sys-config-detail.component.html',
})
export class SysConfigDetailComponent implements OnInit {
    readonly SYSTEM_CONFIGURATION_UPDATE = ApplicationPermissionService.SYSTEM_CONFIGURATION_UPDATE;

    constructor(
        @Inject(MAT_DIALOG_DATA) public systemConfigModel: SystemConfigModel,
        public dialogRef: MatDialogRef<SysConfigDetailComponent>,
        public authService: AuthenticateService,
        public institutionService: InstitutionService
    ) {}

    ngOnInit(): void {
        this.institutionService.getInstitutionById(this.systemConfigModel.institutionId).subscribe({
            next: (value) => {
                this.systemConfigModel.institution = value.body;
            },
        });
    }
}
