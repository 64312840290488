import { Injectable, } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { AuthenticateService } from '../auth/authenticate.service';
import { Observable } from 'rxjs';
import { ACCOUNT_COMPONENT } from '../app.constant';
import { GlCostCodeModel } from '../model/gl-cost-code.model';


@Injectable({
    providedIn: 'root'
})
export class GlCostCodeService {

    private apiProject: string = ACCOUNT_COMPONENT + '/gl-cost-codes';


    constructor(private httpClient: HttpClient, private authService: AuthenticateService) { }

    save(
        glCostCodeModel : GlCostCodeModel
    ):Observable<HttpResponse<GlCostCodeModel>>{
        return this.httpClient.post<GlCostCodeModel>( this.apiProject, glCostCodeModel, {
            observe: 'response',
        });
    }

    getAllGlCodes(
        glCodePageNumber: number,
        glCodePageSize: number,
        glCodeSortOrder: string,
        criteria
    ): Observable<any> {
        let httpParams = new HttpParams();
        if (glCodePageNumber !== undefined || glCodePageNumber !== null) {
            httpParams = httpParams.set('page', `${glCodePageNumber}`);
        }
        if (glCodePageSize) {
            httpParams = httpParams.set('size', `${glCodePageSize}`);
        }
        if (glCodeSortOrder !== undefined || glCodeSortOrder !== null) {
            httpParams = httpParams.set('sort', glCodeSortOrder);
        }

        if (criteria) {
            if (criteria.name) {
                httpParams = httpParams.set('code', criteria.name);
            }
        }
        httpParams = httpParams.set('institutionId', this.authService.getCurInsId());
        return this.httpClient.get(`${this.apiProject}`, {
            observe: 'response',
            params: httpParams,
        });
    }

    update(
        glCostCodeModel: GlCostCodeModel,
    ): Observable<HttpResponse<GlCostCodeModel>> {
        const glCodeId = glCostCodeModel.id;
        return this.httpClient.put<GlCostCodeModel>(this.apiProject + '/' + glCodeId, glCostCodeModel, {
            observe: 'response',
        });
    }

    delete(glCostCodeId: string): Observable<HttpResponse<GlCostCodeModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('currentInstitutionId', this.authService.getCurInsId());
        return this.httpClient.delete<GlCostCodeModel[]>(this.apiProject + '/' + glCostCodeId, {
            observe: 'response',
            params: httpParams,
        });
    }


    getGlCodeById(glCostCodeId: string): Observable<HttpResponse<GlCostCodeModel[]>> {
        return this.httpClient.get<GlCostCodeModel[]>(this.apiProject+ '/' + glCostCodeId, {
            observe: 'response',
        });
    }

    getAll(): Observable<HttpResponse<GlCostCodeModel[]>> {
        return this.httpClient.get<GlCostCodeModel[]>(this.apiProject + '/all', {
            observe: 'response',
        });
    }





}
