import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigurationModule } from './configuration/configuration.module';
import { ErrorModule } from './error/error.module';
import { HomeModule } from './layouts/home/home.module';
import { ReportsUploadModule } from './report-upload/report-upload.module';

@NgModule({
    declarations: [],
    imports: [CommonModule, ConfigurationModule, ErrorModule, HomeModule, ReportsUploadModule],
})
export class ComponentsModule {}
