import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticateService } from 'src/app/auth/authenticate.service';
import { FORM_TEMPLATE_FIELD_INPUT_TYPE } from 'src/app/model/form-template-field.model';
import { InstitutionModel } from 'src/app/model/institution.model';
import { SystemConfigModel } from 'src/app/model/system-config.model';
import { GeneralService } from 'src/app/services/general.service';
import { SystemConfigurationService } from 'src/app/services/system-configuration.service';
import { SysConfigDetailComponent } from '../sys-config-detail/sys-config-detail.component';
@Component({
  selector: 'app-sys-config-dialog-new-or-edit',
  templateUrl: './sys-config-dialog-new-or-edit.component.html',
  
})
export class SysConfigDialogNewOrEditComponent implements OnInit {

    dialogType: string = '';
    isSaving: boolean = false;
    loading: boolean = false;
    systemConfigFormGroup: FormGroup;
    institutions: InstitutionModel[]= [];

    text = FORM_TEMPLATE_FIELD_INPUT_TYPE.TEXT;
    checkbox = FORM_TEMPLATE_FIELD_INPUT_TYPE.CHECKBOX;
    isLoading = FORM_TEMPLATE_FIELD_INPUT_TYPE.LOADING;
    select = FORM_TEMPLATE_FIELD_INPUT_TYPE.SELECT;

    constructor(
        @Inject(MAT_DIALOG_DATA) public systemConfigModel: SystemConfigModel,
        public dialogRef: MatDialogRef<SysConfigDialogNewOrEditComponent>,
        private systemConfigService: SystemConfigurationService,
        private formBuilder: FormBuilder,
        private generalService: GeneralService,
        private matDialog: MatDialog,
        private authService: AuthenticateService,
    ) { }

    ngOnInit(): void {
        if (this.systemConfigModel) {
            this.dialogType = 'update';
            this.systemConfigFormGroup = this.formBuilder.group({
                id: this.formBuilder.control(this.systemConfigModel.id),
                code: this.formBuilder.control(this.systemConfigModel.code, [
                    Validators.required,
                ]),
                description: this.formBuilder.control(this.systemConfigModel.description,[Validators.required]),
                value: this.formBuilder.control(this.systemConfigModel.value,[Validators.required]),
                active: this.formBuilder.control(this.systemConfigModel.active),
            });
        } else {
            this.dialogType = 'create';
            this.systemConfigFormGroup = this.formBuilder.group({
                code: this.formBuilder.control(null, [Validators.required]),
                description: this.formBuilder.control(null, [Validators.required]),
                value: this.formBuilder.control(null,[Validators.required]),
                active: this.formBuilder.control(false),
            });
        }
    }

    activeLoading() {
        this.loading = true;
    }

    deactiveLoading() {
        this.loading = false;
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

    save(): void {
        this.isSaving = true;
        this.activeLoading();
        let systemConfigModel : SystemConfigModel = this.systemConfigFormGroup.getRawValue();
        systemConfigModel.institutionId = this.authService.getCurInsId();
        if (this.dialogType === 'create') {
            this.systemConfigService.save(systemConfigModel).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                },
                error: (error) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.generalService.snackbarMessage(error.message, 'Close');
                },
            });
        } else {
            this.systemConfigService.update(systemConfigModel).subscribe({
                next: (value) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.dialogRef.close('ok');
                    this.openSystemConfigDialogDetail(value.body);
                },
                error: (error) => {
                    this.isSaving = false;
                    this.deactiveLoading();
                    this.generalService.snackbarMessage(error.message, 'Close');
                },
            });
        }
    }


    openSystemConfigDialogCreateOrEdit(systemConfigModel?: SystemConfigModel) {
        const modalRef = this.matDialog.open(SysConfigDialogNewOrEditComponent, {
            data: systemConfigModel,
            panelClass: 'mat-dialog-lg',
        });

        modalRef.afterClosed().subscribe();
    }

    openSystemConfigDialogDetail(systemConfigModel?: SystemConfigModel) {
        this.systemConfigService.getSystemConfigById(systemConfigModel.id).subscribe({
            next:(value)=> {
                const modalRef = this.matDialog.open(SysConfigDetailComponent, {
                    data: value.body,
                    panelClass: 'mat-dialog-md',
                });
        
                modalRef.afterClosed().subscribe({
                    next: (edit) => {
                        if (edit) this.openSystemConfigDialogCreateOrEdit(systemConfigModel);
                    },
                });
            }
        });
        
    }

}
