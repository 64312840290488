import { Injectable, } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { AuthenticateService } from '../auth/authenticate.service';
import { Observable } from 'rxjs';
import { EmploymentTypeModel } from '../model/employment.model';
import { SERVER_API } from '../app.constant';


@Injectable({
    providedIn: 'root'
})
export class EmploymentTypeService {

    private apiEmploymentType: string = SERVER_API + 'api/account-component/admin/employment-types';


    constructor(private httpClient: HttpClient, private authService: AuthenticateService) { }

    save(
        employmentTypeModel : EmploymentTypeModel
    ):Observable<HttpResponse<EmploymentTypeModel>>{
        return this.httpClient.post<EmploymentTypeModel>( this.apiEmploymentType, employmentTypeModel, {
            observe: 'response',
        });
    }

    getAllEmploymentTypes(
        employmentTypePageNumber: number,
        employmentTypePageSize: number,
        employmentTypeSortOrder: string,
        criteria
    ): Observable<any> {
        let httpParams = new HttpParams();
        if (employmentTypePageNumber !== undefined || employmentTypePageNumber !== null) {
            httpParams = httpParams.set('page', `${employmentTypePageNumber}`);
        }
        if (employmentTypePageSize) {
            httpParams = httpParams.set('size', `${employmentTypePageSize}`);
        }
        if (employmentTypeSortOrder !== undefined || employmentTypeSortOrder !== null) {
            httpParams = httpParams.set('sort', employmentTypeSortOrder);
        }

        if (criteria) {
            if (criteria.name) {
                httpParams = httpParams.set('name', criteria.name);
            }
        }
        return this.httpClient.get(`${this.apiEmploymentType}`, {
            observe: 'response',
            params: httpParams,
        });
    }

    update(
        employmentTypeModel: EmploymentTypeModel,
    ): Observable<HttpResponse<EmploymentTypeModel>> {
        const employmentTypeId = employmentTypeModel.id;
        return this.httpClient.put<EmploymentTypeModel>(this.apiEmploymentType + '/' + employmentTypeId, employmentTypeModel, {
            observe: 'response',
        });
    }

    delete(employmentTypeId: string): Observable<HttpResponse<EmploymentTypeModel[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('currentInstitutionId', this.authService.getCurInsId());
        return this.httpClient.delete<EmploymentTypeModel[]>(this.apiEmploymentType + '/' + employmentTypeId, {
            observe: 'response',
            params: httpParams,
        });
    }


    getEmploymentById(employmentTypeId: string): Observable<HttpResponse<EmploymentTypeModel[]>> {
        return this.httpClient.get<EmploymentTypeModel[]>(this.apiEmploymentType+ '/' + employmentTypeId, {
            observe: 'response',
        });
    }





}
