<div class="mx-10 my-6" *appScreenPermission="[USER_READ]">
    <mx-title-bar title="User" buttonIcon="add" buttonLabel="Add User"
        (onButtonClick)="openUserDialogCreateOrEdit('create')" [buttonPermission]="[CREATE_APPLICATION_USER]"></mx-title-bar>
     <mx-search-bar searchFieldName="Name" (onSubmit)="search(0, $event)"></mx-search-bar>
    <div class="my-2">
        <strong>{{ userNumberOfRecords }}</strong> result<span *ngIf="userNumberOfRecords > 1">s</span>
    </div>
    <mat-card-content class="mx-content-table">
        <div *ngIf="loading">
            <mx-data-loading></mx-data-loading>
        </div>
        <table mat-table matSort (matSortChange)="sortPage($event)" [dataSource]="userDataSource" *ngIf="userDataSource.data"
            class="w-full" aria-label="table-user">
            <ng-container matColumnDef="fullName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Full Name</th>
                <td mat-cell *matCellDef="let element" [matTooltip]="element.fullName">
                    {{ element.fullName ?? '-' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                <td mat-cell *matCellDef="let element" [matTooltip]="element.email">
                    {{ element.email ?? '-' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="phoneNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone Number</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.phoneCountry + element.phoneNumber || '-' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef class="text-white">Authority</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngFor="let role of element.authorities.slice(0, 2)"
                        class="inline-block w-fit border-0 text-center rounded-xl px-2 py-0.5 m-0.5 bg-gray text-white">
                        {{ role.name }}
                    </div>
                    <div *ngIf="element.authorities.length > 2"
                        class="inline-block w-fit border-0 text-center rounded-xl px-2 py-0.5 m-0.5 bg-gray text-white">
                        +{{ element.authorities.length - 2 }} more...
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.active"
                        class="w-fit border-0 text-center rounded-xl px-2 py-0.5 bg-green-light text-green">
                        Active
                    </div>
                    <div *ngIf="!element.active"
                        class="w-fit border-0 text-center rounded-xl px-2 py-0.5 bg-gray text-white">
                        Inactive
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="text-white">Actions</th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon (click)="openUserDialogCreateOrEdit('edit', element)" matTooltip="Edit"
                        class="cursor-pointer" *appScreenPermission="[UPDATE_APPLICATION_USER]">edit</mat-icon>
                    <mat-icon (click)="openUserDialogDetail(element)" matTooltip="View"
                        class="cursor-pointer">remove_red_eye</mat-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell text-center" colspan="6">No data match your search "{{ userSearchValue }}"</td>
            </tr>
        </table>
    </mat-card-content>
    <mat-paginator (page)="navigateToPage($event)" [length]="userNumberOfRecords" [pageSize]="userPageSize"
        [pageSizeOptions]="[5, 10, 20, 50]" [showFirstLastButtons]="true" class="mx-paginator">
    </mat-paginator>
</div>
