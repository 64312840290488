export class FilterDates {
    dateType: string = 'DAILY';
    dateFrom: Date =new Date(new Date().setDate(new Date().getDate() - 1));
    dateTo: Date = new Date(new Date().setDate(new Date().getDate() - 1));
    yearMonth: Date = new Date(new Date().setDate(new Date().getDate() - 1));
}

export class WeekFilterDate{
    dateForm : Date;
    dateTo: Date;
}