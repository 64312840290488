import { NgModule } from '@angular/core';
import { ReportsUploadCbsModule } from './report-upload-cbs/report-upload-cbs.module';
import { ReportsUploadRioModule } from './report-upload-rio/report-upload-rio.module';

@NgModule({
    declarations: [],
    imports: [
        ReportsUploadCbsModule,
        ReportsUploadRioModule
    ],
})
export class ReportsUploadModule { }
