import { Output, Component,  EventEmitter, OnInit } from '@angular/core';
import {  FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MONTH_MODE_FORMATS } from '../../constant/datepicker-format.constant';

import  * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';

const moment = _rollupMoment || _moment;

@Component({
    selector: 'monthly-date-picker',
    templateUrl: './monthly-date-picker.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MONTH_MODE_FORMATS },
    ],
})
export class MonthlyDatePickerComponent implements OnInit{
    @Output() onDateChanged = new EventEmitter<Date>();

    dateFormControl: FormControl = new FormControl(moment());

    max: Date;
    min: Date;

    constructor() {
        const currentYear = new Date().getFullYear();
        this.min = new Date(currentYear - 11, 12, 99);
        this.max = new Date(new Date().setDate(new Date().getDate() - 1));
    }

    ngOnInit(): void {
        this._subscribeDateFormControlValueChanges();
    }

    _monthSelectedHandler(event: Moment, datepicker: MatDatepicker<Moment>) {
        const ctrlValue = this.dateFormControl.value;
        ctrlValue.month(event.month());
        ctrlValue.year(event.year());
        this.dateFormControl.setValue(ctrlValue);
        datepicker.close();
    }

    _openDatepickerOnClick(datepicker: MatDatepicker<Moment>) {
        if (!datepicker.opened) {
            datepicker.open();
        }
    }

    _subscribeDateFormControlValueChanges() {
        this.dateFormControl.valueChanges.subscribe((value) => {
            const dateValue = new Date(value);
            this.onDateChanged.emit(dateValue);
        });
    }
}
