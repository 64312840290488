<div class="flex justify-between items-center px-6 py-4 border-b border-b-gray-light">
    <div class="text-lg font-bold">View Entity '{{ institutionModel.name }}'</div>
    <button type="button" data-dismiss="modal" class="text-2xl" (click)="dialogRef.close()">&times;</button>
</div>
<div class="max-h-[70vh] overflow-auto p-6 relative whitespace-pre-line">
    <div class="grid grid-cols-2 gap-4">
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Name</label>
            <div class="mb-6 break-all" maxlength="100">
                {{ institutionModel.name || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Status</label>
            <div
                *ngIf="institutionModel.active"
                class="w-fit border-0 text-center rounded-xl px-2 py-0.5 mb-6 break-all bg-green-light text-green"
            >
                Active
            </div>
            <div
                *ngIf="!institutionModel.active"
                class="w-fit border-0 text-center rounded-xl px-2 py-0.5 mb-6 break-all bg-gray text-white"
            >
                Inactive
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Short Name</label>
            <div class="mb-6 break-all" maxlength="20">
                {{ institutionModel.shortName || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1" *ngIf="institutionModel.tier">
            <label class="text-sm text-gray font-bold">Type</label>
            <div class="mb-6 break-all">
                {{ getEntityType() }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Parent Entity</label>
            <div class="mb-6 break-all">
                {{ institutionModel.parentInstitution?.name || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Industry</label>
            <div class="mb-6 break-all" maxlength="100">
                {{ institutionModel.industry || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Address</label>
            <div class="mb-6 break-all" maxlength="500">
                {{ institutionModel.address || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Phone</label>
            <div class="mb-6 break-all" maxlength="20">
                {{ institutionModel.phone || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Business Registration Number</label>
            <div class="mb-6 break-all" maxlength="100">
                {{ institutionModel.businessRegNo || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Fax</label>
            <div class="mb-6 break-all" maxlength="50">
                {{ institutionModel.fax || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Email</label>
            <div class="mb-6 break-all" maxlength="100">
                {{ institutionModel.email || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Website</label>
            <div class="mb-6 break-all" maxlength="100">
                {{ institutionModel.website || '-' }}
            </div>
        </div>
        <div class="col-span-2">
            <label class="text-sm text-gray font-bold">Projects</label>
            <div class="mb-6 break-all" *ngIf="institutionModel.projects.length > 0; else noProject">
                <mat-chip-list>
                    <mat-chip *ngFor="let item of institutionModel.projects" [removable]="false">
                        {{ item.name }}
                    </mat-chip>
                </mat-chip-list>
            </div>
            <ng-template #noProject>
                <div class="mb-6 break-all">-</div>
            </ng-template>
        </div>
        <div class="col-span-2">
            <label class="text-sm text-gray font-bold">Companies</label>
            <div class="mb-6 break-all" *ngIf="institutionModel.companies.length > 0; else noCompany">
                <mat-chip-list>
                    <mat-chip *ngFor="let item of institutionModel.companies" [removable]="false">
                        {{ item.name }}
                    </mat-chip>
                </mat-chip-list>
            </div>
            <ng-template #noCompany>
                <div class="mb-6 break-all">-</div>
            </ng-template>
        </div>
        <div class="col-span-2">
            <label class="text-sm text-gray font-bold">Form Type Configurations</label>
            <div
                class="mb-6 break-all"
                *ngIf="institutionModel.formTypeConfigurations.length > 0; else noFormTypeConfigurations"
            >
                <mat-chip-list>
                    <mat-chip *ngFor="let item of institutionModel.formTypeConfigurations" [removable]="false">
                        {{ item.code }}
                    </mat-chip>
                </mat-chip-list>
            </div>
            <ng-template #noFormTypeConfigurations>
                <div class="mb-6 break-all">-</div>
            </ng-template>
        </div>
        <ng-container *ngIf="institutionModel.reportRequired">
            <div class="col-span-2 md:col-span-1">
                <label class="text-sm text-gray font-bold">Corporate Budget System</label>
                <div class="mb-6 break-all" maxlength="20">
                    {{ institutionModel.isCBS ?  'Yes' : 'No' }}
                </div>
            </div>
            <div class="col-span-2 md:col-span-1">
                <label class="text-sm text-gray font-bold">RIO</label>
                <div class="mb-6 break-all" maxlength="20">
                    {{ institutionModel.isRio ?  'Yes' : 'No' }}
                </div>
            </div>
            <div class="col-span-2" *ngIf="institutionModel.isRio">
                <label class="text-sm text-gray font-bold">Submission Group</label>
                <div class="mb-6 break-all" maxlength="20">
                    {{ institutionModel.systemConfigurationId }}
                </div>
            </div>
            <div class="col-span-2" *ngIf="institutionModel.isRio">
                <label class="text-sm text-gray font-bold">RIO Templates</label>
                    <div
                    class="mb-6 break-all"
                    *ngIf="institutionModel.rioTemplates.length > 0; else noRioTemplates"
                >
                    <mat-chip-list>
                        <mat-chip *ngFor="let item of institutionModel.rioTemplates" [removable]="false">
                            {{ item.name }}
                        </mat-chip>
                    </mat-chip-list>
                </div>
                <ng-template #noRioTemplates>
                    <div class="mb-6 break-all">-</div>
                </ng-template>
            </div>
        </ng-container>
    </div>
</div>
<div class="flex justify-end p-6 border-t border-gray-light gap-2">
    <button type="button" mat-raised-button class="m-1" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">clear</mat-icon>
        <span class="text-sm font-bold">Close</span>
    </button>

    <button type="button" mat-raised-button class="m-1" (click)="dialogRef.close('edit')" color="accent" *appScreenPermission="[ENTITY_UPDATE]">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">edit</mat-icon>
        <span class="text-sm font-bold">Edit</span>
    </button>
</div>
