import { Component, Inject,} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogSimple {
    title: string;
    message: string;
    buttonPositive: string;
    buttonPositiveColor?: string;
    buttonNegative: string;
}

@Component({
    selector: 'app-dialog-simple',
    templateUrl: './dialog-simple.component.html',
})
export class DialogSimpleComponent{
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogSimple,
        public dialogRef: MatDialogRef<DialogSimpleComponent>
    ) {}

    closeButtonPositive() {
        this.dialogRef.close(this.data.buttonPositive);
    }
}
