import {
    ReportUploadCbsDialogUploadComponent
} from '../report-upload-cbs-dialog-upload/report-upload-cbs-dialog-upload.component';
import {Component, OnInit, ViewChild} from "@angular/core";
import {ApplicationPermissionService} from "../../../../services/application-permission.service";
import {MatTableDataSource} from "@angular/material/table";
import {BehaviorSubject} from "rxjs";
import {MatPaginator} from "@angular/material/paginator";
import {FORM_TEMPLATE_FIELD_INPUT_TYPE} from "../../../../model/form-template-field.model";
import {DropDownListingResponse} from "../../../../model/dropdown-listing-response";
import {FormTypeConfigurationModel} from "../../../../model/form-type-configuration.model";
import {InstitutionUploadListingModel} from "../../../../model/institution.model";
import {ActivatedRoute} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {GeneralService} from "../../../../services/general.service";
import {ReportHeaderService} from "../../../../services/report-header.service";
import {ReportDetailService} from "../../../../services/report-detail.service";
import {InstitutionService} from "../../../../services/institution.service";
import {CompanyService} from "../../../../services/company.service";
import {ProjectService} from "../../../../services/project.service";
import {FormTypeConfigurationService} from "../../../../services/form-type-configuration.service";
import {HttpResponse} from "@angular/common/http";
import {ReportUploadModel} from "../../../../model/report-upload.model";
import {
    ReportUploadCbsDialogDetailComponent
} from "../report-upload-cbs-dialog-detail/report-upload-cbs-dialog-detail.component";
import {DialogSimpleComponent} from "../../../shared/ui/dialog-simple/dialog-simple.component";

@Component({
    selector: 'app-report-upload-cbs-listing',
    templateUrl: './report-upload-cbs-listing.component.html',
})
export class ReportUploadCbsListingComponent implements OnInit {

    readonly REPORT_CREATE = ApplicationPermissionService.REPORT_CREATE;
    readonly REPORT_READ = ApplicationPermissionService.REPORT_READ;

    dataSource = new MatTableDataSource<any>();
    obs: BehaviorSubject<any>;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayColumns = [
        'formType',
        'name',
        'reportingYear',
        'projectName',
        'companyName',
        'status',
        'actions'
    ];

    failColorCode: string = '#f54c40';
    searchValue: String = null;
    numberOfRecords: number = 0;
    currentPage: number = 0;
    pageSize: number = 50;
    sortOrder = ['companyId,asc', 'formType,asc'];
    loading: boolean = false;
    isLoading = FORM_TEMPLATE_FIELD_INPUT_TYPE.LOADING;

    selectedCompany: DropDownListingResponse = {id: '', name: 'All'};
    selectedProject: DropDownListingResponse = {id: '', name: 'All'};
    selectedFormType: FormTypeConfigurationModel = {
        id: '',
        code: 'All',
        createdBy: undefined,
        createdDate: undefined,
        description: undefined,
        institutions: undefined,
        lastModifiedDate: undefined,
        requireAggregation: undefined,
        lastModifiedBy: undefined
    };

    reportingCompanyList: DropDownListingResponse[] = []
    reportingProjectList: DropDownListingResponse[] = []
    reportingFormTypeList: FormTypeConfigurationModel[] = []

    status: string;
    selectedYear: string = '';
    currentYear: string = '';
    reportingYearList: string[];
    institutionTabs: InstitutionUploadListingModel[];
    selectedInstitutionTab: InstitutionUploadListingModel;

    constructor(
        public activatedRoute: ActivatedRoute,
        private matDialog: MatDialog,
        private generalService: GeneralService,
        private reportHeaderService: ReportHeaderService,
        private reportDetailService: ReportDetailService,
        private institutionService: InstitutionService,
        private companyService: CompanyService,
        private projectService: ProjectService,
        private formTypeConfigurationService: FormTypeConfigurationService,
    ) {
    }

    ngOnInit(): void {
        this.activeLoading();
        if (this.activatedRoute.snapshot.queryParams['page']) {
            this.sortOrder = this.activatedRoute.snapshot.queryParams['sort'] ? this.activatedRoute.snapshot.queryParams['sort'] : undefined;
            this.currentPage = this.activatedRoute.snapshot.queryParams['page'] ? this.activatedRoute.snapshot.queryParams['page'] : undefined;
            this.pageSize = this.activatedRoute.snapshot.queryParams['pageSize'] ? this.activatedRoute.snapshot.queryParams['pageSize'] : undefined;
        }
        this.reportHeaderService.getReportingYearList().subscribe((res) => {
            this.reportingYearList = res.body.sort((a: string, b: string) => +b - +a);
            this.selectedYear = this.reportingYearList[0]
            this.currentYear = this.reportingYearList[0]

            this.institutionService.getInstitutionUploadListByParentId().subscribe((res) => {
                this.institutionTabs = res.body;
                this.selectedInstitutionTab = this.institutionTabs?.[0];
                this.refreshForm();
            });
        });
        this.companyService.getAllCompanyDropdownList().subscribe({
            next: (response: HttpResponse<DropDownListingResponse[]>) => {
                this.reportingCompanyList = response.body;
                this.reportingCompanyList.sort((a, b) => a.name.localeCompare(b.name));
                this.reportingCompanyList.unshift({id: '', name: 'All'});
            }
        })
        this.projectService.getAllProjectDropdownList("CBS").subscribe({
            next: (response: HttpResponse<DropDownListingResponse[]>) => {
                this.reportingProjectList = response.body;
                this.reportingProjectList.sort((a, b) => a.name.localeCompare(b.name));
                this.reportingProjectList.unshift({id: '', name: 'All'});
            }
        })
        this.formTypeConfigurationService.getAll().subscribe({
            next: (response: HttpResponse<FormTypeConfigurationModel[]>) => {
                this.reportingFormTypeList = response.body;
                this.reportingFormTypeList.sort((a, b) => a.code.localeCompare(b.code));
                this.reportingFormTypeList.unshift({
                    id: '',
                    code: 'All',
                    createdBy: undefined,
                    createdDate: undefined,
                    description: undefined,
                    institutions: undefined,
                    lastModifiedDate: undefined,
                    requireAggregation: undefined,
                    lastModifiedBy: undefined
                })
            }
        })
    }

    search(currentPage: number, searchValue: String) {
        this.activeLoading()
        this.searchValue = searchValue;
        let companyId = this.selectedCompany.name === 'All' ? null : this.selectedCompany.id
        let projectId = this.selectedProject.name === 'All' ? null : this.selectedProject.id
        let formType = this.selectedFormType.code === 'All' ? null : this.selectedFormType.code

        this.reportDetailService
            .getReportUploadList(this.selectedYear, companyId, projectId, formType, this.currentPage, this.pageSize, this.sortOrder, this.selectedInstitutionTab?.institutionId, {
                name: searchValue,
            })
            .subscribe({
                next: ({body}) => {
                    this.dataSource.data = body.content;
                    this.numberOfRecords = body.totalElements;
                    this.deactiveLoading();

                    if (currentPage === 0) {
                        this.paginator.firstPage();
                    } else {
                        this.paginator.pageIndex = currentPage;
                    }
                },
                error: (error) => {
                    this.generalService.snackbarMessage(error.message, 'Close');
                    this.deactiveLoading();
                },
            });
    }

    refreshForm() {
        this.search(0, null);
    }

    sortPage(event): void {
        this.sortOrder = []
        this.sortOrder.push(`${event.active},${event.direction}`);
        this.search(this.currentPage, this.searchValue);
    }

    navigateToPage(event): void {
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.search(this.currentPage, this.searchValue);
    }

    activeLoading() {
        this.loading = true;
    }

    deactiveLoading() {
        this.loading = false;
    }

    openReportUploadDialogDetail(reportUploadModel?: ReportUploadModel) {
        const modalRef = this.matDialog.open(ReportUploadCbsDialogDetailComponent, {
            data: reportUploadModel,
            panelClass: 'mat-dialog-md'
        });

        modalRef.afterClosed().subscribe({
            next: (edit) => {
                if (edit) this.openReportUploadDialogUpload(reportUploadModel);
            }
        })
    }

    openReportUploadDialogUpload(reportUploadModel?: ReportUploadModel) {
        if (reportUploadModel.status === 'EXTRACTED') {
            const modalRef = this.matDialog.open(DialogSimpleComponent, {
                data: {
                    title: 'Confirm Overwrite ',
                    message: `Are you sure you want to overwrite this report?`,
                    buttonPositive: 'Yes',
                    buttonPositiveColor: 'warn',
                    buttonNegative: 'No',
                },
                panelClass: 'mat-dialog-sm',
                id: 'overwrite-report',
            });
            modalRef.afterClosed().subscribe(yes => {
                if (yes) {
                    this.openUploadDialog(reportUploadModel);
                }
            });
        } else {
            // Status is not 'EXTRACTED', directly open the upload dialog
            this.openUploadDialog(reportUploadModel);
        }
    }

    openUploadDialog(reportUploadModel: ReportUploadModel) {
        const modalRef2 = this.matDialog.open(ReportUploadCbsDialogUploadComponent, {
            data: reportUploadModel,
            panelClass: 'mat-dialog-lg'
        });


        modalRef2.afterClosed().subscribe(yes => {
            if (yes) {
                this.search(this.currentPage, this.searchValue);
            }

        }, error => {
            this.generalService.snackbarMessage(error.message, 'Close');
        });
    }

    openReportDeleteConfirmationDialog(reportUploadModel?: ReportUploadModel) {
        const modalRef = this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: 'Confirm Delete ',
                message: `Are you sure want to delete this submission?`,
                buttonPositive: 'Yes',
                buttonPositiveColor: 'warn',
                buttonNegative: 'No',
            },
            panelClass: 'mat-dialog-sm',
            id: 'delete-report',
        });

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) {
                    this.reportDetailService.delete(reportUploadModel.reportDetailId).subscribe({
                        next: () => {
                            this.activeLoading();
                            this.search(this.currentPage, this.searchValue)
                        },
                        error: error => {
                            this.generalService.snackbarMessage(error.message, 'Close');
                        }
                    });
                }
            },
        });
    }

    toggleYearSelection(year: string) {
        this.selectedYear = year;
    }

    toggleFormTypeSelection(formType: FormTypeConfigurationModel) {
        this.selectedFormType = formType;
    }

    toggleCompanySelection(company: DropDownListingResponse) {
        this.selectedCompany = company
    }

    toggleProjectSelection(project: DropDownListingResponse) {
        this.selectedProject = project
    }

    onSelectedInstitutionTab(institutionUploadListingModel: InstitutionUploadListingModel) {
        this.selectedInstitutionTab = institutionUploadListingModel;
        this.refreshForm();
    }

    resetFilter() {
        this.selectedCompany = {id: '', name: 'All'};
        this.selectedProject = {id: '', name: 'All'};
        this.selectedFormType = {
            id: '',
            code: 'All',
            createdBy: undefined,
            createdDate: undefined,
            description: undefined,
            institutions: undefined,
            lastModifiedDate: undefined,
            requireAggregation: undefined,
            lastModifiedBy: undefined
        };
        this.selectedYear = this.currentYear;
        this.refreshForm()
    }

    downloadReportDetail(reportUploadModel?: ReportUploadModel) {

        this.reportDetailService.downloadReportDetails(reportUploadModel.reportDetailId).subscribe({
            next: (res: any) => {
                // Extract filename from Content-Disposition header
                const contentDispositionHeader = res.headers.get('content-disposition');
                const filename = contentDispositionHeader.split(';')[1].trim().split('=')[1];

                const blob = new Blob([res.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                // Create a URL for the Blob
                const url = URL.createObjectURL(blob);

                // Use the URL to download the Excel file or perform any other action
                // For example, create a link and simulate a click to trigger the download
                const link = document.createElement('a');
                link.href = url;

                link.download = filename;

                link.click();
            },
            error: (error) => {
                this.generalService.snackbarMessage(error.debugMessage, 'Close');
            },
        });
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

    bgBasedOnTier(tier: number): string {
        if (tier === 0) {
            return '#111C44'
        } else if (tier === 1) {
            return '#424154'
        } else if (tier === 2) {
            return '#726663'
        } else {
            return '#D2AF82'

        }
    }
}


export interface SampleReportUploadModel {
    formType: string;
    reportName: string;
    submittedBy: string;
    reportingYear: string;
    projectId: string;
    projectName: string;
    reportDetailId: string;
    company: string;
    function: string;
    unit: string;
}
