import { UserDialogDetailComponent } from './user-dialog-detail/user-dialog-detail.component';
import { UserDialogNewOrEditComponent } from './user-dialog-new-or-edit/user-dialog-new-or-edit.component';
import { SharedModule } from './../../shared/shared.module';
import { UserListingComponent } from './user-listing/user-listing.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { UserDialogResetPasswordComponent } from './user-dialog-reset-password/user-dialog-reset-password.component';

const routes: Routes = [
    {
        path: '',
        component: UserListingComponent,
    },
];

@NgModule({
    declarations: [UserListingComponent, UserDialogNewOrEditComponent, UserDialogDetailComponent, UserDialogResetPasswordComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(routes)],
})
export class UsersModule {}
