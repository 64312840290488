import { Component, Input,  } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'mx-text-field',
    templateUrl: './mx-text-field.component.html',
})
export class MxTextFieldComponent {
    @Input() public formGroupChild: FormGroup;
    @Input() public formControlNameChild: string;
    @Input() public label: String;
    @Input() public placeholder: string = '';
    @Input() public maxLength: number;
    @Input() public autoFocusChild: boolean;
    @Input() public errorMessage: String;
    @Input() public required: boolean = false;
    @Input() public suffixIcon: String;
    @Input() public suffixIconTooltip: string;

}
