import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticateService } from 'src/app/auth/authenticate.service';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
})
export class NotFoundComponent {
    constructor(private authenticateService: AuthenticateService, private location: Location, private router: Router) {}

    isAuthenticated() {
        return this.authenticateService.isAuthenticated();
    }

    goBack() {
        if (this.authenticateService.isAuthenticated()) {
            this.location.back();
        } else {
            this.router.navigate(['']);
        }
    }
}
