export interface Property {
    value: string;
    name: string;
}
export const propertyNameCompare = (a: Property, b: Property) => {
    if (a.name < b.name) {
        return -1;
    } else if (a.name > b.name) {
        return 1;
    } else {
        return 0;
    }
};

export const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            const arrayBuffer = reader.result as ArrayBuffer;
            const data = new Uint8Array(arrayBuffer);
            const binaryString = data.reduce((acc, byte) => acc + String.fromCharCode(byte), '');
            const base64 = btoa(binaryString);
            resolve(base64);
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsArrayBuffer(file);
    });
};
