import { Component, EventEmitter, Input, Output,  } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'mx-attachment',
    templateUrl: './mx-attachment.component.html',
})
export class MxAttachmentComponent {
    @Input() public formGroupChild: FormGroup;
    @Input() public formControlNameChild: string;
    @Input() public label: String;
    @Input() public placeholder: string = '';
    @Input() public maxLength: number;
    @Input() public autoFocusChild: boolean;
    @Input() public errorMessage: String;
    @Input() public required: boolean = false;
    @Input() public suffixIcon: String;
    @Input() public suffixIconTooltip: string;
    @Output() public onSelectionChange = new EventEmitter<any>();

    onFileSelected($event) {
        this.onSelectionChange.emit($event);
    }

}
