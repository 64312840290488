import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticateService } from '../auth/authenticate.service';
import { ApplicationRoleModel } from '../model/application-roles.model';
import { SERVER_API } from '../app.constant';

@Injectable({
    providedIn: 'root',
})
export class ApplicationRolesService {
    private apiAuthority: string = SERVER_API + 'api/account-component/admin/authority';

    constructor(private httpClient: HttpClient, private authService: AuthenticateService) {}

    save(applicationRoleModel: ApplicationRoleModel): Observable<HttpResponse<ApplicationRoleModel>> {
        return this.httpClient.post<ApplicationRoleModel>(this.apiAuthority, applicationRoleModel, {
            observe: 'response'
        });
    }

    public getAllbyInstitution(
        pageNumber: number,
        pageSize: number,
        sortOrder: string,
        criteria
    ): Observable<any> {
        let httpParams = new HttpParams();
        if (pageNumber !== undefined || pageNumber !== null) {
            httpParams = httpParams.set('page', `${pageNumber}`);
        }
        if (pageSize) {
            httpParams = httpParams.set('size', `${pageSize}`);
        }
        if (sortOrder !== undefined || sortOrder !== null) {
            httpParams = httpParams.set('sort', sortOrder);
        }

        if (criteria) {
            if (criteria.name) {
                httpParams = httpParams.set('name', criteria.name);
            }
            if (criteria.currentInstitution) {
                httpParams = httpParams.set('currentInstitutionId', criteria.currentInstitution);
            }
        }
        return this.httpClient.get(`${this.apiAuthority}`, {
            observe: 'response',
            params: httpParams,
        });
    }

    public getAllByInstitutionAndChild(
        pageNumber: number,
        pageSize: number,
        sortOrder: string,
        criteria
    ): Observable<HttpResponse<ApplicationRoleModel[]>> {
        let httpParams = new HttpParams();
        if (pageNumber) {
            httpParams = httpParams.set('page', `${pageNumber}`);
        }
        if (pageSize) {
            httpParams = httpParams.set('size', `${pageSize}`);
        }
        if (sortOrder) {
            httpParams = httpParams.set('sort', sortOrder);
        }

        if (criteria) {
            if (criteria.name) {
                httpParams = httpParams.set('name', criteria.name);
            }
            if (criteria.institution) {
                httpParams = httpParams.set('institutionId', criteria.institution);
            }
            if (criteria.currentInstitution) {
                httpParams = httpParams.set('curIns', criteria.currentInstitution);
            }
        }
        return this.httpClient.get<ApplicationRoleModel[]>(this.apiAuthority, {
            observe: 'response',
            params: httpParams,
        });
    }

    public update(applicationRoleModel: ApplicationRoleModel): Observable<HttpResponse<ApplicationRoleModel>> {
        return this.httpClient.put<ApplicationRoleModel>(this.apiAuthority, applicationRoleModel, {
            observe: 'response'
        });
    }

    public delete(id: string): Observable<HttpResponse<ApplicationRoleModel[]>> {
        return this.httpClient.delete<ApplicationRoleModel[]>(this.apiAuthority + '/' + id, {
            observe: 'response'
        });
    }

    getAllAuthorities(): Observable<HttpResponse<any[]>> {
        return this.httpClient.get<any[]>(this.apiAuthority+ '/no-pagination', {
            observe: 'response'
        });
    }

    getAuthorityById(id: number): Observable<HttpResponse<ApplicationRoleModel[]>> {
        return this.httpClient.get<ApplicationRoleModel[]>(this.apiAuthority+ '/' + id, {
            observe: 'response'
        });
    }
}
