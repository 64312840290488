<div class="mx-10 my-6" *appScreenPermission="[COUNTRY_READ]">
    <mx-title-bar title="Country" buttonIcon="add" buttonLabel="Add Country"
        (onButtonClick)="openCountryDialogCreateOrEdit()" [buttonPermission]="[COUNTRY_CREATE]"></mx-title-bar>
     <mx-search-bar searchFieldName="Name" (onSubmit)="search(0, $event)"></mx-search-bar>
    <div class="my-2">
        <strong>{{ countryNumberOfRecords }}</strong> result<span *ngIf="countryNumberOfRecords > 1">s</span>
    </div>
    <mat-card-content class="mx-content-table">
        <div *ngIf="loading">
            <mx-data-loading></mx-data-loading>
        </div>
        <table mat-table matSort (matSortChange)="sortPage($event)" [dataSource]="countryDataSource" *ngIf="countryDataSource.data"
            class="w-full" aria-label="table-expense-type">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.name || "-" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.code || "-" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.active"
                        class="w-fit border-0 text-center rounded-xl px-2 py-0.5 bg-green-light text-green">
                        Active
                    </div>
                    <div *ngIf="!element.active"
                        class="w-fit border-0 text-center rounded-xl px-2 py-0.5 bg-gray text-white">
                        Inactive
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="text-white">Actions</th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon class="mr-4" (click)="openCountryDialogCreateOrEdit(element)"
                        matTooltip="Edit" *appScreenPermission="[COUNTRY_UPDATE]">edit</mat-icon>
                    <mat-icon (click)="openCountryDialogDetail(element)" matTooltip="View">remove_red_eye</mat-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell text-center" colspan="6">No data match your search "{{ countrySearchValue }}"</td>
            </tr>
        </table>
    </mat-card-content>
    <mat-paginator (page)="navigateToPage($event)" [length]="countryNumberOfRecords" [pageSize]="countryPageSize"
        [pageSizeOptions]="[5, 10, 20, 50]" [showFirstLastButtons]="true" class="mx-paginator">
    </mat-paginator>
</div>
