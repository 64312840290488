import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { Subject } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';
import { ModuleRecord } from 'src/app/model/moduleRecord.model';
import { ModuleResponse } from 'src/app/model/dashboardChartResponse.model';
import { FilterDates } from 'src/app/model/filter-dates.model';
import { DatePipe } from '@angular/common';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';

@Component({
    selector: 'module-records-chart',
    templateUrl: './module-records-chart.component.html',
})
export class ModuleRecordsChartComponent implements OnInit, AfterViewInit {
    readonly DASHBOARD_ACTIVITY_READ = ApplicationPermissionService.DASHBOARD_ACTIVITY_READ;

    @Input() public moduleRecordsFilterDates: Subject<FilterDates>;

    startDate: any;
    dateType: string;

    data = [];

    root: am5.Root;
    chart: am5xy.XYChart;
    legend: am5.Legend;
    series: am5xy.ColumnSeries;

    yAxis: am5xy.ValueAxis<am5xy.AxisRendererY>;
    xAxis: am5xy.CategoryAxis<am5xy.AxisRendererX>;

    bank : string = "Bank";
    loan : string= 'Loan';
    property: string = 'Property';
    vehicle: string = 'Vehicle';
    utility : string= 'Utility';
    insurance : string= 'Insurance';
    creditCard : string= 'Credit Card';

    constructor(private dashboardService: DashboardService, private datePipe: DatePipe) {}

    ngOnInit(): void {
        this.moduleRecordsFilterDates.subscribe((moduleRecordsFilterDates) => {
            this.requestData(moduleRecordsFilterDates);
        });
    }

    requestData(moduleRecordsFilterDates: FilterDates) {
        this.dashboardService
            .getModuleRecord(
                moduleRecordsFilterDates.dateType,
                this.datePipe.transform(moduleRecordsFilterDates.dateFrom, 'yyyy-MM-dd', '+800'),
                this.datePipe.transform(moduleRecordsFilterDates.dateTo, 'yyyy-MM-dd', '+800'),
                this.datePipe.transform(moduleRecordsFilterDates.yearMonth, 'yyyy-MM', '+800')
            )
            .subscribe({
                next: (value) => {
                    this.data = this.processModuleResponse(value.body);
                    this.xAxis.data.setAll(this.data);
                    this.series.data.setAll(this.data);
                    this.legend.data.setAll(this.series.dataItems);
                },
            });
    }

    ngAfterViewInit(): void {
        const bank: string = 'Bank';
        const loan: string = 'Loan';
        const property: string = 'Property';
        const vehicle: string = 'Vehicle';
        const utility: string = 'Utility';
        const insurance: string = 'Insurance';
        const creditCard: string = 'Credit Card';

        this.root = am5.Root.new('chartdiv');

        this.root.setThemes([am5themes_Animated.new(this.root)]);

        this.chart = this.root.container.children.push(
            am5xy.XYChart.new(this.root, {
                panX: false,
                panY: false,
                wheelX: 'panX',
                wheelY: 'zoomX',
                pinchZoomX: true,
                layout: this.root.horizontalLayout,
                paddingBottom:30
            })
        );


        this.xAxis = this.chart.xAxes.push(
            am5xy.CategoryAxis.new(this.root, {
                maxDeviation: 0.2,
                categoryField: 'category',
                renderer: am5xy.AxisRendererX.new(this.root, {}),
            })
        );
        this.xAxis.hide();

        this.yAxis = this.chart.yAxes.push(
            am5xy.ValueAxis.new(this.root, {
                min: 0,
                maxDeviation: 0.3,
                numberFormat: '#.',
                renderer: am5xy.AxisRendererY.new(this.root, {}),
            })
        );

        this.legend = this.chart.children.push(
            am5.Legend.new(this.root, {
                nameField: 'categoryX',
                centerY: am5.p50,
                y: am5.p50,
                centerX: am5.p0,
                x: am5.percent(90),
                layout: this.root.verticalLayout,
                verticalScrollbar: am5.Scrollbar.new(this.root, {
                    orientation: 'vertical',
                }),
            })
        );

        // Create series
        this.series = this.chart.series.push(
            am5xy.ColumnSeries.new(this.root, {
                name: 'Series 1',
                xAxis: this.xAxis,
                yAxis: this.yAxis,
                valueYField: 'count',
                categoryXField: 'category',
            })
        );

        this.series.columns.template.setAll({
            cornerRadiusTL: 5,
            cornerRadiusTR: 5,
            tooltipText: "{categoryX}: {valueYWorking.formatNumber('#.')}",
            tooltipY: 0,
        });

        this.series.data.processor = am5.DataProcessor.new(this.root, {
            numericFields: ['count'],
        });

        let chart: am5xy.XYChart;
        chart = this.chart;
        let series: am5xy.ColumnSeries;
        series = this.series;

        this.series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0 });

        this.series.columns.template.adapters.add('fill', function (fill, target) {
            let item: any;
            item = target.dataItem.dataContext;
            if (item.category === bank) {
                return am5.color(0xEC1F27);
            } else if (item.category === loan) {
                return am5.color(0x970D13);
            } else if (item.category === property) {
                return am5.color(0xCB565B);
            } else if (item.category === vehicle) {
                return am5.color(0xC78486);
            } else if (item.category === utility) {
                return am5.color(0xF09397);
            } else if (item.category === insurance) {
                return am5.color(0xF0A9AC);
            } else if(item.category === creditCard){
                return am5.color(0xFAD0D2);
            }
        });

        this.series.columns.template.adapters.add('stroke', function (stroke, target) {
            return chart.get('colors').getIndex(series.columns.indexOf(target));
        });
        // Set data
        this.xAxis.data.setAll(this.data);
        this.series.data.setAll(this.data);
        this.legend.data.setAll(this.series.dataItems);

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        this.series.appear(1000);
        this.chart.appear(1000, 100);
    }

    private processModuleResponse(data: ModuleResponse): ModuleRecord[] {
        let moduleRecordList: ModuleRecord[] = [];
        let bankModule: ModuleRecord = { category: this.bank, count: data.banksCount };
        moduleRecordList.push(bankModule);
        let loanModule: ModuleRecord = { category: this.loan, count: data.loansCount };
        moduleRecordList.push(loanModule);
        let propertyModule: ModuleRecord = { category: this.property, count: data.propertiesCount };
        moduleRecordList.push(propertyModule);
        let vehicleModule: ModuleRecord = { category: this.vehicle, count: data.vehiclesCount };
        moduleRecordList.push(vehicleModule);
        let utilityModule: ModuleRecord = { category: this.utility, count: data.utilitiesCount };
        moduleRecordList.push(utilityModule);
        let insuranceModule: ModuleRecord = { category: this.insurance, count: data.insuranceCount };
        moduleRecordList.push(insuranceModule);
        let creditCardModule: ModuleRecord = { category: this.creditCard, count: data.creditCardCount };
        moduleRecordList.push(creditCardModule);
        return moduleRecordList;
    }
}
