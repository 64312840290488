import { ACCOUNT_COMPONENT } from './../app.constant';
import { HttpResponse, HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { COMMON_COMPONENT } from '../app.constant';
@Injectable({
    providedIn: 'root',
})
export class GeneralService {
    private commonApi: string = COMMON_COMPONENT;
    private dataApi: string = ACCOUNT_COMPONENT + '/data';

    loading: boolean = false;

    constructor(
        private snackBar: MatSnackBar,
        private translateService: TranslateService,
        private httpClient: HttpClient
    ) {}

    snackbarMessage(msg: string, type: string) {
        // SUCCESS alert message - use type 'success'
        // ERROR alert message - use type 'danger'
        // INFORMATION alert messsage - use type 'info'

        let sb = this.snackBar.open(msg, type, {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
        sb.onAction().subscribe(() => {
            sb.dismiss();
        });
    }

    getAllCountries(sortOrder?: string): Observable<HttpResponse<any[]>> {
        let httpParams = new HttpParams();

        if (sortOrder) {
            httpParams = httpParams.set('sort', sortOrder);
        } else {
            httpParams = httpParams.set('sort', 'name');
        }

        return this.httpClient.get<any[]>(this.commonApi + '/country', {
            observe: 'response',
            params: httpParams,
        });
    }

    getAllEmploymentTypes(): Observable<HttpResponse<any[]>> {
        return this.httpClient.get<any[]>(this.dataApi + '/employment-type', {
            observe: 'response',
        });
    }

    getAttachmentGroup(id: string): Observable<HttpResponse<any[]>> {
        return this.httpClient.get<any[]>(this.commonApi + '/attachment/' + id, {
            observe: 'response',
        });
    }
}
