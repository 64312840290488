import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ApplicationPermissionService} from 'src/app/services/application-permission.service';
import {BehaviorSubject} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {FORM_TEMPLATE_FIELD_INPUT_TYPE} from 'src/app/model/form-template-field.model';
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {GeneralService} from 'src/app/services/general.service';
import {DialogSimpleComponent} from 'src/app/components/shared/ui/dialog-simple/dialog-simple.component';
import {ProjectDialogNewOrEditComponent} from '../project-dialog-new-or-edit/project-dialog-new-or-edit.component';
import {ProjectDialogDetailComponent} from '../project-dialog-detail/project-dialog-detail.component';
import {ProjectService} from 'src/app/services/project.service';
import {ProjectModel} from 'src/app/model/project.model';
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
    selector: 'app-project-listing',
    templateUrl: './project-listing.component.html',
})
export class ProjectListingComponent implements OnInit {

    readonly PROJECT_CREATE = ApplicationPermissionService.PROJECT_CREATE;
    readonly PROJECT_DELETE = ApplicationPermissionService.PROJECT_DELETE;
    readonly PROJECT_UPDATE = ApplicationPermissionService.PROJECT_UPDATE;
    readonly PROJECT_READ = ApplicationPermissionService.PROJECT_READ;

    projectFormGroup: FormGroup;
    projectDataSource = new MatTableDataSource<any>();
    obs: BehaviorSubject<any>;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayColumns = [
        'name',
        'type',
        'active',
        'actions'
    ];

    projectTypes: string[] = ['All', 'CBS', 'RIO'];
    currentType: string = 'All';
    projectSearchValue: String = null;
    projectPageNumbers: number = 0;
    projectNumberOfRecords: number = 0;
    projectCurrentPage: number = 0;
    projectPageSize: number = 50;
    projectSortOrder = 'name,asc';
    loading: boolean = false;

    isLoading = FORM_TEMPLATE_FIELD_INPUT_TYPE.LOADING;
    select = FORM_TEMPLATE_FIELD_INPUT_TYPE.SELECT;
    text = FORM_TEMPLATE_FIELD_INPUT_TYPE.TEXT;

    constructor(
        public projectActivatedRoute: ActivatedRoute,
        private matDialog: MatDialog,
        private projectService: ProjectService,
        private generalService: GeneralService,
        private formBuilder: FormBuilder,
    ) {
        this.projectFormGroup = this.formBuilder.group({
            name: this.formBuilder.control(null),
            type: this.formBuilder.control(this.currentType),
        });
    }

    ngOnInit(): void {
        this.projectActiveLoading();
        if (this.projectActivatedRoute.snapshot.queryParams['page']) {
            this.projectSortOrder = this.projectActivatedRoute.snapshot.queryParams['sort'] ? this.projectActivatedRoute.snapshot.queryParams['sort'] : undefined;
            this.projectCurrentPage = this.projectActivatedRoute.snapshot.queryParams['page'] ? this.projectActivatedRoute.snapshot.queryParams['page'] : undefined;
            this.projectPageSize = this.projectActivatedRoute.snapshot.queryParams['pageSize'] ? this.projectActivatedRoute.snapshot.queryParams['pageSize'] : undefined;
        }
        this.refreshForm();
    }

    openProjectDialogCreateOrEdit(projectModel?: ProjectModel) {
        if (projectModel === undefined) {
            this.passDataToDialogCreateOrEdit(projectModel);
        } else {
            this.projectService.getProjectById(projectModel.id).subscribe({
                next: (value) => {
                    this.passDataToDialogCreateOrEdit(value.body);
                }
            });
        }
    }

    passDataToDialogCreateOrEdit(event: any) {
        const modalRef = this.matDialog.open(ProjectDialogNewOrEditComponent, {
            data: event,
            panelClass: 'mat-dialog-lg'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) this.search(this.projectCurrentPage, this.projectSearchValue, this.currentType);
            }
        })
    }

    openProjectDialogDetail(projectModel?: ProjectModel) {
        this.projectService.getProjectById(projectModel.id).subscribe({
            next: (value) => {
                const modalRef = this.matDialog.open(ProjectDialogDetailComponent, {
                    data: value.body,
                    panelClass: 'mat-dialog-md'
                })

                modalRef.afterClosed().subscribe({
                    next: (edit) => {
                        if (edit) this.openProjectDialogCreateOrEdit(projectModel);
                    }
                })
            }
        });
    }

    openCreateRioReportSummaryDialog(projectModel?: ProjectModel) {

        const modalRef = this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: 'Confirm Create Rio Report Summary ?',
                message: `Are you sure want to create RIO report summary for ${projectModel.name}?`,
                buttonPositive: 'Yes',
                buttonNegative: 'No'
            },
            panelClass: 'mat-dialog-sm'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) {
                    this.projectService.createRioReportSummary(projectModel.id).subscribe({
                        next: ({body}) => {
                            this.generalService.snackbarMessage('RIO Report summary creation request triggered for project '+projectModel.name, 'Close');
                        },
                        error: (error) => {
                            this.generalService.snackbarMessage(error.message, 'Close');
                        },
                    });
                }
            }
        })

    }

    search(currentPage: number, projectSearchValue: String, type: string | null) {
        this.projectActiveLoading();

        if (type === 'All') {
            type = null;
        }

        this.projectSearchValue = projectSearchValue;
        this.projectService
            .getAllProjects(currentPage, this.projectPageSize, this.projectSortOrder, {
                name: projectSearchValue,
                type: type
            })
            .subscribe({
                next: ({body}) => {
                    this.projectDataSource.data = body.content;
                    this.projectNumberOfRecords = body.totalElements;
                    this.projectDeactiveLoading();
                },
                error: (error) => {
                    this.generalService.snackbarMessage(error.message, 'Close');
                    this.projectDeactiveLoading();
                },
            });
    }

    refreshForm() {
        this.search(0, null, this.currentType);
    }

    sortPage(event): void {
        this.projectSortOrder = `${event.active},${event.direction}`;
        this.search(this.projectCurrentPage, this.projectSearchValue, this.currentType);
    }

    navigateToPage(event): void {
        this.projectPageSize = event.pageSize;
        this.projectCurrentPage = event.pageIndex;
        this.search(this.projectCurrentPage, this.projectSearchValue, this.currentType);
    }

    openProjectDialogDelete(projectModel?: ProjectModel): void {
        let message: string;

        if (projectModel.type === 'RIO') {
            message = `Deleting RIO project ${projectModel.name} will delete existing submission and report summary (if exist) for current year month. Do you want to proceed ?`;
        } else {
            message = `Are you sure want to delete ${projectModel.name}?`;
        }

        const modalRef = this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: 'Confirm Delete Project ?',
                message: message,
                buttonPositive: 'Yes',
                buttonNegative: 'No'
            },
            panelClass: 'mat-dialog-sm'
        });

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) {
                    this.projectService.delete(projectModel.id.toString()).subscribe({
                        next: () => {
                            this.search(this.projectCurrentPage, this.projectSearchValue, this.currentType);
                        },
                        error: (error) => {
                            this.generalService.snackbarMessage(error.message, 'Close');
                        },
                    });
                }
            }
        })
    }

    openFailedInactiveDialog(projectModel?: ProjectModel) {

        const modalRef = this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: 'Confirm Create Rio Report Summary ?',
                message: `Are you sure want to create RIO report summary for ${projectModel.name}?`,
                buttonPositive: 'Yes',
                buttonNegative: 'No'
            },
            panelClass: 'mat-dialog-sm'
        })

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) {
                    this.projectService.createRioReportSummary(projectModel.id).subscribe({
                        next: ({body}) => {
                            this.generalService.snackbarMessage('RIO Report summary creation request triggered for project '+projectModel.name, 'Close');
                        },
                        error: (error) => {
                            this.generalService.snackbarMessage(error.message, 'Close');
                        },
                    });
                }
            }
        })

    }

    projectActiveLoading() {
        this.loading = true;
    }

    projectDeactiveLoading() {
        this.loading = false;
    }

    compareString(object1: string, object2: string) {
        return object1 === object2;
    }

    onSubmitSearch() {
        const {value} = this.projectFormGroup;
        this.currentType = value.type;
        this.search(0, value.name, value.type);
    }

}
