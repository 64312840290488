import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { ReportUploadRioDialogDetailComponent } from './report-upload-rio-dialog-detail/report-upload-rio-dialog-detail.component';
import { ReportUploadRioDialogUploadComponent } from './report-upload-rio-dialog-upload/report-upload-rio-dialog-upload.component';
import { ReportUploadRioListingComponent } from './report-upload-rio-listing/report-upload-rio-listing.component';
import { SharedModule } from '../../shared/shared.module';

const routes: Routes = [
  {
      path: '',
      component: ReportUploadRioListingComponent,
  },
];

@NgModule({
  declarations: [
    ReportUploadRioListingComponent,
    ReportUploadRioDialogDetailComponent,
    ReportUploadRioDialogUploadComponent
  ],
  imports: [
    CommonModule, 
    SharedModule, 
    RouterModule.forChild(routes), 
    FormsModule, 
    ReactiveFormsModule
],

})
export class ReportsUploadRioModule { }
