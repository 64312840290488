<div class="flex justify-between items-center px-6 py-4 border-b border-b-gray-light">
    <div class="text-lg font-bold">
        <ng-container *ngIf="dialogType === 'create'; else elseTemplate"> Create New Project </ng-container>
        <ng-template #elseTemplate> Edit Project '{{ projectModel.name }}' </ng-template>
    </div>
    <button type="button" data-dismiss="modal" class="text-2xl focus:outline-none" (click)="dialogRef.close()">
        &times;
    </button>
</div>
<div class="flex justify-between items-center px-6 py-4 border-b border-b-gray-light">
    <div class="flex items-center text-md font-bold">
        <span class="material-icons text-red-500 mr-2">warning</span>
        Disclaimer: Project name needs to be same as in IFCA
    </div>
</div>
<div *ngIf="loading" class="mx-empty-result">
    <div class="mx-data-loading"></div>
</div>
<div class="max-h-[70vh] overflow-auto p-6 relative whitespace-pre-line">
    <form [formGroup]="projectFormGroup">
        <div class="grid grid-cols-2 gap-x-4">
            <mx-input-field
                class="col-span-2 md:col-span-1"
                [inputType]="text"
                [formGroupChild]="projectFormGroup"
                formControlNameChild="name"
                label="Name"
                placeholder="Enter name"
                [maxLength]="200"
                errorMessage="Name is required"
                [autoFocusChild]="true"
                [required]="true"
            ></mx-input-field>
            <mx-input-field
                class="col-span-2 md:col-span-1 flex items-center"
                [inputType]="checkbox"
                [formGroupChild]="projectFormGroup"
                formControlNameChild="active"
                label="Active"
            ></mx-input-field>
            <mx-input-field
                class="col-span-2 md:col-span-1"
                [inputType]="text"
                [formGroupChild]="projectFormGroup"
                formControlNameChild="code"
                label="Code"
                placeholder="Enter code"
                [maxLength]="30"
                [required]="true"
            ></mx-input-field>
            <mx-input-field
                class="col-span-2 md:col-span-1"
                [inputType]="select"
                [formGroupChild]="projectFormGroup"
                formControlNameChild="type"
                label="Type"
                placeholder="Select type"
                [compareWithChild]="compareString"
                [list]=projectTypes
                (onSelectionChange)="onChangeProjectType()"
                [required]="true"
            ></mx-input-field>
            <mx-input-field
                    *ngIf="
                    projectFormGroup.get('type').value === 'RIO'
                    "
                    class="col-span-2"
                    [inputType]="select"
                    [formGroupChild]="projectFormGroup"
                    formControlNameChild="linkedProject"
                    label="LinkedProject"
                    placeholder="Select CBS Project"
                    [compareWithChild]="compareObjects"
                    [list]=cbsNonLinked
            ></mx-input-field>
            <mx-input-field
                    *ngIf="
                    projectFormGroup.get('type').value === 'CBS'
                    "
                    class="col-span-2"
                    [inputType]="select"
                    [formGroupChild]="projectFormGroup"
                    formControlNameChild="linkedProject"
                    label="LinkedProject"
                    placeholder="Select RIO Project"
                    [compareWithChild]="compareObjects"
                    [list]=rioNonLinked
            ></mx-input-field>
            <mx-input-field
                class="col-span-2"
                [inputType]="text"
                [formGroupChild]="projectFormGroup"
                formControlNameChild="description"
                label="Description"
                placeholder="Enter description"
                [maxLength]="255"
                [required]="false"
            ></mx-input-field>
            <div class="my-3 relative">
                <label>Form Type Configuration</label>
                <mat-select
                    style="width: 950px"
                    class="col-span-2 md:col-span-1 mt-1 block w-full pl-3 py-2 border rounded-md text-sm shadow-sm focus:outline-none focus:ring-1 bg-white"
                    [formControlName]="'formTypeConfigurations'"
                    [compareWith]="compareObjects"
                    placeholder="Select form type configuration"
                    multiple
                >
                    <mat-select-trigger>
                        <mat-chip-list>
                            <mat-chip
                                *ngFor="let item of projectFormGroup.get('formTypeConfigurations').value"
                                [removable]="true"
                                (removed)="onToppingRemovedFormTypeConfigurations(item)"
                            >
                                {{ item.code }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                        </mat-chip-list>
                    </mat-select-trigger>
                    <mat-option *ngFor="let item of formTypeConfigurationList" [value]="item">
                        {{ item.code ? item.code : item }}
                    </mat-option>
                </mat-select>
            </div>
            <mx-input-field class="col-span-2" [inputType]="select" [formGroupChild]="projectFormGroup"
                formControlNameChild="company" label="Company" placeholder="Select company"
                [compareWithChild]="compareObjects" [disabledChild]="false" [list]="companies"
                [required]="true"></mx-input-field>
            <mx-input-field
                class="col-span-2"
                [inputType]="multiSelect"
                [formGroupChild]="projectFormGroup"
                formControlNameChild="institutions"
                label="Entity"
                placeholder="Select entity"
                [compareWithChild]="compareObjects"
                [disabledChild]="false"
                [list]="institutionList"
                [required]="false"
            ></mx-input-field>
        </div>
    </form>
    <span class="text-primary text-sm">* Required Field For Submission</span>
</div>
<div class="flex justify-end p-6 border-t border-gray-light gap-2" *ngIf="!loading">
    <button type="button" mat-raised-button class="m-1" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">clear</mat-icon>
        <span class="text-sm font-bold">Cancel</span>
    </button>
    <button
        type="button"
        mat-raised-button
        class="m-1"
        [disabled]="isSaving || projectFormGroup.invalid"
        (click)="save()"
        color="accent"
    >
        <mat-icon class="text-sm mr-2 mt-1 font-bold">save</mat-icon>
        <span class="text-sm font-bold">Save</span>
    </button>
</div>
