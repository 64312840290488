import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationPermissionModel } from '../model/application-permission.model';
import { SERVER_API } from '../app.constant';

@Injectable({
    providedIn: 'root',
})
export class ApplicationPermissionService {
    public static readonly MASTER_CONFIGURATION_MENU = 'MASTER_CONFIGURATION_MENU';
    public static readonly REPORT_UPLOAD_MENU = 'REPORT_UPLOAD_MENU';
    public static readonly REPORT_DOWNLOAD_MENU = 'REPORT_DOWNLOAD_MENU';
    public static readonly REPORT_READ_RIO = 'REPORT_READ_RIO';
    public static readonly REPORT_CREATE_RIO = 'REPORT_CREATE_RIO';
    public static readonly REPORT_DOWNLOAD_RIO = 'REPORT_DOWNLOAD_RIO';
    public static readonly REPORT_READ = 'REPORT_READ';
    public static readonly REPORT_CREATE = 'REPORT_CREATE';
    public static readonly REPORT_DOWNLOAD = 'REPORT_DOWNLOAD';
    public static readonly DASHBOARD_ACTIVITY_READ = 'DASHBOARD_ACTIVITY_READ';
    public static readonly SYSTEM_CONFIGURATION_READ = 'SYSTEM_CONFIGURATION_READ';
    public static readonly SYSTEM_CONFIGURATION_CREATE = 'SYSTEM_CONFIGURATION_CREATE';
    public static readonly SYSTEM_CONFIGURATION_UPDATE = 'SYSTEM_CONFIGURATION_UPDATE';
    public static readonly PERMISSION_READ = 'PERMISSION_READ';
    public static readonly ENTITY_CREATE = 'ENTITY_CREATE';
    public static readonly ENTITY_READ = 'ENTITY_READ';
    // public static readonly ENTITY_DELETE = 'ENTITY_DELETE';
    public static readonly ENTITY_UPDATE = 'ENTITY_UPDATE';
    public static readonly COMPANY_CREATE = 'COMPANY_CREATE';
    public static readonly COMPANY_READ = 'COMPANY_READ';
    public static readonly COMPANY_DELETE = 'COMPANY_DELETE';
    public static readonly COMPANY_UPDATE = 'COMPANY_UPDATE';
    public static readonly USER_READ = 'USER_READ';
    public static readonly USER_CREATE = 'USER_CREATE';
    public static readonly USER_DELETE = 'USER_DELETE';
    public static readonly USER_UPDATE = 'USER_UPDATE';
    public static readonly ROLE_UPDATE = 'ROLE_UPDATE';
    public static readonly ROLE_DELETE = 'ROLE_DELETE';
    public static readonly ROLE_CREATE = 'ROLE_CREATE';
    public static readonly ROLE_READ = 'ROLE_READ';
    public static readonly COUNTRY_DELETE = 'COUNTRY_DELETE';
    public static readonly COUNTRY_CREATE = 'COUNTRY_CREATE';
    public static readonly COUNTRY_UPDATE = 'COUNTRY_UPDATE';
    public static readonly COUNTRY_READ = 'COUNTRY_READ';
    public static readonly EMPLOYMENT_TYPE_DELETE = 'EMPLOYMENT_TYPE_DELETE';
    public static readonly EMPLOYMENT_TYPE_CREATE = 'EMPLOYMENT_TYPE_CREATE';
    public static readonly EMPLOYMENT_TYPE_UPDATE = 'EMPLOYMENT_TYPE_UPDATE';
    public static readonly EMPLOYMENT_TYPE_READ = 'EMPLOYMENT_TYPE_READ';
    public static readonly PROJECT_DELETE = 'PROJECT_DELETE';
    public static readonly PROJECT_CREATE = 'PROJECT_CREATE';
    public static readonly PROJECT_UPDATE = 'PROJECT_UPDATE';
    public static readonly PROJECT_READ = 'PROJECT_READ';
    public static readonly GL_COST_CODE_DELETE = 'GL_COST_CODE_DELETE';
    public static readonly GL_COST_CODE_CREATE = 'GL_COST_CODE_CREATE';
    public static readonly GL_COST_CODE_UPDATE = 'GL_COST_CODE_UPDATE';
    public static readonly GL_COST_CODE_READ = 'GL_COST_CODE_READ';
    public static readonly FORM_TYPE_DELETE = 'FORM_TYPE_DELETE';
    public static readonly FORM_TYPE_CREATE = 'FORM_TYPE_CREATE';
    public static readonly FORM_TYPE_UPDATE = 'FORM_TYPE_UPDATE';
    public static readonly FORM_TYPE_READ = 'FORM_TYPE_READ';
    public static readonly SCHEDULER_CREATE = 'SCHEDULER_CREATE';
    public static readonly SCHEDULER_READ = 'SCHEDULER_READ';
    public static readonly SCHEDULER_UPDATE = 'SCHEDULER_UPDATE';
    public static readonly SCHEDULER_DELETE = 'SCHEDULER_DELETE';
    public static readonly SCHEDULER_PAUSE = 'SCHEDULER_PAUSE';
    public static readonly SCHEDULER_RESUME = 'SCHEDULER_RESUME';
    public static readonly SCHEDULER_RUN_AGGREGATION = 'SCHEDULER_RUN_AGGREGATION';
    public static readonly SCHEDULER_UNFREEZE_SUBMISSION = 'SCHEDULER_UNFREEZE_SUBMISSION';
    public static readonly SCHEDULER_INITIAL_CONSOLIDATION = 'SCHEDULER_INITIAL_CONSOLIDATION';
    public static readonly SCHEDULER_FINAL_CONSOLIDATION = 'SCHEDULER_FINAL_CONSOLIDATION';
    public static readonly SCHEDULER_SHIFT_DATES = 'SCHEDULER_SHIFT_DATES';
    public static readonly RIO_TEMPLATE_CREATE = 'RIO_TEMPLATE_CREATE';
    public static readonly RIO_TEMPLATE_READ = 'RIO_TEMPLATE_READ';
    public static readonly RIO_TEMPLATE_UPDATE = 'RIO_TEMPLATE_UPDATE';
    public static readonly RIO_TEMPLATE_DELETE = 'RIO_TEMPLATE_DELETE';

    private apiAuthority: string = SERVER_API + 'api/account-component/admin/permission';

    constructor(private httpClient: HttpClient) {}

    public getAll(permissionId: number | undefined): Observable<HttpResponse<ApplicationPermissionModel[]>> {
        let params = new HttpParams();
        if (permissionId !== undefined) {
            params = params.set('permissionId', permissionId);
        }
        return this.httpClient.get<ApplicationPermissionModel[]>(this.apiAuthority, {
            observe: 'response',
            params,
        });
    }

    public getAllNoPagination(): Observable<HttpResponse<ApplicationPermissionModel[]>> {
        return this.httpClient.get<ApplicationPermissionModel[]>(this.apiAuthority + '/no-pagination', {
            observe: 'response',
        });
    }
}
