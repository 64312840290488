<div [formGroup]="formGroupChild" class="relative">
    <label [for]="label">{{ label }}<span *ngIf="required" class="text-primary">*</span></label>
    <ngx-mat-intl-tel-input
        class="mx-phone-input"
        [preferredCountries]="['my']"
        [enableSearch]="true"
        [enablePlaceholder]="true"
        [name]="formControlNameChild"
        [describedBy]="formControlNameChild"
        [formControlName]="formControlNameChild"
        #phone
    >
    </ngx-mat-intl-tel-input>
    <p *ngIf="formGroupChild.get(formControlNameChild).invalid && formGroupChild.get(formControlNameChild).dirty"
        class="text-xs text-primary absolute">
        {{ errorMessage }}
    </p>
</div>
