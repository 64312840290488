import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ACCOUNT_COMPONENT } from '../app.constant';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { InstitutionModel } from '../model/institution.model';
import { AuthenticateRequestModel } from '../model/authenticate-request.model';
@Injectable({
    providedIn: 'root',
})
export class AuthenticateService {
    readonly USER_ITEM_ATTRIBUTE = 'user';
    readonly ACCESS_TOKEN = 'access_token';
    readonly USER_CURRENT_INSTITUTION_ATTRIBUTE = 'current_institution';
    readonly SKWY_SIDEMENU_ROUTER = 'skyw_sidemenu_router';

    private postLoginApi: string = ACCOUNT_COMPONENT + '/authenticate';
    body: any;
    user: BehaviorSubject<ApplicationUserAuthenticationResponse>;
    admin: string = 'ADMIN';
    requestUserBody: AuthenticateRequestModel;

    constructor(private httpClient: HttpClient, private router: Router) {
        this.user = new BehaviorSubject<any>(JSON.parse(localStorage.getItem(this.USER_ITEM_ATTRIBUTE)));
    }

    isAuthenticated(): boolean {
        return localStorage.getItem(this.ACCESS_TOKEN) ? true : false;
    }

    logout() {
        localStorage.removeItem(this.ACCESS_TOKEN);
        localStorage.removeItem(this.USER_ITEM_ATTRIBUTE);
        localStorage.removeItem(this.USER_CURRENT_INSTITUTION_ATTRIBUTE);
        localStorage.removeItem(this.SKWY_SIDEMENU_ROUTER)
        this.router.navigate(['login']).then();
    }

    isLoggedIn(): boolean {
        return this.user.value?.authenticated;
    }

    login(username: string, password: string, accessType: string) {
        const params = {
            loginType: this.admin,
            accessType: accessType,
        };
        const loginRawValue = {
            username: username,
            password: password,
        };
        return this.httpClient.post(this.postLoginApi, loginRawValue, { params: params });
    }

    getUser() {
        return JSON.parse(localStorage.getItem(this.USER_ITEM_ATTRIBUTE));
    }

    setUser(authenticationDetails: AuthenticateRequestModel): void {
        localStorage.setItem(this.USER_ITEM_ATTRIBUTE, JSON.stringify(authenticationDetails));
        this.user = new BehaviorSubject<any>(JSON.parse(localStorage.getItem(this.USER_ITEM_ATTRIBUTE)));
    }

    getUsername() {
        return JSON.parse(localStorage.getItem(this.USER_ITEM_ATTRIBUTE)).username;
    }

    getEmail() {
        return JSON.parse(localStorage.getItem(this.USER_ITEM_ATTRIBUTE)).email;
    }

    getFirstCurIns() {
        return JSON.parse(localStorage.getItem(this.USER_ITEM_ATTRIBUTE)).institutions[0];
    }

    getCurInsId() {
        return JSON.parse(localStorage.getItem(this.USER_CURRENT_INSTITUTION_ATTRIBUTE)).id;
    }

    getCurIns() {
        return JSON.parse(localStorage.getItem(this.USER_CURRENT_INSTITUTION_ATTRIBUTE));
    }

    setCurIns(curIns: InstitutionModel): void {
        localStorage.setItem(this.USER_CURRENT_INSTITUTION_ATTRIBUTE, JSON.stringify(curIns));
    }

    hasAuthorityMenu(screenAuthority: any): boolean {
        if (!this.isLoggedIn()) {
            return false;
        }
        for (const authority of this.user.value.authorities) {
            if (screenAuthority instanceof Array) {
                if (screenAuthority.includes(authority.authority)) {
                    return true;
                }
            } else {
                if (screenAuthority === authority.authority) {
                    return true;
                }
            }
        }
        return false;
    }

    getAuthorities() {
        return this.user.value?.authorities;
    }

    getUniquePermission() {
        const authorities = this.getAuthorities()
        const currIns = this.getCurInsId()
        const authoritiesUnique = []

        for (let authority of authorities) {
            if (authority.institution.id === currIns) {
                for (let permission of authority.permissions) {
                    const permissionExist = authoritiesUnique.find((authUnique) => authUnique.code === permission.code)
                    if (!permissionExist) {
                        authoritiesUnique.push(permission)
                    }
                }
            }
        }

        return authoritiesUnique
    }
}

interface ApplicationUserAuthenticationResponse {
    principal?: string;
    username: string;
    password: string;
    authorities: Authority[];
    institutions: InstitutionModel[];
    authenticated: boolean;
    token: string;
    error: boolean;
    httpErrorResponse: HttpErrorResponse;
}

export interface Authority {
    authority: string;
    permissions: any;
    institution: any;
}

export interface PINCodeDetails {
    username: string;
    password: string;
    pinCode: string;
}
