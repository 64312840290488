import { Component,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserModel } from 'src/app/model/user.model';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';

@Component({
    selector: 'app-user-dialog-detail',
    templateUrl: './user-dialog-detail.component.html',
})
export class UserDialogDetailComponent{
    readonly UPDATE_APPLICATION_USER = ApplicationPermissionService.USER_UPDATE;

    constructor(
        @Inject(MAT_DIALOG_DATA) public userModel: UserModel,
        public dialogRef: MatDialogRef<UserDialogDetailComponent>
    ) {}
}
