import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { RioTemplateTypesDialogDetailComponent } from './rio-template-types-dialog-detail/rio-template-types-dialog-detail.component';
import { RioTemplateTypesDialogNewOrEditComponent } from './rio-template-types-dialog-new-or-edit/rio-template-types-dialog-new-or-edit.component';
import { RioTemplateTypesListingComponent } from './rio-template-types-listing/rio-template-types-listing.component';

const routes: Routes = [
    {
        path: '',
        component: RioTemplateTypesListingComponent,
    },
];

@NgModule({
    declarations: [
        RioTemplateTypesListingComponent,
        RioTemplateTypesDialogNewOrEditComponent,
        RioTemplateTypesDialogDetailComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule.forChild(routes),
        FormsModule,
        ReactiveFormsModule
    ]
})
export class RioTemplateTypesModule { }
