import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { FORM_TEMPLATE_FIELD_INPUT_TYPE } from 'src/app/model/form-template-field.model';
import { InstitutionUploadListingModel } from 'src/app/model/institution.model';
import { ReportUploadModel } from 'src/app/model/report-upload.model';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';
import { GeneralService } from 'src/app/services/general.service';
import { InstitutionService } from 'src/app/services/institution.service';
import { ReportUploadRioDialogDetailComponent } from '../report-upload-rio-dialog-detail/report-upload-rio-dialog-detail.component';
import { ReportUploadRioDialogUploadComponent } from '../report-upload-rio-dialog-upload/report-upload-rio-dialog-upload.component';
import { MONTH_LIST_SHORT } from 'src/app/components/shared/constant/datepicker-format.constant';
import { ProjectService } from 'src/app/services/project.service';
import { HttpResponse } from '@angular/common/http';
import { CompanyService } from 'src/app/services/company.service';
import { DropDownListingResponse } from 'src/app/model/dropdown-listing-response';
import { RioReportDetailService } from 'src/app/services/rio-report-detail.service';
import {RioReportHeaderService} from "../../../../services/rio-report-header.service";
import {DialogSimpleComponent} from "../../../shared/ui/dialog-simple/dialog-simple.component";
import {SystemConfigurationService} from "../../../../services/system-configuration.service";

@Component({
  selector: 'app-report-upload-rio-listing',
  templateUrl: './report-upload-rio-listing.component.html',
})
export class ReportUploadRioListingComponent implements OnInit {

    readonly REPORT_CREATE_RIO = ApplicationPermissionService.REPORT_CREATE_RIO;
    readonly REPORT_READ_RIO = ApplicationPermissionService.REPORT_READ_RIO;

    dataSource = new MatTableDataSource<any>();
    obs: BehaviorSubject<any>;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayColumns = [
        'reportingYear',
        'reportingMonth',
        'projectName',
        'companyName',
        'status',
        'actions'
    ];

    failColorCode: string = '#f54c40';
    searchValue: String = null;
    pageNumbers: number = 0;
    numberOfRecords: number = 0;
    currentPage:number = 0;
    pageSize:number = 50;
    sortOrder = 'createdDate,desc';
    loading : boolean = false;
    isLoading = FORM_TEMPLATE_FIELD_INPUT_TYPE.LOADING;

    status: string;
    selectedYear: string = '';
    currentYear: string = '';
    selectedMonth: string = 'All';
    currentMonth: string = 'All';
    selectedCompany: DropDownListingResponse = {id: '', name: 'All'};
    selectedProject: DropDownListingResponse = {id: '', name: 'All'};
    reportingYearList: string[];
    reportingMonthList: string[] = ['All', ...MONTH_LIST_SHORT];
    reportingCompanyList: DropDownListingResponse[] = []
    reportingProjectList: DropDownListingResponse[] = []
    institutionTabs: InstitutionUploadListingModel[];
    selectedInstitutionTab: InstitutionUploadListingModel;

    constructor(
        public activatedRoute: ActivatedRoute,
        private matDialog: MatDialog,
        private generalService : GeneralService,
        private rioReportHeaderService : RioReportHeaderService,
        private rioReportDetailService: RioReportDetailService,
        private institutionService: InstitutionService,
        private projectService: ProjectService,
        private companyService: CompanyService,
        private systemConfigurationService: SystemConfigurationService
    ) { }

    ngOnInit(): void {
        this.activeLoading();
        if (this.activatedRoute.snapshot.queryParams['page']) {
            this.sortOrder = this.activatedRoute.snapshot.queryParams['sort'] ? this.activatedRoute.snapshot.queryParams['sort']: undefined;
            this.currentPage = this.activatedRoute.snapshot.queryParams['page'] ? this.activatedRoute.snapshot.queryParams['page']: undefined;
            this.pageSize = this.activatedRoute.snapshot.queryParams['pageSize'] ? this.activatedRoute.snapshot.queryParams['pageSize']: undefined;
        }

        this.systemConfigurationService.getCurrentRioFinancialYearMonth().subscribe((res) => {
            const parts = res.body.split('-'); // Split the string by '-'
            if (parts.length === 2) { // Ensure there are two parts
                this.selectedMonth = parts[0]; // Assign the first part as the selected month
                this.selectedYear = parts[1]; // Assign the second part as the selected year

                this.currentMonth = parts[0];
                this.currentYear = parts[1];
            } else {
                // Handle unexpected format
                console.error("Unexpected format for the financial year and month.");
            }

            this.rioReportHeaderService.getReportingYearList().subscribe((res) => {
                this.reportingYearList = res.body.sort((a: string, b: string) => +b - +a);
                this.refreshForm()
            });
        });

        this.institutionService.getInstitutionUploadListByParentId().subscribe((res) => {
            this.institutionTabs = res.body;
            this.selectedInstitutionTab = this.institutionTabs?.[0];
        });

        this.companyService.getAllCompanyDropdownList().subscribe({
            next: (response: HttpResponse<DropDownListingResponse[]>) => {
                this.reportingCompanyList = [
                    {id: '', name: 'All'},
                    ...response.body
                ]
            }
        })
        this.projectService.getAllProjectDropdownList("RIO").subscribe({
            next: (response: HttpResponse<DropDownListingResponse[]>) => {
                this.reportingProjectList = [
                    {id: '', name: 'All'},
                    ...response.body
                ]
            }
        })
    }

    search(currentPage: number, searchValue: String){
        this.activeLoading()
        this.searchValue = searchValue;
        this.rioReportDetailService.getReportUploadList(
            this.selectedYear,
            this.selectedMonth === 'All' ? '' : this.selectedMonth,
            this.selectedCompany.id,
            this.selectedProject.id,
            this.currentPage,
            this.pageSize,
            this.sortOrder,
            this.selectedInstitutionTab?.institutionId,
            {
                name: searchValue,
            }
        ).subscribe({
            next: ({ body }) => {
                this.dataSource.data = body.content;
                this.numberOfRecords = body.totalElements;
                this.deactiveLoading();

                if (this.currentPage === 0) {
                    this.paginator.firstPage();
                } else {
                    this.paginator.pageIndex = this.currentPage;
                }

            },
            error: (error) => {
                this.generalService.snackbarMessage(error.message, 'Close');
                this.deactiveLoading();
            },
        });
    }

    refreshForm(){
        this.search(0, null);
    }

    sortPage(event): void {
        if (event.active === 'reportingYear') {
            this.sortOrder = `rh.reportingYear,${event.direction}`;
        } else if (event.active === 'reportingMonth') {
            this.sortOrder = `rh.reportingMonth,${event.direction}`;
        } else {
            this.sortOrder = `${event.active},${event.direction}`;
        }
        this.search(this.currentPage, this.searchValue);
    }

    navigateToPage(event): void {
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.search(this.currentPage, this.searchValue);
    }

    activeLoading(){
        this.loading = true;
    }

    deactiveLoading(){
        this.loading = false;
    }

    openReportUploadDialogDetail(reportUploadModel? : ReportUploadModel){
        const modalRef = this.matDialog.open(ReportUploadRioDialogDetailComponent, {
            data: reportUploadModel,
            panelClass: 'mat-dialog-md'
        });

        modalRef.afterClosed().subscribe({
            next: (edit) => {
                if (edit) this.openReportUploadDialogUpload(reportUploadModel);
            }
        })
    }

    openReportUploadDialogUpload(reportUploadModel? : ReportUploadModel){
        if (reportUploadModel.status === 'EXTRACTED') {
            const modalRef = this.matDialog.open(DialogSimpleComponent, {
                data: {
                    title: 'Confirm Overwrite ',
                    message: `Are you sure you want to overwrite this report?`,
                    buttonPositive: 'Yes',
                    buttonPositiveColor: 'warn',
                    buttonNegative: 'No',
                },
                panelClass: 'mat-dialog-sm',
                id: 'overwrite-report',
            });
            modalRef.afterClosed().subscribe(yes => {
                if (yes) {
                    this.openUploadDialog(reportUploadModel);
                }
            });
        } else {
            // Status is not 'EXTRACTED', directly open the upload dialog
            this.openUploadDialog(reportUploadModel);
        }
    }

    openUploadDialog(reportUploadModel: ReportUploadModel) {
        const modalRef2 = this.matDialog.open(ReportUploadRioDialogUploadComponent, {
            data: reportUploadModel,
            panelClass: 'mat-dialog-lg'
        });


        modalRef2.afterClosed().subscribe(yes => {
            if (yes) {
                this.search(this.currentPage, this.searchValue);
            }

        }, error => {
            this.generalService.snackbarMessage(error.message, 'Close');
        });
    }

    openReportDeleteConfirmationDialog(reportUploadModel?: ReportUploadModel) {
        const modalRef = this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: 'Confirm Delete ',
                message: `Are you sure want to delete this submission?`,
                buttonPositive: 'Yes',
                buttonPositiveColor: 'warn',
                buttonNegative: 'No',
            },
            panelClass: 'mat-dialog-sm',
            id: 'delete-report',
        });

        modalRef.afterClosed().subscribe({
            next: (yes) => {
                if (yes) {
                    this.rioReportDetailService.delete(reportUploadModel.reportDetailId).subscribe({
                        next: () => {
                            this.activeLoading();
                            this.search(this.currentPage, this.searchValue)
                        },
                        error: error => {
                            this.generalService.snackbarMessage(error.message, 'Close');
                        }
                    });
                }
            },
        });
    }

    toggleYearSelection(year: string) {
        this.selectedYear = year;
    }

    toggleMonthSelection(month: string) {
        this.selectedMonth = month;
    }

    toggleCompanySelection(company: DropDownListingResponse) {
        this.selectedCompany = company
    }

    toggleProjectSelection(project: DropDownListingResponse) {
        this.selectedProject = project
    }

    onSelectedInstitutionTab(institutionUploadListingModel: InstitutionUploadListingModel) {
        this.selectedInstitutionTab = institutionUploadListingModel;
        if (this.selectedYear) {
            this.refreshForm();
        }
    }

    resetFilter() {
        this.selectedCompany = {id: '', name: 'All'};
        this.selectedProject = {id: '', name: 'All'};
        this.selectedYear = this.currentYear;
        this.selectedMonth = this.currentMonth;
        this.refreshForm()
    }

    downloadReportDetail(reportUploadModel? : ReportUploadModel) {

        this.rioReportDetailService.downloadReportDetails(reportUploadModel.reportDetailId).subscribe({
            next: (res: any) => {
                // Extract filename from Content-Disposition header
                const contentDispositionHeader = res.headers.get('content-disposition');
                const filename = contentDispositionHeader.split(';')[1].trim().split('=')[1];

                const blob = new Blob([res.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                // Create a URL for the Blob
                const url = URL.createObjectURL(blob);

                // Use the URL to download the Excel file or perform any other action
                // For example, create a link and simulate a click to trigger the download
                const link = document.createElement('a');
                link.href = url;

                link.download = filename;

                link.click();
            },
            error: (error) => {
                this.generalService.snackbarMessage(error.debugMessage, 'Close');
            },
        });
    }

    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

    bgBasedOnTier(tier: number): string {
        if (tier === 0) {
            return '#111C44'
        } else if (tier === 1) {
            return '#424154'
        } else if (tier === 2) {
            return '#726663'
        } else {
            return '#D2AF82'

        }
    }
}
