<form [formGroup]="searchFormGroup" (ngSubmit)="onSearchSubmit()" class="my-3">
    <div class="px-3 py-2 border rounded-md bg-white">
        <div class="flex">
            <input
                matInput
                id="username"
                formControlName="searchName"
                [placeholder]="'Search ' + (searchFieldName ? searchFieldName : '')"
            />
            <mat-icon
                class="mr-2 close cursor-pointer"
                *ngIf="searchFormGroup?.value?.searchName?.length > 0"
                (click)="refreshForm()"
                >close</mat-icon
            >
            <mat-icon class="mr-2 search cursor-pointer" (click)="onSearchSubmit()">search</mat-icon>
        </div>
    </div>
</form>
