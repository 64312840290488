import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import { Subject } from 'rxjs';
import { FilterDates } from 'src/app/model/filter-dates.model';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { DatePipe } from '@angular/common';
import { AppDownloadResponse, ModuleResponse } from 'src/app/model/dashboardChartResponse.model';
import { ModuleRecord } from 'src/app/model/moduleRecord.model';

@Component({
    selector: 'mx-pie-chart',
    templateUrl: './mx-pie-chart.component.html',
})
export class MxPieChartComponent implements OnInit, AfterViewInit {
    
    readonly DASHBOARD_ACTIVITY_READ = ApplicationPermissionService.DASHBOARD_ACTIVITY_READ;
    @Input() public appDownloadFilterDates: Subject<FilterDates>;

    appDownloadRoot: am5.Root;
    appDownloadChart: am5percent.PieChart;
    appDownloadLegend: am5.Legend;
    appDownloadSeries: am5percent.PieSeries;

    appDownloadData = [];

    install : string = 'User Installed';
    uninstall : string = 'User Uninstall';

    constructor(
        private dashboardService: DashboardService,
        private datePipe: DatePipe
    ) {}

    ngOnInit(): void {
        this.appDownloadFilterDates.subscribe((appDownloadFilterDates) => {
            this.requestAppDownloadData(appDownloadFilterDates);
        });
    }

    requestAppDownloadData(appDownloadFilterDates: FilterDates) {
        this.dashboardService
            .getAppDownload(
                appDownloadFilterDates.dateType,
                this.datePipe.transform(appDownloadFilterDates.dateFrom, 'yyyy-MM-dd', '+800'),
                this.datePipe.transform(appDownloadFilterDates.dateTo, 'yyyy-MM-dd', '+800'),
                this.datePipe.transform(appDownloadFilterDates.yearMonth, 'yyyy-MM', '+800')
            )
            .subscribe({
                next: (value) => {
                    this.appDownloadData = this.processAppDownloadResponse(value.body);
                    this.appDownloadSeries.data.setAll(this.appDownloadData);
                    this.appDownloadLegend.data.setAll(this.appDownloadSeries.dataItems);
                },
            });
    }

    ngAfterViewInit() {
        // Create root and this.chart
        this.appDownloadRoot = am5.Root.new('appDownloadChart');
        this.appDownloadChart = this.appDownloadRoot.container.children.push(
            am5percent.PieChart.new(this.appDownloadRoot, {
                layout: this.appDownloadRoot.horizontalLayout,
            })
        );

        let chart: am5percent.PieChart;
        chart = this.appDownloadChart;

        // Create this.series
        this.appDownloadSeries = this.appDownloadChart.series.push(
            am5percent.PieSeries.new(this.appDownloadRoot, {
                name: 'Series',
                valueField: 'count',
                categoryField: 'category',
                tooltipText: 'count',
            })
        );

        this.appDownloadSeries.labels.template.setAll({
            fontSize: 20,
            fill: am5.color(0x550000),
            text: '{category}',
        });

        this.appDownloadSeries.slices.template.set('tooltipText', 'Total Count : {value}');

        this.appDownloadSeries.slices.template.adapters.add('fill', function (fill, target) {
            let item: any;
            item = target.dataItem.dataContext;
            if (item.category === 'User Installed') {
                return am5.color(0xEC1F27);
            } else if (item.category === 'User Uninstall') {
                return am5.color(0xDC8588);
            }
        });

        this.appDownloadSeries.slices.template.adapters.add('stroke', function (stroke, target) {
            let item: any;
            item = target.dataItem.dataContext;
            if (item.category === 'User Installed') {
                return am5.color(0xEC1F27);
            } else if (item.category === 'User Uninstall') {
                return am5.color(0xDC8588);
            }
        });

        // Add legend
        this.appDownloadLegend = this.appDownloadChart.children.push(
            am5.Legend.new(this.appDownloadRoot, {
                centerY: am5.p50,
                y: am5.p50,
                centerX: null,
                x: null,
                layout: this.appDownloadRoot.verticalLayout,
                verticalScrollbar: am5.Scrollbar.new(this.appDownloadRoot, {
                    orientation: 'vertical',
                }),
            })
        );

    }

    private processAppDownloadResponse(data: AppDownloadResponse): ModuleRecord[] {
        let moduleRecordList: ModuleRecord[] = [];
        let installModule: ModuleRecord = { category: this.install, count: data.installCount };
        moduleRecordList.push(installModule);
        let uninstallModule: ModuleRecord = { category: this.uninstall, count: data.uninstallCount };
        moduleRecordList.push(uninstallModule);
        return moduleRecordList;
    }

}
