import { RouterModule, Routes } from "@angular/router";
import { SchedulerListingComponent } from "./scheduler-listing/scheduler-listing.component";
import { NgModule } from "@angular/core";
import { SchedulerNewOrEditComponent } from "./scheduler-new-or-edit/scheduler-new-or-edit.component";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../../shared/shared.module";

const routes: Routes = [
    {
        path: '',
        component: SchedulerListingComponent,
    },
];
@NgModule({
    declarations: [SchedulerNewOrEditComponent,SchedulerListingComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(routes), FormsModule, ReactiveFormsModule],
})
export class SchedulerModule {}
