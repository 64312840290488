import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticateService } from 'src/app/auth/authenticate.service';

@Directive({
    selector: '[appScreenPermission]',
})
export class ScreenPermissionDirective {
    constructor(
        private authService: AuthenticateService,
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef
    ) {}

    @Input()
    set appScreenPermission(permissionName: string[]) {
        this.viewContainerRef.clear();
        const currIns = this.authService.getCurInsId();
        const authorities = this.authService.getAuthorities();
        const authoritiesUnique = []

        // If no permission set, show container to all user.
        // Remove this, if want to hide container when no permission set.
        if (permissionName.length === 0) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }

        // Combine Authority: Let say have more than 1 Authority for same Entity, 
        // both authority have same permission let say USER_READ, 
        // prevent it from showint 2 User page
        for (let authority of authorities) {
            if (authority.institution.id === currIns) {
                // find either permission already added 
                for (let permission of authority.permissions) {
                    const permissionExist = authoritiesUnique.find((authUnique) => authUnique.code === permission.code)
                    // Push to uniquePermission if not exist yet
                    if (!permissionExist) {
                        authoritiesUnique.push(permission)
                    }
                }
            }
        }

        permissionName.forEach((p) => {
            if (authoritiesUnique.some((a) => a.code === p)) {
                this.viewContainerRef.createEmbeddedView(this.templateRef);
            }
        });
    }
}
