import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { Subject } from 'rxjs';
import { FilterDates } from 'src/app/model/filter-dates.model';
import { DashboardService } from 'src/app/services/dashboard.service';
import { DatePipe } from '@angular/common';
import { ModuleRecord } from 'src/app/model/moduleRecord.model';

@Component({
    selector: 'active-user-chart',
    templateUrl: './active-user-chart.component.html',
})
export class ActiveUserChartComponent implements OnInit, AfterViewInit {

    @Input() public activeUserFilterDates: Subject<FilterDates>;
    activeUserData = [];

    daily = 'DAILY';
    weekly = 'WEEKLY';
    monthly = 'MONTHLY';
    activeUserRoot: am5.Root;
    activeUserChart: am5xy.XYChart;
    activeUserLegend: am5.Legend;
    activeUserSeries: am5xy.ColumnSeries;

    yAxis: am5xy.ValueAxis<am5xy.AxisRendererY>;
    xAxis: am5xy.CategoryAxis<am5xy.AxisRendererX>;

    constructor(private dashboardService: DashboardService, private datePipe: DatePipe) {}

    ngOnInit(): void {
        this.activeUserFilterDates.subscribe((activeUserFilterDates) => {
            this.requestActiveUserData(activeUserFilterDates);
        });
    }

    requestActiveUserData(activeUserFilterDates: FilterDates) {
        this.dashboardService
            .getActiveUser(
                activeUserFilterDates.dateType,
                this.datePipe.transform(activeUserFilterDates.dateFrom, 'yyyy-MM-dd', '+800'),
                this.datePipe.transform(activeUserFilterDates.dateTo, 'yyyy-MM-dd', '+800'),
                this.datePipe.transform(activeUserFilterDates.yearMonth, 'yyyy-MM', '+800')
            )
            .subscribe({
                next: (value) => {
                    this.activeUserData = this.processActiveUserResponse(value.body, activeUserFilterDates.dateType);
                    this.xAxis.data.setAll(this.activeUserData);
                    this.activeUserSeries.data.setAll(this.activeUserData);
                    this.activeUserLegend.data.setAll(this.activeUserChart.series.values);
                },
            });
    }

    ngAfterViewInit(): void {
        this.activeUserRoot = am5.Root.new('chartActiveUser');

        this.activeUserRoot.setThemes([am5themes_Animated.new(this.activeUserRoot)]);

        this.activeUserChart = this.activeUserRoot.container.children.push(
            am5xy.XYChart.new(this.activeUserRoot, {
                panX: true,
                panY: true,
                wheelX: 'panX',
                wheelY: 'zoomX',
                pinchZoomX: true,
                layout: this.activeUserRoot.horizontalLayout,
                paddingBottom: 30,
            })
        );

        this.xAxis = this.activeUserChart.xAxes.push(
            am5xy.CategoryAxis.new(this.activeUserRoot, {
                maxDeviation: 0.3,
                categoryField: 'category',
                renderer: am5xy.AxisRendererX.new(this.activeUserRoot, {
                    minGridDistance: 30,
                }),
            })
        );

        this.xAxis.get('renderer').labels.template.setAll({
            textAlign: 'center',
        });

        this.yAxis = this.activeUserChart.yAxes.push(
            am5xy.ValueAxis.new(this.activeUserRoot, {
                min: 0,
                maxDeviation: 0,
                renderer: am5xy.AxisRendererY.new(this.activeUserRoot, { inside: false }),
            })
        );

        this.activeUserSeries = this.activeUserChart.series.push(
            am5xy.ColumnSeries.new(this.activeUserRoot, {
                name: 'User count that open the app',
                xAxis: this.xAxis,
                yAxis: this.yAxis,
                valueYField: 'count',
                categoryXField: 'category',
                tooltip: am5.Tooltip.new(this.activeUserRoot, {
                    pointerOrientation: 'vertical',
                    labelText: 'Total Count: {valueY}',
                }),
                fill: am5.color(0xf09397),
            })
        );

        this.activeUserSeries.columns.template.setAll({
            cornerRadiusTL: 5,
            cornerRadiusTR: 5,
            tooltipText: "Total Count: {valueYWorking.formatNumber('#.')}",
            tooltipY: 0,
        });

        this.activeUserSeries.columns.template.setAll({
            width: 80,
        });

        this.activeUserLegend = this.activeUserChart.children.push(
            am5.Legend.new(this.activeUserRoot, {
                centerY: am5.p50,
                y: am5.p50,
                centerX: am5.p0,
                x: am5.percent(80),
                layout: this.activeUserRoot.verticalLayout,
                verticalScrollbar: am5.Scrollbar.new(this.activeUserRoot, {
                    orientation: 'vertical',
                }),
            })
        );

        this.xAxis.data.setAll(this.activeUserData);
        this.activeUserSeries.data.setAll(this.activeUserData);

        // Make stuff animate on load
        this.activeUserSeries.appear(1000);
        this.activeUserChart.appear(1000, 100);
    }

    private processActiveUserResponse(data: any, dataType: string): ModuleRecord[] {
        let activeUserRecordList: ModuleRecord[] = [];
        if(dataType === this.daily){
            data.forEach((element) => {
                const date = element.dateFrom.day + '\n' + element.dateFrom.formatted;
                let dataRecord: ModuleRecord = {
                    category: date,
                    count: element.activeUserCount,
                };
                activeUserRecordList.push(dataRecord);
            });
            return activeUserRecordList;
        }else if(dataType === this.weekly){
            data.forEach((element) => {
                const week = element.dateFrom.formatted + '-\n' + element.dateTo.formatted;
                let dataRecord: ModuleRecord = {
                    category: week,
                    count: element.activeUserCount,
                };
                activeUserRecordList.push(dataRecord);
            });
            return activeUserRecordList;

        }else if(dataType === this.monthly){
            data.forEach((element) => {
                let dataRecord: ModuleRecord = {
                    category: element.yearMonth.formatted,
                    count: element.activeUserCount,
                };
                activeUserRecordList.push(dataRecord);
            });
            return activeUserRecordList;
        }
    }
}
