<div class="flex flex-wrap">
	<div class="flex-1 m-0.5">
		<mat-form-field appearance="outline" class="mr-2">
			<mat-label>Date Type</mat-label>
			<mat-select (selectionChange)="toggleDateType($event)" [formControl]="dateTypeFormControl">
				<mat-option *ngFor="let dateType of dateTypes" [value]="dateType.code">
					{{
					dateType.code }}</mat-option>
			</mat-select>
		</mat-form-field>

		<ng-container *ngIf="this.mode ==='DAILY'">
			<multi-date-picker mode="DAY"  [max]="today" (onDailyDateChanged)="dailyDateChanged($event)"></multi-date-picker>
		</ng-container>

		<ng-container *ngIf="this.mode ==='WEEKLY'">
			<multi-date-picker mode="WEEK"  [max]="today" (onWeeklyDateChanged)="weeklyDateChanged($event)"></multi-date-picker>
		</ng-container>

		<ng-container *ngIf="this.mode ==='MONTHLY'">
			<multi-date-picker mode="MONTH"  [max]="today" (onMonthlyDateChanged)="monthlyDateChanged($event)"></multi-date-picker>
		</ng-container>
	</div>
</div>