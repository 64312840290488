<section class="flex items-center h-screen p-16">
    <div class="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div class="max-w-xl text-center">
            <img src="../../../../assets/not_found_illustration.svg" class="h-2/3 w-2/3 inline" alt="page not found" />
            <div class="mt-4 mb-4 md:mb-8 font-extrabold text-3xl md:text-7xl">
                <span class="sr-only">Error</span>Page Not Found
            </div>
            <div class="text-lg md:text-3xl font-semibold">Sorry, we couldn't find this page.</div>
            <div class="mt-4 mb-8">
                {{
                    isAuthenticated()
                        ? "But don't worry, you can find plenty of other things on our homepage."
                        : 'Try searching again or click button below to go to Login page.'
                }}
            </div>
            <button class="px-8 py-3 font-semibold rounded bg-accent hover:z-10 hover:shadow-md" (click)="goBack()">
                Back to {{ isAuthenticated() ? 'Previous Page' : 'Login' }}
            </button>
        </div>
    </div>
</section>
