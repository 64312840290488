<div class="flex justify-between items-center px-6 py-4 border-b border-b-gray-light">
    <div class="text-lg font-bold">View Form Type Configuration '{{ formTypeConfigurationModel.code }}'</div>
    <button type="button" data-dismiss="modal" class="text-2xl" (click)="dialogRef.close()">&times;</button>
</div>
<div class="max-h-[70vh] overflow-auto p-6 relative whitespace-pre-line">
    <div class="grid grid-cols-2 gap-4">
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Code</label>
            <div class="mb-6 break-all" maxlength="100">
                {{ formTypeConfigurationModel.code || '-' }}
            </div>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Required Aggregation</label>
            <br/>
            <mat-checkbox [checked]="formTypeConfigurationModel.requireAggregation" disabled="true"></mat-checkbox>
        </div>
        <div class="col-span-2 md:col-span-1">
            <label class="text-sm text-gray font-bold">Description</label>
            <div class="mb-6 break-all">
                {{ formTypeConfigurationModel.description || '-' }}
            </div>
        </div>
        
    </div>
</div>
<div class="flex justify-end p-6 border-t border-gray-light gap-2">
    <button type="button" mat-raised-button class="m-1" data-dismiss="modal" (click)="dialogRef.close()">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">clear</mat-icon>
        <span class="text-sm font-bold">Close</span>
    </button>

    <button type="button" mat-raised-button class="m-1" (click)="dialogRef.close('edit')" color="accent" *appScreenPermission="[FORM_TYPE_UPDATE]">
        <mat-icon class="text-sm mr-2 mt-1 font-bold">edit</mat-icon>
        <span class="text-sm font-bold">Edit</span>
    </button>
</div>