import {  Component, EventEmitter, OnInit,  Input,  Output } from '@angular/core';
@Component({
    selector: 'multi-date-picker',
    templateUrl: './multi-date-picker.component.html',
})
export class MultiDatePickerComponent{
    @Input() mode: 'YEAR' | 'WEEK' | 'DAY' | 'MONTH';
    @Input() max: Date;
    @Input() min: Date;
    
    @Output() onDailyDateChanged = new EventEmitter<Date>();
    @Output() onMonthlyDateChanged = new EventEmitter<Date>();
    @Output() onWeeklyDateChanged = new EventEmitter<any>();

    dailyDateChanged($event: Date) {
        this.onDailyDateChanged.emit($event);
    }

    monthlyDateChanged($event: Date) {
        this.onMonthlyDateChanged.emit($event);
    }

    weeklyDateChanged($event){
        this.onWeeklyDateChanged.emit($event);
    }
}
