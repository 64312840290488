import { Component, AfterViewInit, Inject, NgZone, PLATFORM_ID, Input, OnInit } from '@angular/core';
import { DatePipe, isPlatformBrowser } from '@angular/common';

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { Subject } from 'rxjs';
import { FilterDates } from 'src/app/model/filter-dates.model';
import { ApplicationPermissionService } from 'src/app/services/application-permission.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { ModuleRecord } from 'src/app/model/moduleRecord.model';

@Component({
    selector: 'app-time-spent-chart',
    templateUrl: './app-time-spent-chart.component.html',
})
export class AppTimeSpentChartComponent implements OnInit, AfterViewInit {
    readonly DASHBOARD_ACTIVITY_READ = ApplicationPermissionService.DASHBOARD_ACTIVITY_READ;

    @Input() public appTimeSpentFilterDates: Subject<FilterDates>;

    appTimeSpentData = [];

    DAILY = 'DAILY';
    WEEKLY = 'WEEKLY';
    MONTHLY = 'MONTHLY';
    appTimeSpentRoot: am5.Root;
    appTimeSpentSeries: am5xy.LineSeries;
    appTimeSpentCursor: am5xy.XYCursor;
    appTimeSpentChart: am5xy.XYChart;
    appTimeSpentLegend: am5.Legend;

    yAxis: am5xy.ValueAxis<am5xy.AxisRendererY>;
    xAxis: am5xy.CategoryAxis<am5xy.AxisRendererX>;

    constructor(private dashboardService: DashboardService, private datePipe: DatePipe) {}

    ngOnInit(): void {
        this.appTimeSpentFilterDates.subscribe((appTimeSpentFilterDates) => {
            this.requestAppTimeSpentData(appTimeSpentFilterDates);
        });
    }

    requestAppTimeSpentData(appTimeSpentFilterDates: FilterDates) {
        this.dashboardService
            .getAppTimeSpent(
                appTimeSpentFilterDates.dateType,
                this.datePipe.transform(appTimeSpentFilterDates.dateFrom, 'yyyy-MM-dd', '+800'),
                this.datePipe.transform(appTimeSpentFilterDates.dateTo, 'yyyy-MM-dd', '+800'),
                this.datePipe.transform(appTimeSpentFilterDates.yearMonth, 'yyyy-MM', '+800')
            )
            .subscribe({
                next: (value) => {
                    this.appTimeSpentData = this.processAppTimeSpentResponse(
                        value.body,
                        appTimeSpentFilterDates.dateType
                    );
                    this.xAxis.data.setAll(this.appTimeSpentData);
                    this.appTimeSpentSeries.data.setAll(this.appTimeSpentData);
                    this.appTimeSpentLegend.data.setAll(this.appTimeSpentChart.series.values);
                },
            });
    }

    ngAfterViewInit() {
        this.appTimeSpentRoot = am5.Root.new('chartAppTimeSpent');

        this.appTimeSpentRoot.setThemes([am5themes_Animated.new(this.appTimeSpentRoot)]);

        this.appTimeSpentChart = this.appTimeSpentRoot.container.children.push(
            am5xy.XYChart.new(this.appTimeSpentRoot, {
                panX: false,
                panY: false,
                wheelX: 'panX',
                wheelY: 'zoomX',
                pinchZoomX: true,
                layout: this.appTimeSpentRoot.horizontalLayout,
                paddingBottom: 30,
            })
        );

        // Create axes
        this.xAxis = this.appTimeSpentChart.xAxes.push(
            am5xy.CategoryAxis.new(this.appTimeSpentRoot, {
                maxDeviation: 0.3,
                categoryField: 'category',
                renderer: am5xy.AxisRendererX.new(this.appTimeSpentRoot, {
                    minGridDistance: 50,
                    strokeOpacity: 0.2,
                }),
            })
        );

        this.xAxis.get('renderer').labels.template.setAll({
            textAlign: 'center',
        });
        
        this.yAxis = this.appTimeSpentChart.yAxes.push(
            am5xy.ValueAxis.new(this.appTimeSpentRoot, {
                min: 0,
                maxDeviation: 0,
                renderer: am5xy.AxisRendererY.new(this.appTimeSpentRoot, { inside: false }),
                numberFormat: '#.#  hour',
            })
        );

        this.xAxis.set(
            'tooltip',
            am5.Tooltip.new(this.appTimeSpentRoot, {
                themeTags: ['axis'],
            })
        );

        // Axes Tooltips
        this.yAxis.set(
            'tooltip',
            am5.Tooltip.new(this.appTimeSpentRoot, {
                themeTags: ['axis'],
            })
        );

        // Add cursor
        this.appTimeSpentChart.set(
            'cursor',
            am5xy.XYCursor.new(this.appTimeSpentRoot, {
                behavior: 'zoomXY',
                xAxis: this.xAxis,
            })
        );

        // Add series
        this.appTimeSpentSeries = this.appTimeSpentChart.series.push(
            am5xy.LineSeries.new(this.appTimeSpentRoot, {
                name: 'Users app screen time',
                minBulletDistance: 10,
                xAxis: this.xAxis,
                yAxis: this.yAxis,
                valueYField: 'count',
                categoryXField: 'category',
                tooltip: am5.Tooltip.new(this.appTimeSpentRoot, {
                    pointerOrientation: 'horizontal',
                    labelText: 'Total hour: {valueY}',
                }),
            })
        );

        // Add legend
        this.appTimeSpentLegend = this.appTimeSpentChart.children.push(
            am5.Legend.new(this.appTimeSpentRoot, {
                centerY: am5.p50,
                y: am5.p50,
                centerX: am5.p0,
                x: am5.percent(80),
                layout: this.appTimeSpentRoot.verticalLayout,
                verticalScrollbar: am5.Scrollbar.new(this.appTimeSpentRoot, {
                    orientation: 'vertical',
                }),
            })
        );

        this.xAxis.data.setAll(this.appTimeSpentData);
        this.appTimeSpentSeries.data.setAll(this.appTimeSpentData);

        // Make stuff animate on load
        this.appTimeSpentSeries.appear(1000, 100);
        this.appTimeSpentChart.appear(1000, 100);
    }

    private processAppTimeSpentResponse(data: any, dataType: string): ModuleRecord[] {
        let appTimeSpentRecordList: ModuleRecord[] = [];
        if (dataType === this.DAILY) {
            data.forEach((element) => {
                const date = element.dateFrom.day + '\n' + element.dateFrom.formatted;
                let dataRecord: ModuleRecord = {
                    category: date,
                    count: this.returnHour(element.screenTimeCount),
                };
                appTimeSpentRecordList.push(dataRecord);
            });
            return appTimeSpentRecordList;
        } else if (dataType === this.WEEKLY) {
            data.forEach((element) => {
                const week = element.dateFrom.formatted + '-\n' + element.dateTo.formatted;
                let dataRecord: ModuleRecord = {
                    category: week,
                    count: this.returnHour(element.screenTimeCount),
                };
                appTimeSpentRecordList.push(dataRecord);
            });
            return appTimeSpentRecordList;
        } else if (dataType === this.MONTHLY) {
            data.forEach((element) => {
                let dataRecord: ModuleRecord = {
                    category: element.yearMonth.formatted,
                    count: this.returnHour(element.screenTimeCount),
                };
                appTimeSpentRecordList.push(dataRecord);
            });
            return appTimeSpentRecordList;
        }
    }

    returnHour(count:any){
        return Math.round((count/ 3600) * 100) / 100 ;
    }
}
