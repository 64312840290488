import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApplicationPermissionSelectedDirective } from './directives/application-permission-selected.directive';
import { ApplicationRoleSelectedDirective } from './directives/application-role-selected.directive';
import { InfiniteScrollAutoCompleteDirective } from './directives/infinite-scroll-auto-complete-driective';
import { OrganizationSelectedDirective } from './directives/organization-selected.directive';

@NgModule({
    declarations: [
        ApplicationRoleSelectedDirective,
        OrganizationSelectedDirective,
        ApplicationPermissionSelectedDirective,
        InfiniteScrollAutoCompleteDirective,
    ],
    imports: [CommonModule],
    exports: [
        ApplicationRoleSelectedDirective,
        OrganizationSelectedDirective,
        ApplicationPermissionSelectedDirective,
        InfiniteScrollAutoCompleteDirective,
    ],
})
export class DirectivesModule {}
