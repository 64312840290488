import { Component, Injectable, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDateRangePicker } from '@angular/material/datepicker';
import {
    MatDateRangeSelectionStrategy,
    DateRange,
    MAT_DATE_RANGE_SELECTION_STRATEGY,
} from '@angular/material/datepicker';
import { DAY_MODE_FORMATS } from '../../constant/datepicker-format.constant';
import { WeekFilterDate } from 'src/app/model/filter-dates.model';
@Injectable()
export class SevenDayRangeSelectionStrategy<D> implements MatDateRangeSelectionStrategy<D> {
    constructor(private _dateAdapter: DateAdapter<D>) {}

    selectionFinished(date: D | null): DateRange<D> {
        return this._createDayRange(date);
    }

    createPreview(activeDate: D | null): DateRange<D> {
        return this._createDayRange(activeDate);
    }

    private _createDayRange(date: D | null): DateRange<D> {
        if (date) {
            const start = this._dateAdapter.addCalendarDays(date, -6);
            const end = this._dateAdapter.addCalendarDays(date, 0);

            return new DateRange<D>(start, end);
        }

        return new DateRange<D>(null, null);
    }
}

@Component({
    selector: 'weekly-date-picker',
    templateUrl: './weekly-date-picker.component.html',
    providers: [
        {
            provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
            useClass: SevenDayRangeSelectionStrategy,
        },
        { provide: MAT_DATE_FORMATS, useValue: DAY_MODE_FORMATS },
    ],
})
export class WeeklyDatePickerComponent implements OnInit {

    @Output() onDateChanged = new EventEmitter<WeekFilterDate>();

    min: Date;
    max: Date;
    dateFromFormControl = new FormControl(new Date(new Date().setDate(new Date().getDate() - 7)));
    dateToFormControl = new FormControl(new Date(new Date().setDate(new Date().getDate() - 1)));

    constructor() {
        const currentYear = new Date().getFullYear();
        this.min = new Date(currentYear - 11, 12, 99);
        this.max = new Date(new Date().setDate(new Date().getDate() - 1));
    }

    ngOnInit(): void {
        this._subscribeDateFormControlValueChanges();
    }

    _openDatepickerOnClick(datepicker: MatDateRangePicker<WeeklyDatePickerComponent>) {
        if (!datepicker.opened) {
            datepicker.open();
        }
    }

    _subscribeDateFormControlValueChanges() {
        this.dateToFormControl.valueChanges.subscribe(() => {
            let weekRange :WeekFilterDate = {
                dateForm : this.dateFromFormControl.value,
                dateTo: this.dateToFormControl.value
            }
            this.onDateChanged.emit(weekRange);
        });
    }
}
