import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmploymentDialogDetailComponent } from './employment-dialog-detail/employment-dialog-detail.component';
import { EmploymentDialogNewOrEditComponent } from './employment-dialog-new-or-edit/employment-dialog-new-or-edit.component';
import { EmploymentTypeListingComponent } from './employment-type-listing/employment-type-listing.component';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const routes: Routes = [
  {
      path: '',
      component: EmploymentTypeListingComponent,
      // canActivate: [
      //     AuthenticatedGuard
      // ]
  },
];

@NgModule({
  declarations: [
    EmploymentDialogDetailComponent,
    EmploymentDialogNewOrEditComponent,
    EmploymentTypeListingComponent
  ],
  imports: [CommonModule, SharedModule, RouterModule.forChild(routes), FormsModule, ReactiveFormsModule],

})
export class EmploymentTypeModule { }
