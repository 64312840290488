import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { DASHBOARD_DATE_ENUM } from 'src/app/components/shared/constant/datepicker-format.constant';
import { Subject } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';
import { FilterDates, WeekFilterDate } from 'src/app/model/filter-dates.model';

@Component({
    selector: 'app-dashboard-action',
    templateUrl: './dashboard-action.component.html',
})
export class DashboardActionComponent implements OnInit {
    @Output() onDatesChanged = new EventEmitter<FilterDates>();

    dateTypeFormControl = new FormControl('DAILY');
    filterDates: FilterDates = new FilterDates();

    dateTypes: any;
    mode = 'DAILY';
    today = new Date(new Date().setDate(new Date().getDate() - 1));

    constructor(private dashboardService: DashboardService) {}

    ngOnInit(): void {
        this.dashboardService.getDateTypeEnum().subscribe({
            next: (value) => {
                this.dateTypes = value.body;
            },
        });
    }

    toggleDateType(data) {
        this.mode = data.value;
        if (data.value === 'DAILY') {
            this.filterDates.dateType = data.value;
            this.filterDates.dateFrom = new Date(new Date().setDate(new Date().getDate() - 1));
            this.onDatesChanged.emit(this.filterDates);
        } 
        else if (data.value === 'WEEKLY') {
            this.filterDates.dateType = data.value;
            this.filterDates.dateFrom = new Date(new Date().setDate(new Date().getDate() - 7));
            this.filterDates.dateTo = new Date(new Date().setDate(new Date().getDate() - 1));
            this.onDatesChanged.emit(this.filterDates);
        } else if (data.value === 'MONTHLY') {
            this.filterDates.dateType = data.value;
            this.filterDates.yearMonth = new Date();
            this.onDatesChanged.emit(this.filterDates);
        }
    }

    dailyDateChanged($event: Date) {
        this.filterDates.dateFrom = $event;
        this.onDatesChanged.emit(this.filterDates);
    }

    monthlyDateChanged($event: Date) {
        this.filterDates.yearMonth = $event;
        this.onDatesChanged.emit(this.filterDates);
    }

    weeklyDateChanged($event: WeekFilterDate) {
        this.filterDates.dateFrom = $event.dateForm;
        this.filterDates.dateTo = $event.dateTo;
        this.onDatesChanged.emit(this.filterDates);
    }
}
